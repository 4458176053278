import {
    SLIDE_DEFAULT_DURATION,
    TEMPLATE_SLIDE_DEFAULT_DURATION,
    SLIDE_PLACEHOLDER_URL_SHORT,
    SLIDE_TRANSITION_DEFAULT_DURATION,
    SLIDE_TYPE,
} from '../constants/slides';

export const createActiveSellerSlide = (projectId, slides) => {
    return {
        slideType: SLIDE_TYPE.AssetBased,
        projectId,
        transitionName: 'fade',
        transitionDuration: SLIDE_TRANSITION_DEFAULT_DURATION,
        index: Math.max(slides.length, 0),
        imageUrl: SLIDE_PLACEHOLDER_URL_SHORT,
        duration: SLIDE_DEFAULT_DURATION,
        logo: null,
    };
};

export const createStorySlide = (projectId, slides, templateId) => {
    return {
        slideType: SLIDE_TYPE.TemplateBased,
        projectId,
        templateId,
        index: Math.max(slides.length, 0),
        duration: TEMPLATE_SLIDE_DEFAULT_DURATION,
    };
};
