import React, { useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { bool, func, oneOf, object, shape, string, number, oneOfType } from 'prop-types';
import { compose, withState } from 'recompose';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import IconButton from '../IconButton';
import PickerDialog from './PickerDialog';
import { DEFAULT_CONVERTER, converters } from './transformers';
import RefContext from '../../utils/refs-context';
import { Typography } from '@material-ui/core';
import { EyedropperIcon } from '../../constants/icons';
import classes from './ColorPicker.module.scss';

const ColorPicker = ({
    onChange,
    onChangeComplete,
    convert = 'hex',
    // Text field
    name,
    showEyedropper,
    toggleEyeDropper,
    hintText,
    placeholder,
    className,
    label,
    TextFieldProps,
    value,
    // State
    showPicker,
    setShowPicker,
    internalValue,
    transparency,
    transparencyPosition,
    setValue,
    disabled,
    variant = 'outlined',
    defaultValue,
    ...custom
}) => {
    const colorPickerRef = useRef(null);
    const inputPickerRef = useRef(null);
    const { refs, setRefs } = useContext(RefContext);
    const { eyedropperOn = false } = refs;

    useEffect(() => {
        if (colorPickerRef && colorPickerRef.current) {
            refs[name] = colorPickerRef.current;
            refs[`${name}_onChange`] = onChangeComplete;
            setRefs(refs);
        }
    }, [colorPickerRef, setRefs, refs, name, onChangeComplete]);

    const handleToggleEyeDropper = () => {
        toggleEyeDropper(name);
    };

    return (
        <div className={classes.colorPicker}>
            <FormControl
                variant={variant}
                className={clsx(classes.colorFieldForm, className)}
            >
                <TextField
                    label={label}
                    variant={variant}
                    inputRef={colorPickerRef}
                    ref={inputPickerRef}
                    name={name}
                    value={value === undefined ? internalValue : value}
                    placeholder={hintText || placeholder}
                    onKeyPress={ev => {
                        if (ev.key === 'Enter') {
                            ev.preventDefault();
                            setShowPicker(false);
                            onChangeComplete(value);
                        }
                    }}
                    onClick={() => {
                        eyedropperOn && toggleEyeDropper(name);
                        setShowPicker(true);
                    }}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    InputProps={{ autoComplete: 'off' }}
                    className={classes.colorPickerInput}
                    {...TextFieldProps}
                    {...custom}
                    disabled={disabled}
                />
                {showPicker && !disabled && (
                    <PickerDialog
                        value={value === undefined ? internalValue : value}
                        onClick={ev => {
                            // Do not close it clicked inside input
                            if (ev.target?.name === name) {
                                return;
                            }
                            setShowPicker(false);
                        }}
                        onChangeComplete={c => {
                            const newValue = {
                                value: converters[convert](c),
                                transparency: Math.ceil(c.rgb.a * 100),
                            };
                            setValue(newValue);
                            onChangeComplete(newValue);
                        }}
                        onChange={c => {
                            const newValue = {
                                value: converters[convert](c),
                                transparency: Math.ceil(c.rgb.a * 100),
                            };

                            onChange(newValue);
                            setValue(newValue);
                        }}
                    />
                )}
            </FormControl>
            <div
                className={classes.colorBox}
                style={{
                    backgroundColor: value,
                }}
            />
            {showEyedropper && (
                <IconButton
                    aria-label="eyedropper"
                    className={clsx(
                        classes.eyedropperIcon,
                        eyedropperOn && classes.disabled,
                    )}
                    onClick={handleToggleEyeDropper}
                    disableFocusRipple
                    disableRipple
                    disabled={eyedropperOn || disabled}
                >
                    <EyedropperIcon fontSize="inherit" />
                </IconButton>
            )}
            <Typography
                variant="body1"
                className={clsx(classes.transparency, classes[transparencyPosition])}
            >
                {transparency}%
            </Typography>
        </div>
    );
};

ColorPicker.propTypes = {
    value: string,
    onChange: func,
    convert: oneOf(Object.keys(converters)),
    defaultValue: object,
    name: string,
    hintText: string,
    placeholder: string,
    label: string,
    floatingLabelText: string,
    TextFieldProps: shape(TextField.propTypes),
    showPicker: bool,
    disabled: bool,
    transparency: number,
    transparencyPosition: oneOf(['left', 'center', 'right']),
    setShowPicker: func,
    internalValue: oneOfType([object, string]),
    setValue: func,
    setShowColorPicker: func,
    showEyedropper: bool,
    handleToggleEyeDropper: func,
};

ColorPicker.defaultProps = {
    convert: DEFAULT_CONVERTER,
};

const makeColorPicker = compose(
    withState('showPicker', 'setShowPicker', false),
    withState('internalValue', 'setValue', ({ defaultValue }) =>
        typeof defaultValue === 'string' || defaultValue == null
            ? defaultValue
            : defaultValue.value,
    ),
);

const MakedColorPicker = makeColorPicker(ColorPicker);

const ColorPickerField = ({
    input: { value, onChange, ...restInput },
    meta: { touched, error },
    ...restProps
}) => (
    <MakedColorPicker
        value={value}
        onChange={onChange}
        errorText={touched && error}
        {...restInput}
        {...restProps}
    />
);

ColorPickerField.propTypes = {
    input: object,
    meta: object,
};

export default MakedColorPicker;

export { ColorPickerField };
