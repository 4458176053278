import React, { useEffect } from 'react';
import { array, bool, func } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import clsx from 'clsx';
import Btn from '../../components/Button';
import { USER_NOTIFICATION_TYPES } from '../../constants/notifications';
import { dateShortFormat } from '../../utils/helpers';
import classes from './NotificationsList.module.scss';
import { sleep } from '../../utils/common';
import { locale } from '../../constants/locales';
import User from '../../types/User';

function NotificationsList(props) {
    const {
        list,
        location,
        unreadNotifications = [],
        processVideo,
        markAllRead,
        getUserNotifications,
        errorNotification,
        popupList,
        authUser,
    } = props;

    useEffect(() => {
        // We're marking notifications READ on popup open
        const makeAllRead = async () => {
            const userNotificationsId = unreadNotifications.map(i => i.id);
            if (Object.keys(userNotificationsId).length) {
                markAllRead(userNotificationsId);
                await sleep(5000);
                getUserNotifications();
            }
        };
        const makeAllReadHistory = async () => {
            if (unreadNotifications && Object.keys(unreadNotifications).length) {
                if (
                    location &&
                    location.pathname.includes('/user-notifications-history')
                ) {
                    const userNotificationsId = unreadNotifications.map(i => i.id);
                    try {
                        await markAllRead(userNotificationsId);
                        await sleep(5000);
                        getUserNotifications({}, 'both');
                    } catch (e) {
                        console.info(e);
                    }
                }
            }
        };
        try {
            if (popupList) {
                makeAllRead();
            } else {
                makeAllReadHistory();
            }
        } catch (e) {
            console.error(e);
        }
    }, [getUserNotifications, location, markAllRead, popupList, unreadNotifications]);

    // update list only on close to hide badge
    useEffect(() => {
        return () => {
            if (popupList) {
                getUserNotifications();
            }
        };
    }, [getUserNotifications, popupList]);

    function restart({ params }) {
        try {
            const { type, templateId, projectId } = params;
            processVideo(templateId || projectId, type);
        } catch (e) {
            console.error(e);
        }
    }

    function handleDownload(videoUrl) {
        if (videoUrl === '') {
            errorNotification({ message: locale.FILE_DELETED });
        }
    }

    return (
        <div
            className={clsx(
                classes.notifications,
                popupList && classes.notificationPopup,
            )}
        >
            {list.length ? (
                <List disablePadding component="ul" style={{ ul: { padding: '0' } }}>
                    {list.map(notification => (
                        <li
                            key={notification.id}
                            className={classes.notificationsListItem}
                        >
                            <div
                                className={clsx(
                                    classes.notificationsDate,
                                    !notification.readBy.includes(authUser?.id) &&
                                        classes.unRead,
                                    notification.type === USER_NOTIFICATION_TYPES.Error &&
                                        classes.error,
                                )}
                            >
                                {dateShortFormat(notification.createdAt)}
                            </div>
                            <Typography
                                className={clsx(
                                    classes.notificationsStatus,
                                    notification.type === USER_NOTIFICATION_TYPES.Error &&
                                        classes.errorText,
                                )}
                                variant="h6"
                            >
                                {notification.type === USER_NOTIFICATION_TYPES.Error
                                    ? 'Rendering is failed'
                                    : 'Rendering is complete'}
                            </Typography>
                            <Typography>{notification.message}</Typography>
                            <Typography variant="body2">
                                ({notification?.companyName}
                                {notification?.projectType && (
                                    <span>, {notification?.projectType}</span>
                                )}
                                )
                            </Typography>
                            <div className={classes.notificationsBtn}>
                                {notification.type === USER_NOTIFICATION_TYPES.Error ? (
                                    <Btn onClick={() => restart(notification)}>
                                        Restart
                                    </Btn>
                                ) : (
                                    <Btn
                                        onClick={() =>
                                            handleDownload(notification.params.videoUrl)
                                        }
                                        to={
                                            notification.params &&
                                            notification.params.videoUrl
                                        }
                                        component="button"
                                    >
                                        Download
                                    </Btn>
                                )}
                            </div>
                        </li>
                    ))}
                </List>
            ) : (
                <Typography align="center" className="noData">
                    No notifications. You will get one when
                    <br /> create a story/active seller.
                </Typography>
            )}
        </div>
    );
}

NotificationsList.propTypes = {
    list: array,
    authUser: User,
    unreadNotifications: array,
    processVideo: func,
    markAllRead: func,
    getUserNotifications: func,
    errorNotification: func,
    popupList: bool,
};

export default NotificationsList;
