import React from 'react';
import { bool } from 'prop-types';
import clsx from 'clsx';
import { Form } from 'formik';
import Typography from '@material-ui/core/Typography';
import FormikField from '../FormikField';
import { locale } from '../../constants/locales';
import { ROLES_OPTIONS } from '../../constants/roles';
import { COMPANIES_OPTIONS } from '../../constants/companies';
import classes from '../../pages/UserProfile/UserProfile.module.scss';
import { CHECKBOX_FIELD } from '../../constants/variables';

function UserForm({ values, isAdmin, selfUpdate = false, handleSubmit }) {
    const onKeyPress = async ev => {
        if (ev && ev.key === 'Enter') {
            ev.preventDefault();
            await handleSubmit();
        }
    };

    return (
        Object.keys(values).length && (
            <Form onKeyPress={onKeyPress}>
                <div className={classes.fieldBox}>
                    <FormikField
                        label={locale.User.FIRST_NAME}
                        type="text"
                        name="firstName"
                        className={classes.formControl}
                        fullWidth
                    />
                    <FormikField
                        label={locale.User.LAST_NAME}
                        type="text"
                        name="lastName"
                        className={classes.formControl}
                        fullWidth
                    />
                    <FormikField
                        label={locale.User.EMAIL_ADDRESS}
                        type="text"
                        name="email"
                        className={classes.formControl}
                        fullWidth
                        disabled={!isAdmin}
                    />
                </div>
                <div className={classes.fieldBox}>
                    <Typography variant="body2" align="left">
                        {locale.User.ROLE}
                    </Typography>
                    <div className={clsx('flexColumn', 'alignStart')}>
                        {Object.values(ROLES_OPTIONS).map(role => (
                            <FormikField
                                value={role.value}
                                checked={values.roles.includes(role.value)}
                                key={role.value}
                                label={role.label}
                                name="roles"
                                type={CHECKBOX_FIELD}
                                disabled={!isAdmin || (isAdmin && selfUpdate)}
                            />
                        ))}
                    </div>
                </div>
                <div className={classes.fieldBox}>
                    <Typography variant="body2" align="left">
                        {locale.User.COMPANY}
                    </Typography>
                    <div className={clsx('flexColumn', 'alignStart')}>
                        {Object.values(COMPANIES_OPTIONS).map(company => (
                            <FormikField
                                value={company.value}
                                checked={values.companyNames.includes(company.value)}
                                key={company.value}
                                label={company.label}
                                name="companyNames"
                                type={CHECKBOX_FIELD}
                                disabled={!isAdmin}
                            />
                        ))}
                    </div>
                </div>
            </Form>
        )
    );
}

UserForm.propTypes = {
    isAdmin: bool,
};

export default UserForm;
