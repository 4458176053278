import { SAVE_USERS_LIST } from '../actions/types';

const initialState = {
    list: [],
    loading: false,
};

export default (state = initialState, { type, users }) => {
    switch (type) {
        case SAVE_USERS_LIST:
            return {
                ...state,
                ...users,
                loading: true,
            };
        default:
            return state;
    }
};
