import { SHOW_DURATION_NOTIFICATION, CLEAR_DURATION_NOTIFICATION } from './types';

const showDurationNotification = () => ({
    type: SHOW_DURATION_NOTIFICATION,
});

const clearNotification = () => ({
    type: CLEAR_DURATION_NOTIFICATION,
});

export const showDurationNotifications = () => dispatch => {
    dispatch(showDurationNotification());
};

export const clearDurationNotifications = () => async dispatch => {
    dispatch(clearNotification());
};
