import { connect } from 'react-redux';
import NotificationsList from './NotificationsList';
import { processVideo } from '../../actions/slides';
import { errorNotification } from '../../actions/notifications';
import { markAllRead, getUserNotifications } from '../../actions/user-notifications';

const mapStateToProps = state => ({
    authUser: state?.auth?.user,
});

const mapDispatchToProps = {
    processVideo,
    markAllRead,
    getUserNotifications,
    errorNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
