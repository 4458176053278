import { PROCESS_VIDEO, STOP_PROCESS_VIDEO } from './types';
import API from '../utils/api';
import { errorNotification } from './notifications';
import { ROLES } from '../constants/roles';
import { onlyRole } from '../utils/roles';

export const onProcessing = data => async dispatch => {
    dispatch({
        type: PROCESS_VIDEO,
        data,
    });
};

export const stopProcessing = () => async dispatch => {
    dispatch({
        type: STOP_PROCESS_VIDEO,
    });
};

export const processing = data => async dispatch => {
    dispatch({
        type: PROCESS_VIDEO,
        data,
    });
};

export const deleteFromQueue = data => async dispatch => {
    const response = await API.deleteFromQueue(data).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const getQueueProgress = () => async (dispatch, getState) => {
    try {
        const { auth } = getState();
        if (auth?.user?.roles && !onlyRole(auth.user.roles, ROLES.DESIGNER)) {
            const response = await API.getProgress();
            if (response) {
                dispatch(processing(response));

                return response;
            }
        }

        return null;
    } catch (e) {
        return dispatch(
            errorNotification({
                message: e.message,
                data: { preventDuplicate: true },
            }),
        );
    }
};

export const socketUnsubscribe = () => async () => {
    try {
        const socketId = localStorage.getItem('socketId');
        await API.socketUnsubscribe(socketId);
    } catch (e) {
        console.info('Socket error: ', e && e.message);
    }
};
