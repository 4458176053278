import * as auth from './auth';
import * as projects from './projects';
import * as slides from './slides';
import * as notifications from './user-notifications';
import * as progress from './progress';
import * as users from './users';
import * as templates from './templates';
import * as categories from './categories';
import * as system from './system';

export default {
    ...auth,
    ...projects,
    ...slides,
    ...notifications,
    ...progress,
    ...users,
    ...templates,
    ...categories,
    ...system,
};
