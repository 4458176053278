import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { bool, string } from 'prop-types';
import classes from './Preloader.module.scss';

export const Preloader = ({ loading, color = 'secondary' }) =>
    loading && (
        <div className={classes.Preloader}>
            <LinearProgress
                classes={{
                    root: classes.preloaderRoot,
                    barColorPrimary: classes.colorPrimary,
                    barColorSecondary: classes.colorSecondary,
                }}
                variant="indeterminate"
                color={color}
            />
        </div>
    );

Preloader.propTypes = {
    loading: bool.isRequired,
    color: string,
};

export default Preloader;
