import { LOGIN, LOGOUT } from './types';
import API from '../utils/api';
import { go } from './navigation';
import { errorNotification, infoNotification, clearNotifications } from './notifications';
import { removeUserNotifications, getUserNotifications } from './user-notifications';
import { socketUnsubscribe } from './processingProgress';
import {
    getVideoProgress,
    setupSocketConnection,
    subscribeOnMessages,
} from '../utils/sockets';
import { getBaseRoute, removeUserLocalData } from '../utils/common';
import { locale } from '../constants/locales';
import persistStore from '../store/configureStore';
import { loadFonts } from '../utils/canvas';
import { UNAUTH_PATHS } from '../constants/variables';
import { unlockAllItems } from './lockedItems';

const { persistor } = persistStore();

export const loginSuccess = user => ({
    type: LOGIN,
    user,
});

export const logout = () => ({
    type: LOGOUT,
});

const handleLogin = async (response, dispatch, getState) => {
    const { user, token } = response;
    if (response.token) {
        localStorage.setItem('access_token', token.accessToken);
        localStorage.setItem('user_id', user.id);
        dispatch(loginSuccess(user));
        dispatch(infoNotification(locale.LOGIN_SUCCESS));
        if (user) {
            if (Object.keys(user).length) {
                for await (const [index, companyName] of user.companyNames.entries()) {
                    await loadFonts(companyName, index);
                }
            }
        }
        // Connect to sockets on successful auth
        subscribeOnMessages(...setupSocketConnection(dispatch, getState));
        const baseRoute = getBaseRoute(user.roles);
        dispatch(go(baseRoute));
        dispatch(getUserNotifications());
    }
};

export const loginUser = values => async (dispatch, getState) => {
    const response = await API.login(values).catch(error => {
        dispatch(errorNotification(error));
    });
    // Catch user with reset password and redirect him to new password setup screen
    if (response && response.statusCode === 204) {
        const data = {
            actionType: locale.NotificationActionTypes.SET_PASSWORD,
            url: UNAUTH_PATHS.setPassword,
        };
        dispatch(infoNotification(locale.SET_NEW_PASSWORD, null, data));

        return null;
    }

    if (response && response.user) {
        return handleLogin(response, dispatch, getState);
    }

    dispatch(errorNotification(response));

    return null;
};

export const setNewPassword = values => async dispatch => {
    const response = await API.setPassword(values).catch(error => {
        dispatch(errorNotification(error));
    });

    if (response && response.user) {
        return handleLogin(response, dispatch);
    }
    dispatch(errorNotification(response));

    return null;
};

export const logoutUser = () => async (dispatch, getState) => {
    const { auth } = getState();
    if (auth?.user?.roles) {
        await dispatch(unlockAllItems(auth.user.roles));
    }
    if (persistor) {
        await persistor.purge();
        await persistor.persist();
    }
    // Clear previous notification
    await dispatch(clearNotifications());
    await dispatch(removeUserNotifications());
    console.info('log out user');
    await dispatch(socketUnsubscribe());
    removeUserLocalData();
    console.info('User data deleted...');
    dispatch(logout());
    await dispatch(go(UNAUTH_PATHS.login));
    const onFocusHandler = () => getVideoProgress(dispatch);
    window.removeEventListener('focus', onFocusHandler);
    dispatch(infoNotification(locale.LOGOUT_SUCCESS));
};
