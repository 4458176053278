import { connect } from 'react-redux';
import UserNotificationsList from './UserNotificationsList';
import { getUserNotifications, markAllRead } from '../../actions/user-notifications';
import { popupClose } from '../../actions/popup';

const mapStateToProps = state => ({
    userNotifications: state?.userNotifications?.list,
    authUser: state?.auth?.user,
});

const mapDispatchToProps = {
    markAllRead,
    getUserNotifications,
    popupClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserNotificationsList);
