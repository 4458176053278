import { CLEAR_TEMPLATES, SAVE_TEMPLATES } from '../actions/types';

const initialState = {
    list: [],
    loading: false,
};

export default (state = initialState, { type, templates }) => {
    switch (type) {
        case SAVE_TEMPLATES:
            return {
                ...state,
                ...templates,
                loading: true,
            };
        case CLEAR_TEMPLATES:
            return initialState;
        default:
            return state;
    }
};
