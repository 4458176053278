import { connect } from 'react-redux';
import Actions from './Actions';
import { uploadMedia } from '../../../actions/templates';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    uploadMedia,
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
