import { connect } from 'react-redux';
import TransitionsPanel from './TransitionsPanel';
import { saveCanvasToHistory } from '../../../actions/canvas';

const mapStateToProps = state => ({
    companyName: state && state.template.companyName,
});

const mapDispatchToProps = {
    saveCanvasToHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransitionsPanel);
