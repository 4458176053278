import { SAVE_USER_NOTIFICATIONS, CLEAR_USER_NOTIFICATIONS } from '../actions/types';

const initialState = {
    list: [],
};

export default (state = initialState, { type, notifications }) => {
    switch (type) {
        case SAVE_USER_NOTIFICATIONS:
            return {
                ...state,
                list: notifications.items,
                itemCount: notifications.meta.itemCount,
                totalItems: notifications.meta.totalItems,
                pageCount: notifications.meta.currentPage,
            };
        case CLEAR_USER_NOTIFICATIONS:
            return initialState;
        default:
            return state;
    }
};
