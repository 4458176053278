import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import DeleteIcon from '@material-ui/icons/Close';
import ChevronRight from '@material-ui/icons/ChevronRight';
import IconButton from '../../components/IconButton';
import { locale } from '../../constants/locales';
import classes from './DurationNotifications.module.scss';

export default function DurationNotifications({ durationNotificationIsOn, className }) {
    const [collapsed, setCollapsed] = useState(false);

    const handleCollapse = () => {
        setCollapsed(!collapsed);
    };

    return (
        <Fade
            in={durationNotificationIsOn}
            timeout={300}
            style={{ bottom: 0, width: '100%', position: 'absolute', zIndex: 9 }}
        >
            <div>
                <div
                    className={clsx(
                        classes.durationNotifications,
                        className,
                        collapsed && classes.collapsed,
                    )}
                >
                    <WarningRoundedIcon className={classes.notificationIcon} />
                    <div className={classes.text}>
                        {locale.Messages.VIDEO_DURATION_NOTIFICATION}
                    </div>
                    <div>
                        <IconButton aria-label="remove" onClick={handleCollapse}>
                            {!collapsed && (
                                <DeleteIcon
                                    className={classes.btn}
                                    fontSize="small"
                                    color="primary"
                                />
                            )}
                            {collapsed && (
                                <ChevronRight
                                    className={classes.btn}
                                    fontSize="small"
                                    color="primary"
                                />
                            )}
                        </IconButton>
                    </div>
                </div>
            </div>
        </Fade>
    );
}

DurationNotifications.propTypes = {
    durationNotificationIsOn: bool,
    className: string,
};
