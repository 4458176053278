import {
    ANIMATION_TYPE,
    TRANSITIONS_ANIMATION_EASING,
    APPEARANCE_TRANSITIONS_TYPE,
    DISAPPEARANCE_TRANSITIONS_TYPE,
} from '../constants/transitions';
import { convertMStoS, convertStoMS } from './helpers';
import { ANIMATION_DELAY, TRANSITION_DURATION } from '../constants/sizes';

export const getAppearanceAnimationsParams = (
    values,
    canvas,
    activeObject,
    width,
    height,
) => {
    activeObject.setCoords();
    const { top, left, opacity, aCoords } = activeObject;
    const canvasScaledWidth = canvas.width / canvas.viewportTransform[0];
    const canvasScaledHeight = canvas.height / canvas.viewportTransform[0];
    const {
        typeTransition,
        options: { duration, easing, delay },
    } = values;
    switch (typeTransition) {
        case APPEARANCE_TRANSITIONS_TYPE[0].value: {
            return {
                params: {
                    opacity,
                    top,
                    left,
                    animation: {
                        typeTransition,
                        property: 'opacity',
                        value: 1,
                        group: 'none',
                        options: {
                            from: 1,
                            duration: convertStoMS(1),
                            delay: convertStoMS(0),
                            easing: TRANSITIONS_ANIMATION_EASING[0].value,
                        },
                    },
                },
                value: 1,
            };
        }
        case APPEARANCE_TRANSITIONS_TYPE[1].value: {
            return {
                params: {
                    top,
                    left,
                    opacity: 1,
                    animation: {
                        typeTransition,
                        property: 'visible',
                        value: 1,
                        group: 'opacity',
                        options: {
                            from: 0,
                            duration: convertStoMS(0),
                            delay: convertStoMS(delay),
                        },
                    },
                },
                value: 1,
            };
        }
        case APPEARANCE_TRANSITIONS_TYPE[2].value: {
            return {
                params: {
                    top,
                    left,
                    opacity: 0,
                    animation: {
                        typeTransition,
                        property: 'opacity',
                        value: 1,
                        group: 'opacity',
                        options: {
                            from: 0,
                            duration: convertStoMS(duration),
                            delay: convertStoMS(delay),
                            easing,
                        },
                    },
                },
                value: 1,
            };
        }
        case APPEARANCE_TRANSITIONS_TYPE[3].value: {
            const [farPoint] = Object.values(aCoords).sort((a, b) => b.y - a.y);
            const shiftTop = Math.round(farPoint.y + 2 - top) * -1;
            return {
                params: {
                    opacity,
                    left,
                    top: shiftTop,
                    animation: {
                        typeTransition,
                        property: 'top',
                        value: activeObject.top,
                        group: 'direction',
                        options: {
                            from: shiftTop,
                            duration: convertStoMS(duration),
                            delay: convertStoMS(delay),
                            easing,
                        },
                    },
                },
                value: activeObject.top,
            };
        }
        case APPEARANCE_TRANSITIONS_TYPE[4].value: {
            const [farPoint] = Object.values(aCoords).sort((a, b) => a.y - b.y);
            const shiftBottom =
                farPoint.y + (canvasScaledHeight - farPoint.y) + (top - farPoint.y);
            return {
                params: {
                    opacity,
                    left,
                    top: shiftBottom,
                    animation: {
                        typeTransition,
                        property: 'top',
                        group: 'direction',
                        value: activeObject.top,
                        options: {
                            from: shiftBottom,
                            duration: convertStoMS(duration),
                            delay: convertStoMS(delay),
                            easing,
                        },
                    },
                },
                value: activeObject.top,
            };
        }
        case APPEARANCE_TRANSITIONS_TYPE[5].value: {
            const [farPoint] = Object.values(aCoords).sort((a, b) => b.x - a.x);
            const shiftRight = Math.round(farPoint.x + 2 - left) * -1;
            return {
                params: {
                    opacity,
                    top,
                    left: shiftRight,
                    animation: {
                        typeTransition,
                        property: 'left',
                        group: 'direction',
                        value: activeObject.left,
                        options: {
                            from: shiftRight,
                            duration: convertStoMS(duration),
                            delay: convertStoMS(delay),
                            easing,
                        },
                    },
                },
                value: activeObject.left,
            };
        }
        case APPEARANCE_TRANSITIONS_TYPE[6].value: {
            const [farPoint] = Object.values(aCoords).sort((a, b) => a.x - b.x);
            const shiftLeft =
                farPoint.x + (canvasScaledWidth - farPoint.x) + (left - farPoint.x);
            return {
                params: {
                    opacity,
                    top,
                    left: shiftLeft,
                    animation: {
                        typeTransition,
                        property: 'left',
                        group: 'direction',
                        value: activeObject.left,
                        options: {
                            from: shiftLeft,
                            duration: convertStoMS(duration),
                            delay: convertStoMS(delay),
                            easing,
                        },
                    },
                },
                value: activeObject.left,
            };
        }
        default: {
            return null;
        }
    }
};

export const getDisappearanceAnimationsParams = (
    values,
    canvas,
    activeObject,
    width,
    height,
) => {
    activeObject.setCoords();
    const { top, left, opacity, aCoords } = activeObject;
    const canvasScaledWidth = canvas.width / canvas.viewportTransform[0];
    const canvasScaledHeight = canvas.height / canvas.viewportTransform[0];
    const {
        typeTransition,
        options: { duration, easing, delay },
    } = values;
    switch (typeTransition) {
        case DISAPPEARANCE_TRANSITIONS_TYPE[0].value: {
            return {
                params: {
                    opacity,
                    top,
                    left,
                    animation: {
                        typeTransition,
                        property: 'opacity',
                        value: 1,
                        group: 'none',
                        options: {
                            from: 1,
                            duration: convertStoMS(1),
                            delay: convertStoMS(0),
                            easing: TRANSITIONS_ANIMATION_EASING[0].value,
                        },
                    },
                },
                value: 1,
            };
        }
        case DISAPPEARANCE_TRANSITIONS_TYPE[1].value: {
            return {
                params: {
                    top,
                    left,
                    opacity: 1,
                    animation: {
                        typeTransition,
                        property: 'visible',
                        value: 0,
                        group: 'opacity',
                        options: {
                            from: 1,
                            duration: convertStoMS(0),
                            delay: convertStoMS(delay),
                        },
                    },
                },
                value: 1, // current static opacity
            };
        }
        case DISAPPEARANCE_TRANSITIONS_TYPE[2].value: {
            return {
                params: {
                    top,
                    left,
                    opacity: 1,
                    animation: {
                        typeTransition,
                        property: 'opacity',
                        value: 0,
                        group: 'opacity',
                        options: {
                            from: 1,
                            duration: convertStoMS(duration),
                            delay: convertStoMS(delay),
                            easing,
                        },
                    },
                },
                value: 1, // current static opacity
            };
        }
        case DISAPPEARANCE_TRANSITIONS_TYPE[3].value: {
            const [farPoint] = Object.values(aCoords).sort((a, b) => b.y - a.y);
            const shiftTop = Math.round(farPoint.y + 2 - top) * -1;
            return {
                params: {
                    opacity,
                    left,
                    top: activeObject.top,
                    animation: {
                        typeTransition,
                        property: 'top',
                        value: shiftTop,
                        group: 'direction',
                        options: {
                            from: activeObject.top,
                            duration: convertStoMS(duration),
                            delay: convertStoMS(delay),
                            easing,
                        },
                    },
                },
                value: activeObject.top,
            };
        }
        case DISAPPEARANCE_TRANSITIONS_TYPE[4].value: {
            const [farPoint] = Object.values(aCoords).sort((a, b) => a.y - b.y);
            const shiftBottom =
                farPoint.y + (canvasScaledHeight - farPoint.y) + (top - farPoint.y);
            return {
                params: {
                    opacity,
                    left,
                    top: activeObject.top,
                    animation: {
                        typeTransition,
                        property: 'top',
                        group: 'direction',
                        value: shiftBottom,
                        options: {
                            from: activeObject.top,
                            duration: convertStoMS(duration),
                            delay: convertStoMS(delay),
                            easing,
                        },
                    },
                },
                value: activeObject.top,
            };
        }
        case DISAPPEARANCE_TRANSITIONS_TYPE[5].value: {
            const [farPoint] = Object.values(aCoords).sort((a, b) => b.x - a.x);
            const shiftRight = Math.round(farPoint.x + 2 - left) * -1;
            return {
                params: {
                    opacity,
                    top,
                    left: activeObject.left,
                    animation: {
                        typeTransition,
                        property: 'left',
                        group: 'direction',
                        value: shiftRight,
                        options: {
                            from: activeObject.left,
                            duration: convertStoMS(duration),
                            delay: convertStoMS(delay),
                            easing,
                        },
                    },
                },
                value: activeObject.left,
            };
        }
        case DISAPPEARANCE_TRANSITIONS_TYPE[6].value: {
            const [farPoint] = Object.values(aCoords).sort((a, b) => a.x - b.x);
            const shiftLeft =
                farPoint.x + (canvasScaledWidth - farPoint.x) + (left - farPoint.x);
            return {
                params: {
                    opacity,
                    top,
                    left: activeObject.left,
                    animation: {
                        typeTransition,
                        property: 'left',
                        group: 'direction',
                        value: shiftLeft,
                        options: {
                            from: activeObject.left,
                            duration: convertStoMS(duration),
                            delay: convertStoMS(delay),
                            easing,
                        },
                    },
                },
                value: activeObject.left,
            };
        }
        default: {
            return null;
        }
    }
};

export const getAnimatedProp = value => {
    const transMap = {
        left: 'left',
        right: 'left',
        top: 'top',
        bottom: 'top',
        opacity: 'opacity',
        appear: 'visible',
        none: 'none',
    };

    return transMap[value];
};

export const getAppearanceAnimations = animation =>
    animation && animation[ANIMATION_TYPE.APPEARANCE];

export const isAppearanceAnimation = animation => {
    const appearanceAnimations = getAppearanceAnimations(animation);
    return !!(Array.isArray(appearanceAnimations) && appearanceAnimations.length);
};

export const getDisappearanceAnimations = animation =>
    animation && animation[ANIMATION_TYPE.DISAPPEARANCE];

export const isDisappearanceAnimation = animation => {
    const disappearanceAnimations = getDisappearanceAnimations(animation);
    return !!(Array.isArray(disappearanceAnimations) && disappearanceAnimations.length);
};

export function convertAnimations(animations) {
    if (animations) {
        return animations.map(animation => ({
            value: animation.value,
            group: animation.group,
            property: animation.property,
            typeTransition:
                animation.typeTransition ?? APPEARANCE_TRANSITIONS_TYPE[0].value,
            options: {
                duration: animation.options.duration
                    ? convertMStoS(animation.options.duration)
                    : TRANSITION_DURATION,
                delay: animation.options.delay
                    ? convertMStoS(animation.options.delay)
                    : ANIMATION_DELAY,
                easing: animation.options.easing ?? TRANSITIONS_ANIMATION_EASING[0].value,
                from: animation.options.from,
            },
        }));
    }
    return null;
}
