import API from '../utils/api';
import {
    errorNotification,
    infoNotification,
    warningNotification,
} from './notifications';
import { hideLoader, showLoader } from './loading';
import { go } from './navigation';
import { locale } from '../constants/locales';
import { CLEAR_TEMPLATE, SAVE_TEMPLATE, SAVE_TEMPLATES, CLEAR_TEMPLATES } from './types';
import { ROOT_PATHS } from '../constants/variables';

const saveTemplate = template => ({
    type: SAVE_TEMPLATE,
    template,
});

const saveTemplates = templates => ({
    type: SAVE_TEMPLATES,
    templates,
});

const clearUserTemplate = () => ({
    type: CLEAR_TEMPLATE,
});

const clearUserTemplates = () => ({
    type: CLEAR_TEMPLATES,
});

export const getTemplateById = id => async (dispatch, getState) => {
    const response = await API.getTemplateById(id).catch(error => {
        dispatch(errorNotification(error));
        // Handle 404 error
        if (error && error.message.includes("doesn't exist")) {
            dispatch(go(ROOT_PATHS.templates));
        }
    });
    if (response) {
        if (Object.keys(response).length) {
            const { auth } = getState();
            if (response.lockedBy && auth?.user?.id !== response.lockedBy.id) {
                dispatch(warningNotification(locale.LockedTemplateWarning));
                dispatch(go(ROOT_PATHS.templates));
            } else {
                dispatch(saveTemplate(response));
            }
        }

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const createTemplate = values => async dispatch => {
    const response = await API.createTemplate(values).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        dispatch(saveTemplate(response));

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const getTemplates = (query = {}) => async dispatch => {
    if (Object.keys(query).length) {
        const response = await API.getTemplates({ ...query }).catch(error => {
            dispatch(errorNotification(error));
        });
        if (response) {
            if (Object.keys(response).length) {
                const {
                    items = [],
                    meta: { itemCount, totalItems, currentPage: pageCount },
                } = response;

                dispatch(
                    saveTemplates({
                        list: items,
                        itemCount,
                        totalItems,
                        pageCount,
                    }),
                );
            }

            return response;
        }
        dispatch(errorNotification(response));
    }

    return null;
};

export const editTemplate = (id, data, showMessage = false) => async dispatch => {
    const response = await API.editTemplate(id, data).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        if (showMessage) {
            dispatch(infoNotification(locale.Messages.EDITED));
        }

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const deleteTemplate = id => async dispatch => {
    dispatch(showLoader());
    const response = await API.deleteTemplate(id).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        dispatch(hideLoader());
        return response;
    }
    dispatch(errorNotification(response));
    dispatch(hideLoader());
    return null;
};

export const publishTemplate = id => async dispatch => {
    dispatch(showLoader());
    const response = await API.publishTemplate(id).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        dispatch(infoNotification(locale.Messages.PUBLISHED));
        dispatch(hideLoader());
        return response;
    }
    dispatch(errorNotification(response));
    dispatch(hideLoader());
    return null;
};

export const archiveTemplate = id => async dispatch => {
    dispatch(showLoader());
    const response = await API.archiveTemplate(id).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        dispatch(infoNotification(locale.Messages.ARCHIVED));
        dispatch(hideLoader());
        return response;
    }
    dispatch(errorNotification(response));
    dispatch(hideLoader());
    return null;
};

export const uploadMedia = file => async dispatch => {
    dispatch(showLoader());
    const response = await API.addAsset(file).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        dispatch(hideLoader());
        return response;
    }
    dispatch(errorNotification(response));
    dispatch(hideLoader());
    return null;
};

export const clearTemplate = () => async dispatch => {
    dispatch(clearUserTemplate());
};

export const clearTemplates = () => async dispatch => {
    dispatch(clearUserTemplates());
};

export const duplicateTemplate = id => async dispatch => {
    dispatch(showLoader());
    const response = await API.duplicateTemplate(id).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        dispatch(hideLoader());
        dispatch(infoNotification(response.message));
        return response;
    }
    dispatch(errorNotification(response));
    dispatch(hideLoader());
    return null;
};
