import React, { Component } from 'react';
import clsx from 'clsx';
import CSSTransitionGroup from 'react-transition-groupv1/CSSTransitionGroup';
import { arrayOf, bool, func, number, string } from 'prop-types';
import CarouselSlide from './CarouselSlide';
import ButtonReplay from '../../../components/ButtonReplay';
import { sleep } from '../../../utils/common';
import SlideObject from '../../../types/Slide';
import { SLIDE_FADE_DEFAULT_DURATION, TRANSITION_NAMES } from '../../../constants/slides';
import classes from './PreviewCarousel.module.scss';
import Spinner from '../../../components/Spinner';

class PreviewCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            counter: 0,
            stopVideo: false,
            setBg: false,
            transitionDuration: 2,
            slideDuration: 2,
            isLoading: true,
        };
        this.mounted = false;
        this.nextSlide = this.nextSlide.bind(this);
    }

    setAsyncState = newState =>
        new Promise(resolve => this.setState(newState, () => resolve()));

    async nextSlide() {
        const { counter } = this.state;
        const nextSlide = counter + 1;
        await this.setAsyncState({
            counter: nextSlide,
        });
    }

    async nextTransition() {
        const { slides } = this.props;
        const { counter } = this.state;
        const transitionDuration = +slides[counter + 1].transitionDuration;
        await this.setAsyncState({
            transitionDuration,
        });
    }

    async loop() {
        const { slides, videoEnd } = this.props;
        const { counter, transitionDuration } = this.state;
        let slideDuration =
            slides[counter].index > 1
                ? slides[counter].duration + transitionDuration
                : slides[counter].duration;
        // Check if previous slide is "fade" slide
        if (slides[counter - 1] && slides[counter - 1].duration === 0) {
            slideDuration += SLIDE_FADE_DEFAULT_DURATION;
        } else if (counter === 1) {
            slideDuration += transitionDuration;
        }
        this.setState({ slideDuration });
        await sleep(slideDuration * 1000);
        if (this.mounted) {
            if (counter + 1 < slides.length) {
                await this.nextTransition();
                await this.nextSlide();
                this.loop();
            } else {
                await this.setAsyncState({
                    counter: 0,
                    stopVideo: true,
                });
                videoEnd(false);
            }
        }
    }

    async componentDidMount() {
        this.mounted = true;
        const { slides } = this.props;
        if (Object.keys(slides).length > 1) {
            this.transitionDuration = +slides[1].transitionDuration;
        }
        const [slide] = slides;
        if (slide && slide?.imageUrl?.indexOf('.mp4') >= 0 && !slide.fadeIn) {
            await sleep(300)
                .then(() => this.mounted && this.setState({ setBg: true }))
                .catch(e => console.info(e));
        } else {
            this.setState({ setBg: true });
        }
        this.setState({ isLoading: false });

        // console.time("loop");
        this.loop();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const {
            slides,
            transitions,
            width,
            height,
            isPlaying,
            replayPreview,
            company,
        } = this.props;
        const {
            counter,
            stopVideo,
            setBg,
            transitionDuration,
            slideDuration,
            isLoading,
        } = this.state;
        const timeOut = transitionDuration ? Math.round(transitionDuration * 1000) : 1000;
        let transitionName = transitions[counter];

        // None transition in Active Seller are implemented with fade transition with 0 transitionDuration
        if (transitions[counter] === TRANSITION_NAMES.None) {
            transitionName = TRANSITION_NAMES.Fade;
        }

        return (
            <div className={classes.carousel} style={{ width, height }}>
                <Spinner loading={isLoading} opaque />
                {!isPlaying && <ButtonReplay handleClick={replayPreview} />}
                <CSSTransitionGroup
                    transitionName={transitionName}
                    transitionEnterTimeout={timeOut}
                    transitionLeaveTimeout={0}
                    className={classes.slide}
                    component="div"
                    transitionAppear={false}
                >
                    <CarouselSlide
                        className={clsx(classes.slide, setBg && classes.whiteBg)}
                        key={slides[counter].id}
                        index={counter}
                        slides={slides}
                        stopVideo={stopVideo}
                        transitionTimeout={transitionDuration}
                        slideDuration={slideDuration}
                        isLast={counter === transitions.length - 1}
                        company={company}
                    />
                </CSSTransitionGroup>
            </div>
        );
    }
}

PreviewCarousel.propTypes = {
    slides: arrayOf(SlideObject).isRequired,
    transitions: arrayOf(string).isRequired,
    width: number.isRequired,
    height: number.isRequired,
    isPlaying: bool,
    replayPreview: func.isRequired,
    videoEnd: func.isRequired,
    company: string.isRequired,
};

export default PreviewCarousel;
