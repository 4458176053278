import API from '../utils/api';
import { CLEAR_CATEGORIES, SAVE_CATEGORIES } from './types';
import { errorNotification, infoNotification } from './notifications';
import { locale } from '../constants/locales';

export const saveCategories = categories => ({
    type: SAVE_CATEGORIES,
    categories,
});

export const clearCategories = () => ({
    type: CLEAR_CATEGORIES,
});

export const createNewCategory = values => async dispatch => {
    const response = await API.createNewCategory(values).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        dispatch(infoNotification(locale.Messages.CREATED_CATEGORY));

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const getCategories = (query = {}, stateUpdate = true) => async dispatch => {
    const response = await API.getCategories({ ...query }).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        if (Object.keys(response).length && stateUpdate) {
            const {
                items = [],
                meta: { itemCount, totalItems, currentPage: pageCount },
            } = response;
            await dispatch(
                saveCategories({
                    list: items,
                    itemCount,
                    totalItems,
                    pageCount,
                }),
            );
        }

        return response;
    }

    dispatch(errorNotification(response));

    return null;
};

export const editCategory = (id, data, showMessage = false) => async dispatch => {
    const response = await API.editCategory(id, data).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        dispatch(getCategories());
        if (showMessage) {
            dispatch(infoNotification(locale.Messages.CATEGORY_EDITED));
        }

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const deleteCategory = id => async dispatch => {
    const response = await API.deleteCategory(id).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const clearAllCategories = () => async dispatch => {
    dispatch(clearCategories());
};
