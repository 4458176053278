import React from 'react';
import clsx from 'clsx';
import { string, bool } from 'prop-types';
import Chip from '@material-ui/core/Chip';
import classes from './Chip.module.scss';

function ChipWrapper({ label, className, roundBorder, ...rest }) {
    return (
        <Chip
            {...rest}
            label={label}
            className={clsx(
                className,
                classes.chip,
                roundBorder ? classes.round : classes.default,
            )}
        />
    );
}

ChipWrapper.propTypes = {
    label: string,
    className: string,
    roundBorder: bool,
};

export default ChipWrapper;
