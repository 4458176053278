import React from 'react';
import { useField } from 'formik';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import { locale } from '../../constants/locales';
import classes from './FormikField.module.scss';

export const AutocompleteField = ({
    onChangeCallback,
    label,
    className,
    options,
    disabled,
    field: { name },
    ...props
}) => {
    // Skip meta destructuring
    const [input, , helpers] = useField({ name, ...props });
    const { setValue } = helpers;

    return (
        <FormControl className={className}>
            <Autocomplete
                {...input}
                disabled={disabled}
                className={classes.autoComplete}
                fullWidth
                multiple
                noOptionsText={locale.NO_RESULTS_FOUND}
                options={options}
                getOptionLabel={option => option?.label}
                getOptionSelected={(option, value) => value?.value === option?.value}
                onChange={(event, value) => {
                    setValue(value);
                }}
                renderTags={(value, getTagProps) =>
                    value &&
                    value.map((option, index) => (
                        <Chip
                            classes={{
                                root: classes.chip,
                                deleteIcon: classes.deleteIcon,
                                deleteIconSmall: classes.chipIcon,
                            }}
                            deleteIcon={<CloseIcon />}
                            size="small"
                            label={option.label}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                renderInput={params => (
                    <TextField
                        className={className}
                        {...props}
                        {...params}
                        label={label}
                    />
                )}
                renderOption={(option, { selected }) =>
                    option ? (
                        <div className={classes.listItem}>
                            <div>{option.label}</div>
                            {selected && (
                                <div>
                                    <CheckIcon className={classes.checkIcon} />
                                </div>
                            )}
                        </div>
                    ) : null
                }
            />
        </FormControl>
    );
};
