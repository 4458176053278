import {
    INFO_NOTIFICATION,
    WARNING_NOTIFICATION,
    ERROR_NOTIFICATION,
    CLEAR_NOTIFICATION,
} from './types';

export const clearNotification = () => ({
    type: CLEAR_NOTIFICATION,
});

export const infoNotification = (message, user = null, data) => dispatch => {
    dispatch({
        type: INFO_NOTIFICATION,
        message,
        user,
        data,
    });
};

export const warningNotification = (message, user = null, data) => dispatch => {
    dispatch({
        type: WARNING_NOTIFICATION,
        message,
        user,
        data: { preventDuplicate: true, ...data },
    });
};

export const errorNotification = err => dispatch => {
    if (err) {
        let errorText = '';
        const { message, error, data } = err;
        if (typeof message === 'string') {
            errorText = message;
        } else {
            errorText = error;
        }
        dispatch({
            type: ERROR_NOTIFICATION,
            message: errorText,
            data: { preventDuplicate: true, ...data },
        });
    }
};

export const clearNotifications = () => async dispatch => {
    dispatch(clearNotification());
};
