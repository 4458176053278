import API from '../utils/api';
import { errorNotification } from './notifications';
import {
    SAVE_USER_NOTIFICATIONS,
    CLEAR_USER_NOTIFICATIONS,
    SAVE_USER_NOTIFICATIONS_HISTORY,
} from './types';
import { onlyRole } from '../utils/roles';
import { ROLES } from '../constants/roles';
import { USER_NOTIFICATIONS_MAX_COUNT } from '../constants/notifications';

const saveUserNotifications = notifications => ({
    type: SAVE_USER_NOTIFICATIONS,
    notifications,
});

const saveUserNotificationsHistory = notifications => ({
    type: SAVE_USER_NOTIFICATIONS_HISTORY,
    notifications,
});

const clearUserNotifications = () => ({
    type: CLEAR_USER_NOTIFICATIONS,
});

/**
 * action getUserNotifications
 * @param query - query params.
 * @param stateKey - "true" set for user notification history, "false" set for user notification in popup.
 */

export const getUserNotifications = (query = {}, stateKey = false) => async (
    dispatch,
    getState,
) => {
    try {
        const { auth } = getState();
        if (auth?.user?.roles && !onlyRole(auth.user.roles, ROLES.DESIGNER)) {
            const response = await API.getUserNotifications({
                ...{ limit: USER_NOTIFICATIONS_MAX_COUNT },
                ...query,
            });
            if (Object.keys(response).length) {
                if (Array.isArray(response.items) && stateKey) {
                    dispatch(saveUserNotificationsHistory(response));
                }
                if (Array.isArray(response.items) && !stateKey) {
                    dispatch(saveUserNotifications(response));
                }
                if (Array.isArray(response.items) && stateKey === 'both') {
                    dispatch(saveUserNotificationsHistory(response));
                    dispatch(saveUserNotifications(response));
                }

                return response;
            }
        }

        return null;
    } catch (e) {
        return dispatch(
            errorNotification({
                message: e.message,
                data: { preventDuplicate: true },
            }),
        );
    }
};

export const markAllRead = ids => async dispatch => {
    const promises = ids.map(id =>
        API.updateUserNotifications(id, {}).catch(error => {
            dispatch(errorNotification(error));
        }),
    );
    const response = await Promise.all(promises);
    if (Object.keys(response).length) {
        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const removeUserNotifications = () => async dispatch => {
    dispatch(clearUserNotifications());
};
