import React from 'react';
import { func, object, string } from 'prop-types';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Slide from '../../../components/Slide';
import classes from './ActiveSellerPreview.module.scss';

function ActiveSellerPreview({ slide, company, addFile, style, dimensions }) {
    return (
        <div className={classes.preview} style={style}>
            <Slide
                company={company}
                dimensions={dimensions}
                className={classes.MainSlide}
                slide={slide}
                mainSlide
            />
            <Fab aria-label="Add" onClick={addFile} className={classes.AddSlideBtn}>
                <AddIcon className={classes.AddSlideIcon} />
            </Fab>
        </div>
    );
}

ActiveSellerPreview.propTypes = {
    slide: object,
    addFile: func,
    style: object,
    company: string.isRequired,
};

export default ActiveSellerPreview;
