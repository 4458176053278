import { SAVE_SLIDES, CLEAR_SLIDES } from '../actions/types';

const initialState = {
    list: [],
};

export default (state = initialState, { type, slides }) => {
    switch (type) {
        case SAVE_SLIDES:
            return {
                ...state,
                list: slides,
            };
        case CLEAR_SLIDES:
            return initialState;
        default:
            return state;
    }
};
