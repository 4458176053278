import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { array, bool, func, number } from 'prop-types';
import clsx from 'clsx';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import IconButton from '../../../components/IconButton';
import CategoryIcon from '@material-ui/icons/Category';
import { customFabric as fabric } from '../../../components/FabricComponents';
import { allyProps, TabPanel } from './TabPanel';
import Tooltip from '../../../components/Tooltip';
import Grid from '../../../components/Grid';
import Btn from '../../../components/Button';
import { usePrevious } from '../../../utils/hooks';
import {
    saveCanvasState,
    preventRotate,
    getCanvasGridCellSize,
} from '../../../utils/canvas';
import {
    GET_COMPANY_DEFAULT_FONT,
    TOOLTIP_PLACEMENTS,
} from '../../../constants/variables';
import { WithCanvasContext } from '../../../utils/context';
import {
    ELLIPSE_CREATE_OPTIONS,
    IMAGE_CREATE_OPTIONS,
    LINE_BOUND_POINTS,
    LINE_CREATE_OPTIONS,
    LINE_OPTIONS,
    PLACEHOLDER_CREATE_OPTIONS,
    RECT_CREATE_OPTIONS,
    TEXT_CREATE_OPTIONS,
    CANVAS_TYPE_OBJECTS,
} from '../../../constants/canvas';
import { LIMIT_DURATION_VIDEO } from '../../../constants/sizes';
import { BUILD_FULL_FILE_PATH } from '../../../constants/slides';
import { CanvasTabsSchema } from '../../../schemas/canvas-tabs';
import { locale } from '../../../constants/locales';
import classes from './CanvasTabsPanel.module.scss';
import { roundNumber } from '../../../utils/common';

function SimpleTabs({
    isDraft,
    animationPlaying,
    isVisible = false,
    uploadMedia,
    canvas,
    companyName,
    durationNotificationIsOn,
    showDurationNotifications,
    clearDurationNotifications,
    saveCanvasToHistory,
    showLoader,
    hideLoader,
    files,
    clearFiles,
    canvasIndex = 0,
    addObject,
    setAddObject,
    initiateUpload,
}) {
    const inputEl = useRef(null);
    const prevFiles = usePrevious(files);
    const prevAddedObject = usePrevious(addObject);
    const [value, setValue] = useState(0);
    const canvasIndexRef = useRef(canvasIndex);

    const methods = useMemo(
        () => ({
            addText: async () => {
                const [fontFamily] = GET_COMPANY_DEFAULT_FONT(companyName);
                const canvasWidth = roundNumber(
                    (canvas.scaledDimensions.width || canvas.width) * 0.5,
                    0,
                );
                const addTextEl = new fabric.AnimatedTextbox(
                    CanvasTabsSchema.Objects.addText.label,
                    {
                        fontFamily,
                        width: canvasWidth,
                        ...TEXT_CREATE_OPTIONS(),
                    },
                );
                const { width } = addTextEl.measureLine(0);
                const lineWidth = width * 1.2;
                addTextEl.set({
                    width: lineWidth,
                    left: lineWidth * 0.5 + 10,
                    top: addTextEl.get('height') * 0.5 + 10,
                });
                canvas.add(addTextEl).setActiveObject(addTextEl);
                await saveCanvasState(
                    canvas,
                    saveCanvasToHistory,
                    canvasIndexRef?.current,
                );
                canvas.renderAll();
            },
            addRect: async () => {
                const options = RECT_CREATE_OPTIONS(
                    canvas.scaledDimensions.width || canvas.width,
                );
                const addRectEl = new fabric.AnimatedRect(options);
                const { cellX, cellY } = getCanvasGridCellSize(canvas);
                const multiplierX = Math.ceil(addRectEl.width / cellX);
                const multiplierY = Math.ceil(addRectEl.height / cellY);
                addRectEl.set({
                    width: multiplierX * cellX,
                    height: multiplierY * cellY,
                });
                canvas.add(addRectEl).setActiveObject(addRectEl);
                await saveCanvasState(
                    canvas,
                    saveCanvasToHistory,
                    canvasIndexRef?.current,
                );
                canvas.renderAll();
            },
            addLine: async () => {
                const options = LINE_CREATE_OPTIONS(true);
                const addLineEl = new fabric.Line(
                    [25, 50, canvas.getWidth() / 2, 50],
                    options,
                );
                addLineEl.setControlsVisibility(LINE_BOUND_POINTS);
                canvas.add(addLineEl).setActiveObject(addLineEl);
                await saveCanvasState(
                    canvas,
                    saveCanvasToHistory,
                    canvasIndexRef?.current,
                );
                canvas.renderAll();
            },
            addPlaceholder: async () => {
                const cWidth = canvas.scaledDimensions.width || canvas.width;
                const cHeight = canvas.scaledDimensions.height || canvas.height;
                const line1 = new fabric.Polyline(
                    [
                        { x: 1, y: 1 },
                        {
                            x: cWidth,
                            y: Math.ceil(cWidth * 0.5),
                        },
                    ],
                    LINE_OPTIONS,
                );
                const line2 = new fabric.Polyline(
                    [
                        { x: cWidth, y: 1 },
                        {
                            x: 1,
                            y: Math.ceil(cWidth * 0.5),
                        },
                    ],
                    LINE_OPTIONS,
                );
                const addPlaceholderEl = new fabric.AnimatedPlaceholder(
                    PLACEHOLDER_CREATE_OPTIONS(canvas),
                );
                const group = new fabric.Group([addPlaceholderEl, line1, line2], {
                    ...PLACEHOLDER_CREATE_OPTIONS(canvas),
                    lockScalingFlip: true,
                    stroke: null,
                });
                preventRotate(group);
                group.scaleToWidth(cWidth);
                group.setCoords();
                group.set({ top: cHeight * 0.5 - group.getScaledHeight() * 0.5 });
                group.setCoords();
                canvas.add(group).setActiveObject(group);
                await saveCanvasState(
                    canvas,
                    saveCanvasToHistory,
                    canvasIndexRef?.current,
                );
                canvas.renderAll();
            },
            addEllipse: async () => {
                const options = ELLIPSE_CREATE_OPTIONS(
                    canvas.scaledDimensions.width || canvas.width,
                );
                const addEllipseEl = new fabric.Ellipse(options);
                canvas.add(addEllipseEl).setActiveObject(addEllipseEl);
                await saveCanvasState(
                    canvas,
                    saveCanvasToHistory,
                    canvasIndexRef?.current,
                );
                canvas.renderAll();
            },
        }),
        [canvas, companyName, saveCanvasToHistory],
    );

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (canvasIndexRef) {
            canvasIndexRef.current = canvasIndex;
        }
    }, [canvasIndex]);

    useEffect(() => {
        async function handleInitiatingFileUpload() {
            await handleFile();
        }
        if (initiateUpload !== 0) {
            handleInitiatingFileUpload().catch(e => {
                console.info('Error during file upload. Error', e);
            });
        }
    }, [initiateUpload]);

    const handleChangeFile = useCallback(
        async file => {
            try {
                if (canvas && file) {
                    showLoader();
                    const { url, duration, previewUrl, dimensions } = await uploadMedia(
                        file,
                    );
                    const mediaElement = duration
                        ? fabric.VideoImage
                        : fabric.AnimatedImage;
                    const options = duration
                        ? {
                              dimensions,
                              videoSrc: BUILD_FULL_FILE_PATH(url),
                              ...IMAGE_CREATE_OPTIONS(),
                              videoDuration: duration,
                          }
                        : { dimensions, ...IMAGE_CREATE_OPTIONS() };
                    mediaElement.fromURL(
                        BUILD_FULL_FILE_PATH(duration ? previewUrl : url),
                        function (img) {
                            const canvasWidth = roundNumber(canvas.getWidth() * 0.33, 0);
                            if (duration) {
                                // Check video duration
                                if (duration > LIMIT_DURATION_VIDEO) {
                                    showDurationNotifications();
                                } else if (durationNotificationIsOn) {
                                    clearDurationNotifications();
                                }
                            }
                            img.scaleToWidth(canvasWidth);
                            canvas.add(img).setActiveObject(img);
                            canvas.renderAll();
                            saveCanvasState(
                                canvas,
                                saveCanvasToHistory,
                                canvasIndexRef?.current,
                            );
                            hideLoader();
                            canvas.renderAll();
                        },
                        options,
                    );
                }
                // reset file input
                if (inputEl?.current) {
                    inputEl.current.value = '';
                }
                clearFiles();
                canvas.renderAll();
            } catch (e) {
                hideLoader();
                console.error(e);
            }
        },
        [
            canvas,
            clearDurationNotifications,
            clearFiles,
            durationNotificationIsOn,
            hideLoader,
            saveCanvasToHistory,
            showDurationNotifications,
            showLoader,
            uploadMedia,
        ],
    );

    const handleChangeFiles = useCallback(
        async files => {
            try {
                const filesPromises = Array.from(files).map(file =>
                    handleChangeFile(file),
                );
                await Promise.all(filesPromises);
            } catch (e) {
                console.error(e);
            }
        },
        [handleChangeFile],
    );

    async function handleAddPlaceholder(event) {
        try {
            await methods.addPlaceholder(event);
        } catch (e) {
            console.error(e);
        }
    }

    async function handleFile() {
        await inputEl?.current?.click();
    }

    useEffect(() => {
        const getMethodByObjectType = type => {
            switch (type) {
                case CANVAS_TYPE_OBJECTS.line:
                    return methods.addLine;
                case CANVAS_TYPE_OBJECTS.ellipse:
                    return methods.addEllipse;
                case CANVAS_TYPE_OBJECTS.animatedTextbox:
                    return methods.addText;
                case CANVAS_TYPE_OBJECTS.animatedRect:
                    return methods.addRect;
                default:
                    return null;
            }
        };

        if (methods && addObject && prevAddedObject !== addObject) {
            const creator = getMethodByObjectType(addObject);
            if (creator) {
                creator()
                    .then(() => {
                        setAddObject(null);
                    })
                    .catch(e => {
                        console.error(e);
                        setAddObject(null);
                    });
            } else {
                setAddObject(null);
            }
        }
    }, [methods, addObject, prevAddedObject, setAddObject]);

    useEffect(() => {
        if (files && Object.keys(files).length) {
            const [file] = files;
            if (file && Object.keys(file).length && Object.keys(prevFiles).length === 0) {
                handleChangeFiles(files);
            }
            if (
                file &&
                Object.keys(file).length &&
                Object.keys(prevFiles).length &&
                prevFiles[0] !== file
            ) {
                handleChangeFiles(files);
            }
        }
    }, [files, handleChangeFiles, prevFiles]);

    const addImageVideo = (
        <>
            <Btn
                btnType="simpleBtn"
                text={`${locale.OBJECTS.IMAGE}/${locale.OBJECTS.VIDEO}`}
                variant="contained"
                onClick={handleFile}
                className={classes.addImageVideo}
                size="medium"
                disabled={animationPlaying}
            />
            <Tooltip
                title={locale.Messages.ADD_IMAGE_VIDEO}
                placement={TOOLTIP_PLACEMENTS.TOP}
            >
                <Btn
                    btnType="simpleBtn"
                    variant="contained"
                    size="medium"
                    className={classes.message}
                    onlyIcon={<HelpOutlineOutlinedIcon />}
                />
            </Tooltip>
        </>
    );

    const addPlaceholder = (
        <>
            <Btn
                btnType="simpleBtn"
                text={locale.OBJECTS.PLACEHOLDER}
                variant="contained"
                onClick={event => handleAddPlaceholder(event)}
                className={classes.addPlaceholder}
                size="medium"
                disabled={animationPlaying}
            />
            <Tooltip
                title={locale.Messages.ADD_PLACEHOLDER}
                placement={TOOLTIP_PLACEMENTS.TOP}
            >
                <Btn
                    btnType="simpleBtn"
                    variant="contained"
                    size="medium"
                    className={classes.message}
                    onlyIcon={<HelpOutlineOutlinedIcon />}
                />
            </Tooltip>
        </>
    );

    return (
        <div className={clsx(classes.tabPanel, isVisible && classes.rootPanel)}>
            {isVisible && (
                <>
                    <Typography
                        className={classes.smallTitle}
                        variant="subtitle2"
                        align="left"
                        gutterBottom
                    >
                        <strong>{locale.ADD_OBJECT}</strong>
                    </Typography>
                    {!isDraft && (
                        <div className={classes.subTitle}>
                            <Typography align="left" variant="body1">
                                {locale.Messages.PUBLISHED_NOTICE}
                            </Typography>
                        </div>
                    )}
                    {isDraft && (
                        <>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                scrollButtons="off"
                                variant="fullWidth"
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                                aria-label="icon label tabs example"
                                classes={{
                                    root: classes.tabs,
                                }}
                            >
                                <Tab
                                    label={locale.OBJECTS.MEDIA}
                                    icon={<AddPhotoAlternateIcon />}
                                    classes={{
                                        root: classes.tab,
                                    }}
                                    {...allyProps(0)}
                                />
                                <Tab
                                    label={locale.OBJECTS.OBJECTS}
                                    icon={<CategoryIcon />}
                                    classes={{
                                        root: classes.tab,
                                    }}
                                    {...allyProps(1)}
                                />
                            </Tabs>
                            <TabPanel
                                value={value}
                                index={0}
                                className={classes.tabPanel}
                            >
                                <input
                                    ref={inputEl}
                                    onChange={event =>
                                        handleChangeFiles(event.target.files)
                                    }
                                    type="file"
                                    multiple
                                    hidden
                                />
                                <div className={classes.imageVideo}>{addImageVideo}</div>
                                <div className={classes.placeholder}>
                                    {addPlaceholder}
                                </div>
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index={1}
                                className={classes.tabPanel}
                            >
                                <Grid container spacing={1}>
                                    {CanvasTabsSchema &&
                                        CanvasTabsSchema.Objects &&
                                        Object.values(CanvasTabsSchema.Objects).map(
                                            ({
                                                label,
                                                Icon,
                                                methodName,
                                                iconClass,
                                                disabled,
                                                show,
                                            }) =>
                                                show && (
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        className={classes.gridItem}
                                                        key={label}
                                                    >
                                                        <IconButton
                                                            id={label}
                                                            disabled={
                                                                animationPlaying ||
                                                                disabled
                                                            }
                                                            aria-label={label}
                                                            onClick={event =>
                                                                methods[methodName](event)
                                                            }
                                                            classes={{
                                                                root: classes.btnRoot,
                                                                label: classes.btnLabel,
                                                            }}
                                                        >
                                                            <Icon
                                                                fontSize="inherit"
                                                                className={iconClass}
                                                            />
                                                        </IconButton>
                                                        <Typography
                                                            className={clsx(
                                                                classes.labelText,
                                                                disabled &&
                                                                    classes.disabled,
                                                            )}
                                                            align="left"
                                                            variant="caption"
                                                        >
                                                            {label}
                                                        </Typography>
                                                    </Grid>
                                                ),
                                        )}
                                </Grid>
                            </TabPanel>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

SimpleTabs.propTypes = {
    saveCanvasToHistory: func.isRequired,
    isDraft: bool,
    durationNotificationIsOn: bool.isRequired,
    showDurationNotifications: func.isRequired,
    clearDurationNotifications: func.isRequired,
    showLoader: func.isRequired,
    hideLoader: func.isRequired,
    clearFiles: func.isRequired,
    files: array,
    animationPlaying: bool.isRequired,
    canvasIndex: number.isRequired,
    initiateUpload: number,
};

export default WithCanvasContext(SimpleTabs);
