import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { func, object, oneOf, shape, string } from 'prop-types';
import { useSnackbar } from 'notistack';
import Btn from '../Button';
import { usePrevious } from '../../utils/hooks';
import { sleep } from '../../utils/common';
import UserObject from '../../types/User';
import { NOTIFICATION_TYPES, NOTIFICATION_TIMEOUT } from '../../constants/notifications';
import { locale } from '../../constants/locales';
import classes from './Notification.module.scss';

export const Notification = ({
    notification,
    authUser,
    restoreSlide,
    restoreProject,
    go,
    clearNotification,
}) => {
    const prevNotification = usePrevious(notification);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const notificationRef = useRef(notification);

    useEffect(() => {
        if (notificationRef) {
            notificationRef.current = notification;
        }
        const { type, message, user, data, key } = notification;

        const clear = () => {
            // Trigger "clearNotification" only on last notification
            if (notification?.key === notificationRef?.current?.key) {
                clearNotification();
            } else if (notification?.data?.preventDuplicate) {
                //preventDuplicate case - triggers only once
                clearNotification();
            }
        };

        function handleRestoreSlide(key) {
            if (data) {
                closeSnackbar(key);
                restoreSlide(data.slideId, data.projectId);
            }
        }

        function handleRestoreProject(key) {
            if (data) {
                closeSnackbar(key);
                restoreProject(data.query, data.projectId);
            }
        }

        function handleSetPassword(key) {
            if (data) {
                closeSnackbar(key);
                go(data.url);
            }
        }
        const action = key => (
            <>
                {data && data.actionType === locale.NotificationActionTypes.SLIDE_UNDO && (
                    <Btn
                        className={clsx(classes.btn, classes.notificationBtn)}
                        onClick={() => handleRestoreSlide(key)}
                    >
                        {locale.Navigations.UNDO}
                    </Btn>
                )}
                {data && data.actionType === locale.NotificationActionTypes.PROJECT_UNDO && (
                    <Btn
                        className={clsx(classes.btn, classes.notificationBtn)}
                        onClick={() => handleRestoreProject(key)}
                    >
                        {locale.Navigations.UNDO}
                    </Btn>
                )}
                {data && data.actionType === locale.NotificationActionTypes.SET_PASSWORD && (
                    <Btn
                        className={clsx(classes.btn, classes.notificationBtn)}
                        onClick={() => handleSetPassword(key)}
                    >
                        {locale.Navigations.SET}
                    </Btn>
                )}
            </>
        );
        if (notification.type) {
            if (prevNotification && prevNotification?.key === notification.key) {
                return;
            }
            // Check if message is for particular user and this user is same as auth user
            if (user && user.id !== authUser.id) {
                return;
            }
            // Prevent messages duplicates
            const preventDuplicate = data?.preventDuplicate
                ? data.preventDuplicate
                : false;
            if (message) {
                enqueueSnackbar(message, {
                    key: preventDuplicate ? undefined : key,
                    disableWindowBlurListener: true,
                    variant: type,
                    preventDuplicate,
                    action,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    onExited: async () => {
                        await sleep(NOTIFICATION_TIMEOUT);
                        clear();
                    },
                });
            }
        }
    }, [
        notification,
        authUser.id,
        closeSnackbar,
        enqueueSnackbar,
        go,
        prevNotification,
        restoreProject,
        restoreSlide,
        clearNotification,
    ]);

    return null;
};

Notification.propTypes = {
    notification: shape({
        type: oneOf(NOTIFICATION_TYPES),
        message: string,
        authUser: object,
        data: object,
        key: string.isRequired,
    }),
    authUser: UserObject,
    restoreProject: func.isRequired,
    go: func.isRequired,
    clearNotification: func.isRequired,
};

export default Notification;
