import React from 'react';
import clsx from 'clsx';
import { useField } from 'formik';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreIcon from '@material-ui/icons/ArrowDropDown';
import IconButton from '../../components/IconButton';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import { NUMBER_FIELD } from '../../constants/variables';
import classes from './FormikField.module.scss';

export const DropdownInputField = ({
    label,
    className,
    options,
    onChangeCallback,
    variant = 'outlined',
    form: { values },
    field: { name },
    ...props
}) => {
    // Skip meta destructuring
    const [input, meta, helpers] = useField({ name, ...props });
    const { error, touched } = meta;
    const { setValue } = helpers;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleMenuOpen(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleChooseFontSize(option) {
        if (option) {
            setValue(option);
            if (onChangeCallback != null) {
                onChangeCallback({ ...values, [name]: option });
            }
        }
        handleMenuClose();
    }

    function handleSelectAll(event) {
        const { target } = event;
        if (target?.value) {
            target.select();
        }
    }

    return (
        <FormControl className={clsx(className, classes.selectInput)}>
            <TextField
                {...input}
                {...props}
                label={label}
                variant={variant || 'outlined'}
                type={NUMBER_FIELD}
                helperText={touched ? error : null}
                error={Boolean(error) ?? false}
                onClick={event => handleSelectAll(event)}
                onChange={event => {
                    const { value } = event.target;
                    const cleanValue =
                        value !== '' && !Number.isNaN(Number(value))
                            ? Number(value)
                            : value;
                    setValue(cleanValue);
                }}
            />
            <IconButton
                id={label}
                onClick={event => handleMenuOpen(event)}
                className={clsx(classes.expand, open && classes.expandOpen)}
            >
                <ExpandMoreIcon />
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorEl}
                transition
                disablePortal
                className={classes.dropdownInput}
                placement="bottom-end"
            >
                <Grow in={open} {...(open ? { timeout: 200 } : {})}>
                    <Paper className={classes.dropdownInputMenu}>
                        <ClickAwayListener onClickAway={handleMenuClose}>
                            <MenuList autoFocusItem={open} id="font-size-menu">
                                {options.map(option => (
                                    <MenuItem
                                        key={option}
                                        onClick={() => handleChooseFontSize(option)}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            </Popper>
        </FormControl>
    );
};
