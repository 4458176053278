import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useField } from 'formik';

const InputField = React.forwardRef((props, ref) => (
    <TextField inputRef={ref} {...props} />
));

export const CommonField = ({
    label,
    className,
    field: { name },
    innerRef,
    onChange,
    withSelection = false,
    ...props
}) => {
    const [input, meta, helpers] = useField({ name, ...props });
    const { error, touched } = meta;
    const { setValue } = helpers;

    return (
        <InputField
            ref={innerRef}
            className={className}
            label={label}
            {...input}
            {...props}
            helperText={touched ? error : null}
            error={Boolean(error) ?? false}
            variant="outlined"
            onClick={({ target }) => withSelection && target?.value && target.select()}
            onChange={event => {
                if (onChange) {
                    onChange(event);
                } else {
                    const { value } = event.target;
                    const cleanValue =
                        value !== '' && !Number.isNaN(Number(value))
                            ? Number(value)
                            : value;
                    setValue(cleanValue);
                }
            }}
        />
    );
};
