import React, { createContext, useState } from 'react';
// Create Context
const RefContext = createContext({});
export const RefProvider = RefContext.Provider;
export const RefConsumer = RefContext.Consumer;

export const RefContextProvider = ({ children }) => {
    const [refs, setRefs] = useState({});

    return <RefProvider value={{ refs, setRefs }}>{children}</RefProvider>;
};

export default RefContext;
