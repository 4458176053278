import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { func, number, shape, string } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ReplayIcon from '@material-ui/icons/Replay';
import Container from '../../components/Container';
import Btn from '../../components/Button';
import { useMount, useUnmount } from '../../utils/hooks';
import { locale } from '../../constants/locales';
import User from '../../types/User';
import classes from './SystemPage.module.scss';

function SystemPage({
    user,
    videoServiceStatus,
    videoInstanceStatus,
    getVideoInstanceStatus,
    getVideoServiceStatus,
    startVideoInstance,
    stopVideoInstance,
    restartBackend,
}) {
    const isMounted = useRef(true);
    const [instanceRunning, setInstanceRunning] = useState(false);
    const [instanceStopped, setInstanceStopped] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const fetchStatuses = async () => {
        await Promise.all([getVideoServiceStatus(), getVideoInstanceStatus()]);
    };
    useEffect(() => {
        if (videoInstanceStatus?.InstanceState) {
            setInstanceRunning(videoInstanceStatus.InstanceState.Code === 16);
            setInstanceStopped(videoInstanceStatus.InstanceState.Code === 80);
            setIsLoading(false);
        }
        if (videoInstanceStatus == null) {
            setInstanceRunning(false);
            setInstanceStopped(true);
            setIsLoading(true);
        }
    }, [videoInstanceStatus]);

    useMount(() => {
        fetchStatuses();
    }, []);

    useUnmount(() => {
        isMounted.current = false;
    });

    const refresh = async () => {
        await fetchStatuses();
    };

    const startInstance = async () => {
        await startVideoInstance();
        setTimeout(() => {
            isMounted?.current && fetchStatuses();
        }, 15000);
    };

    const stopInstance = async () => {
        await stopVideoInstance();
        setTimeout(() => {
            isMounted?.current && fetchStatuses();
        }, 2500);
    };

    return (
        !!Object.keys(user).length && (
            <Container
                className={classes.systemPage}
                preset={{ withHeader: true }}
                maxWidth="sm"
            >
                <Paper elevation={0} classes={{ root: classes.wrapper }}>
                    <div className="flexRow">
                        <Typography
                            className={clsx(
                                classes.text,
                                videoInstanceStatus?.InstanceState?.Name &&
                                    !isLoading &&
                                    classes[videoInstanceStatus.InstanceState.Name],
                            )}
                            align="center"
                            variant="subtitle1"
                            display="block"
                        >
                            {locale.System.AWS_STATUS}
                            <span className={classes.statusText}>
                                {videoInstanceStatus?.InstanceState?.Name ?? ' - '}
                            </span>
                        </Typography>
                    </div>
                    <div className="flexRow">
                        <Typography
                            className={clsx(
                                classes.text,
                                videoServiceStatus?.InstanceState?.Name &&
                                    !isLoading &&
                                    classes[videoServiceStatus.InstanceState.Name],
                            )}
                            align="center"
                            variant="subtitle1"
                            display="block"
                        >
                            {locale.System.SERVICE_STATUS}
                            <span className={classes.statusText}>
                                {videoServiceStatus?.InstanceState?.Name ?? ' - '}
                            </span>
                        </Typography>
                    </div>
                    <div className="flexRow">
                        <Btn
                            disabled={isLoading || !instanceRunning}
                            className={classes.button}
                            size="small"
                            variant="contained"
                            btnType="simpleBtn"
                            onClick={stopInstance}
                            text={locale.System.STOP_INSTANCE}
                        />
                        <Btn
                            disabled={isLoading || !instanceStopped}
                            className={classes.button}
                            size="small"
                            variant="contained"
                            btnType="simpleBtn"
                            onClick={startInstance}
                            text={locale.System.START_INSTANCE}
                        />
                        <Btn
                            className={classes.button}
                            text={locale.System.UPDATE_STATUS}
                            size="small"
                            variant="contained"
                            btnType="simpleBtn"
                            onClick={refresh}
                            startIcon={<ReplayIcon fontSize="inherit" />}
                        />
                    </div>
                    <div className="flexRow">
                        <Typography
                            className={classes.caption}
                            align="left"
                            variant="body2"
                        >
                            {locale.Messages.SYSTEM_RESTART}
                        </Typography>
                    </div>
                </Paper>
                <Paper elevation={0} classes={{ root: classes.wrapper }}>
                    <div className="flexRow">
                        <Typography
                            className={clsx(classes.text, classes.running)}
                            align="center"
                            variant="subtitle1"
                            display="block"
                        >
                            {locale.System.RESTART_BACKEND_SERVICE}
                        </Typography>
                    </div>
                    <div className="flexRow">
                        <Btn
                            className={classes.button}
                            size="small"
                            variant="contained"
                            btnType="simpleBtn"
                            onClick={restartBackend}
                            text={locale.System.RESTART_BACKEND}
                        />
                    </div>
                </Paper>
            </Container>
        )
    );
}

SystemPage.propTypes = {
    user: User.isRequired,
    getVideoServiceStatus: func.isRequired,
    getVideoInstanceStatus: func.isRequired,
    startVideoInstance: func.isRequired,
    stopVideoInstance: func.isRequired,
    restartBackend: func.isRequired,
    videoServiceStatus: shape({
        InstanceState: shape({
            Code: number,
            Name: string,
        }),
    }),
    videoInstanceStatus: shape({
        InstanceState: shape({
            Code: number,
            Name: string,
        }),
    }),
};

export default SystemPage;
