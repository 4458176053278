import React, { useState, useEffect } from 'react';
import { func, object, bool, oneOfType, number, string } from 'prop-types';
import withSizes from 'react-sizes';
import Grid from '../../components/Grid';
import {
    ACTIVE_BAR_HEIGHT,
    HEADER_HEIGHT,
    PADDING_STORY_AREA,
    SLIDES_HEIGHT,
} from '../../constants/variables';
import { PROJECT_TYPES } from '../../constants/project-types';
import { SLIDE_ASPECT_RATIO } from '../../constants/sizes';
import classes from './Editor.module.scss';
import ActiveSellerEditor from './ActiveSellerEditor';
import StoryEditor from './StoryEditor';
import { useMount } from '../../utils/hooks';

const Editor = ({
    width,
    height,
    params,
    history,
    project,
    progress,
    processingProjectId,
    getSlides,
    createSlide,
    updateSlide,
    getProjectById,
    clearAllSlides,
    clearCanvasHistory,
    clearDurationNotifications,
    clearProject,
}) => {
    const { projectId } = params;
    const { projectType } = project;
    const [blockHeight, setBlockHeight] = useState(0);

    // Fetch project on mount
    useMount(() => {
        const fetchProject = async () => {
            await getSlides(projectId);
            await getProjectById(projectId);
        };
        try {
            fetchProject().catch(e => console.info(e));
        } catch (e) {
            console.error(e);
        }
    }, [projectId, getProjectById, getSlides]);

    // Clean up on unmount
    useEffect(() => {
        return () => {
            clearDurationNotifications();
            clearAllSlides();
            clearCanvasHistory();
            clearProject();
        };
    }, [clearDurationNotifications, clearAllSlides, clearCanvasHistory, clearProject]);

    async function handleCreate(data) {
        return createSlide(data);
    }

    async function handleUpdate(data, updateSlidesList = true) {
        return updateSlide(data.id, data, projectId, updateSlidesList);
    }

    useEffect(() => {
        setBlockHeight(height + PADDING_STORY_AREA * 2);
    }, [height]);

    const isProcessingCurrentProject =
        progress > 0 && progress < 100 && processingProjectId === projectId;

    return (
        !!Object.keys(project).length && (
            <Grid container spacing={0} className={classes.editorComponent}>
                {projectType === PROJECT_TYPES.ACTIVE_SELLER && (
                    <ActiveSellerEditor
                        projectId={projectId}
                        company={project.companyName}
                        blockHeight={blockHeight}
                        handleCreate={handleCreate}
                        handleUpdate={handleUpdate}
                        isProcessingCurrentProject={isProcessingCurrentProject}
                    />
                )}
                {projectType === PROJECT_TYPES.STORY && (
                    <StoryEditor
                        width={width}
                        height={height}
                        project={project}
                        history={history}
                        blockHeight={blockHeight}
                        handleCreate={handleCreate}
                        handleUpdate={handleUpdate}
                        setBlockHeight={setBlockHeight}
                        isProcessingCurrentProject={isProcessingCurrentProject}
                    />
                )}
            </Grid>
        )
    );
};

Editor.propTypes = {
    width: number.isRequired,
    height: number.isRequired,
    params: object.isRequired,
    progress: oneOfType([number, bool]),
    processingProjectId: oneOfType([string, bool]),
    project: object.isRequired,
    getSlides: func.isRequired,
    clearAllSlides: func.isRequired,
    createSlide: func.isRequired,
    updateSlide: func.isRequired,
    getProjectById: func.isRequired,
    saveCanvasToHistory: func.isRequired,
    clearCanvasHistory: func.isRequired,
    clearDurationNotifications: func.isRequired,
    clearProject: func.isRequired,
};

const mapSizesToProps = ({ height }) => ({
    height: Math.floor(
        height -
            (SLIDES_HEIGHT + HEADER_HEIGHT + ACTIVE_BAR_HEIGHT + PADDING_STORY_AREA * 2),
    ),
    width: Math.floor(
        (height -
            (SLIDES_HEIGHT +
                HEADER_HEIGHT +
                ACTIVE_BAR_HEIGHT +
                PADDING_STORY_AREA * 2)) *
            SLIDE_ASPECT_RATIO,
    ),
});

export default withSizes(mapSizesToProps)(Editor);
