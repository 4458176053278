import { connect } from 'react-redux';
import DurationNotifications from './DurationNotifications';

const mapStateToProps = state => ({
    durationNotificationIsOn: Boolean(state?.showDurationNotifications?.show),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DurationNotifications);
