import React, { useEffect, useState } from 'react';
import { array, bool, number, object, func, string } from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';
// material-ui icons
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import IconButton from '../../components/IconButton';
import Container from '../Container';
import { DEFAULT_PAGE_SIZE } from '../../constants/filters';
import { EDITOR_PATHS } from '../../constants/variables';
import classes from './Pagination.module.scss';

function Pagination(props) {
    const {
        resetKey,
        count,
        rowPerPage = DEFAULT_PAGE_SIZE,
        onChangePageCallback,
        rowsPerPageOptions,
        labelRowsPerPage,
        rows,
        history,
    } = props;

    const savedPagination = JSON.parse(localStorage.getItem('pagination'));
    const [page, setPage] = useState(savedPagination?.page || 1);
    const [rowsPerPage, setRowsPerPage] = useState(savedPagination?.limit || rowPerPage);

    // Reset page at parent component changes
    useEffect(() => {
        if (resetKey) {
            setPage(1);
            localStorage.setItem(
                'pagination',
                JSON.stringify({ page: 1, limit: rowsPerPage }),
            );
        }
    }, [resetKey, rowsPerPage, setPage]);

    if (history) {
        history.listen(async location => {
            const { pathname } = location;
            const locationPathTemplate = pathname.includes(EDITOR_PATHS.templateEditor);
            const locationPathProject = pathname.includes(EDITOR_PATHS.projectEditor);
            const locationPathUserProfile = pathname.includes('users/');

            if (locationPathTemplate || locationPathProject || locationPathUserProfile) {
                localStorage.setItem(
                    'pagination',
                    JSON.stringify({ page, limit: rowsPerPage }),
                );
            }
        });
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
        onChangePageCallback({ page: newPage, limit: rowsPerPage });
    }

    function handleChangeRowsPerPage(event) {
        const p = event.target.value === count ? page : 0;
        if (event.target.value > count) {
            setPage(p + 1);
        }
        if (event.target.value < count) {
            setPage(1);
        }
        setRowsPerPage(parseInt(event.target.value, 10));
        onChangePageCallback({ page: p, limit: +event.target.value });
    }

    function TablePaginationActions({ onChangePage }) {
        function handleFirstPageButtonClick(event) {
            onChangePage(event, 1);
        }

        function handleBackButtonClick(event) {
            onChangePage(event, page - 1);
        }

        function handleNextButtonClick(event) {
            onChangePage(event, page + 1);
        }

        function handleLastPageButtonClick(event) {
            onChangePage(event, Math.max(1, Math.ceil(count / rowsPerPage)));
        }

        return (
            <>
                <div className={classes.paginationNav}>
                    <IconButton
                        onClick={handleFirstPageButtonClick}
                        disabled={page === 1}
                        aria-label="First Page"
                    >
                        <FirstPageIcon />
                    </IconButton>
                    <IconButton
                        onClick={handleBackButtonClick}
                        disabled={page === 1}
                        aria-label="Previous Page"
                    >
                        <KeyboardArrowLeft />
                    </IconButton>

                    <IconButton
                        onClick={handleNextButtonClick}
                        disabled={page - 1 >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="Next Page"
                    >
                        <KeyboardArrowRight />
                    </IconButton>
                    <IconButton
                        onClick={handleLastPageButtonClick}
                        disabled={page - 1 >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="Last Page"
                    >
                        <LastPageIcon />
                    </IconButton>
                </div>
                <div className="MuiTablePagination-spacer" />
            </>
        );
    }

    return (
        <Container
            className={classes.pagination}
            innerClassName={classes.innerContainer}
            maxWidth="md"
            noPadding
        >
            <TablePagination
                count={count}
                page={page - 1}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                rows={rows}
                labelRowsPerPage={labelRowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                component="nav"
                classes={{
                    selectRoot: classes.selectRoot,
                    caption: classes.caption,
                }}
            />
        </Container>
    );
}

Pagination.propTypes = {
    resetKey: bool,
    count: number.isRequired,
    onChangePageCallback: func.isRequired,
    rowsPerPageOptions: array,
    labelRowsPerPage: string,
    rows: array.isRequired,
    history: object,
};

export default Pagination;
