import CropIcon from '@material-ui/icons/Crop';
import FlipIcon from '@material-ui/icons/Flip';
import RestoreIcon from '@material-ui/icons/Restore';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { environment } from '../../environments/environment';
import { DEVELOP_ENVS } from '../constants/variables';
import { locale } from '../constants/locales';
import classes from '../containers/Canvas/ActionsPanel/ActionsPanel.module.scss';

const isDev = DEVELOP_ENVS.includes(environment.env);

export const ActionsSchema = Object.freeze({
    crop: {
        label: locale.Actions.CROP,
        Icon: CropIcon,
        methodName: 'crop',
        disabled: true,
        show: true,
    },
    flipX: {
        label: locale.Actions.FLIP_VERTICAL,
        Icon: FlipIcon,
        methodName: 'flipX',
        disabled: true,
        show: true,
    },
    flipY: {
        label: locale.Actions.FLIP_HORIZONTAL,
        Icon: FlipIcon,
        methodName: 'flipY',
        iconClass: classes.rotate,
        disabled: true,
        show: true,
    },
    resetState: {
        label: locale.Actions.RESET,
        Icon: RestoreIcon,
        methodName: 'resetState',
        disabled: false,
        show: isDev,
    },
    done: {
        label: locale.Actions.DONE,
        Icon: DoneIcon,
        methodName: 'done',
        disabled: false,
        show: false,
    },
    cancel: {
        label: locale.Actions.CANCEL,
        Icon: CloseIcon,
        methodName: 'cancel',
        disabled: false,
        show: false,
    },
});

export const CMActionsSchema = Object.freeze({
    uploadMedia: {
        label: locale.Actions.UPLOAD_MEDIA,
        Icon: AddPhotoAlternateIcon,
        methodName: 'uploadMedia',
        disabled: true,
        show: true,
    },
});
