import React, { useRef } from 'react';
import clsx from 'clsx';
import { bool, object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Preloader from '../../components/Preloader';
import ProcessingQueueList from '../../components/ProcessingQueueList';
import Chip from '../../components/Chip';
import UserNotificationList from '../UserNotificationsList';
import UserMenu from '../UserMenu';
import { AuthProvider } from '../../utils/auth-context';
import { EDITOR_PATHS, UNAUTH_PATHS } from '../../constants/variables';
import { ROLES } from '../../constants/roles';
import { COMPANY_LOGOS } from '../../constants/companies';
import { PROJECT_TYPES, PROJECT_TYPES_OPTIONS } from '../../constants/project-types';
import { STATUSES_TEMPLATE } from '../../constants/statuses';
import { getHeaderTitle, renderValue } from '../../utils/common';
import User from '../../types/User';
import Template from '../../types/Template';
import { onlyRole } from '../../utils/roles';
import { styles } from './styles';

const Header = props => {
    const {
        isLoading,
        location,
        history,
        classes,
        user,
        template,
        project,
        open,
        showMenu,
    } = props;

    const { pathname } = location;
    const isLogin =
        pathname === UNAUTH_PATHS.login || pathname === UNAUTH_PATHS.setPassword;
    const locationPathTemplate = pathname.includes(EDITOR_PATHS.templateEditor);
    const locationPathProject = pathname.includes(EDITOR_PATHS.projectEditor);
    const locationPathPreview =
        pathname.includes(EDITOR_PATHS.projectPreview) ||
        pathname.includes(EDITOR_PATHS.templatePreview);

    const isStory = project?.projectType === PROJECT_TYPES.STORY;
    const isActiveSeller = project?.projectType === PROJECT_TYPES.ACTIVE_SELLER;
    const isEditorMode =
        (!!Object.keys(template).length || !!Object.keys(project).length) &&
        (locationPathTemplate || locationPathProject || locationPathPreview);

    const { roles } = user;
    const isDesigner = roles && onlyRole(roles, ROLES.DESIGNER);
    const headerTitle = getHeaderTitle(pathname);
    const prevLocation = useRef(null);

    history.listen(({ pathname: newPathname }) => {
        if (prevLocation && prevLocation.current !== newPathname) {
            const { current } = prevLocation;
            if (current) {
                const isUser = newPathname.includes('user') && current.includes('user');
                const isTemplate =
                    newPathname.includes('template') && current.includes('template');
                const isProject =
                    newPathname.includes('project') && current.includes('project');
                if (isUser || isProject || isTemplate) {
                    return;
                }
            }
            localStorage.removeItem('pagination');
            prevLocation.current = newPathname;
        }
    });

    return (
        !isLogin && (
            <AuthProvider
                value={{
                    roles: {
                        isAdmin: roles && roles.includes(ROLES.ADMIN),
                        isUser: roles && roles.includes(ROLES.CONTENT_MANAGER),
                        isDesigner: roles && roles.includes(ROLES.DESIGNER),
                    },
                }}
            >
                {!!Object.keys(user).length && (
                    <div className={classes.root}>
                        <AppBar
                            position="static"
                            color="default"
                            className={clsx(classes.appBar, {
                                [classes.appBarShift]: showMenu && open,
                                [classes.appBarStandalone]: !showMenu,
                            })}
                        >
                            <Preloader loading={isLoading} color="primary" />
                            <Toolbar className={classes.toolbar} disableGutters>
                                <div className={classes.flexRow}>
                                    {isEditorMode ? (
                                        <div className={classes.flexRow}>
                                            {project && !!Object.keys(project).length && (
                                                <>
                                                    <Typography
                                                        className={clsx(classes.title)}
                                                        variant="h6"
                                                        noWrap
                                                    >
                                                        {project.title}
                                                    </Typography>
                                                    <div className={classes.companyImage}>
                                                        <img
                                                            src={
                                                                COMPANY_LOGOS[
                                                                    project.companyName
                                                                ]
                                                            }
                                                            alt={project.companyName}
                                                        />
                                                    </div>
                                                    <Chip
                                                        label={
                                                            (isStory &&
                                                                PROJECT_TYPES_OPTIONS[1]
                                                                    .label) ||
                                                            (isActiveSeller &&
                                                                PROJECT_TYPES_OPTIONS[0]
                                                                    .label)
                                                        }
                                                        size="small"
                                                        variant="outlined"
                                                        className={classes.marginLeft}
                                                    />
                                                </>
                                            )}
                                            {template && !!Object.keys(template).length && (
                                                <>
                                                    <Typography
                                                        className={clsx(classes.title)}
                                                        variant="h6"
                                                        noWrap
                                                    >
                                                        {template.name}
                                                    </Typography>
                                                    {template.status ===
                                                        STATUSES_TEMPLATE.DRAFT && (
                                                        <Typography
                                                            variant="subtitle1"
                                                            className={classes.typeName}
                                                        >
                                                            (
                                                            {renderValue(template.status)}
                                                            )
                                                        </Typography>
                                                    )}
                                                    <div className={classes.companyImage}>
                                                        <img
                                                            src={
                                                                COMPANY_LOGOS[
                                                                    template.companyName
                                                                ]
                                                            }
                                                            alt={template.companyName}
                                                        />
                                                    </div>
                                                    <Chip
                                                        label={'Template'}
                                                        size="small"
                                                        variant="outlined"
                                                        className={classes.marginLeft}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    ) : null}
                                    <Typography
                                        className={classes.title}
                                        variant="h5"
                                        noWrap
                                    >
                                        {headerTitle}
                                    </Typography>
                                </div>
                                <div className={clsx(classes.rightSide, 'flexRow')}>
                                    {!isLogin && (
                                        <>
                                            {!isDesigner && (
                                                <>
                                                    <ProcessingQueueList />
                                                    <UserNotificationList
                                                        location={location}
                                                    />
                                                </>
                                            )}
                                            <UserMenu />
                                        </>
                                    )}
                                </div>
                            </Toolbar>
                        </AppBar>
                    </div>
                )}
            </AuthProvider>
        )
    );
};

Header.propTypes = {
    location: object.isRequired,
    isLoading: bool.isRequired,
    classes: object.isRequired,
    history: object.isRequired,
    user: User.isRequired,
    open: bool,
    template: Template.isRequired,
};

export default withRouter(withStyles(styles)(Header));
