import { connect } from 'react-redux';
import UserProfile from './UserProfile';
import {
    getUserById,
    updateUserById,
    resetPassword,
    changePassword,
    removeUser,
} from '../../actions/users';

const mapStateToProps = state => ({
    authUser: state.auth && state.auth.user,
});

const mapDispatchToProps = {
    getUserById,
    updateUserById,
    resetPassword,
    changePassword,
    removeUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
