import API from '../utils/api';
import { errorNotification } from './notifications';

import { SAVE_AVAILABLE_TEMPLATES, CLEAR_AVAILABLE_TEMPLATES } from './types';

const saveTemplatesByProjectId = templates => ({
    type: SAVE_AVAILABLE_TEMPLATES,
    templates,
});

const clearTemplates = () => ({
    type: CLEAR_AVAILABLE_TEMPLATES,
});

export const getAvailableTemplates = (id, query = {}) => async dispatch => {
    const response = await API.getAvailableTemplates(id, query).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        dispatch(saveTemplatesByProjectId(response));

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const clearAvailableTemplates = () => async dispatch => {
    dispatch(clearTemplates());
};
