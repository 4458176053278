import React from 'react';
import { any, arrayOf, oneOf } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { ROLES } from '../../constants/roles';
import { AuthProvider, AuthConsumer } from '../../utils/auth-context';
import { buildRolesString } from '../../utils/common';
import { COMPANIES } from '../../constants/companies';

const accessToken = localStorage.getItem('access_token');

const AuthRoute = ({
    showMenu,
    isLoggedIn,
    roles,
    companyNames,
    authUser,
    go,
    back,
    component: Component,
    ...rest
}) => (
    <Route
        {...rest}
        render={props => {
            if (isLoggedIn || accessToken) {
                return (
                    <AuthProvider
                        value={{
                            showMenu,
                            roles: {
                                isAdmin: roles && roles.includes(ROLES.ADMIN),
                                isUser: roles && roles.includes(ROLES.CONTENT_MANAGER),
                                isDesigner: roles && roles.includes(ROLES.DESIGNER),
                                data: roles && roles,
                                text: buildRolesString(roles),
                            },
                            companyNames: {
                                isOpus:
                                    companyNames && companyNames.includes(COMPANIES.OPUS),
                                isSomeday:
                                    companyNames &&
                                    companyNames.includes(COMPANIES.SOMEDAY),
                                data: companyNames && companyNames,
                            },
                        }}
                    >
                        <AuthConsumer>
                            {ctx => (
                                <Component
                                    {...props}
                                    roles={ctx.roles}
                                    companyNames={ctx.companyNames}
                                    showMenu={ctx.showMenu}
                                    go={go}
                                    back={back}
                                    authUser={authUser}
                                />
                            )}
                        </AuthConsumer>
                    </AuthProvider>
                );
            }

            return (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location },
                    }}
                />
            );
        }}
    />
);

AuthRoute.propTypes = {
    component: any.isRequired,
    roles: arrayOf(oneOf([ROLES.ADMIN, ROLES.DESIGNER, ROLES.CONTENT_MANAGER])),
    companyNames: arrayOf(oneOf([COMPANIES.OPUS, COMPANIES.SOMEDAY])),
};

export default AuthRoute;
