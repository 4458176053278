import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { PersistGate } from 'redux-persist/integration/react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter, push } from 'connected-react-router';
import { SnackbarProvider } from 'notistack';
import AuthRoute from './components/AuthRoute';
import Notification from './components/Notification';
import Header from './containers/Header';
import SideMenu from './containers/SideMenu';
import EditorPage from './pages/EditorPage';
import Login from './pages/Login';
import CreatePassword from './pages/CreatePassword';
import Templates from './pages/Templates';
import Projects from './pages/Projects';
import UserProfilePage from './pages/UserProfile';
import UserCreatePage from './pages/UserCreate';
import UsersListPage from './pages/UsersListPage';
import SystemPage from './pages/SystemPage';
import ProjectPreview from './pages/ProjectPreviewPage';
import TemplatePreviewPage from './pages/TemplatePreviewPage';
import UserNotificationsHistory from './pages/UserNotificationsHistoryPage';
import TemplateEditor from './pages/TemplateEditor';
import NotFound from './pages/NotFound';
// Utils
import { useMount } from './utils/hooks';
import persistStore, { history } from './store/configureStore';
import {
    getVideoProgress,
    setupSocketConnection,
    subscribeOnMessages,
} from './utils/sockets';
import { isRootPath, removeUserLocalData } from './utils/common';
// Misc
import { loadFonts } from './utils/canvas';
import { logout } from './actions/auth';
// Constants
import { ROLES } from './constants/roles';
import { locale } from './constants/locales';
import { UNAUTH_PATHS } from './constants/variables';
import { COMPANIES } from './constants/companies';
// Styles
import { theme } from './theme.js';
import './App.scss';

const { store, persistor } = persistStore();

/**
 * @return {null}
 */

function App() {
    const [showMenu, toggleMenu] = useState(false);
    const [open, setOpen] = useState(false);
    const [fontsLoaded, toggleFontsLoading] = useState(false);

    const handleDrawerToggle = bool => {
        if (bool != null && typeof bool === 'boolean') {
            setOpen(bool);
        } else {
            setOpen(!open);
        }
    };

    async function init() {
        const { location } = history;
        const isLogin =
            location &&
            (location.pathname === UNAUTH_PATHS.login ||
                location.pathname === UNAUTH_PATHS.setPassword);
        // SAFARI DETECT AND USER WARNING
        if (
            navigator.userAgent.search('Safari') >= 0 &&
            navigator.userAgent.search('Chrome') < 0
        ) {
            alert(
                "We detected that You're using Safari. It could lead to the further system issues. Please use Google Chrome instead!",
            );
        }
        // Load all app fonts to prevent canvas-related font issues
        if (!fontsLoaded) {
            for await (const [index, companyName] of Object.values(COMPANIES).entries()) {
                try {
                    await loadFonts(companyName, index);
                } catch (e) {
                    console.info(`Failed to load ${companyName} fonts`);
                }
            }
            toggleFontsLoading(true);
        }

        if (!isLogin) {
            const { auth } = store.getState();
            if (auth && Object.keys(auth.user).length) {
                const checkMenu = () => {
                    const userId = localStorage.getItem('user_id');
                    const { roles } = auth.user;
                    if (userId && roles) {
                        const isAdmin = roles && roles.includes(ROLES.ADMIN);
                        if (roles && (isAdmin || roles.length >= 1)) {
                            toggleMenu(true);
                            if (!isRootPath(location)) {
                                setOpen(false);
                            }
                        }
                    }
                };

                subscribeOnMessages(
                    ...setupSocketConnection(store.dispatch, store.getState),
                );
                const onFocusHandler = () => {
                    checkMenu();
                    getVideoProgress(store.dispatch);
                };
                // Update video processing info on tab activation
                window.addEventListener('focus', onFocusHandler);
            } else {
                // Redirect to Login
                removeUserLocalData();
                await store.dispatch(logout());
                await store.dispatch(push(UNAUTH_PATHS.login));
            }
        }
    }

    useMount(async () => await init());

    if (!fontsLoaded) {
        return null;
    }

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <SnackbarProvider maxSnack={3}>
                    <ConnectedRouter history={history}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <div className="App">
                                <Notification />
                                <div className="responsiveWarning">
                                    <h3>{locale.RESPONSIVE_WARNING}</h3>
                                </div>
                                <SideMenu
                                    handleDrawerToggle={handleDrawerToggle}
                                    open={open}
                                    toggleMenu={toggleMenu}
                                    showMenu={showMenu}
                                />
                                <div className="content">
                                    <Header
                                        text={locale.HEADER_TITLE}
                                        showMenu={showMenu}
                                        handleDrawerToggle={handleDrawerToggle}
                                        open={open}
                                    />
                                    <Switch>
                                        <Route
                                            exact
                                            path="/"
                                            render={props => (
                                                <Login
                                                    {...props}
                                                    toggleMenu={toggleMenu}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/create-password"
                                            component={CreatePassword}
                                        />
                                        <AuthRoute
                                            exact
                                            path="/templates"
                                            component={Templates}
                                            showMenu={showMenu}
                                        />
                                        <AuthRoute
                                            exact
                                            path="/users"
                                            component={UsersListPage}
                                            showMenu={showMenu}
                                        />
                                        <AuthRoute
                                            exact
                                            path="/projects"
                                            component={Projects}
                                            showMenu={showMenu}
                                        />
                                        <AuthRoute
                                            exact
                                            path="/:projectId/project-editor"
                                            component={EditorPage}
                                        />
                                        <AuthRoute
                                            exact
                                            path="/:projectId/project-preview"
                                            component={ProjectPreview}
                                        />
                                        <AuthRoute
                                            exact
                                            path="/user-profile"
                                            component={UserProfilePage}
                                        />
                                        <AuthRoute
                                            exact
                                            path="/users/create"
                                            component={UserCreatePage}
                                        />
                                        <AuthRoute
                                            exact
                                            path="/users/:userId"
                                            component={UserProfilePage}
                                        />
                                        <AuthRoute
                                            exact
                                            path="/:templateId/template-editor"
                                            component={TemplateEditor}
                                        />
                                        <AuthRoute
                                            exact
                                            path="/:templateId/template-preview"
                                            component={TemplatePreviewPage}
                                        />
                                        <AuthRoute
                                            path="/user-notifications-history"
                                            component={UserNotificationsHistory}
                                        />
                                        <AuthRoute
                                            exact
                                            path="/system"
                                            component={SystemPage}
                                            showMenu={showMenu}
                                        />
                                        <Route component={NotFound} />
                                    </Switch>
                                </div>
                            </div>
                        </ThemeProvider>
                    </ConnectedRouter>
                </SnackbarProvider>
            </PersistGate>
        </Provider>
    );
}

export default App;
