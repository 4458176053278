import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import loading from './loading';
import notifications from './notifications';
import template from './template';
import templates from './templates';
import project from './project';
import projects from './projects';
import slides from './slides';
import processingProgress from './processingProgress';
import userNotifications from './user-notifications';
import userNotificationsHistory from './user-notifications-history';
import showDurationNotifications from './duration-notifications';
import popup from './popup';
import users from './users';
import canvas from './canvas';
import lockedItems from './lockedItems';
import availableTemplates from './available-templates';
import categories from './categories';
import system from './system';

export default history =>
    combineReducers({
        router: connectRouter(history),
        auth,
        loading,
        notifications,
        template,
        templates,
        project,
        projects,
        slides,
        processingProgress,
        userNotifications,
        userNotificationsHistory,
        showDurationNotifications,
        popup,
        users,
        canvas,
        lockedItems,
        availableTemplates,
        categories,
        system,
    });
