import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import FormikField from '../FormikField';
import Grow from '@material-ui/core/Grow';
import { SELECT_FIELD } from '../../constants/variables';
import { locale } from '../../constants/locales';
import FormikWrapper from '../FormikWrapper';
import { ALL_TEMPLATES_CATEGORY_MOCK } from '../../constants/categories';
import { buildCategoriesOptions } from '../../utils/common';
import classes from './CategoriesPicker.module.scss';
import { Form } from 'formik';

function CategoriesPicker({ categories, onChange }) {
    const form = useRef(null);
    const [initialValues] = useState({
        category: ALL_TEMPLATES_CATEGORY_MOCK.id,
    });

    const handleChange = ({ category }) => {
        onChange(category === ALL_TEMPLATES_CATEGORY_MOCK.id ? null : category);
    };

    return (
        <FormikWrapper
            ref={form}
            initialValues={initialValues}
            enableReinitialize
            validationSchema={null}
        >
            {({ values, isSubmitting }) =>
                Object.keys(values).length > 0 &&
                values && (
                    <Form className={classes.categoriesPickerForm}>
                        <FormikField
                            MenuProps={{
                                variant: 'menu',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                TransitionComponent: Grow,
                                getContentAnchorEl: null,
                                PopoverClasses: {
                                    paper: classes.categoriesPickerPopover,
                                },
                            }}
                            variant="outlined"
                            className={clsx(classes.formControl, 'formControl')}
                            id="category"
                            name="category"
                            margin="dense"
                            label={locale.CATEGORY}
                            type={SELECT_FIELD}
                            select
                            options={buildCategoriesOptions(categories, true)}
                            disabled={isSubmitting}
                            onChangeCallback={handleChange}
                        />
                    </Form>
                )
            }
        </FormikWrapper>
    );
}

export default CategoriesPicker;
