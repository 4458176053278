import React from 'react';
import { bool, func, object } from 'prop-types';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PowerSettings from '@material-ui/icons/PowerSettingsNew';
import NavigationItem from '../../components/NavigationItem';
import IconButton from '../../components/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import { buildCompanyLogos, getBaseRoute, isRootPath } from '../../utils/common';
import { useMount } from '../../utils/hooks';
import { ROLES } from '../../constants/roles';
import { COLORS } from '../../constants/colors';
import User from '../../types/User';
import { locale } from '../../constants/locales';
import { ROOT_PATHS } from '../../constants/variables';
import { ProjectsIcon, TemplatesIcon, UserManagementIcon } from '../../constants/icons';
import { styles } from './styles';

const SideMenu = props => {
    const {
        classes,
        user,
        open,
        handleDrawerToggle,
        history,
        toggleMenu,
        showMenu,
    } = props;
    const { roles, companyNames } = user;
    const isAdmin = roles && roles.includes(ROLES.ADMIN);
    const isUser = roles && roles.includes(ROLES.CONTENT_MANAGER);
    const isDesigner = roles && roles.includes(ROLES.DESIGNER);
    const userAccess = isAdmin || isUser;
    const designerAccess = isAdmin || isDesigner;

    const checkMenu = location => {
        const userId = localStorage.getItem('user_id');
        if (userId && roles && (isAdmin || roles.length >= 1)) {
            toggleMenu(true);
            //Collapse menu
            if (!isRootPath(location) && handleDrawerToggle) {
                handleDrawerToggle(false);
            }
        } else {
            toggleMenu(false);
        }
    };

    useMount(() => {
        checkMenu(history.location);
    });
    // Track history changes here since we cannot track them inside App.js as since it is app top-level
    // and control " showMenu" inside App.js from this component
    history.listen(location => {
        checkMenu(location);
    });

    const drawer = (
        <List className={classes.sideMenu}>
            {userAccess && (
                <NavigationItem
                    key={locale.PROJECTS}
                    link={ROOT_PATHS.projects}
                    className={classes.navItem}
                >
                    <ListItem button disableRipple>
                        <ListItemIcon>
                            <ProjectsIcon />
                        </ListItemIcon>
                        <ListItemText primary={locale.PROJECTS} />
                    </ListItem>
                </NavigationItem>
            )}
            {designerAccess && (
                <NavigationItem
                    key={locale.TEMPLATES}
                    link={ROOT_PATHS.templates}
                    className={classes.navItem}
                >
                    <ListItem button disableRipple>
                        <ListItemIcon>
                            <TemplatesIcon />
                        </ListItemIcon>
                        <ListItemText primary={locale.TEMPLATES} />
                    </ListItem>
                </NavigationItem>
            )}
            {isAdmin && (
                <NavigationItem
                    key={locale.USERS_MANAGEMENT}
                    link={ROOT_PATHS.users}
                    className={classes.navItem}
                >
                    <ListItem button disableRipple>
                        <ListItemIcon>
                            <UserManagementIcon />
                        </ListItemIcon>
                        <ListItemText primary={locale.USERS_MANAGEMENT} />
                    </ListItem>
                </NavigationItem>
            )}
            {(userAccess || isDesigner) && (
                <NavigationItem
                    key={locale.SYSTEM_MANAGEMENT}
                    link={ROOT_PATHS.system}
                    className={classes.navItem}
                >
                    <ListItem button disableRipple>
                        <ListItemIcon>
                            <PowerSettings htmlColor={COLORS.primary} />
                        </ListItemIcon>
                        <ListItemText primary={locale.SYSTEM_MANAGEMENT} />
                    </ListItem>
                </NavigationItem>
            )}
        </List>
    );

    return showMenu ? (
        <Drawer
            open={open}
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paperAnchorDockedLeft: classes.paperAnchorDockedLeft,
                paper: clsx(classes.drawerPaper, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
            anchor="left"
        >
            <div
                className={clsx(classes.drawerTop, {
                    [classes.drawerTopOpen]: showMenu && open,
                })}
            >
                {user && companyNames && (
                    <NavigationItem
                        link={getBaseRoute(roles)}
                        className={clsx(open && classes.navOpen)}
                    >
                        <div
                            className={clsx(classes.flexRow, {
                                [classes.logoShow]: open,
                                [classes.logoHidden]: !open,
                            })}
                        >
                            {buildCompanyLogos(companyNames)}
                        </div>
                    </NavigationItem>
                )}
                <IconButton
                    aria-label="toggle drawer"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                    disableRipple
                >
                    {open ? <ChevronLeftIcon /> : <MenuIcon />}
                </IconButton>
            </div>
            {drawer}
        </Drawer>
    ) : null;
};

SideMenu.propTypes = {
    location: object.isRequired,
    classes: object.isRequired,
    user: User.isRequired,
    open: bool,
    showMenu: bool,
    toggleMenu: func,
    handleDrawerToggle: func,
};

export default withWidth()(withRouter(withStyles(styles)(SideMenu)));
