export const COMPANIES = Object.freeze({
    OPUS: 'OPUS',
    SOMEDAY: 'SOMEDAY',
});

export const COMPANIES_OPTIONS = Object.freeze([
    { value: COMPANIES.OPUS, label: 'Opus' },
    { value: COMPANIES.SOMEDAY, label: 'Someday' },
]);

export const companyRegex = new RegExp(`(${COMPANIES.OPUS}|${COMPANIES.SOMEDAY})`);

export const INSTAGRAM_COMPANY_NAME = Object.freeze({
    OPUS: 'opus_fashion',
    SOMEDAY: 'someday.fashion',
});

export const COMPANY_LOGOS = {
    [COMPANIES.OPUS]: `/assets/images/${COMPANIES.OPUS}.svg`,
    [`${COMPANIES.OPUS}-WHITE`]: `/assets/images/${COMPANIES.OPUS}-WHITE.png`,
    [COMPANIES.SOMEDAY]: `/assets/images/${COMPANIES.SOMEDAY}.svg`,
    ALL: `/assets/images/ALL.svg`,
};
