import React from 'react';
import Typography from '@material-ui/core/Typography';
import classes from './NotFound.module.scss';
import { locale } from '../../constants/locales';
import Container from '../../components/Container';

const NotFound = () => (
    <Container className={classes.NotFound} preset={{ withBar: true }} maxWidth="sm">
        <Typography variant="h4" align="center">
            {locale.PAGE_NOT_FOUND}
        </Typography>
    </Container>
);

export default NotFound;
