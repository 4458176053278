import React from 'react';
import { array, bool, func, node, oneOfType, oneOf, object, string } from 'prop-types';
import clsx from 'clsx';
import { Field } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '../../components/Fields/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { CommonField } from './CommonField';
import { SwitchField } from './SwitchField';
import { CheckboxField } from './CheckboxField';
import { AutocompleteField } from './Autocomplete';
import { SelectTextField } from './SelectTextField';
import { DropdownInputField } from './DropdownInputField';
import { SearchField } from './SearchField';
import { MultipleSelectField } from './MultipleSelectField';
import DiscreteSliderField from './DiscreteSliderField';
import {
    AUTOCOMPLETE,
    PASSWORD_FIELD,
    SELECT_FIELD,
    DROPDOWN_FIELD,
    DISCRETE_SLIDER_FIELD,
    TEXT_FIELD,
    CHECKBOX_FIELD,
    NUMBER_FIELD,
    SWITCH_FIELD,
    MULTIPLE_SELECT_FIELD,
    SEARCH_FIELD,
} from '../../constants/variables';
import classes from './FormikField.module.scss';

export default function FormikField(props) {
    const {
        options,
        select,
        className,
        type,
        fullWidth,
        multiple,
        selectedValues = [],
    } = props;

    const componentsMap = {
        [AUTOCOMPLETE]: AutocompleteField,
        [PASSWORD_FIELD]: CommonField,
        [TEXT_FIELD]: CommonField,
        [DROPDOWN_FIELD]: DropdownInputField,
        [DISCRETE_SLIDER_FIELD]: DiscreteSliderField,
        [NUMBER_FIELD]: CommonField,
        [SELECT_FIELD]: SelectTextField,
        [CHECKBOX_FIELD]: CheckboxField,
        [SWITCH_FIELD]: SwitchField,
        [SEARCH_FIELD]: SearchField,
        [MULTIPLE_SELECT_FIELD]: MultipleSelectField,
    };

    const component = componentsMap[type];
    let cleanProps;
    if (
        [
            SELECT_FIELD,
            DROPDOWN_FIELD,
            DISCRETE_SLIDER_FIELD,
            MULTIPLE_SELECT_FIELD,
            SEARCH_FIELD,
        ].includes(type)
    ) {
        cleanProps = { ...props };

        if (type === MULTIPLE_SELECT_FIELD) {
            cleanProps.renderValue = selected => {
                return (
                    selected &&
                    selected?.map(s => s.charAt(0) + s.slice(1).toLowerCase()).join(', ')
                );
            };
        }
    } else {
        cleanProps = { ...props };
        if (type === SWITCH_FIELD) {
            cleanProps.type = CHECKBOX_FIELD;
        }
        delete cleanProps.onChangeCallback;
    }
    delete cleanProps.selectedValues;

    const isSelect = select && options && Array.isArray(options);
    const isSimpleSelect = isSelect && !multiple;
    const isMultipleSelect = isSelect && multiple;

    const drawSimpleItem = item =>
        item.value ? (
            <MenuItem key={item.label} value={item.value}>
                {item.label}
            </MenuItem>
        ) : (
            <MenuItem key={item} value={item}>
                {item}
            </MenuItem>
        );
    const drawItemWithCheckbox = item =>
        item.value ? (
            <MenuItem key={item.label} value={item.value}>
                <Checkbox checked={selectedValues.indexOf(item.value) > -1} />
                <ListItemText primary={item.label} />
            </MenuItem>
        ) : (
            <MenuItem key={item} value={item}>
                <Checkbox checked={selectedValues.indexOf(item) > -1} />
                <ListItemText primary={item} />
            </MenuItem>
        );

    return (
        <Field
            component={component}
            {...cleanProps}
            className={clsx(className, fullWidth && classes.fullWidth, 'formControl')}
        >
            {isSimpleSelect && options.map(opt => drawSimpleItem(opt))}
            {isMultipleSelect && options.map(opt => drawItemWithCheckbox(opt))}
        </Field>
    );
}

FormikField.propTypes = {
    label: oneOfType([node, object, string]),
    id: string,
    error: bool,
    success: bool,
    helperText: node,
    fullWidth: bool,
    required: bool,
    autoComplete: string,
    disabled: bool,
    name: string,
    type: oneOf([
        PASSWORD_FIELD,
        SELECT_FIELD,
        AUTOCOMPLETE,
        DROPDOWN_FIELD,
        DISCRETE_SLIDER_FIELD,
        TEXT_FIELD,
        CHECKBOX_FIELD,
        NUMBER_FIELD,
        SWITCH_FIELD,
        SEARCH_FIELD,
        MULTIPLE_SELECT_FIELD,
    ]),
    defaultValue: oneOfType([string, bool]),
    variant: oneOf(['standard', 'outlined', 'filled']),
    autoFocus: bool,
    className: string,
    options: array,
    onChangeCallback: func,
    value: oneOfType([string, array]),
};
