import React, { Component, createRef } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import clsx from 'clsx';
import Dropzone from 'react-dropzone';
import debouncePromise from 'debounce-promise-with-cancel';
import { customFabric as fabric } from '../../components/FabricComponents';
import initAligningGuidelines from '../../components/FabricComponents/addons/aligning_guidelines';
import initCenteringGuidelines from '../../components/FabricComponents/addons/centering_guidelines';
import ActionsPanel from './ActionsPanel';
import CanvasLayers from '../../components/CanvasLayers';
import CanvasZoom from '../../components/CanvasZoom';
import DurationNotifications from '../../components/DurationNotifications';
import ColorPicker from '../../components/ColorPicker';
import Grid from '../../components/Grid';
import CanvasTabsPanel from './CanvasTabsPanel';
import ActionBar from '../../components/ActionBar';
import Spinner from '../../components/Spinner';
import BorderColorPicker from '../../components/BorderColorPicker';
import SnappingOptionsForm from './SnappingOptionsForm';
import TextPanel from './TextPanel';
import TransitionsPanel from './TransitionsPanel';
import { withRefsContext } from '../../components/withRefsContext/withRefsContext';
import {
    saveCanvasState,
    setCanvasObjectParams,
    restoreLastActiveObject,
    checkVideoDuration,
    validateFontForCanvas,
    handleTextChange,
    buildGrid,
    removeGrid,
    snapMoving,
    snapScaling,
    checkArrowDirection,
    isLine,
    isRect,
} from '../../utils/canvas';
import { CanvasProvider } from '../../utils/context';
import { convertToSeconds } from '../../utils/common';
import { rgb2hex } from '../../utils/helpers';
import TemplateObject from '../../types/Template';
import UserObject from '../../types/User';
import { DEFAULT_BG_COLOR } from '../../constants/variables';
import { locale } from '../../constants/locales';
import { STATUSES_TEMPLATE } from '../../constants/statuses';
import {
    OMIT_INPUT_TYPES,
    CANVAS_TYPE_OBJECTS,
    ACCEPT_FILE_TYPES,
    DEFAULT_DIRECTION_STEP,
    Direction,
    KEYDOWN_EVENT_CODES,
    KEYDOWN_COMBINATION_EVENT_CODES,
    generateId,
    CANVAS_CLONE_PROPERTIES,
    CHECK_AVAILABLE_COLOR_PICKER,
    UNLOCKED_OBJECT_DEFAULT_CONFIGURATION,
} from '../../constants/canvas';
import { CANVAS_GRID_SIZE, LIMIT_DURATION_VIDEO } from '../../constants/sizes';
import classes from './Canvas.module.scss';
import AnimationsPanel from './AnimationsPanel';
import { getTypeColorPicker } from '../../constants/color-pickers';

class Canvas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canvas: null,
            activeObject: null,
            rehydrated: false,
            isDraft: true,
            isVisible: true,
            shouldStopAnimation: false,
            animationPlaying: false,
            hasGrid: false,
            gridSize: CANVAS_GRID_SIZE,
            objectSnapping: false,
            files: [],
            activeDrop: false,
            historyModeOn: false,
            addObject: null,
            savingTemplate: false,
            initiateUpload: 0,
            eyedropperOn: false,
            currentPickerType: null,
            cursorPosition: {
                left: 0,
                top: 0,
            },
        };
        this.skipRedirect = false;
        this.mounted = false;
        this._clipboard = createRef();
        this.updateZoom = this.updateZoom.bind(this);
        this.resetPlayingAnimation = this.resetPlayingAnimation.bind(this);
        this.animateCanvas = this.animateCanvas.bind(this);
        this.toggleAnimationPlaying = this.toggleAnimationPlaying.bind(this);
        this.abortAnimation = this.abortAnimation.bind(this);
        this.toggleSnapping = this.toggleSnapping.bind(this);
        this.toggleCanvasGrid = this.toggleCanvasGrid.bind(this);
        this.toggleEyeDropper = this.toggleEyeDropper.bind(this);
        this.handleGridSize = this.handleGridSize.bind(this);
        this.onDragOccur = this.onDragOccur.bind(this);
        this.onDropAccepted = this.onDropAccepted.bind(this);
        this.onDropRejected = this.onDropRejected.bind(this);
        this.clearFiles = this.clearFiles.bind(this);
        this._rehydrateCanvas = this._rehydrateCanvas.bind(this);
        this.rerenderCanvas = this.rerenderCanvas.bind(this);
        this.saveCanvas = this.saveCanvas.bind(this);
        this.setStateAsync = this.setStateAsync.bind(this);
        this.attachEventListener = this.attachEventListener.bind(this);
        this.detachEventListener = this.detachEventListener.bind(this);
        this.debouncingSaveCanvas = this.debouncingSaveCanvas.bind(this);
        this.setAddObject = this.setAddObject.bind(this);
        this.setActiveObject = this.setActiveObject.bind(this);
        this.doUpload = this.doUpload.bind(this);
    }

    doUpload() {
        this.setState(state => ({
            initiateUpload: state.initiateUpload + 1,
        }));
    }

    setAddObject(addObjectNewState) {
        this.setState({
            addObject: addObjectNewState,
        });
    }

    setActiveObject(newActiveObject) {
        this.setState({
            activeObject: newActiveObject,
        });
    }

    setStateAsync(state) {
        return new Promise(resolve => {
            if (this.mounted) {
                this.setState(state, resolve);
            }
        });
    }

    async saveCanvas(withHistoryUpdate = true, onExit = false) {
        const {
            canvasIndex,
            template,
            saveCanvasToHistory,
            saveCanvasWithoutHistory,
        } = this.props;
        if (onExit) {
            this.setState({ savingTemplate: true });
        }
        if (template.status === STATUSES_TEMPLATE.DRAFT) {
            const { canvas, animationPlaying } = this.state;
            // Stop all playings animations inside Transitions panel
            if (animationPlaying) {
                this.resetPlayingAnimation();
            }
            const canvasUpdater = withHistoryUpdate
                ? saveCanvasToHistory
                : saveCanvasWithoutHistory;
            await saveCanvasState(canvas, canvasUpdater, canvasIndex, true, onExit);
            canvas.renderAll();
        }
    }

    async toggleSnapping() {
        const { objectSnapping, canvas } = this.state;
        canvas.set('objectSnapping', !objectSnapping);
        await this.setStateAsync({ objectSnapping: !objectSnapping });
        this.saveCanvas(true);
    }

    async handleGridSize(gridSize) {
        const { canvas, hasGrid } = this.state;
        canvas.set('gridSize', gridSize);
        if (canvas && Object.keys(canvas).length && hasGrid) {
            removeGrid(canvas);
            buildGrid(canvas);
        }
        await this.setStateAsync({ gridSize });
        this.saveCanvas(true);
    }

    async toggleEyeDropper(namePicker) {
        const { canvas, eyedropperOn } = this.state;
        const { refs, setRefs } = this.props;
        refs['eyedropperOn'] = !eyedropperOn;
        setRefs(refs);
        await this.setStateAsync({
            currentPickerType: namePicker,
            eyedropperOn: !eyedropperOn,
        });
        canvas.selection = eyedropperOn;
        canvas.forEachObject(function (o) {
            o.selectable = eyedropperOn;
        });
        canvas.requestRenderAll();
    }

    async toggleCanvasGrid() {
        const { hasGrid, canvas } = this.state;
        canvas.set('hasGrid', !hasGrid);
        if (!hasGrid) {
            if (canvas && Object.keys(canvas).length) {
                buildGrid(canvas);
            }
        } else if (canvas && Object.keys(canvas).length) {
            removeGrid(canvas);
        }
        await this.setStateAsync({ hasGrid: !hasGrid });
        this.saveCanvas(true);
    }

    onDragOccur(bool) {
        const { isDraft } = this.state;
        if (isDraft) {
            this.setState({ activeDrop: bool });
        }
    }

    clearFiles() {
        this.setState({ files: [] });
    }

    onDropAccepted(files) {
        const { isDraft } = this.state;
        if (isDraft) {
            this.setState({ files, activeDrop: false });
        }
    }

    onDropRejected() {
        const { errorNotification } = this.props;
        const { isDraft } = this.state;
        if (isDraft) {
            errorNotification({
                message: locale.Messages.FILE_SHOULD_BE_MEDIA_FILE,
            });
            this.setState({ activeDrop: false });
        }
    }

    async toggleAnimationPlaying(bool) {
        await this.setStateAsync({ animationPlaying: bool });
    }

    abortAnimation() {
        const { shouldStopAnimation } = this.state;

        return shouldStopAnimation;
    }

    animateCanvas() {
        const { canvas } = this.state;
        if (!this.mounted) {
            return;
        }
        canvas.renderAll();
        fabric.util.requestAnimFrame(this.animateCanvas);
    }

    rerenderCanvas() {
        const { canvas } = this.state;
        if (canvas) {
            canvas.renderAll();
        }
    }

    checkTemplateStatus(canvas, templateStatus) {
        // Check if template status is "DRAFT"
        // and block notification of canvas
        const isDraft = templateStatus === STATUSES_TEMPLATE.DRAFT;
        if (this.mounted) {
            this.setState({ isDraft });
            if (!isDraft) {
                canvas.forEachObject(o => {
                    // Omit canvas grid lines
                    if (o.objectId) {
                        o.selectable = false;
                        o.evented = false;
                    }

                    return true;
                });
                canvas.selection = false;
            }
        }
    }

    _rehydrateCanvas(canvas, prevState, withSave = false) {
        const { activeObject } = this.state;
        const {
            durationNotificationIsOn,
            showDurationNotifications,
            clearDurationNotifications,
            template,
            loadCallback,
        } = this.props;
        // Deserialize custom objects
        const canvasLoaded = async () => {
            // Restore proper zoom
            const { width, height } = this.props;
            if (
                width !== prevState.canvasInitialWidth &&
                width !== prevState.canvasCurrentWidth
            ) {
                const scale = width / prevState.canvasInitialWidth;
                let zoom = canvas.getZoom();
                zoom *= scale;
                canvas.setDimensions({ width, height });
                if (!Number.isNaN(Number(zoom)) && Number(zoom) > 0) {
                    canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
                    canvas.set({
                        scaledDimensions: { width: width / zoom, height: height / zoom },
                    });
                }
                canvas.renderAll();
            }
            // Show/hide duration notification
            checkVideoDuration(
                prevState.objects,
                showDurationNotifications,
                clearDurationNotifications,
                durationNotificationIsOn,
            );
            // Update fonts to proper ones if template company was changed
            validateFontForCanvas(canvas, template.companyName);
            this.checkTemplateStatus(canvas, template.status);
            // Restore focus on last active object
            restoreLastActiveObject(activeObject, canvas);
            await this.setStateAsync({
                canvas,
                hasGrid: prevState.hasGrid,
                objectSnapping: prevState.objectSnapping,
                gridSize: prevState.gridSize || CANVAS_GRID_SIZE,
                rehydrated: true,
            });
            // Call this block only on initial canvasData load
            if (withSave) {
                if (
                    prevState.hasGrid != null &&
                    prevState.hasGrid &&
                    template.status === STATUSES_TEMPLATE.DRAFT
                ) {
                    buildGrid(canvas);
                }
            }
            // Fix viewport after initial canvasData load
            this.updateZoom(withSave);
            loadCallback();
            canvas.renderAll();
        };
        try {
            canvas.set({
                backgroundColor: prevState.background,
                savedColor: prevState.savedColor,
                gridSize: prevState.gridSize || CANVAS_GRID_SIZE,
            });
            canvas = canvas.loadFromJSON(prevState, canvasLoaded);
            canvas.renderAll();
        } catch (e) {
            this.setState({ canvas });
        }
    }

    // TODO Add some kind of debounce to allow only last method call for different event handlers
    resetPlayingAnimation() {
        // console.info("resetPlayingAnimation");
        const { canvas } = this.state;
        this.setStateAsync({ shouldStopAnimation: true });
        if (canvas) {
            canvas.forEachObject(o => {
                // Restore previous state for interrupted animation
                if (o.storedAnimation) {
                    // Reset animation only on exit since we're passing withPreview = true
                    // Abort playing animation
                    setCanvasObjectParams(o, {
                        ...o.storedAnimation,
                        storedAnimation: null,
                    });
                }
            });
        }
        // We need to have this here since React omitting sequential change of the same property
        setTimeout(
            () => this.mounted && this.setState({ shouldStopAnimation: false }),
            0,
        );
        canvas && canvas.renderAll();
    }

    async addObject(newObjectType) {
        const { addObject } = this.state;
        return new Promise((resolve, reject) => {
            if (!addObject) {
                this.setState(
                    {
                        addObject: newObjectType,
                    },
                    resolve,
                );
            } else {
                reject(
                    `${addObject} is creating. Please, wait until creating will be finished.`,
                );
            }
        });
    }

    actionKeys = {
        exit: async () => {
            const { eyedropperOn, canvas } = this.state;
            if (eyedropperOn) {
                const { refs, setRefs } = this.props;
                if (refs) {
                    refs['eyedropperOn'] = false;
                    setRefs(refs);
                }
                await this.setStateAsync({
                    eyedropperOn: false,
                    cursorPosition: {
                        left: 0,
                        top: 0,
                    },
                });
                canvas.selection = true;
                canvas.forEachObject(function (o) {
                    o.selectable = true;
                });
                canvas.requestRenderAll();
            }
        },
        moveSelected: direction => {
            const { canvas, activeObject, objectSnapping } = this.state;

            if (activeObject && !activeObject.isLocked) {
                if (objectSnapping) {
                    snapMoving(activeObject, canvas, direction);
                } else {
                    checkArrowDirection(activeObject, direction, DEFAULT_DIRECTION_STEP);
                }

                activeObject.setCoords();
                canvas.renderAll();
                return true;
            }
            return false;
        },
        remove: () => {
            const { canvas, activeObject } = this.state;
            const {
                clearDurationNotifications,
                warningNotification,
                authUser,
            } = this.props;
            if (activeObject && !activeObject.isLocked) {
                const realActiveObject = canvas.getActiveObject();
                if (realActiveObject.type === CANVAS_TYPE_OBJECTS.activeSelection) {
                    canvas.discardActiveObject();
                    realActiveObject.getObjects().forEach(o => {
                        realActiveObject.removeWithUpdate(o);
                        canvas.remove(o);
                    });
                    canvas.renderAll();
                } else {
                    canvas.remove(activeObject);
                }
            } else if (activeObject && activeObject.isLocked) {
                warningNotification(locale.Messages.UNABLE_TO_DELETE, authUser, {
                    preventDuplicate: true,
                });
            } else {
                warningNotification(locale.Messages.DELETE_OBJECT_UNAVAILABLE, authUser, {
                    preventDuplicate: true,
                });
            }
            const videoObjects = canvas.getObjects().filter(o => {
                const elDuration = convertToSeconds(o.videoDuration && o.videoDuration);

                return elDuration && elDuration > LIMIT_DURATION_VIDEO;
            });
            if (!videoObjects.length) {
                clearDurationNotifications();
            }
        },
        copy: () => {
            const { activeObject } = this.state;
            if (activeObject && this._clipboard) {
                const clipPath = activeObject.clipPath;
                activeObject.clone(cloned => {
                    // We have fabric.js bug that cloned element doesn't save original clipPath
                    // for Image elements so we need to set them explicitly and reset after animation
                    cloned.set({ clipPath });
                    this._clipboard.current = cloned;
                }, CANVAS_CLONE_PROPERTIES);
            }
        },
        paste: () => {
            if (this._clipboard?.current) {
                const { canvas } = this.state;
                return new Promise(resolve => {
                    const clipPath = this._clipboard?.current.clipPath;
                    this._clipboard.current.clone(clonedObj => {
                        // We have fabric.js bug that cloned element doesn't save original clipPath
                        // for Image elements so we need to set them explicitly and reset after animation
                        clonedObj.set({ clipPath });
                        let needShiftToTopRightCorner = false;
                        let newTopPosition = clonedObj.top + 10;
                        let newLeftPosition = clonedObj.left + 10;

                        const objWidth = clonedObj.getScaledWidth();
                        const objHeight = clonedObj.getScaledHeight();
                        const newRightPosition = newLeftPosition + objWidth;
                        const newBottomPosition = newTopPosition + objHeight;

                        // check is new position over canvas size
                        if (
                            newRightPosition > canvas.width ||
                            newBottomPosition > canvas.height
                        ) {
                            newTopPosition = 0;
                            newLeftPosition = canvas.width - objWidth;
                            needShiftToTopRightCorner = true;
                        }

                        clonedObj.set({
                            left: newLeftPosition,
                            top: newTopPosition,
                            evented: true,
                            ...generateId(),
                        });

                        if (clonedObj.isLocked) {
                            clonedObj.set(UNLOCKED_OBJECT_DEFAULT_CONFIGURATION);
                        }

                        if (clonedObj.type === 'activeSelection') {
                            // active selection needs a reference to the canvas
                            clonedObj.canvas = canvas;
                            clonedObj.forEachObject(function (obj) {
                                obj.set({
                                    ...generateId(),
                                });
                                canvas.add(obj);
                            });
                            // this should solve the unselectability
                            clonedObj.setCoords();
                        } else {
                            canvas.add(clonedObj);
                        }
                        if (!needShiftToTopRightCorner) {
                            this._clipboard.current.top += 10;
                            this._clipboard.current.left += 10;
                        }
                        canvas.setActiveObject(clonedObj).renderAll();
                        return resolve();
                    }, CANVAS_CLONE_PROPERTIES);
                });
            }
        },
        selectAll: () => {
            try {
                const { canvas } = this.state;
                canvas.discardActiveObject();
                const validObjects = canvas
                    .getObjects()
                    .filter(
                        o =>
                            Object.prototype.hasOwnProperty.call(o, 'objectId') &&
                            !o.isLocked,
                    );
                const sel = new fabric.ActiveSelection(validObjects, {
                    canvas: canvas,
                });
                canvas.setActiveObject(sel);
                canvas.renderAll();
            } catch (e) {
                console.info('Cannot select elements', e?.message);
            }
        },
        openColorPicker: async () => {
            const { canvas, activeObject } = this.state;
            const activeObj = canvas.getActiveObject() || activeObject;
            let needUpdate = false;
            if (activeObj) {
                const { savedColor, stroke, fill, enableColor } = activeObj;
                if (!enableColor) {
                    needUpdate = true;
                    activeObj.set({ enableColor: true });
                    if (isLine(activeObj)) {
                        activeObj.set(
                            savedColor ? { stroke: savedColor } : { savedColor: stroke },
                        );
                    } else if (isRect(activeObj)) {
                        activeObj.set(
                            savedColor
                                ? { fill: savedColor, backgroundColor: savedColor }
                                : { savedColor: fill },
                        );
                    } else {
                        activeObj.set(
                            savedColor ? { fill: savedColor } : { savedColor: fill },
                        );
                    }
                }
            } else if (canvas) {
                const { savedColor, enableColor } = canvas;
                if (!enableColor) {
                    needUpdate = true;
                    canvas.set({ enableColor: true, backgroundColor: savedColor });
                }
            }
            canvas.renderAll();
            needUpdate && (await this.saveCanvas(true));
            this.setState({ canvas, activeObject: activeObj }, () => {
                setTimeout(() => {
                    const { refs } = this.props;
                    const { activeObject } = this.state;
                    const isColorPicker = CHECK_AVAILABLE_COLOR_PICKER.includes(
                        activeObject?.type,
                    );
                    if (
                        activeObject &&
                        isColorPicker &&
                        refs[getTypeColorPicker(activeObject.type)]
                    ) {
                        refs[getTypeColorPicker(activeObject.type)].click();
                    } else if (refs[getTypeColorPicker(canvas.type)]) {
                        refs[getTypeColorPicker(canvas.type)].click();
                    }
                }, 0);
            });
        },
        addLine: () => this.addObject(CANVAS_TYPE_OBJECTS.line),
        addRect: () => this.addObject(CANVAS_TYPE_OBJECTS.animatedRect),
        addText: () => this.addObject(CANVAS_TYPE_OBJECTS.animatedTextbox),
        addEllipse: () => this.addObject(CANVAS_TYPE_OBJECTS.ellipse),
        addImage: () => {
            this.doUpload();
        },
    };

    eventHandlers = {
        keydown: async event => {
            const { activeObject, eyedropperOn } = this.state;
            const key = event.which || event.keyCode;
            // Close Canvas zoom on Esc
            if (eyedropperOn && key === KEYDOWN_EVENT_CODES.ESCAPE) {
                return this.actionKeys.exit();
            }
            // Handle Text and Crop elements with enabled editing - omit processing events and let Fabric to handle it
            if (
                activeObject &&
                (activeObject.type === CANVAS_TYPE_OBJECTS.crop ||
                    (activeObject.type === CANVAS_TYPE_OBJECTS.animatedTextbox &&
                        !activeObject.selectable))
            ) {
                return false;
            }
            // Handle text input Ctrl+C/V
            const { type, tagName } = event.target;
            if (
                type &&
                tagName &&
                tagName === 'INPUT' &&
                OMIT_INPUT_TYPES.includes(type)
            ) {
                return false;
            }
            if (event[KEYDOWN_COMBINATION_EVENT_CODES.CTRL_KEY]) {
                // Prevent browser in-built hotkeys
                event.preventDefault();
            }

            if (Object.values(KEYDOWN_EVENT_CODES).includes(key)) {
                if (eyedropperOn && key !== KEYDOWN_EVENT_CODES.ESCAPE) {
                    // Close Canvas zoom on other events also
                    await this.actionKeys.exit();
                }
                try {
                    switch (key) {
                        case KEYDOWN_EVENT_CODES.LEFT:
                            return this.actionKeys.moveSelected(Direction.LEFT);
                        case KEYDOWN_EVENT_CODES.TOP:
                            return this.actionKeys.moveSelected(Direction.TOP);
                        case KEYDOWN_EVENT_CODES.RIGHT:
                            return this.actionKeys.moveSelected(Direction.RIGHT);
                        case KEYDOWN_EVENT_CODES.DOWN:
                            return this.actionKeys.moveSelected(Direction.BOTTOM);
                        case KEYDOWN_EVENT_CODES.C:
                            if (event[KEYDOWN_COMBINATION_EVENT_CODES.CTRL_KEY]) {
                                if (event[KEYDOWN_COMBINATION_EVENT_CODES.SHIFT_KEY]) {
                                    await this.actionKeys.openColorPicker();
                                } else {
                                    this.actionKeys.copy();
                                }
                            }
                            // We need return false to avoid saving current canvas in canvas history
                            // when user do copy to clipboard
                            return false;
                        case KEYDOWN_EVENT_CODES.V:
                            if (!event[KEYDOWN_COMBINATION_EVENT_CODES.CTRL_KEY]) {
                                return false;
                            }
                            await this.actionKeys.paste();
                            break;
                        case KEYDOWN_EVENT_CODES.A:
                            if (!event[KEYDOWN_COMBINATION_EVENT_CODES.CTRL_KEY]) {
                                return false;
                            }
                            this.actionKeys.selectAll();
                            // We need return false to avoid saving current canvas in canvas history
                            // when user do copy to clipboard
                            return false;
                        case KEYDOWN_EVENT_CODES.DELETE:
                            this.actionKeys.remove();
                            break;
                        case KEYDOWN_EVENT_CODES.L:
                            if (!event[KEYDOWN_COMBINATION_EVENT_CODES.CTRL_KEY]) {
                                return false;
                            }
                            await this.actionKeys.addLine();
                            break;
                        case KEYDOWN_EVENT_CODES.O:
                            if (!event[KEYDOWN_COMBINATION_EVENT_CODES.CTRL_KEY]) {
                                return false;
                            }
                            await this.actionKeys.addEllipse();
                            break;
                        case KEYDOWN_EVENT_CODES.R:
                            if (!event[KEYDOWN_COMBINATION_EVENT_CODES.CTRL_KEY]) {
                                return false;
                            }
                            await this.actionKeys.addRect();
                            break;
                        case KEYDOWN_EVENT_CODES.T:
                            if (!event[KEYDOWN_COMBINATION_EVENT_CODES.CTRL_KEY]) {
                                return false;
                            }
                            await this.actionKeys.addText();
                            break;
                        case KEYDOWN_EVENT_CODES.I:
                            if (!event[KEYDOWN_COMBINATION_EVENT_CODES.CTRL_KEY]) {
                                return false;
                            }
                            await this.actionKeys.addImage();
                            break;
                    }
                    return true;
                } catch (e) {
                    console.error(e);
                }
            }
            return false;
        },
    };

    componentDidMount() {
        this.mounted = true;
        const {
            history,
            backgroundColor,
            canvasData,
            template,
            errorNotification,
            saveCanvasToHistory,
            loadCallback,
        } = this.props;
        // Listen to redirects
        history.listen(async (newLocation, action) => {
            if (!this.skipRedirect && action === 'POP') {
                // Save state
                await this.saveCanvas(false);
                this.skipRedirect = true;
            }
        });
        const prevState = canvasData;
        const canvas = new fabric.Canvas(this.c, {
            backgroundColor,
            controlsAboveOverlay: true,
            skipOffscreen: true,
            savedColor: backgroundColor,
            preserveObjectStacking: true,
            templateId: template.id,
            selection: true,
        });
        try {
            // try to use WebGL Filters
            fabric.filterBackend = new fabric.WebglFilterBackend();
        } catch (e) {
            // Fallback to standart Canvas2D
            fabric.filterBackend = new fabric.Canvas2dFilterBackend();
        }
        fabric.filterBackend = fabric.initFilterBackend();
        canvas.set('type', 'Canvas');
        canvas.set('hasGrid', false);
        canvas.set('objectSnapping', false);
        // Create debounced save function for keydown event listeners
        this.saveCanvasDebounce = debouncePromise(
            () => saveCanvasState(canvas, saveCanvasToHistory),
            400,
        );
        if (prevState && Object.keys(prevState).length) {
            // Existing template case
            // Only save canvas to history on mount
            this._rehydrateCanvas(
                canvas,
                prevState,
                template.status === STATUSES_TEMPLATE.DRAFT,
            );
        } else {
            // Initial template creation case
            this.checkTemplateStatus(canvas, template.status);
            if (this.mounted) {
                this.setState({ canvas, rehydrated: true }, async () => {
                    this.updateZoom(false);
                    await this.saveCanvas(true);
                    loadCallback();
                });
            }
        }
        canvas.on('object:moving', ev => {
            try {
                // snap to grid
                const { target } = ev;
                const { objectSnapping } = this.state;
                if (objectSnapping) {
                    snapMoving(target, canvas);
                }
            } catch (e) {
                console.info(e);
            }
        });
        // Attach listeners
        canvas.on('object:modified', event => {
            // TODO Improve clipPath reposition
            const { target } = event;
            if (target) {
                this.setState({
                    activeObject: target,
                });
            }
            if (target?.type !== CANVAS_TYPE_OBJECTS.crop) {
                this.saveCanvas();
            }
        });
        canvas.on('object:scaling', event => {
            // snap to grid
            const { objectSnapping } = this.state;
            if (objectSnapping) {
                snapScaling(event, canvas);
            }
        });
        canvas.on('object:removed', ({ target }) => {
            // console.log('object:removed');
            // Clear active object only on valid elements removal (not grid)
            if (this.mounted && target?.objectId) {
                this.setState({
                    activeObject: null,
                    isVisible: true,
                });
            }
        });
        canvas.on('selection:created', event => {
            // console.log('selection:created');
            if (this.mounted) {
                this.setState({ activeObject: event.target });
            }
        });
        canvas.on('selection:cleared', ({ e, target, deselected }) => {
            // console.info('selection:cleared');
            // Only if user interacted with canvas (we have valid event.target)
            // and not programmatically evented (e.g. canvas.discardActiveObject())
            // Stop all playings animations inside Transitions panel
            const { animationPlaying, isVisible, eyedropperOn, canvas } = this.state;
            if (e && template.status === STATUSES_TEMPLATE.DRAFT && isVisible) {
                // Restore layers block
                canvas.forEachObject(
                    obj => obj.objectId && !obj.evented && obj.set('evented', true),
                );
                canvas.renderAll();
            }
            if (this.mounted && target && !eyedropperOn) {
                this.setState({ activeObject: null, isVisible: true });
            }
            if (eyedropperOn && canvas && deselected && deselected[0]) {
                // Force selection previous for eyedropper focus lose
                this.setState({ activeObject: deselected?.[0] });
                canvas.setActiveObject(deselected?.[0]);
                canvas.requestRenderAll();
            }
            if (animationPlaying && target) {
                this.resetPlayingAnimation();
            }
        });
        canvas.on('selection:updated', async ({ target, deselected }) => {
            // console.info('selection:updated');
            if (this.mounted) {
                const { eyedropperOn, canvas } = this.state;
                const activeObject = canvas?.getActiveObject();
                if (eyedropperOn && canvas && deselected && deselected[0]) {
                    // Force selection previous for eyedropper focus lose
                    this.setState({ activeObject: deselected?.[0] });
                    canvas.setActiveObject(deselected?.[0]);
                    canvas.requestRenderAll();
                } else {
                    // Hide left and right panel for Cropping
                    await this.setStateAsync({
                        activeObject: target,
                        isVisible: !(
                            activeObject && activeObject.type === CANVAS_TYPE_OBJECTS.crop
                        ),
                    });
                }
                // Stop all playings animations inside Transitions panel
                // only if user interacted with canvas (we have valid event)
                // and not programmatically evented
                const { animationPlaying } = this.state;
                if (animationPlaying && target) {
                    this.resetPlayingAnimation();
                }
            }
        });
        canvas.on('mouse:down', async event => {
            if (this.mounted) {
                const {
                    canvas,
                    animationPlaying,
                    currentPickerType,
                    eyedropperOn,
                } = this.state;
                const { refs } = this.props;
                if (eyedropperOn) {
                    try {
                        event && event.e.stopPropagation();
                        const { x, y } = event.pointer;
                        const ctx = canvas.getContext('2d');
                        const { devicePixelRatio } = window;
                        const [r, g, b] = ctx.getImageData(
                            +x * devicePixelRatio,
                            +y * devicePixelRatio,
                            1,
                            1,
                        ).data;
                        const pickedColor = rgb2hex(`rgb(${[r, g, b].join()})`);
                        if (refs && refs[`${currentPickerType}_onChange`]) {
                            refs[`${currentPickerType}_onChange`](pickedColor);
                        }
                        await this.actionKeys.exit();
                    } catch (e) {
                        console.error(e?.message);
                        // TODO catch error in the future
                    }
                } else {
                    if (animationPlaying && event.target) {
                        this.resetPlayingAnimation();
                    }
                    this.setState({ activeObject: event.target });
                }
            }
        });
        canvas.on('mouse:move', event => {
            if (this.mounted) {
                const { eyedropperOn, canvas } = this.state;
                if (eyedropperOn) {
                    try {
                        const { x: left, y: top } = event.pointer;
                        canvas.setCursor('crosshair');
                        this.setState({
                            cursorPosition: {
                                left,
                                top,
                            },
                        });
                    } catch (e) {
                        console.error(e?.message);
                        // TODO catch error in the future
                    }
                }
            }
        });
        canvas.on('dragenter', () => {
            this.onDragOccur(true);
        });
        canvas.on('text:changed', async ({ target }) => {
            handleTextChange(target, errorNotification);
        });
        if (template.status === STATUSES_TEMPLATE.DRAFT) {
            this.attachEventListener();
        }
    }

    async debouncingSaveCanvas(event) {
        const { saveCanvasToHistory, canvasIndex } = this.props;
        this.saveCanvasDebounce.cancel();
        const withSave = await this.eventHandlers.keydown(event);
        if (withSave) {
            const { canvas } = this.state;
            this.saveCanvasDebounce = debouncePromise(
                () => saveCanvasState(canvas, saveCanvasToHistory, canvasIndex),
                400,
            );
            await this.saveCanvasDebounce();
        }
    }

    attachEventListener() {
        document.addEventListener('keydown', this.debouncingSaveCanvas, false);
    }

    detachEventListener() {
        document.removeEventListener('keydown', this.debouncingSaveCanvas);
    }

    updateZoom(withHistoryUpdate = false) {
        const { canvas } = this.state;
        const { width, height, saveCanvasToHistory, canvasIndex } = this.props;
        let w = width;
        let h = height;
        const ratio = canvas.getWidth() / canvas.getHeight();
        if (width / height > ratio) {
            w = height * ratio;
        } else {
            h = width / ratio;
        }
        const scale = w / canvas.getWidth();
        let zoom = canvas.getZoom();
        zoom *= scale;
        canvas.setDimensions({ width: w, height: h });
        if (!Number.isNaN(Number(zoom)) && Number(zoom) > 0) {
            canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
            canvas.set({ scaledDimensions: { width: w / zoom, height: h / zoom } });
        }
        initAligningGuidelines(canvas);
        initCenteringGuidelines(canvas);
        if (withHistoryUpdate) {
            saveCanvasState(canvas, saveCanvasToHistory, canvasIndex);
        }
        canvas.renderAll();
    }

    componentDidUpdate(prevProps) {
        const {
            width,
            height,
            canvasData,
            template,
            canvasIndex,
            canvasHistoryLength,
        } = this.props;
        const { historyModeOn } = this.state;
        // scale and rerender on resize
        if (
            (width && prevProps.width !== width) ||
            (height && prevProps.height !== height)
        ) {
            this.updateZoom();
        }
        if (
            prevProps.canvasData?.objects?.length > 0 &&
            canvasData?.objects?.length === 0
        ) {
            // Canvas reset
            if (this.mounted) {
                this.setState({
                    activeObject: null,
                });
            }
        }
        // Update canvas only at Undo/Redo operations
        if (
            prevProps.canvasIndex !== canvasIndex &&
            canvasIndex !== canvasHistoryLength - 1 &&
            canvasData
        ) {
            this.setState({ historyModeOn: true });
            const { canvas } = this.state;
            if (canvas) {
                this._rehydrateCanvas(canvas, canvasData, false);
            }
        }
        if (
            prevProps.canvasIndex < canvasIndex &&
            canvasIndex === canvasHistoryLength - 1 &&
            historyModeOn &&
            canvasData
        ) {
            this.setState({ historyModeOn: false });
            const { canvas } = this.state;
            if (canvas) {
                this._rehydrateCanvas(canvas, canvasData, false);
            }
        }
        if (prevProps.template && prevProps.template.status !== template.status) {
            const { canvas } = this.state;
            if (canvas) {
                canvas.discardActiveObject();
                canvas.renderAll();
                this.checkTemplateStatus(canvas, template.status);
            }
        }
        if (
            prevProps.canvasIndex >= 0 &&
            canvasIndex >= 0 &&
            prevProps.canvasIndex !== canvasIndex
        ) {
            const { canvas } = this.state;
            canvas.renderAll();
        }
        // Reset canvas
        if (prevProps.canvasData && canvasData === null) {
            const { canvas } = this.state;
            if (canvas) {
                canvas.clear();
                canvas.set('backgroundColor', DEFAULT_BG_COLOR);
            }
        }
    }

    componentWillUnmount() {
        const { clearDurationNotifications } = this.props;
        clearDurationNotifications();
        this.mounted = false;
        window.onbeforeunload = null;
        this.setState({
            activeObject: null,
            canvas: null,
            isVisible: true,
        });
        this.detachEventListener();
    }

    render() {
        const {
            canvas,
            activeObject,
            isVisible,
            hasGrid,
            gridSize,
            objectSnapping,
            files,
            animationPlaying,
            activeDrop,
            addObject,
            savingTemplate,
            eyedropperOn,
            cursorPosition,
        } = this.state;
        const {
            template,
            width,
            height,
            undo,
            redo,
            canvasHistoryLength,
            canvasIndex,
            canvasData,
        } = this.props;

        return (
            template &&
            Object.keys(template).length && (
                <Grid container spacing={0} className={classes.canvasComponent}>
                    <CanvasProvider value={{ canvas }}>
                        <ActionBar
                            templateId={template.id}
                            editorMode
                            goBackLink="/templates"
                            withValidation
                            handleFormUpdate={() => this.saveCanvas(true, true)}
                            goBackText={
                                template.status === STATUSES_TEMPLATE.DRAFT
                                    ? locale.SAVE_AND_CLOSE
                                    : locale.BACK
                            }
                            showPreview
                            showPublish
                            showArchive
                            undoRedoButtons
                            undo={undo}
                            redo={redo}
                            canvasIndex={canvasIndex}
                            canvasHistoryLength={canvasHistoryLength}
                            isVisible={isVisible}
                            animationPlaying={animationPlaying}
                        />

                        <Grid
                            item
                            xs={3}
                            className={classes.borderRight}
                            style={{ height: height + 32 }}
                        >
                            <>
                                <CanvasTabsPanel
                                    initiateUpload={this.state.initiateUpload}
                                    isDraft={template.status === STATUSES_TEMPLATE.DRAFT}
                                    animationPlaying={animationPlaying}
                                    isVisible={isVisible}
                                    files={files}
                                    clearFiles={this.clearFiles}
                                    canvasIndex={canvasIndex}
                                    addObject={addObject}
                                    setAddObject={this.setAddObject}
                                />
                                {template.status === STATUSES_TEMPLATE.DRAFT &&
                                    isVisible && (
                                        <CanvasLayers
                                            slide={template}
                                            canvasData={canvasData}
                                            saveCanvas={this.saveCanvas}
                                            activeObject={activeObject}
                                            setActiveObject={this.setActiveObject}
                                        />
                                    )}
                            </>
                        </Grid>
                        <Grid item xs={6} className={classes.canvasBox}>
                            {eyedropperOn && (
                                <CanvasZoom
                                    cursorPosition={cursorPosition}
                                    show={eyedropperOn}
                                />
                            )}
                            <Dropzone
                                noClick
                                accept={ACCEPT_FILE_TYPES}
                                onDragEnter={() => this.onDragOccur(true)}
                                onDragLeave={() => this.onDragOccur(false)}
                                onDropAccepted={this.onDropAccepted}
                                onDropRejected={this.onDropRejected}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        {...getRootProps({
                                            className: clsx(
                                                'dropzone',
                                                activeDrop && 'activeDrop',
                                            ),
                                        })}
                                    >
                                        <input {...getInputProps()} />
                                        <canvas
                                            ref={c => {
                                                this.c = c;
                                            }}
                                            width={width + 'px'}
                                            height={height + 'px'}
                                            className={classes.canvas}
                                        />
                                    </div>
                                )}
                            </Dropzone>
                            <DurationNotifications />
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            className={clsx(classes.borderLeft, classes.overflowAuto)}
                            style={{ height: height + 32, textAlign: 'left' }}
                        >
                            <ActionsPanel
                                activeObject={activeObject}
                                isVisible={isVisible}
                                disableAll={
                                    activeObject?.isLocked ||
                                    animationPlaying ||
                                    template.status !== STATUSES_TEMPLATE.DRAFT
                                }
                                canvasIndex={canvasIndex}
                            />
                            {template.status === STATUSES_TEMPLATE.DRAFT && isVisible && (
                                <>
                                    <ColorPicker
                                        activeObject={canvas}
                                        canvasIndex={canvasIndex}
                                        toggleEyeDropper={this.toggleEyeDropper}
                                        showEyedropper
                                    />
                                    <SnappingOptionsForm
                                        hasGrid={hasGrid}
                                        gridSize={gridSize}
                                        objectSnapping={objectSnapping}
                                        toggleCanvasGrid={this.toggleCanvasGrid}
                                        toggleSnapping={this.toggleSnapping}
                                        handleGridSize={this.handleGridSize}
                                    />
                                    <TextPanel
                                        activeObject={activeObject}
                                        canvasIndex={canvasIndex}
                                        disabled={activeObject?.isLocked}
                                    />
                                    <ColorPicker
                                        activeObject={activeObject}
                                        canvasIndex={canvasIndex}
                                        disabled={activeObject?.isLocked}
                                        toggleEyeDropper={this.toggleEyeDropper}
                                        showEyedropper
                                    />
                                    <BorderColorPicker
                                        activeObject={activeObject}
                                        canvasIndex={canvasIndex}
                                        disabled={activeObject?.isLocked}
                                        toggleEyeDropper={this.toggleEyeDropper}
                                        showEyedropper
                                    />
                                    <AnimationsPanel
                                        activeObject={activeObject}
                                        disabled={activeObject?.isLocked}
                                        canvasIndex={canvasIndex}
                                        resetPlayingAnimation={this.resetPlayingAnimation}
                                        abortAnimation={this.abortAnimation}
                                        animationPlaying={animationPlaying}
                                        toggleAnimationPlaying={
                                            this.toggleAnimationPlaying
                                        }
                                    />
                                    <TransitionsPanel
                                        activeObject={activeObject}
                                        canvasIndex={canvasIndex}
                                        resetPlayingAnimation={this.resetPlayingAnimation}
                                        abortAnimation={this.abortAnimation}
                                        animationPlaying={animationPlaying}
                                        toggleAnimationPlaying={
                                            this.toggleAnimationPlaying
                                        }
                                        disabled={activeObject?.isLocked}
                                    />
                                </>
                            )}
                        </Grid>
                    </CanvasProvider>
                    {savingTemplate && (
                        <div className={classes.savingOverlay}>
                            <Spinner loading={savingTemplate} fixed overlay />
                        </div>
                    )}
                </Grid>
            )
        );
    }
}

Canvas.defaultProps = {
    width: 600,
    height: 400,
    backgroundColor: DEFAULT_BG_COLOR,
};

Canvas.propTypes = {
    refs: object,
    width: number,
    height: number,
    canvasIndex: number,
    canvasData: object,
    canvasHistoryLength: number,
    template: TemplateObject,
    authUser: UserObject.isRequired,
    undo: func.isRequired,
    redo: func.isRequired,
    backgroundColor: string,
    saveCanvasToHistory: func.isRequired,
    saveCanvasWithoutHistory: func.isRequired,
    durationNotificationIsOn: bool.isRequired,
    showDurationNotifications: func.isRequired,
    clearDurationNotifications: func.isRequired,
    loadCallback: func.isRequired,
};

export default withRefsContext(Canvas);
