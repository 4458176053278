import { connect } from 'react-redux';
import Editor from './Editor';
import {
    getSlides,
    clearAllSlides,
    createSlide,
    updateSlide,
} from '../../actions/slides';
import { getProjectById, clearProject } from '../../actions/projects';
import { clearDurationNotifications } from '../../actions/duration-notifications';
import { saveCanvasToHistory, clearCanvasHistory } from '../../actions/canvas';

const mapStateToProps = (state, ownProps) => ({
    project: state.project,
    progress:
        ownProps.progress >= 0
            ? ownProps.progress
            : !!Object.keys(state.processingProgress.list).length &&
              state.processingProgress.list[0] &&
              state.processingProgress.list[0].progress,
    processingProjectId:
        !!Object.keys(state.processingProgress.list).length &&
        state.processingProgress.list[0].project.id,
});

const mapDispatchToProps = {
    getSlides,
    createSlide,
    updateSlide,
    getProjectById,
    clearAllSlides,
    clearCanvasHistory,
    saveCanvasToHistory,
    clearDurationNotifications,
    clearProject,
};
export default connect(mapStateToProps, mapDispatchToProps)(Editor);
