import { get, put, del, post } from './api';

export const createProject = values => post('/projects/create', values);
export const getProjects = query => get('/projects', query);
export const getProjectById = id => get(`/projects/${id}`);
export const updateProject = (id, data) => put(`/projects/${id}`, data);
export const duplicationProject = id => post(`/projects/${id}/duplicate`);
export const deleteProject = id => del(`/projects/${id}`);
export const restoreProject = id => put(`/projects/${id}/restore`);
export const lockProject = id => post(`/projects/${id}/lock`);
export const unlockProject = id => post(`/projects/${id}/unlock`);
export const unlockAllProjects = () => post(`/projects/unlockAll`);
