import { connect } from 'react-redux';
import TemplatePreviewPage from './TemplatePreviewPage';
import { clearTemplate, getTemplateById } from '../../actions/templates';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    getTemplateById,
    clearTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePreviewPage);
