import React, { useState, useContext } from 'react';
import { bool, func } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Alert from '../../components/Alert';
import FiltersForm from '../../containers/FiltersForm';
import ProjectsList from '../../containers/ProjectsList';
import Container from '../../components/Container';
import ActionBar from '../../components/ActionBar';
import ActionButton from '../../components/ActionButton';
import withSpinner from '../../components/withSpinner';
import Pagination from '../../components/Pagination';
import { useMount, useUnmount } from '../../utils/hooks';
import { buildOptions, storeFiltersToLocalStorage } from '../../utils/common';
import AuthContext from '../../utils/auth-context';
import validationSchema from '../../schemas/validations/create-project';
import { COMPANIES, COMPANIES_OPTIONS } from '../../constants/companies';
import {
  PROJECT_TYPES,
  PROJECT_TYPES_OPTIONS,
} from '../../constants/project-types';
import {
  DEFAULT_TEMPLATES_PAGE_OPTIONS,
  DEFAULT_PROJECTS_PAGE_SIZE,
  PROJECTS_FILTERS,
  PATH_TO_LOCAL_STORE,
} from '../../constants/filters';
import {
  MULTIPLE_SELECT_FIELD,
  SELECT_FIELD,
  TEXT_FIELD,
} from '../../constants/variables';
import { ORDERS, ORDERS_OPTIONS } from '../../constants/orders';
import {
  STATUSES_PROJECT,
  STATUSES_PROJECT_OPTIONS,
} from '../../constants/statuses';
import { locale } from '../../constants/locales';
import { PlusIcon } from '../../constants/icons';
import { COLORS } from '../../constants/colors';

function Projects(props) {
  const {
    authUser,
    projects,
    go,
    createProject,
    deleteProject,
    getProjects,
    updateProject,
    clearProjects,
    clearTemplate,
    clearProject,
    toggleLoading,
    totalItems,
    isLoading,
    infoNotification,
    history,
  } = props;
  const { companyNames } = useContext(AuthContext);
  const [params, setParams] = useState({
    limit: DEFAULT_PROJECTS_PAGE_SIZE,
    page: 1,
  });

  const [projectToDeleteId, setProjectToDeleteId] = useState(null);
  const [openDeleteAlert, setDeleteAlertOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [resetKey, setResetKey] = useState(false);

  const fetchData = async (query = params) => {
    await getProjects(query);
    // Hide spinner
    if (isLoading) {
      toggleLoading();
    }
  };

  // We need to clear template AFTER "saveCanvasToHistory" action will get template from state
  useMount(() => {
    clearProject();
    clearTemplate();
  });
  useUnmount(() => clearProjects());

  async function handleChange(data) {
    setResetKey(false);
    if (data) {
      await fetchData({ ...filters, ...data });
      setParams(data);
    }
  }

  const handleCreateProject = async (formData) => {
    const res = await createProject(formData);
    if (res && res.id) {
      return go(`${res.id}/project-editor`);
    }

    return res;
  };

  async function handleUpdateProject(formData, id) {
    const res = await updateProject(id, formData, true);
    await fetchData({ ...filters, ...params });

    return res;
  }

  function handleDeleteAlertOpen(id) {
    setProjectToDeleteId(id);
    setDeleteAlertOpen(true);
  }

  function handleDeleteAlertClose(event, reason) {
    if (reason !== 'backdropClick') {
      setDeleteAlertOpen(false);
    }
  }

  async function handleDeleteAlertAction() {
    const message = locale.Messages.PROJECT_WAS_DELETED(
      projects.find((p) => p.id === projectToDeleteId).title ?? ''
    );
    const data = {
      actionType: locale.NotificationActionTypes.PROJECT_UNDO,
      query: { ...filters, ...params },
      projectId: projectToDeleteId,
    };

    infoNotification(message, authUser, data);
    await deleteProject(projectToDeleteId);
    await handleUpdateProjects();
    handleDeleteAlertClose();
  }

  async function handleUpdateProjects() {
    await fetchData({ ...filters, ...params });
  }

  // Get all filters changes
  async function handleFiltersChange({ search, sort, ...values }) {
    const companies = values.companyNames.length
      ? values.companyNames
      : Object.values(COMPANIES);
    const projectType = values.projectType.length
      ? values.projectType
      : Object.values(PROJECT_TYPES);
    const projectStatuses = values.projectStatuses.length
      ? values.projectStatuses
      : Object.values(STATUSES_PROJECT);
    let paginationValues = { page: 1, limit: DEFAULT_PROJECTS_PAGE_SIZE };
    try {
      const storedPagination = localStorage.getItem('pagination');
      if (storedPagination) {
        paginationValues = JSON.parse(storedPagination);
      }
    } catch (e) {
      console.info(e);
    }
    // Reset page on filters change
    let updatedParams = { ...params, ...paginationValues };
    if (params.page > 1) {
      setResetKey(true);
      updatedParams = { ...updatedParams, page: 1 };
    }
    setParams(updatedParams);
    setFilters({
      projectTypes: projectType,
      companyNames: companies,
      search,
      sort,
      projectStatuses,
    });
    await fetchData({
      projectTypes: projectType,
      companyNames: companies,
      search,
      sort,
      projectStatuses,
      ...updatedParams,
    });
    storeFiltersToLocalStorage(PATH_TO_LOCAL_STORE.PROJECTS, {
      sort,
      search: '',
      ...values,
    });
  }

  return (
    <>
      <ActionBar>
        <FiltersForm
          path={PATH_TO_LOCAL_STORE.PROJECTS}
          onChange={handleFiltersChange}
          config={{
            validationSchema: null,
            fields: [
              {
                id: PROJECTS_FILTERS.SORT,
                label: locale.ORDER,
                type: SELECT_FIELD,
                value: ORDERS.CANVAS_DATA_LAST_UPDATE,
                options: buildOptions(
                  Object.values(ORDERS),
                  ORDERS_OPTIONS,
                  true
                ),
              },
              {
                id: PROJECTS_FILTERS.PROJECT_TYPE,
                label: locale.TYPE,
                type: MULTIPLE_SELECT_FIELD,
                value: [PROJECT_TYPES.ACTIVE_SELLER, PROJECT_TYPES.STORY],
                options: buildOptions(
                  Object.values(PROJECT_TYPES),
                  PROJECT_TYPES_OPTIONS,
                  true
                ),
                selectedValues: [
                  PROJECT_TYPES.ACTIVE_SELLER,
                  PROJECT_TYPES.STORY,
                ],
                filterMode: true,
              },
              {
                id: PROJECTS_FILTERS.COMPANY_NAMES,
                label: locale.User.COMPANY,
                type: MULTIPLE_SELECT_FIELD,
                value: [
                  ...(Array.isArray(companyNames.data)
                    ? companyNames.data
                    : []),
                ],
                options: buildOptions(
                  companyNames.data,
                  COMPANIES_OPTIONS,
                  true
                ),
                selectedValues: [
                  ...(Array.isArray(companyNames.data)
                    ? companyNames.data
                    : []),
                ],
                filterMode: true,
              },
              {
                id: PROJECTS_FILTERS.PROJECT_STATUSES,
                label: locale.STATUS,
                type: MULTIPLE_SELECT_FIELD,
                value: [
                  STATUSES_PROJECT.NEW,
                  STATUSES_PROJECT.READY,
                  STATUSES_PROJECT.FAILED,
                ],
                options: buildOptions(
                  Object.values(STATUSES_PROJECT),
                  STATUSES_PROJECT_OPTIONS,
                  true
                ),
                selectedValues: [
                  STATUSES_PROJECT.NEW,
                  STATUSES_PROJECT.READY,
                  STATUSES_PROJECT.FAILED,
                ],
                filterMode: true,
              },
            ],
          }}
          filterDropdown
          filtersOpen={filtersOpen}
          setFiltersOpen={setFiltersOpen}
        />
        <ActionButton
          confirmAction={handleCreateProject}
          config={{
            dialogTitle: locale.CREATE_PROJECT,
            validationSchema,
            id: null,
            dialogText: locale.ENTER_PROJECT_TITLE,
            actionBtnText: locale.CREATE,
            fields: [
              {
                id: 'title',
                value: null,
                label: locale.PROJECT_TITLE,
                type: TEXT_FIELD,
              },
              {
                id: 'projectType',
                value: PROJECT_TYPES_OPTIONS[0].value,
                label: locale.PROJECT_TYPE,
                type: SELECT_FIELD,
                options: PROJECT_TYPES_OPTIONS,
              },
              {
                id: 'companyName',
                value:
                  (companyNames.isOpus && COMPANIES_OPTIONS[0].value) ||
                  COMPANIES_OPTIONS[1].value,
                label: locale.FOR_COMPANY,
                type: SELECT_FIELD,
                options: buildOptions(
                  companyNames.data,
                  COMPANIES_OPTIONS,
                  true
                ),
              },
            ],
          }}
        >
          <PlusIcon size="small" fill={COLORS.white} />
        </ActionButton>
      </ActionBar>

      {!!Object.keys(projects).length && (
        <Container
          maxWidth="md"
          preset={
            filtersOpen
              ? { withHeaderBarPaginationAndFilters: true }
              : { withHeaderAndBarAndPagination: true }
          }
        >
          <ProjectsList
            projects={projects}
            confirmAction={handleUpdateProject}
            deleteAlertOpen={handleDeleteAlertOpen}
            handleListUpdate={handleUpdateProjects}
          />
        </Container>
      )}
      {!isLoading && !Object.keys(projects).length && (
        <Container
          maxWidth="md"
          preset={
            filtersOpen
              ? { withHeaderBarPaginationAndFilters: true }
              : { withHeaderAndBarAndPagination: true }
          }
          innerClassName="noSearchData"
        >
          <Typography variant="body1" align="center">
            {filters.search
              ? locale.NO_RESULTS_FOUND
              : locale.NO_DATA_AVAILABLE}
          </Typography>
        </Container>
      )}
      <Alert
        alertConfig={{
          alertTitle: locale.actionString(locale.DELETE, 'project'),
          alertText: locale.actionTextString(
            locale.DELETE,
            locale.CREATED_PROJECT
          ),
          alertBtnText: locale.DELETE,
          alertBtnColor: 'primary',
          actionClick: handleDeleteAlertAction,
          cancelAction: handleDeleteAlertClose,
        }}
        open={openDeleteAlert}
      />
      {!!Object.keys(projects).length && projects && (
        <Pagination
          resetKey={resetKey}
          count={totalItems}
          rowPerPage={DEFAULT_PROJECTS_PAGE_SIZE}
          rowsPerPageOptions={DEFAULT_TEMPLATES_PAGE_OPTIONS}
          rows={projects}
          labelRowsPerPage={locale.Messages.ITEMS_PER_PAGE(locale.PROJECTS)}
          onChangePageCallback={handleChange}
          history={history}
          component="nav"
        />
      )}
    </>
  );
}

Projects.propTypes = {
  isLoading: bool,
  go: func.isRequired,
  createProject: func.isRequired,
  deleteProject: func.isRequired,
  getProjects: func.isRequired,
  updateProject: func.isRequired,
  clearProjects: func.isRequired,
  clearProject: func.isRequired,
  clearTemplate: func.isRequired,
  infoNotification: func.isRequired,
};

export default withSpinner(Projects);
