import React, { useContext } from 'react';
import RefContext from '../../utils/refs-context';

export const withRefsContext = WrappedComponent => {
    return function RefsWrapper(props) {
        const { refs, setRefs } = useContext(RefContext);

        return <WrappedComponent refs={refs} setRefs={setRefs} {...props} />;
    };
};

export default withRefsContext;
