import { post, get, put, del } from './api';

export const createTemplate = values => post('/templates/create', values);
export const getTemplateById = id => get(`/templates/${id}`);
export const getAvailableTemplates = (id, query) =>
    get(`/projects/${id}/templates`, query);
export const getTemplates = query => get(`/templates`, query);
export const editTemplate = (id, data) => put(`/templates/${id}`, data);
export const deleteTemplate = id => del(`/templates/${id}`);
export const publishTemplate = id => put(`/templates/${id}/publish`);
export const archiveTemplate = id => put(`/templates/${id}/archive`);
export const duplicateTemplate = id => post(`/templates/${id}/duplicate`);
export const lockTemplate = id => post(`/templates/${id}/lock`);
export const unlockTemplate = id => post(`/templates/${id}/unlock`);
export const unlockAllTemplates = () => post(`/templates/unlockAll`);
