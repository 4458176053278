import React from 'react';
import Typography from '@material-ui/core/Typography';
import { string, bool } from 'prop-types';
import classes from './DownloadFileType.module.scss';
import Btn from '../Button';

const DownloadFileType = ({ text, href, children, disabled, onClick }) => (
    <Btn
        href={href}
        classes={{ label: classes.contentCenter }}
        className={classes.button}
        disabled={disabled}
        onClick={onClick}
    >
        <>
            <div className={classes.iconCenter}>{children}</div>
            <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                className={classes.buttonSubTitle}
            >
                {text}
            </Typography>
        </>
    </Btn>
);

DownloadFileType.propTypes = {
    href: string.isRequired,
    text: string.isRequired,
    disabled: bool,
};

export default DownloadFileType;
