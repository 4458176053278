import React, { useContext } from 'react';
import { any, bool, oneOf, shape } from 'prop-types';
import clsx from 'clsx';
import ContainerWrapper from '@material-ui/core/Container';
import AuthContext from '../../utils/auth-context';
import classes from './Container.module.scss';

function Container(props) {
    const {
        children,
        withBar,
        preset,
        className,
        innerClassName,
        maxWidth,
        noPadding,
        ...rest
    } = props;
    const { showMenu } = useContext(AuthContext);

    return (
        <div
            className={clsx(
                preset?.withHeader && classes.withHeader,
                preset?.withBar && classes.withBar,
                preset?.withHeaderAndBar && classes.withHeaderAndBar,
                preset?.withHeaderAndBarAndPagination &&
                    classes.withHeaderAndBarAndPagination,
                preset?.withHeaderBarPaginationAndFilters &&
                    classes.withHeaderBarPaginationAndFilters,
                className,
                classes.container,
                noPadding && classes.noPadding,
                showMenu && classes.AuthLayout,
            )}
        >
            <ContainerWrapper
                maxWidth={maxWidth}
                {...rest}
                className={clsx(
                    classes.innerContainer,
                    innerClassName,
                    noPadding && classes.noPadding,
                )}
            >
                {children}
            </ContainerWrapper>
        </div>
    );
}

Container.propTypes = {
    maxWidth: oneOf(['sm', 'md', 'lg', 'xl', false]),
    children: any,
    preset: shape({
        withBar: bool,
        withHeader: bool,
        withHeaderAndBar: bool,
        withHeaderAndBarAndPagination: bool,
        withHeaderBarPaginationAndFilters: bool,
    }),
};

export default Container;
