import { CANVAS_TYPE_OBJECTS, CHECK_AVAILABLE_COLOR_PICKER } from './canvas';

export const COLOR_PICKER_BACKGROUND = 'background_colorpicker';
export const COLOR_PICKER_BORDER = 'border_colorpicker';
export const COLOR_PICKER_COMMON = 'common_colorpicker';

export const getTypeColorPicker = (type, prop) => {
    if (type === CANVAS_TYPE_OBJECTS.canvas) {
        return COLOR_PICKER_BACKGROUND;
    } else if (prop === 'stroke') {
        return COLOR_PICKER_BORDER;
    } else if (CHECK_AVAILABLE_COLOR_PICKER.includes(type)) {
        return COLOR_PICKER_COMMON;
    }
};
