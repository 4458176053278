import * as Yup from 'yup';
import { locale } from '../../constants/locales';

export default Yup.object().shape({
    fontFamily: Yup.string(),
    fontSize: Yup.number()
        .transform(cv => Number(cv))
        .typeError(locale.Messages.MUST_BE_A_TYPE('number'))
        .min(1, locale.Messages.MUST_BE_BIGGER_THEN(0))
        .max(100, locale.Messages.MUST_BE_EQUAL_OR_LESS(100))
        .test('defined', locale.Messages.REQUIRED_FIELD, value => value !== undefined),
    charSpacing: Yup.number()
        .typeError(locale.Messages.MUST_BE_A_TYPE('number'))
        .min(-100, locale.Messages.MUST_BE_BIGGER_THEN(-100))
        .max(100, locale.Messages.MUST_BE_EQUAL_OR_LESS(100))
        .test('defined', locale.Messages.REQUIRED_FIELD, value => value !== undefined),
    lineHeight: Yup.number()
        .typeError(locale.Messages.MUST_BE_A_TYPE('number'))
        .min(0.1, locale.Messages.MUST_BE_BIGGER_THEN(0))
        .max(10, locale.Messages.MUST_BE_EQUAL_OR_LESS(10))
        .test('defined', locale.Messages.REQUIRED_FIELD, value => value !== undefined),
    maxCharacters: Yup.number()
        .typeError(locale.Messages.MUST_BE_A_TYPE('number'))
        .min(1, locale.Messages.MUST_BE_BIGGER_THEN(0))
        .nullable(),
});
