import { createTheme } from '@material-ui/core/styles';
import { COLORS } from './constants/colors';

export const theme = createTheme({
  shape: {
    borderRadius: 0,
  },
  palette: {
    divider: COLORS.secondary,
    background: {
      default: COLORS.lightgray,
      paper: COLORS.white,
    },
    common: {
      black: COLORS.black,
      white: COLORS.white,
    },
    error: {
      contrastText: COLORS.white,
      dark: COLORS.darkRed,
      light: COLORS.lightRed,
      main: COLORS.red,
    },
    primary: {
      contrastText: COLORS.white,
      dark: COLORS.primary,
      light: COLORS.gray,
      main: COLORS.primary,
    },
    secondary: {
      contrastText: COLORS.primary,
      dark: COLORS.midGray,
      light: COLORS.secondary,
      main: COLORS.secondary,
    },
  },
  typography: {
    fontFamily: 'SFProDisplay, Roboto, Arial',
    subtitle1: {
      fontWeight: 600,
      fontSize: 14,
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: 12,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': ['SFProDisplay'],
      },
    },
    MuiButton: {
      // Name of the rule
      outlinedSecondary: {
        // Some CSS
        borderColor: COLORS.primary,
        color: COLORS.primary,
        '&:hover': {
          borderColor: COLORS.black,
          backgroundColor: COLORS.secondary,
        },
      },
    },
  },
  props: {
    // The default props to change
    MuiButtonBase: {
      height: 36,
    },
  },
});
