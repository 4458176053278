import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { customFabric as fabric } from '../../../components/FabricComponents';
import { arrayOf, bool, func, number, object } from 'prop-types';
import Dropzone from 'react-dropzone';
import debouncePromise from 'debounce-promise-with-cancel';
import StoryCanvasPreview from '../StoryCanvasPreview';
import TemplateSlides from '../TemplateSlides';
import ActionBar from '../../../components/ActionBar';
import StoryOptionsForm from '../StoryOptionsForm';
import Grid from '../../../components/Grid';
import Actions from '../Actions';
import DurationNotifications from '../../../components/DurationNotifications';
import CanvasLayers from '../../../components/CanvasLayers';
import { useMount, usePrevious, useUnmount } from '../../../utils/hooks';
import {
    adjustImageWithCrop,
    adjustImageWithCropScaling,
    handleTextChange,
    saveCanvasState,
    findPlaceholder,
    getAllObjectsInPoint,
    getNewSlideDuration,
} from '../../../utils/canvas';
import { CanvasProvider } from '../../../utils/context';
import { createStorySlide } from '../../../utils/slides';
import {
    DEFAULT_BG_COLOR,
    HISTORY_DEFAULT_INDEX,
    ROOT_PATHS,
} from '../../../constants/variables';
import {
    ACCEPT_FILE_TYPES,
    CANVAS_TYPE_OBJECTS,
    DROP_ICON_CODE,
    DROP_ICON_OPTIONS,
} from '../../../constants/canvas';
import { locale } from '../../../constants/locales';
import SlideObject from '../../../types/Slide';
import ProjectObject from '../../../types/Project';
import classes from '../Editor.module.scss';
import Typography from '@material-ui/core/Typography';
import { TEMPLATE_SLIDE_DEFAULT_DURATION } from '../../../constants/slides';
import Spinner from '../../../components/Spinner';

const StoryEditor = ({
    authUser,
    width,
    height,
    project,
    blockHeight,
    handleCreate,
    handleUpdate,
    isProcessingCurrentProject,
    slides,
    canvasHistory,
    clearCanvasHistory,
    saveCanvasToHistory,
    clearDurationNotifications,
    errorNotification,
    warningNotification,
    hideLoader,
    showLoader,
}) => {
    const isMounted = useRef(true);
    const { id: projectId, companyName: projectCompanyName } = project;
    const [slide, setSlide] = useState({});
    const [initCanvas, setInitCanvas] = useState({});
    const [canvasIndex, setCanvasIndex] = useState(HISTORY_DEFAULT_INDEX);
    const [canvasData, setCanvasData] = useState({});
    const [activeObject, setActiveObject] = useState(null);
    const [dropObject, setDropObject] = useState(null);
    const prevDropObject = usePrevious(dropObject);
    const canvasIndexRef = useRef(canvasIndex);
    const canvasRef = useRef(initCanvas);
    const slideTemplateDurationRef = useRef(0);
    const [activeDrop, setActiveDrop] = useState(false);
    const [droppedFile, setDroppedFile] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        isMounted.current = true;
        if (canvasIndexRef) {
            canvasIndexRef.current = canvasIndex;
        }
        if (canvasRef) {
            canvasRef.current = initCanvas;
        }
    }, [canvasIndex, initCanvas]);

    useEffect(() => {
        const cleanUp = () => {
            if (prevDropObject.type === CANVAS_TYPE_OBJECTS.group) {
                prevDropObject
                    .getObjects()
                    .forEach(o => o.type === 'text' && prevDropObject.remove(o));
            } else {
                prevDropObject.set('opacity', 1);
            }
            if (initCanvas && Object.keys(initCanvas).length) {
                initCanvas.renderAll();
            }
        };
        // Hover on placeholder
        if (dropObject && dropObject !== prevDropObject) {
            setDropObject(dropObject);
            if (prevDropObject) {
                cleanUp();
            }
            if (dropObject.type === CANVAS_TYPE_OBJECTS.group) {
                dropObject.add(
                    new fabric.Text(DROP_ICON_CODE, DROP_ICON_OPTIONS(dropObject)),
                );
            } else {
                dropObject.set('opacity', 0.5);
            }
            if (initCanvas && Object.keys(initCanvas).length) {
                initCanvas.renderAll();
            }
        }
        // Leave placeholder
        if (dropObject == null && prevDropObject && dropObject !== prevDropObject) {
            //Reset
            setDropObject(null);
            cleanUp();
        }
    }, [dropObject, setDropObject, prevDropObject, initCanvas]);

    // TODO: Fix later!!!
    // Please, don't use await when execute because of very long time execution
    const saveCanvas = async (withHistoryUpdate = true, withPreview = false) => {
        if (canvasRef?.current) {
            await saveCanvasState(
                canvasRef.current,
                saveCanvasToHistory,
                canvasIndexRef.current,
                withHistoryUpdate,
                withPreview,
            );
            canvasRef.current.renderAll();
        }
    };

    const saveCanvasDebounce = debouncePromise(saveCanvas, 400);

    useMount(() => {
        // TODO Find how to se forwardRef here
        const initialCanvas = new fabric.Canvas(
            document.getElementById('preview-canvas'),
            {
                preserveObjectStacking: true,
                controlsAboveOverlay: true,
                skipOffscreen: true,
                selection: false,
                type: 'Canvas',
                backgroundColor: DEFAULT_BG_COLOR,
            },
        );
        // Attach listeners
        initialCanvas.on('object:moving', ({ target }) => {
            adjustImageWithCrop(target);
        });
        initialCanvas.on('object:modified', ({ target }) => {
            if (target) {
                isMounted.current && setActiveObject(target);
            }
            // Send API requests and update preview with no await
            saveCanvas();
        });
        initialCanvas.on('object:scaling', ({ target }) => {
            adjustImageWithCropScaling(target);
        });
        initialCanvas.on('object:removed', () => {
            isMounted.current && setActiveObject(null);
            initialCanvas.renderAll();
        });
        initialCanvas.on('selection:created', ({ target }) => {
            if (target) {
                isMounted.current && setActiveObject(target);
            }
        });
        initialCanvas.on('selection:cleared', event => {
            // Restore layers block
            initialCanvas.forEachObject(obj => obj.set('evented', true));
            initialCanvas.renderAll();
            if (event.target) {
                isMounted.current && setActiveObject(null);
            }
        });
        initialCanvas.on('selection:updated', ({ target }) => {
            if (target) {
                isMounted.current && setActiveObject(target);
            }
        });
        initialCanvas.on('mouse:down', ({ target }) => {
            if (target) {
                isMounted.current && setActiveObject(target);
            }
        });
        initialCanvas.on('text:changed', async ({ target }) => {
            handleTextChange(target, errorNotification);
        });
        // Drag&drop
        initialCanvas.on('drop', event => {
            const {
                e: { offsetX, offsetY },
            } = event;
            const point = new fabric.Point(offsetX, offsetY);

            initialCanvas.discardActiveObject();
            isMounted.current && setActiveObject(null);

            const allObjects = getAllObjectsInPoint(initialCanvas, point);
            const currentObject = findPlaceholder(allObjects, point);

            if (currentObject) {
                initialCanvas.setActiveObject(currentObject);
                isMounted.current && setActiveObject(currentObject);
                initialCanvas.renderAll();
            } else {
                isMounted.current && setActiveObject(null);
                initialCanvas.discardActiveObject();
                warningNotification(locale.Messages.SELECT_PLACEHOLDER, authUser, {
                    preventDuplicate: true,
                });
            }

            initialCanvas.renderAll();
        });
        // Zoom image with Mouse scroll + Ctrl
        initialCanvas.on('mouse:wheel', event => {
            const { e, target } = event;
            if (
                e.ctrlKey &&
                target &&
                target?.clipPath?.type === CANVAS_TYPE_OBJECTS.animatedPlaceholder
            ) {
                // step for scaling
                const step = 0.4;
                // Get delta sign from event, invert it and use const value
                const shift = Math.sign(e.deltaY) * -1 * 100;
                const delta = shift / 1000;
                const { minScaleLimit, scaleX, top, left } = target;
                const minScale =
                    minScaleLimit ||
                    Math.max(
                        target.getScaledWidth() / target.width,
                        target.getScaledHeight() / target.height,
                    );
                let initialZoom = scaleX;
                initialZoom += delta * step;
                if (initialZoom > 2) initialZoom = 2;
                if (initialZoom < minScale) initialZoom = minScale;
                const { x, y } = target.getCenterPoint();
                target.set({
                    scaleX: initialZoom,
                    scaleY: initialZoom,
                });
                target.setCoords();
                const { x: offsetX, y: offsetY } = target.getCenterPoint();
                target.set({
                    left: left - (offsetX - x),
                    top: top - (offsetY - y),
                });
                adjustImageWithCropScaling(target);
                initialCanvas.renderAll();
                saveCanvasDebounce();
            }
            // Block mouse wheel events above canvas
            e.preventDefault();
            e.stopPropagation();
        });
        // Initialize canvas
        setInitCanvas(initialCanvas);
        setLoading(false);
        initialCanvas.requestRenderAll();
    });

    useUnmount(() => {
        isMounted.current = false;
        setCanvasData(null);
    });

    useEffect(() => {
        setCanvasIndex(canvasHistory.length - 1);
        if (canvasHistory.length > 0) {
            setCanvasData(canvasHistory[canvasHistory.length - 1]);
        }
        if (Object.keys(initCanvas).length) {
            initCanvas.renderAll();
        }
    }, [canvasHistory.length, setCanvasIndex, canvasHistory, initCanvas]);

    const undo = () => {
        discardActiveObject();
        const newIndex = canvasIndex - 1 >= 0 ? canvasIndex - 1 : 0;
        setCanvasData(canvasHistory[newIndex]);
        setCanvasIndex(newIndex);
    };

    const redo = () => {
        discardActiveObject();
        const newIndex =
            canvasIndex + 1 < canvasHistory.length - 1
                ? canvasIndex + 1
                : canvasHistory.length - 1;
        setCanvasData(canvasHistory[newIndex]);
        setCanvasIndex(newIndex);
    };

    async function selectSlide(data, templateId) {
        // console.info('selectSlide', data.id, data.imageUrl);
        if (data.id) {
            // Initial load when canvasData is empty
            if (canvasData && Object.keys(canvasData).length === 0) {
                saveCanvasToHistory(data.canvasData, HISTORY_DEFAULT_INDEX, false);
                setCanvasData(data.canvasData);
            }
            // Save canvas with preview and API call
            if (slide && Object.keys(slide).length && data.id !== slide.id) {
                // Only call saving if slide canvas was updated
                if (slide.isChangedAfterProcessing) {
                    saveCanvas(false);
                }
                if (data?.canvasData) {
                    await clearCanvasHistory();
                    setCanvasData(data.canvasData);
                    await saveCanvasToHistory(
                        data.canvasData,
                        HISTORY_DEFAULT_INDEX,
                        false,
                    );
                    setActiveObject(null);
                }
            }
            slideTemplateDurationRef.current = getNewSlideDuration(data);
            setSlide(data);
        } else if (!data.id && data.imageUrl === 'empty') {
            // Create new slide
            try {
                showLoader();
                let newSlide;
                if (Array.isArray(templateId)) {
                    const newSlides = await Promise.all(
                        templateId.map((id, index) =>
                            handleCreate(
                                createStorySlide(
                                    projectId,
                                    new Array(index + slides.length),
                                    id,
                                ),
                            ),
                        ),
                    );
                    // Select first slide to fit apps/frontend/src/app/containers/Editor/TemplateSlides/TemplateSlides.js:112
                    newSlide = newSlides[0];
                } else {
                    newSlide = await handleCreate(
                        createStorySlide(projectId, slides, templateId),
                    );
                }

                if (newSlide) {
                    if (newSlide?.canvasData) {
                        const slideDuration = getNewSlideDuration(newSlide);
                        await handleUpdate(
                            {
                                id: newSlide.id,
                                duration:
                                    slideDuration > 0
                                        ? slideDuration
                                        : TEMPLATE_SLIDE_DEFAULT_DURATION,
                            },
                            false,
                        );
                        slideTemplateDurationRef.current = slideDuration;
                        await clearCanvasHistory();
                        setCanvasData(newSlide.canvasData);
                        await saveCanvasToHistory(
                            newSlide.canvasData,
                            HISTORY_DEFAULT_INDEX,
                            true,
                        );
                    }
                }
            } catch (e) {
                console.error(e);
            } finally {
                hideLoader();
            }
        }
        // Delete last slide case
        if (data && !Object.keys(data).length) {
            clearCanvas();
            clearCanvasHistory();
            setSlide({});
        }
    }

    const discardActiveObject = () => {
        if (Object.keys(initCanvas).length) {
            initCanvas.discardActiveObject();
        }
    };

    const clearCanvas = () => {
        if (Object.keys(initCanvas).length) {
            clearCanvasHistory();
            clearDurationNotifications();
            initCanvas.clear();
            initCanvas.set('backgroundColor', DEFAULT_BG_COLOR);
            setCanvasData({});
        }
    };

    const onDragOccur = bool => {
        isMounted.current && setActiveDrop(bool);
    };

    const onDragOver = event => {
        if (initCanvas && Object.keys(initCanvas).length) {
            const { clientX, clientY } = event;
            const { left, top } = initCanvas.lowerCanvasEl.getBoundingClientRect();
            const offsetX = clientX - left;
            const offsetY = clientY - top;
            const point = new fabric.Point(offsetX, offsetY);
            const allObjects = getAllObjectsInPoint(initCanvas, point);
            if (allObjects.length) {
                const currentObject = findPlaceholder(allObjects, point);
                if (currentObject && Object.keys(currentObject).length) {
                    setDropObject(currentObject);
                } else {
                    setDropObject(null);
                }
            } else {
                setDropObject(null);
            }
        }
    };

    const onDropAccepted = files => {
        if (isMounted.current) {
            setDropObject(null);
            setActiveDrop(false);
            if (files && files.length === 1) {
                setDroppedFile(files[0]);
            } else {
                warningNotification(locale.Messages.SELECT_ONLY_ONE_FILE, authUser, {
                    preventDuplicate: true,
                });
                setDroppedFile([]);
            }
        }
    };

    const onDropRejected = () => {
        errorNotification({
            message: locale.Messages.FILE_SHOULD_BE_MEDIA_FILE,
        });
        if (isMounted.current) {
            setActiveDrop(false);
            setDroppedFile([]);
        }
    };

    return (
        <>
            {isLoading && (
                <Spinner loading={isLoading} fixed opaque={canvasIndexRef.current < 0} />
            )}
            <CanvasProvider value={{ canvas: initCanvas }}>
                <ActionBar
                    projectId={projectId}
                    goBackLink={ROOT_PATHS.projects}
                    showPreview
                    // withValidation here is doing saving when user clicks back button
                    // since we are doing saving canvas every time when something is changed
                    // we may noе do one more saving on back button click
                    withValidation
                    handleFormUpdate={() => saveCanvas(true, true)}
                    undoRedoButtons
                    undo={undo}
                    redo={redo}
                    canvasIndex={canvasIndex}
                    canvasHistoryLength={canvasHistory.length}
                    storyDownloadButton
                    processVideoDisabled={isProcessingCurrentProject}
                    currentSlide={slide}
                    editorMode
                />

                <Grid
                    item
                    xs={3}
                    className={clsx(classes.borderRight, classes.overflow)}
                    style={{ height: blockHeight }}
                >
                    <CanvasLayers
                        slide={slide}
                        canvasData={canvasData}
                        activeObject={activeObject}
                        setActiveObject={setActiveObject}
                        dndDisabled
                    />
                </Grid>
                <Grid
                    style={{ height: blockHeight }}
                    item
                    xs={6}
                    className={classes.relative}
                >
                    <Dropzone
                        noClick
                        accept={ACCEPT_FILE_TYPES}
                        onDragOver={ev => onDragOver(ev)}
                        onDragEnter={() => onDragOccur(true)}
                        onDragLeave={() => onDragOccur(false)}
                        onDropAccepted={onDropAccepted}
                        onDropRejected={onDropRejected}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div
                                {...getRootProps({
                                    className: clsx(
                                        'dropzone',
                                        activeDrop && 'activeDrop',
                                    ),
                                })}
                            >
                                <input {...getInputProps()} />
                                <StoryCanvasPreview
                                    projectCompanyName={projectCompanyName}
                                    width={width}
                                    height={height}
                                    setUpCanvas={setInitCanvas}
                                    activeObject={activeObject}
                                    canvasData={canvasData}
                                    canvasIndex={canvasIndex}
                                    currentSlideId={slide?.id}
                                />
                            </div>
                        )}
                    </Dropzone>
                    <DurationNotifications />
                </Grid>
                <Grid
                    item
                    style={{ height: blockHeight }}
                    xs={3}
                    className={clsx(
                        classes.borderLeft,
                        classes.overflowScroll,
                        classes.relative,
                        classes.rightPanel,
                        classes.flexColumn,
                    )}
                >
                    <div className={classes.storySlideOptions}>
                        <Actions
                            activeObject={activeObject}
                            saveCanvas={saveCanvas}
                            handleUpdate={handleUpdate}
                            toggleLoading={setLoading}
                            droppedFile={droppedFile}
                            setDroppedFile={setDroppedFile}
                            slideDuration={slide?.duration}
                        />
                        {slide && !!Object.keys(slide).length && (
                            <StoryOptionsForm
                                slide={slide}
                                handleUpdate={handleUpdate}
                                slideTemplateDuration={slideTemplateDurationRef.current}
                            />
                        )}
                    </div>
                    {slide && !!Object.keys(slide).length && (
                        <div className={classes.additionalInfo}>
                            {(slide?.duration === 0 || slide?.duration >= 1) && (
                                <Typography
                                    align="left"
                                    variant="caption"
                                    display="block"
                                >
                                    Template duration: {slideTemplateDurationRef.current}{' '}
                                    seconds
                                </Typography>
                            )}
                        </div>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <TemplateSlides
                        projectId={projectId}
                        activeSlide={slide}
                        selectSlide={selectSlide}
                        setSlide={setSlide}
                    />
                </Grid>
            </CanvasProvider>
        </>
    );
};

StoryEditor.propTypes = {
    width: number.isRequired,
    height: number.isRequired,
    project: ProjectObject.isRequired,
    history: object.isRequired,
    blockHeight: number.isRequired,
    slides: arrayOf(SlideObject),
    canvasHistory: arrayOf(object),
    handleCreate: func.isRequired,
    handleUpdate: func.isRequired,
    saveCanvasToHistory: func.isRequired,
    clearCanvasHistory: func.isRequired,
    clearDurationNotifications: func.isRequired,
    isProcessingCurrentProject: bool.isRequired,
    errorNotification: func.isRequired,
    showLoader: func.isRequired,
    hideLoader: func.isRequired,
};

export default StoryEditor;
