import { oneOf, oneOfType, shape, string } from 'prop-types';
import User from './User';
import { STATUSES_PROJECT } from '../constants/statuses';
import { COMPANIES } from '../constants/companies';
import { PROJECT_TYPES } from '../constants/project-types';

export default shape({
    id: string.isRequired,
    title: string.isRequired,
    videoUrl: oneOfType([string]),
    companyName: oneOf([COMPANIES.OPUS, COMPANIES.SOMEDAY]),
    lockedBy: User,
    projectType: oneOf([PROJECT_TYPES.ACTIVE_SELLER, PROJECT_TYPES.STORY]).isRequired,
    status: oneOf([
        STATUSES_PROJECT.NEW,
        STATUSES_PROJECT.FAILED,
        STATUSES_PROJECT.READY,
    ]),
    createdAt: string.isRequired,
    updatedAt: string.isRequired,
    canvasDataUpdatedAt: string.isRequired,
});
