// We cannot override expanded styling through SCSS :(
import { COLORS } from '../../constants/colors';

export default theme => ({
    overflow: {
        maxHeight: '50%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    panel: {
        width: '100%',
        backgroundColor: COLORS.transparent,
        '&.Mui-expanded': {
            margin: 0,
        },
        '&:before': {
            display: 'none',
        },
    },
    content: {
        margin: '0',
        minHeight: theme.spacing(8),
        '&.Mui-expanded': {
            margin: '0',
        },
    },
    heading: {
        fontWeight: 'bold',
        padding: theme.spacing(1),
        margin: 0,
        alignSelf: 'center',
    },
    list: {
        width: '100%',
    },
    details: {
        display: 'block',
    },
    icon: {
        backgroundColor: 'transparent',
        minWidth: theme.spacing(5),
    },
    defaultColor: {
        color: theme.palette.text.primary,
    },
    imageIcon: {
        maxWidth: '80%',
        maxHeight: '80%',
    },
    dragging: {
        backgroundColor: theme.palette.action.hover,
    },
    nonDragging: {
        position: 'initial',
    },
    listItem: {
        // borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.grey['50']}`,
        '&.Mui-focusVisible': {
            background: 'none',
        },
        '.MuiToggleButton-root': {
            opacity: 0,
        },
        '&:hover .MuiToggleButton-root': {
            opacity: 1,
        },
    },
    activeObject: {
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.action.hover,
    },
});
