import React, { useState, useEffect, useContext } from 'react';
import { number, shape } from 'prop-types';
import { WithCanvasContext } from '../../utils/context';
import RefContext from '../../utils/refs-context';
import { rgb2hex } from '../../utils/helpers';
import CanvasObject from '../../types/Canvas';
import classes from './CanvasZoom.module.scss';

const CanvasZoom = ({ canvas, cursorPosition = { left: 0, top: 0 } }) => {
    const { refs } = useContext(RefContext);
    const { eyedropperOn = false } = refs;
    const [color, setColor] = useState(null);
    const [src, setSrc] = useState(null);
    const multiplier = 5;

    useEffect(() => {
        if (canvas) {
            if (!src) {
                const img = canvas?.toDataURL({
                    format: 'jpeg',
                    quality: 0.5,
                    multiplier,
                    withoutShadow: true,
                    withoutTransform: true,
                });
                setSrc(img);
                canvas.requestRenderAll();
            }
            const ctx = canvas.getContext('2d');
            const { devicePixelRatio } = window;
            const [r, g, b] = ctx.getImageData(
                +cursorPosition.left * devicePixelRatio,
                +cursorPosition.top * devicePixelRatio,
                1,
                1,
            ).data;
            setColor(rgb2hex(`rgb(${[r, g, b].join()})`));
        }
    }, [canvas, src, cursorPosition]);

    return eyedropperOn && src ? (
        <div className={classes.zoomPreviewWrapper}>
            <div className={classes.zoomPreview}>
                <div className={classes.grid} />
                <div className={classes.colorContainer}>{color}</div>
                <img
                    style={{
                        left: 79 - cursorPosition.left * multiplier,
                        top: 79 - cursorPosition.top * multiplier,
                    }}
                    className={classes.canvasPreview}
                    src={src}
                    alt="Canvas zoomed preview"
                />
            </div>
        </div>
    ) : null;
};
CanvasZoom.propTypes = {
    canvas: CanvasObject,
    cursorPosition: shape({
        left: number,
        top: number,
    }).isRequired,
};
export default WithCanvasContext(CanvasZoom);
