import React, { useState } from 'react';
import { bool, object, shape, string } from 'prop-types';
import ItemLockedWarning from '../../components/ItemLockedWarning';
import Grid from '../../components/Grid';
import TemplatePreview from '../../containers/TemplatePreview';
import { EDITOR_TYPE } from '../../constants/item';
import { useMount } from '../../utils/hooks';
import classes from '../../components/CanvasPreview/CanvasPreview.module.scss';

function TemplatePreviewPage({ match: { params }, getTemplateById }) {
    const { templateId } = params;
    const [canvasDataCurrentTemplate, setCanvasDataCurrentTemplate] = useState(null);

    const fetchTemplate = async () => {
        const currentTemplate = await getTemplateById(templateId);
        if (Object.keys(currentTemplate).length) {
            const { canvasData } = currentTemplate;
            setCanvasDataCurrentTemplate(canvasData);
        }
    };

    useMount(() => {
        try {
            fetchTemplate();
        } catch (e) {
            console.error(e);
        }
    });

    return (
        <Grid container spacing={0} className={classes.canvasPreview}>
            {canvasDataCurrentTemplate && (
                <TemplatePreview
                    canvasData={canvasDataCurrentTemplate}
                    templateId={templateId}
                />
            )}
            <ItemLockedWarning itemId={templateId} editorType={EDITOR_TYPE.TEMPLATE} />
        </Grid>
    );
}

TemplatePreviewPage.propTypes = {
    match: shape({
        isExact: bool,
        params: object.isRequired,
        path: string,
        url: string,
    }),
};

export default TemplatePreviewPage;
