import { connect } from 'react-redux';
import UserNotificationsHistoryPage from './UserNotificationsHistoryPage';
import { getUserNotifications } from '../../actions/user-notifications';

const mapStateToProps = state => ({
    authUser: state?.auth?.user,
    list: state.userNotificationsHistory && state.userNotificationsHistory.list,
    totalItems:
        state.userNotificationsHistory && state.userNotificationsHistory.totalItems,
});

const mapDispatchToProps = {
    getUserNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserNotificationsHistoryPage);
