import React from 'react';
import { func, node, oneOfType, object, oneOf, string, bool } from 'prop-types';
import clsx from 'clsx';
import { ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { theme } from '../../theme';
import classes from './Button.module.scss';

/**
 * @param text - Text for button
 * @param disabled - If true, the button will be disabled.
 * @param children - list of options
 * @param to - list of options
 * @param location - Avoid redirects on the same page and saving to history
 * @param component - The component used for the root node. Either a string to use a DOM element or a component or link.
 * @param color - The color of the component. It supports those theme colors that make sense for this component.
 * @param size - Size
 * @param btnType - Type of button
 * @param className - classes
 * @param marginReset
 * @param onlyIcon - Only icon
 * @param rest - other default props
 * */

function Btn({
    text,
    disabled = false,
    children,
    to,
    location,
    component = Link,
    btnType,
    className,
    color,
    marginReset,
    size,
    onlyIcon,
    ...rest
}) {
    let links;
    if (to) {
        links = {
            component,
            to,
        };
        if (component === 'button') {
            delete links.to;
            links.href = to;
        }
    }
    // Avoid redirects on the same page and saving to history
    if (location && location.pathname === to) {
        links = undefined;
    }

    return (
        <ThemeProvider theme={theme}>
            <Button
                {...rest}
                {...links}
                disabled={disabled}
                color={color}
                className={clsx(
                    classes.btn,
                    classes[btnType] || '',
                    (size === 'small' && classes.smallBtn) ||
                        (size === 'medium' && classes.mediumBtn) ||
                        (size === 'large' && classes.largeBtn) ||
                        '',
                    marginReset && classes.marginReset,
                    className,
                )}
            >
                {onlyIcon && <span className={classes.onlyIcon}>{onlyIcon}</span>}
                {children && <span className={classes.children}>{children}</span>}
                {text && <span className={classes.textBtn}>{text}</span>}
            </Button>
        </ThemeProvider>
    );
}

Btn.propTypes = {
    className: oneOfType([object, string]),
    size: oneOf(['small', 'medium', 'large']),
    color: oneOf(['inherit', 'primary', 'secondary']),
    btnType: oneOf([
        'defaultBtn',
        'primaryBtn',
        'secondaryBtn',
        'simpleBtn',
        'collapseBtn',
    ]),
    text: string,
    to: string,
    onClick: func,
    onlyIcon: node,
    marginReset: bool,
    startIcon: node, // Element placed before the children.
    endIcon: node, // Element placed after the children.
};

export default Btn;
