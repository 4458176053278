import { connect } from 'react-redux';
import ProjectPreviewPage from './ProjectPreviewPage';
import { getSlides } from '../../actions/slides';
import { getProjectById, clearProject } from '../../actions/projects';

const mapStateToProps = state => ({
    project: state?.project,
    slides: state?.slides?.list || [],
});

const mapDispatchToProps = {
    getSlides,
    getProjectById,
    clearProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPreviewPage);
