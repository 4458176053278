import { connect } from 'react-redux';
import ItemLockedWarning from './ItemLockedWarning';
import {
    continueEdit,
    lockItem,
    unlockItemAndFetchData,
    clearFinishEditingMessage,
} from '../../actions/lockedItems';
import { go } from '../../actions/navigation';

const mapStateToProps = state => ({
    isStillEditing: state?.lockedItems?.isStillEditing,
    finishEditing: state?.lockedItems?.finishEditing,
    authUser: state.auth && state.auth.user,
});

const mapDispatchToProps = {
    go,
    lockItem,
    continueEdit,
    unlockItemAndFetchData,
    clearFinishEditingMessage,
};
export default connect(mapStateToProps, mapDispatchToProps)(ItemLockedWarning);
