import React, { useState, useRef, useContext } from 'react';
import { func } from 'prop-types';
import { Form } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '../../components/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormikWrapper from '../FormikWrapper';
import FormikField from '../FormikField';
import { useMount, useUnmount } from '../../utils/hooks';
import Btn from '../Button';
import {
    SELECT_FIELD,
    PASSWORD_FIELD,
    TEXT_FIELD,
    MULTIPLE_SELECT_FIELD,
} from '../../constants/variables';
import { locale } from '../../constants/locales';
import AuthContext from '../../utils/auth-context';
import classes from './FormDialog.module.scss';

export const withFormDialog = WrappedComponent => {
    return function FormDialog(props) {
        const { companyNames } = useContext(AuthContext);
        const mounted = useRef(false);
        const form = useRef(null);
        const [open, setOpen] = useState(false);
        const [formValues, setValues] = useState({});
        const [typeValues, setTypeValues] = React.useState({});
        const [dialogConfig, setConfig] = React.useState({});

        useMount(() => {
            mounted.current = true;
        });
        useUnmount(() => {
            mounted.current = false;
        });
        function setFormValues(fields) {
            const values = {};
            const typesValues = {};
            if (fields && fields.length > 0) {
                fields.map(field => {
                    values[field.id] = field.value || '';
                    typesValues[field.id] = {
                        type: field.type,
                        showType: field.type,
                    };
                    // If user have only one company we're hiding "Show all" option
                    if (field.id === 'companyNames') {
                        if (field.type === MULTIPLE_SELECT_FIELD) {
                            values[field.id] =
                                field.options.length > 1
                                    ? field.selectedValues
                                    : companyNames?.data;
                        } else {
                            const [item] = companyNames.data;
                            values[field.id] = item;
                        }
                    }

                    return true;
                });
            }
            if (mounted?.current) {
                setValues(values);
                setTypeValues(typesValues);
            }
        }

        function handleClickOpen(config = {}) {
            // Config is passed from ProjectList - override if it is an event object
            setConfig({ ...{}, ...config });
            setFormValues(config.fields);
            if (!open) {
                setOpen(true);
            }
        }

        const handleChangeType = prop => {
            const type =
                typeValues[prop].showType === PASSWORD_FIELD
                    ? TEXT_FIELD
                    : PASSWORD_FIELD;
            const updatedField = {
                ...typeValues[prop],
                showType: type,
            };
            setTypeValues({ ...typeValues, [prop]: updatedField });
        };

        function handleClose() {
            setOpen(false);
        }

        const submit = async (values, submitProps) => {
            const { setSubmitting, validateForm } = submitProps;
            const errors = await validateForm(values);
            await setSubmitting(false);
            if (!Object.keys(errors).length) {
                const { confirmAction } = dialogConfig.confirmAction
                    ? dialogConfig
                    : props;
                const close = await confirmAction(values, dialogConfig.id);
                if (close) {
                    handleClose();
                }
            }
        };

        async function handleAction() {
            await form?.current?.handleSubmit?.();
        }

        const onKeyPress = async ev => {
            if (ev && ev.key === 'Enter') {
                ev.preventDefault();
                await handleAction();
            }
        };

        return (
            <>
                <WrappedComponent
                    {...props}
                    openFormDialog={handleClickOpen}
                    closeFormDialog={handleClose}
                    setFormValues={setFormValues}
                />
                <Dialog
                    fullWidth
                    maxWidth="xs"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    classes={{
                        paper: classes.dialog,
                    }}
                >
                    <DialogTitle id="form-dialog-title" classes={{ root: classes.title }}>
                        {dialogConfig.dialogTitle}
                    </DialogTitle>
                    <DialogContent classes={{ root: classes.noSidePadding }}>
                        <DialogContentText>{dialogConfig.dialogText}</DialogContentText>
                        {!!Object.keys(dialogConfig).length && (
                            <FormikWrapper
                                ref={form}
                                initialValues={formValues}
                                validationSchema={dialogConfig.validationSchema || null}
                                enableReinitialize
                                onSubmit={submit}
                            >
                                {({ values, handleSubmit, isSubmitting }) =>
                                    Object.keys(values).length > 0 && (
                                        <Form
                                            className={classes.form}
                                            onSubmit={handleSubmit}
                                            onKeyPress={onKeyPress}
                                        >
                                            {dialogConfig.fields.map((field, index) => (
                                                <FormikField
                                                    key={field.id}
                                                    className={classes.gutterBottom}
                                                    label={field.label}
                                                    select={[
                                                        SELECT_FIELD,
                                                        MULTIPLE_SELECT_FIELD,
                                                    ].includes(field.type)}
                                                    multiple={
                                                        field.type ===
                                                        MULTIPLE_SELECT_FIELD
                                                    }
                                                    margin="dense"
                                                    variant={field.variant || 'outlined'}
                                                    type={typeValues[field.id].showType}
                                                    name={field.id}
                                                    options={field.options}
                                                    autoComplete={
                                                        field.autocomplete || 'off'
                                                    }
                                                    selectedValues={values[field.id]}
                                                    fullWidth
                                                    autoFocus={index === 0}
                                                    onChangeCallback={
                                                        field.onChangeCallback
                                                    }
                                                    disabled={
                                                        isSubmitting ||
                                                        field.disabled ||
                                                        (field.options &&
                                                            [SELECT_FIELD].includes(
                                                                field.type,
                                                            ) &&
                                                            field.options.length < 2)
                                                    }
                                                    InputProps={{
                                                        endAdornment: typeValues[field.id]
                                                            .type === PASSWORD_FIELD && (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label={
                                                                        locale.TOGGLE_PASS
                                                                    }
                                                                    onClick={() =>
                                                                        handleChangeType(
                                                                            field.id,
                                                                        )
                                                                    }
                                                                >
                                                                    {typeValues[field.id]
                                                                        .showType ===
                                                                    PASSWORD_FIELD ? (
                                                                        <VisibilityOff />
                                                                    ) : (
                                                                        <Visibility />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            ))}
                                        </Form>
                                    )
                                }
                            </FormikWrapper>
                        )}
                    </DialogContent>
                    <DialogActions classes={{ root: classes.noSidePadding }}>
                        <Btn
                            text="Cancel"
                            variant="outlined"
                            color="secondary"
                            onClick={handleClose}
                        />
                        <Btn
                            color="primary"
                            variant="contained"
                            text={dialogConfig.actionBtnText}
                            // color={dialogConfig.alertBtnColor || 'primary'}
                            // variant="text"
                            onClick={() => handleAction()}
                        />
                    </DialogActions>
                </Dialog>
            </>
        );
    };
};

withFormDialog.propTypes = {
    confirmAction: func.isRequired,
    noCloseOnAction: func.isRequired,
};
export default withFormDialog;
