import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { COLORS } from './colors';

export function RectIcon(props) {
    return (
        <SvgIcon {...props}>
            <rect width="24" height="24" fill="transparent" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 7H19V4H17V7H14V9H17V12H19V9H22V7ZM17 15V18H5V9H11V7H3V20H19V15H17Z"
                fill={props?.fill || COLORS.black}
            />
        </SvgIcon>
    );
}

export function PlaceholderIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M19.5798 3.08L3.14982 19.51C3.23982 19.85 3.41982 20.16 3.65982 20.41C3.90982 20.65 4.21982 20.83 4.55982 20.92L20.9998 4.49C20.8098 3.8 20.2698 3.26 19.5798 3.08ZM11.9498 3L3.06982 11.88V14.71L14.7798 3H11.9498ZM5.06982 3C3.96982 3 3.06982 3.9 3.06982 5V7L7.06982 3H5.06982ZM19.0698 21C19.6198 21 20.1198 20.78 20.4798 20.41C20.8498 20.05 21.0698 19.55 21.0698 19V17L17.0698 21H19.0698ZM9.35982 21H12.1898L21.0698 12.12V9.29L9.35982 21Z"
                fill={props?.fill || COLORS.black}
            />
        </SvgIcon>
    );
}

export function SendMessage(props) {
    return (
        <SvgIcon {...props}>
            <rect width="25" height="22" fill="transparent" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.6735 2.92544L10.876 9.10781L12.9853 17.9703L21.6735 2.92544ZM10.0135 9.11762L12.2439 19.2545L12.5625 20.7026L13.3039 19.4185L22.8261 2.92544L23.7845 1.26543L23.8926 1.20258L23.8208 1.20258L21.9032 1.20258L2.85874 1.20258L1.37598 1.20258L2.47077 2.20258L10.0306 9.10781L10.0135 9.11762ZM21.376 2.20258L10.376 8.20258L3.95353 2.20258H21.376Z"
                fill={props?.fill || COLORS.white}
                stroke={props?.stroke || COLORS.white}
                strokeWidth="0.5"
            />
        </SvgIcon>
    );
}

export function TakePhotoIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.3284 2.89655C8.8406 2.89655 7.63447 4.10268 7.63447 5.59051H5.19085C4.0607 5.59051 3.14453 6.50668 3.14453 7.63683V17.014C3.14453 18.1442 4.0607 19.0603 5.19085 19.0603H19.058C20.1881 19.0603 21.1043 18.1442 21.1043 17.014V7.63683C21.1043 6.50668 20.1881 5.59051 19.058 5.59051H16.6144C16.6144 4.10268 15.4082 2.89655 13.9204 2.89655H10.3284ZM8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12ZM12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6Z"
                fill={props?.fill || COLORS.white}
            />
        </SvgIcon>
    );
}

export function ProjectsIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 3C5.44772 3 5 3.44772 5 4V10C5 10.5523 5.44772 11 6 11H8C8.55228 11 9 10.5523 9 10V4C9 3.44772 8.55228 3 8 3H6ZM11 4H13V10H11V4ZM10 4C10 3.44772 10.4477 3 11 3H13C13.5523 3 14 3.44772 14 4V10C14 10.5523 13.5523 11 13 11H11C10.4477 11 10 10.5523 10 10V4ZM15 4C15 3.44772 15.4477 3 16 3H18C18.5523 3 19 3.44772 19 4V10C19 10.5523 18.5523 11 18 11H16C15.4477 11 15 10.5523 15 10V4ZM6 14H8V20H6V14ZM5 14C5 13.4477 5.44772 13 6 13H8C8.55228 13 9 13.4477 9 14V20C9 20.5523 8.55228 21 8 21H6C5.44772 21 5 20.5523 5 20V14ZM10 14C10 13.4477 10.4477 13 11 13H13C13.5523 13 14 13.4477 14 14V20C14 20.5523 13.5523 21 13 21H11C10.4477 21 10 20.5523 10 20V14ZM16 14H18V20H16V14ZM15 14C15 13.4477 15.4477 13 16 13H18C18.5523 13 19 13.4477 19 14V20C19 20.5523 18.5523 21 18 21H16C15.4477 21 15 20.5523 15 20V14Z"
                fill={props?.fill || COLORS.primary}
            />
        </SvgIcon>
    );
}

export function TemplatesIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 4C4.44772 4 4 4.44772 4 5V9C4 9.55228 4.44772 10 5 10H13C13.5523 10 14 9.55228 14 9V5C14 4.44772 13.5523 4 13 4H5ZM8.5 12C8.22386 12 8 12.2239 8 12.5C8 12.7761 8.22386 13 8.5 13H13.5C13.7761 13 14 12.7761 14 12.5C14 12.2239 13.7761 12 13.5 12H8.5ZM8 14.5C8 14.2239 8.22386 14 8.5 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H8.5C8.22386 15 8 14.7761 8 14.5ZM13 16C12.4477 16 12 16.4477 12 17V19C12 19.5523 12.4477 20 13 20H19C19.5523 20 20 19.5523 20 19V17C20 16.4477 19.5523 16 19 16H13Z"
                fill={props?.fill || COLORS.primary}
            />
            <rect
                x="6.5"
                y="2.5"
                width="11"
                height="19"
                rx="0.5"
                fill={props?.fill || COLORS.transparent}
                stroke={props?.stroke || COLORS.primary}
            />
        </SvgIcon>
    );
}

export function UserManagementIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 10C9.65685 10 11 8.65685 11 7C11 5.34315 9.65685 4 8 4C6.34315 4 5 5.34315 5 7C5 8.65685 6.34315 10 8 10ZM17 10C18.1046 10 19 9.10457 19 8C19 6.89543 18.1046 6 17 6C15.8954 6 15 6.89543 15 8C15 9.10457 15.8954 10 17 10ZM4 13C4 11.8954 4.89543 11 6 11H10C11.1046 11 12 11.8954 12 13V19C12 19.5523 11.5523 20 11 20H5C4.44772 20 4 19.5523 4 19V13ZM16 11C14.8954 11 14 11.8954 14 13V17C14 17.5523 14.4477 18 15 18H19C19.5523 18 20 17.5523 20 17V13C20 11.8954 19.1046 11 18 11H16Z"
                fill={props?.fill || COLORS.primary}
            />
        </SvgIcon>
    );
}

export function CapitalizeIcon(props) {
    return (
        <SvgIcon {...props} viewBox="-1 4 22 5">
            <path
                d="M8.43226 12H10.5963L6.58851 0.726562H4.32288L0.322885 12H2.36195L3.3307 9.11719H7.47913L8.43226 12ZM5.34632 2.875H5.47913L7.01038 7.52344H3.79945L5.34632 2.875ZM15.1432 10.6172C14.2838 10.6172 13.6666 10.1875 13.6666 9.46875C13.6666 8.77344 14.1744 8.38281 15.2604 8.3125L17.1901 8.1875V8.86719C17.1901 9.85938 16.3151 10.6172 15.1432 10.6172ZM14.5729 12.1406C15.6901 12.1406 16.6276 11.6562 17.1041 10.8281H17.2369V12H19.1041V6.16406C19.1041 4.35156 17.8698 3.28125 15.6744 3.28125C13.6432 3.28125 12.2291 4.24219 12.0729 5.75H13.901C14.0807 5.17188 14.6901 4.85938 15.5807 4.85938C16.6276 4.85938 17.1901 5.32812 17.1901 6.16406V6.88281L14.9869 7.01562C12.9088 7.14062 11.7448 8.03125 11.7448 9.57812C11.7448 11.1406 12.9244 12.1406 14.5729 12.1406Z"
                fill={props?.fill || COLORS.blackOpacity}
            />
        </SvgIcon>
    );
}

export function LowerCaseIcon(props) {
    return (
        <SvgIcon {...props} viewBox="-2 1 22 5">
            <path
                d="M3.94349 7.61719C3.08412 7.61719 2.46693 7.1875 2.46693 6.46875C2.46693 5.77344 2.97474 5.38281 4.06068 5.3125L5.99037 5.1875V5.86719C5.99037 6.85938 5.11537 7.61719 3.94349 7.61719ZM3.37318 9.14062C4.49037 9.14062 5.42787 8.65625 5.90443 7.82812H6.03724V9H7.90443V3.16406C7.90443 1.35156 6.67005 0.28125 4.47474 0.28125C2.44349 0.28125 1.02943 1.24219 0.873178 2.75H2.7013C2.88099 2.17188 3.49037 1.85938 4.38099 1.85938C5.42787 1.85938 5.99037 2.32812 5.99037 3.16406V3.88281L3.78724 4.01562C1.70912 4.14062 0.545053 5.03125 0.545053 6.57812C0.545053 8.14062 1.72474 9.14062 3.37318 9.14062ZM13.1622 7.61719C12.3029 7.61719 11.6857 7.1875 11.6857 6.46875C11.6857 5.77344 12.1935 5.38281 13.2794 5.3125L15.2091 5.1875V5.86719C15.2091 6.85938 14.3341 7.61719 13.1622 7.61719ZM12.5919 9.14062C13.7091 9.14062 14.6466 8.65625 15.1232 7.82812H15.256V9H17.1232V3.16406C17.1232 1.35156 15.8888 0.28125 13.6935 0.28125C11.6622 0.28125 10.2482 1.24219 10.0919 2.75H11.9201C12.0997 2.17188 12.7091 1.85938 13.5997 1.85938C14.6466 1.85938 15.2091 2.32812 15.2091 3.16406V3.88281L13.006 4.01562C10.9279 4.14062 9.7638 5.03125 9.7638 6.57812C9.7638 8.14062 10.9435 9.14062 12.5919 9.14062Z"
                fill={props?.fill || COLORS.blackOpacity}
            />
        </SvgIcon>
    );
}

export function UpperCaseIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 4 22 5">
            <path
                d="M8.28906 12H10.4531L6.44531 0.726562H4.17969L0.179688 12H2.21875L3.1875 9.11719H7.33594L8.28906 12ZM5.20312 2.875H5.33594L6.86719 7.52344H3.65625L5.20312 2.875ZM19.6641 12H21.8281L17.8203 0.726562H15.5547L11.5547 12H13.5938L14.5625 9.11719H18.7109L19.6641 12ZM16.5781 2.875H16.7109L18.2422 7.52344H15.0312L16.5781 2.875Z"
                fill={props?.fill || COLORS.blackOpacity}
            />
        </SvgIcon>
    );
}

export function FilterIcon(props) {
    return (
        <SvgIcon {...props} viewBox="6 5 24 24">
            <path
                d="M17 24H19C19.55 24 20 23.55 20 23C20 22.45 19.55 22 19 22H17C16.45 22 16 22.45 16 23C16 23.55 16.45 24 17 24ZM9 13C9 13.55 9.45 14 10 14H26C26.55 14 27 13.55 27 13C27 12.45 26.55 12 26 12H10C9.45 12 9 12.45 9 13ZM13 19H23C23.55 19 24 18.55 24 18C24 17.45 23.55 17 23 17H13C12.45 17 12 17.45 12 18C12 18.55 12.45 19 13 19Z"
                fill={props?.fill || COLORS.primary}
            />
        </SvgIcon>
    );
}

export function PlusIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 4 22 16">
            <path
                d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                fill={props?.fill || COLORS.primary}
            />
        </SvgIcon>
    );
}

export function PlayIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 10 12">
            <path
                d="M0 0.972017V11.028C0 11.7948 0.857565 12.2607 1.51799 11.8433L9.54165 6.81535C10.1528 6.43679 10.1528 5.56321 9.54165 5.17494L1.51799 0.156668C0.857565 -0.260713 0 0.205201 0 0.972017Z"
                fill={props?.fill || COLORS.primary}
            />
        </SvgIcon>
    );
}

export function EyedropperIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
            <path
                d="M17.5277 6.47147C17.2237 6.16744 16.8194 6 16.3895 6C15.9595 6 15.5553 6.16744 15.2513 6.47147L12.6303 9.09244L12.3605 8.82264C12.2479 8.71012 12.1092 8.63981 11.9639 8.61169C11.9397 8.60699 11.9153 8.6035 11.8908 8.60115C11.8663 8.5988 11.8417 8.59763 11.8172 8.59763C11.7926 8.59763 11.768 8.5988 11.7436 8.60115C11.7191 8.6035 11.6947 8.60703 11.6705 8.61169C11.5252 8.63981 11.3864 8.71016 11.2739 8.82264C11.2177 8.87889 11.1719 8.9417 11.1368 9.00864C11.1251 9.03093 11.1145 9.0537 11.1051 9.07684C11.077 9.14621 11.0594 9.21887 11.0524 9.2923C11.05 9.31677 11.0488 9.34137 11.0488 9.36592C11.0488 9.41508 11.0535 9.46423 11.0629 9.51265C11.077 9.58531 11.1016 9.65631 11.1367 9.72321C11.1719 9.79011 11.2176 9.85292 11.2739 9.9092L11.4489 10.0842L6.86911 14.6641C6.81101 14.7222 6.76526 14.7914 6.73474 14.8677L6.07869 16.5042C5.94392 16.8405 5.98395 17.2194 6.18583 17.5178C6.39006 17.8198 6.72704 18 7.0872 18C7.22628 18 7.36344 17.9733 7.49498 17.9205L9.13154 17.2645C9.2078 17.2339 9.2771 17.1882 9.33517 17.1301L13.915 12.5503L14.09 12.7252C14.1087 12.744 14.1282 12.7616 14.1483 12.778C14.1886 12.8108 14.2314 12.8389 14.276 12.8624C14.3875 12.921 14.5104 12.9503 14.6333 12.9503C14.6345 12.9503 14.6357 12.9501 14.6368 12.9501C14.7094 12.9498 14.7819 12.9394 14.8521 12.9187C14.9471 12.8905 15.0378 12.8436 15.1183 12.778C15.1384 12.7616 15.1579 12.744 15.1766 12.7252C15.2704 12.6315 15.3349 12.5195 15.37 12.4007C15.3981 12.3057 15.4075 12.2063 15.3981 12.1083C15.3934 12.0593 15.3841 12.0107 15.37 11.9632C15.3559 11.9157 15.3372 11.8693 15.3137 11.8246C15.2786 11.7577 15.2329 11.6949 15.1766 11.6386L14.9068 11.3688L17.5278 8.74788C18.1553 8.12036 18.1553 7.09912 17.5277 6.47147ZM8.56363 16.1825L7.31701 16.6821L7.81672 15.4356L12.3085 10.9438L13.0554 11.6907L8.56363 16.1825Z"
                fill={props?.fill || COLORS.primary}
            />
        </SvgIcon>
    );
}

export function CameraIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" fill={props?.fill || COLORS.primary}>
            <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z" />
        </SvgIcon>
    );
}

export function InfoIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 18 18" fill={props?.fill || COLORS.primary}>
            <path
                d="M8.16675 4.83317H9.83342V6.49984H8.16675V4.83317ZM8.16675 8.1665H9.83342V13.1665H8.16675V8.1665ZM9.00008 0.666504C4.40008 0.666504 0.666748 4.39984 0.666748 8.99984C0.666748 13.5998 4.40008 17.3332 9.00008 17.3332C13.6001 17.3332 17.3334 13.5998 17.3334 8.99984C17.3334 4.39984 13.6001 0.666504 9.00008 0.666504ZM9.00008 15.6665C5.32508 15.6665 2.33341 12.6748 2.33341 8.99984C2.33341 5.32484 5.32508 2.33317 9.00008 2.33317C12.6751 2.33317 15.6667 5.32484 15.6667 8.99984C15.6667 12.6748 12.6751 15.6665 9.00008 15.6665Z"
                fill="#3D3D3D"
            />
        </SvgIcon>
    );
}
