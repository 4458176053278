import * as Yup from 'yup';
import { locale } from '../../constants/locales';
import { companyRegex } from '../../constants/companies';

export default Yup.object().shape({
    name: Yup.string().required(locale.Messages.REQUIRED_FIELD),
    companyName: Yup.string()
        .required(locale.Messages.REQUIRED_FIELD)
        .matches(companyRegex, locale.Messages.NOT_VALID(locale.User.COMPANY)),
});
