import React, { useEffect, useRef, useState } from 'react';
import { bool, func, object } from 'prop-types';
import ListItemText from '@material-ui/core/ListItemText';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Form } from 'formik';
import FormikWrapper from '../../FormikWrapper';
import FormikField from '../../FormikField';
import { findCanvasItem, getObjectLabel } from '../../../utils/canvas';
import { useUnmount } from '../../../utils/hooks';
import { TEXT_FIELD } from '../../../constants/variables';
import validationSchema from '../../../schemas/validations/rename-field';
import classes from './RenameField.module.scss';
import { MAX_LENGTH_RENAME_LAYER } from '../../../constants/sizes';
import { locale } from '../../../constants/locales';

const RenameForm = ({ object, saveCanvas, canvas, dndDisabled, errorNotification }) => {
    const [showForm, setShowForm] = useState(false);
    const initialValues = {
        rename: object?.objectName || getObjectLabel(object?.type).label,
    };
    const renameForm = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (showForm && inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showForm]);

    const handleDoubleClick = () => {
        if (object.objectId) {
            setShowForm(true);
        } else {
            setShowForm(false);
        }
    };

    const handleChange = ({ target: { value } }) => {
        if (renameForm?.current) {
            const { setFieldValue } = renameForm.current;
            if (value.length > MAX_LENGTH_RENAME_LAYER) {
                const newValue = value.slice(0, MAX_LENGTH_RENAME_LAYER);
                setFieldValue('rename', newValue, false);
                errorNotification({
                    message: locale.Messages.MAX_CHARACTERS_LAYERS_NAME(
                        MAX_LENGTH_RENAME_LAYER,
                    ),
                    data: { preventDuplicate: true },
                });
            } else {
                setFieldValue('rename', value, false);
            }
        }
    };

    const submit = async (values, submitProps, withHistoryUpdate = false) => {
        const { setSubmitting, validateForm } = submitProps;
        const errors = await validateForm(values);
        setSubmitting(false);

        if (!Object.keys(errors).length) {
            const { rename } = values;
            const { object: activeObject } = findCanvasItem(object.objectId, canvas);
            if (activeObject) {
                activeObject.set('objectName', rename);
                // Update canvas and history only on final submit
                withHistoryUpdate && (await saveCanvas());
            }
        }
    };

    // Trigger validation and submission
    const change = async () => {
        try {
            await renameForm?.current?.validateForm?.();
            await renameForm?.current?.handleSubmit?.();
        } catch (e) {
            console.info(e);
        }
    };

    const handleClickAway = async () => {
        try {
            const { setSubmitting, validateForm } = renameForm.current;
            await submit(
                // Get latest value directly from input
                { rename: inputRef.current?.value ?? initialValues.rename },
                { setSubmitting, validateForm },
                true,
            );
            setShowForm(false);
        } catch (e) {
            console.info(e?.message);
        }
    };

    const onKeyPress = async ev => {
        if (ev && ev.key === 'Enter') {
            ev.preventDefault();
            await handleClickAway();
        }
    };

    useUnmount(() => {
        renameForm.current = null;
    });

    return showForm && !dndDisabled ? (
        <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
            <div className={classes.renameForm}>
                <FormikWrapper
                    ref={renameForm}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={submit}
                >
                    {({ values, handleSubmit }) =>
                        Object.keys(values).length && (
                            <Form
                                onKeyPress={onKeyPress}
                                onSubmit={handleSubmit}
                                onChange={change}
                            >
                                <FormikField
                                    innerRef={inputRef}
                                    name="rename"
                                    classes={{ root: classes.listItemText }}
                                    type={TEXT_FIELD}
                                    fullWidth
                                    onChange={handleChange}
                                />
                            </Form>
                        )
                    }
                </FormikWrapper>
            </div>
        </ClickAwayListener>
    ) : (
        <ListItemText
            primary={initialValues.rename}
            classes={{ root: classes.listItemTextHeight }}
            onDoubleClick={handleDoubleClick}
        />
    );
};

RenameForm.propTypes = {
    object: object,
    canvas: object,
    saveCanvas: func,
    dndDisabled: bool,
};
export default RenameForm;
