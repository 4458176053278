import { connect } from 'react-redux';
import Templates from './Templates';
import { go } from '../../actions/navigation';
import {
    createTemplate,
    getTemplates,
    editTemplate,
    clearTemplates,
} from '../../actions/templates';
import { getCategories } from '../../actions/categories';

const mapStateToProps = state => ({
    templates: state.templates && state.templates.list,
    totalItems: state.templates && state.templates.totalItems,
    categories: state?.categories?.list,
});

const mapDispatchToProps = {
    createTemplate,
    getTemplates,
    editTemplate,
    clearTemplates,
    go,
    getCategories,
};
export default connect(mapStateToProps, mapDispatchToProps)(Templates);
