export const NOTIFICATION_TYPES = Object.freeze(['error', 'success', 'warning']);

export const USER_NOTIFICATION_TYPES = Object.freeze({
    Error: 'ERROR',
    Warning: 'WARNING',
    Success: 'SUCCESS',
});

export const USER_NOTIFICATIONS_MAX_COUNT = 10;
export const NOTIFICATION_TIMEOUT = 10000;
