import { connect } from 'react-redux';
import TextPanel from './TextPanel';
import { saveCanvasToHistory } from '../../../actions/canvas';

const mapStateToProps = state => ({
    companyName: state?.template?.companyName,
    authUser: state?.auth?.user,
});

const mapDispatchToProps = {
    saveCanvasToHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextPanel);
