import React from 'react';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import { arrayOf, bool, oneOfType, number, string, shape } from 'prop-types';
import classes from './Progress.module.scss';

const Progress = ({ progress = 0, projectId, relative = false }) => {
    // Show progress only for processing project
    let updatedProgress = 0;
    if (typeof progress === 'number') {
        updatedProgress = progress;
    } else if (Array.isArray(progress)) {
        const inLineProject = progress.find(item => projectId === item.id);
        if (inLineProject) {
            updatedProgress = inLineProject.progress;
        }
    }

    const showProgress = updatedProgress > 0 && updatedProgress < 100;

    return (
        showProgress && (
            <div className={clsx(classes.ProgressLoader, relative && classes.relative)}>
                <LinearProgress
                    classes={{
                        colorSecondary: classes.colorSecondary,
                    }}
                    variant="determinate"
                    color="primary"
                    value={updatedProgress}
                />
            </div>
        )
    );
};

Progress.propTypes = {
    progress: oneOfType([
        number,
        arrayOf(
            shape({
                id: string,
                progress: number,
            }),
        ),
    ]),
    projectId: string,
    relative: bool,
};

export default Progress;
