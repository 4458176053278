import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { any, bool, func, node, string } from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '../../components/IconButton';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import { usePrevious } from '../../utils/hooks';
import classes from './Popup.module.scss';

export default function Popup({
    children,
    iconButton,
    setOpenOnce,
    popupClose,
    popupOpen,
    btnClassName = '',
    menuRight = false,
    menuLeft = false,
    noArrow = false,
}) {
    const [open, setOpen] = useState(false);
    const prevOpen = usePrevious(open);
    const handleClick = () => {
        if (setOpenOnce) {
            setOpenOnce();
        }
        popupOpen();
        setOpen(prev => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (popupClose && prevOpen) {
            setOpen(false);
        }
    }, [popupClose, prevOpen]);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.Popup}>
                <IconButton
                    className={clsx(btnClassName, 'opusIconBtn')}
                    aria-label="video"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    {iconButton}
                </IconButton>
                {open && (
                    <Grow
                        in={open}
                        style={{
                            transformOrigin: '0 0',
                        }}
                        {...(open
                            ? {
                                  timeout: 500,
                              }
                            : {})}
                        className={classes.menuWrap}
                    >
                        <div>
                            <Paper
                                className={clsx(
                                    classes.menu,
                                    menuRight && classes.menuRight,
                                    menuLeft && classes.menuLeft,
                                    noArrow && classes.noArrow,
                                )}
                            >
                                {children || null}
                            </Paper>
                        </div>
                    </Grow>
                )}
            </div>
        </ClickAwayListener>
    );
}

Popup.propTypes = {
    children: any,
    iconButton: node,
    setOpenOnce: func,
    btnClassName: string,
    popupClose: bool,
    popupOpen: func,
    menuLeft: bool,
    menuRight: bool,
    noArrow: bool,
};
