import React from 'react';
import clsx from 'clsx';
import { any, bool, func, oneOf, string } from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Btn from '../Button';
import Checkbox from '../Fields/Checkbox';
import { Grid } from '../Grid/Grid';
import classes from './Collapse.module.scss';

export default function CollapseWrapper({
    children,
    checkbox,
    checkboxColor = 'primary',
    expanded,
    disabled,
    label,
    onChange,
    timeout = 'auto',
    labelPlacement,
    collapseBtn,
    checkboxClassName,
    fullWidth,
    className,
    name,
}) {
    const plusIcon = <AddIcon />;
    const minusIcon = <RemoveIcon />;

    return (
        <>
            {checkbox && (
                <Checkbox
                    name={name}
                    checked={!!expanded}
                    color={checkboxColor}
                    label={label}
                    onChange={onChange}
                    disabled={disabled}
                    labelBold
                    fullWidth={fullWidth}
                    labelPlacement={labelPlacement}
                    checkboxClassName={checkboxClassName}
                    className={clsx(className, classes.collapseTitle)}
                />
            )}
            {!checkbox && label && !collapseBtn && (
                <Typography
                    variant="subtitle2"
                    align="left"
                    gutterBottom
                    onClick={onChange}
                >
                    {label}
                </Typography>
            )}
            {!checkbox && label && collapseBtn && (
                <Grid
                    container
                    spacing={1}
                    className="pointer"
                    onClick={() => onChange({ target: { checked: !expanded } })}
                >
                    <Grid item xs={10}>
                        <Typography variant="subtitle1" align="left" gutterBottom>
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <Btn
                            btnType="collapseBtn"
                            variant="contained"
                            size="small"
                            onClick={() => onChange({ target: { checked: !expanded } })}
                            onlyIcon={expanded ? minusIcon : plusIcon}
                        />
                    </Grid>
                </Grid>
            )}
            <Collapse in={expanded} timeout={timeout} unmountOnExit>
                {children}
            </Collapse>
        </>
    );
}

CollapseWrapper.propTypes = {
    children: any,
    checkbox: bool,
    disabled: bool,
    expanded: bool,
    labelPlacement: oneOf(['bottom', 'start', 'end', 'top']),
    label: string,
    onChange: func,
};
