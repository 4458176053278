import { shape, string } from 'prop-types';

export default shape({
    id: string,
    createdAt: string,
    updatedAt: string,
    canvasDataUpdatedAt: string,
    name: string,
    companyName: string,
});
