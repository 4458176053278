import { connect } from 'react-redux';
import ActiveSellerSlides from './ActiveSellerSlides';
import { updateIndexes, deleteSlide } from '../../../actions/slides';
import { infoNotification } from '../../../actions/notifications';

const mapStateToProps = state => ({
    authUser: state.auth && state.auth.user,
    slides: state?.slides?.list,
});
const mapDispatchToProps = {
    updateIndexes,
    deleteSlide,
    infoNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveSellerSlides);
