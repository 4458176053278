export const TEMPLATES_DEFAULT_QUERY = {
    statuses: ['DRAFT', 'PUBLISHED'],
    companyNames: ['OPUS', 'SOMEDAY'],
    search: '',
    limit: 8,
    page: 1,
};

export const PROJECTS_DEFAULT_QUERY = {
    projectTypes: ['ACTIVE SELLER', 'STORY'],
    projectStatuses: ['NEW', 'READY', 'FAILED'],
    companyNames: ['OPUS', 'SOMEDAY'],
    search: '',
    sort: 'canvasDataUpdatedAt',
    limit: 16,
    page: 1,
};

export const buildTemplatesDefaultBaseQuery = data => {
    let query = {};
    try {
        const savedFilters = localStorage.getItem('templates_filters');
        const filterValues = JSON.parse(savedFilters);
        if (!filterValues) {
            throw new Error('no previously stored filters');
        }
        const statuses = filterValues.statuses.length
            ? filterValues.statuses
            : TEMPLATES_DEFAULT_QUERY.statuses;
        const companyNames = filterValues.companyNames.length
            ? filterValues.companyNames
            : PROJECTS_DEFAULT_QUERY.companyNames;
        const categories = filterValues.categories.length ? filterValues.categories : [];
        query = { ...query, statuses, companyNames, categories };
    } catch (e) {
        // console.info(e?.message);
    }
    try {
        const savedPagination = localStorage.getItem('pagination');
        const paginationValues = JSON.parse(savedPagination);
        if (!paginationValues) {
            throw new Error('no previously stored pagination');
        }
        query = { ...query, ...paginationValues };
    } catch (e) {
        // console.info(e?.message);
    }
    return { ...PROJECTS_DEFAULT_QUERY, ...query, ...data };
};

export const buildProjectsDefaultBaseQuery = data => {
    let query = {};
    try {
        const savedFilters = localStorage.getItem('projects_filters');
        const filterValues = JSON.parse(savedFilters);
        if (!filterValues) {
            throw new Error('no previously stored filters');
        }
        const companyNames = filterValues.companyNames.length
            ? filterValues.companyNames
            : PROJECTS_DEFAULT_QUERY.companyNames;
        const projectTypes = filterValues.projectType.length
            ? filterValues.projectType
            : PROJECTS_DEFAULT_QUERY.projectTypes;
        const projectStatuses = filterValues.projectStatuses.length
            ? filterValues.projectStatuses
            : PROJECTS_DEFAULT_QUERY.projectStatuses;
        const sort = filterValues.sort ? filterValues.sort : PROJECTS_DEFAULT_QUERY.sort;
        query = { ...query, sort, projectStatuses, projectTypes, companyNames };
    } catch (e) {
        // console.info(e?.message);
    }
    try {
        const savedPagination = localStorage.getItem('pagination');
        const paginationValues = JSON.parse(savedPagination);
        if (!paginationValues) {
            throw new Error('no previously stored pagination');
        }
        query = { ...query, ...paginationValues };
    } catch (e) {
        // console.info(e?.message);
    }

    return { ...PROJECTS_DEFAULT_QUERY, ...query, ...data };
};
