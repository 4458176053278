import React, { useRef } from 'react';
import { bool, object, func, number } from 'prop-types';
import IconButton from '../../components/IconButton';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import { WithCanvasContext } from '../../utils/context';
import { useMount, useUnmount } from '../../utils/hooks';
import classes from './UndoRedoButtons.module.scss';
import { locale } from '../../constants/locales';

function UndoRedoButtons({
    canvas,
    redo,
    undo,
    canvasIndex,
    canvasHistoryLength,
    disabled,
}) {
    const undoRef = useRef(null);
    const redoRef = useRef(null);
    const disableUndo = canvasIndex <= 0;
    const disableRedo =
        canvasHistoryLength - 1 === canvasIndex && canvasHistoryLength > 0;

    const undoAction = () => {
        undo();
        canvas.renderAll();
    };
    const redoAction = () => {
        redo();
        canvas.renderAll();
    };

    const btnHandlerAsync = e => {
        if (e.ctrlKey) {
            // z === keycode 90
            if (e.keyCode === 90 && !undoRef?.current?.disabled) {
                undoRef.current.click();
            }
            // y === keycode 89
            if (e.keyCode === 89 && !redoRef?.current?.disabled) {
                redoRef.current.click();
            }
        }
    };

    // Ctrl-z, Ctrl-y handling
    useMount(() => document.addEventListener('keydown', btnHandlerAsync));
    useUnmount(() => document.removeEventListener('keydown', btnHandlerAsync));

    return (
        <div className={classes.BtnWrap}>
            <IconButton
                aria-label={locale.UNDO}
                className={classes.margin}
                disabled={disableUndo || disabled}
                onClick={undoAction}
                ref={undoRef}
                size="small"
            >
                <UndoIcon />
            </IconButton>
            <IconButton
                aria-label={locale.REDO}
                className={classes.margin}
                disabled={disableRedo || disabled}
                onClick={redoAction}
                ref={redoRef}
                size="small"
            >
                <RedoIcon />
            </IconButton>
        </div>
    );
}

UndoRedoButtons.propTypes = {
    canvas: object,
    undo: func.isRequired,
    redo: func.isRequired,
    canvasIndex: number,
    canvasHistoryLength: number,
    disabled: bool,
};

export default WithCanvasContext(UndoRedoButtons);
