import React, { useRef, useState } from 'react';
import { any, bool, oneOf, string } from 'prop-types';
import clsx from 'clsx';
import { Tooltip as TooltipWrapper } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { TOOLTIP_PLACEMENTS } from '../../constants/variables';
import classes from './Tooltips.module.scss';

const Tooltip = ({
    children,
    noHover = true,
    tooltipClass,
    // PopperProps,
    appearDelay = 0,
    ...rest
}) => {
    const popperRef = useRef(null);
    const tooltip = useRef(null);
    const [open, setOpen] = useState(false);

    function handleOpen({ type }) {
        if (type.includes('mouse') && noHover) {
            return;
        }
        setTimeout(() => {
            setOpen(true);
        }, appearDelay);
    }

    function handleClose({ type }) {
        if (type.includes('mouse') && noHover) {
            return;
        }
        setTimeout(() => {
            setOpen(false);
        }, appearDelay);
    }

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div ref={tooltip}>
                <TooltipWrapper
                    open={open}
                    interactive={false}
                    disableFocusListener={noHover}
                    disableHoverListener={noHover}
                    disableTouchListener={noHover}
                    onMouseEnter={handleOpen}
                    onMouseLeave={handleClose}
                    onClick={handleOpen}
                    onClose={handleClose}
                    PopperProps={{
                        popperRef,
                        anchorEl: tooltip.current,
                        modifiers: {
                            flip: {
                                enabled: false,
                            },
                            preventOverflow: {
                                enabled: true,
                                boundariesElement: 'scrollParent',
                            },
                        },
                    }}
                    classes={{ tooltip: clsx(classes.tooltip, tooltipClass) }}
                    {...rest}
                >
                    <div style={{ display: 'inline-block' }}>{children}</div>
                </TooltipWrapper>
            </div>
        </ClickAwayListener>
    );
};

Tooltip.propTypes = {
    text: string,
    children: any,
    noHover: bool,
    tooltipClass: string,
    placement: oneOf(Object.values(TOOLTIP_PLACEMENTS)),
};

export default Tooltip;
