import React, { Component } from 'react';
import { object, string } from 'prop-types';
import { nanoid as generate } from 'nanoid';
import withSizes from 'react-sizes';
import CanvasPreview from '../../components/CanvasPreview';
import ProgressTimeline from '../../components/ProgressTimeline';
import classes from './TemplatePreview.module.scss';
import { SLIDE_ASPECT_RATIO } from '../../constants/sizes';
import ActionBar from '../../components/ActionBar';

class TemplatePreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: 0,
            isPlaying: true,
            instagramMode: false,
        };
        this.mounted = false;
        this.updateProgress = this.updateProgress.bind(this);
        this.handleRestart = this.handleRestart.bind(this);
        this.handleInstagramMode = this.handleInstagramMode.bind(this);
    }

    updateProgress(progress) {
        this.setState({
            progress,
            isPlaying: progress !== 100,
        });
    }

    handleInstagramMode() {
        this.setState({ instagramMode: !this.state.instagramMode });
    }

    async handleRestart() {
        this.setState((_, prevProps) => {
            return {
                isPlaying: true,
                progress: 0,
                canvasData: { ...prevProps.canvasData },
            };
        });
    }

    componentDidMount() {
        this.mounted = true;
        this.setState((_, prevProps) => {
            return {
                isPlaying: true,
                progress: 0,
                canvasData: prevProps.canvasData,
            };
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const { width, templateId, clearTemplate } = this.props;
        const { progress, canvasData, isPlaying, instagramMode } = this.state;

        return (
            <>
                <ActionBar
                    withValidation
                    handleFormUpdate={clearTemplate}
                    templateId={templateId}
                    goBackLink={`/${templateId}/template-editor`}
                    isPlaying={isPlaying}
                    replayPreview={() => this.handleRestart(0)}
                    isVisible
                    showInstagramMode
                    instagramMode={instagramMode}
                    setInstagramMode={this.handleInstagramMode}
                />
                {canvasData && !!Object.keys(canvasData).length && (
                    <div className={classes.carousel}>
                        <ProgressTimeline
                            slides={[
                                {
                                    id: generate(),
                                },
                            ]}
                            counter={0}
                            progress={progress}
                            handleSelectedSlide={this.handleRestart}
                            width={width - 16}
                            isPlaying={isPlaying}
                            instagramMode={instagramMode}
                            projectPreview
                        />
                        <CanvasPreview
                            standalone
                            counter={0}
                            index={0}
                            templateId={templateId}
                            templatePreview
                            isPlayingStory={isPlaying}
                            canvasData={canvasData}
                            updateProgress={this.updateProgress}
                            restart={this.handleRestart}
                            instagramMode={instagramMode}
                        />
                    </div>
                )}
            </>
        );
    }
}

const mapSizesToProps = ({ height }) => ({
    height: height - (72 + 64),
    width: (height - (72 + 64)) * SLIDE_ASPECT_RATIO,
});

TemplatePreview.propTypes = {
    canvasData: object.isRequired,
    templateId: string.isRequired,
};

export default withSizes(mapSizesToProps)(TemplatePreview);
