import { connect } from 'react-redux';
import Projects from './Projects';
import { go } from '../../actions/navigation';
import {
    createProject,
    getProjects,
    updateProject,
    deleteProject,
    clearProjects,
    clearProject,
} from '../../actions/projects';
import { clearTemplate } from '../../actions/templates';
import { infoNotification } from '../../actions/notifications';

const mapStateToProps = state => ({
    authUser: state.auth && state.auth.user,
    projects: state.projects && state.projects.list,
    totalItems: state.projects && state.projects.totalItems,
});

const mapDispatchToProps = {
    createProject,
    getProjects,
    deleteProject,
    updateProject,
    clearProjects,
    clearProject,
    clearTemplate,
    go,
    infoNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(Projects);
