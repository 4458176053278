import { connect } from 'react-redux';
import ProjectsList from './ProjectsList';
import { duplicationProject } from '../../actions/projects';
import { handleStartItemEditing } from '../../actions/lockedItems';

const mapStateToProps = state => ({
    processingQueueIds: state?.processingProgress?.list?.map(i => ({
        id: i.project.id,
        progress: i.progress,
    })),
    authUser: state.auth && state.auth.user,
});

const mapDispatchToProps = {
    duplicationProject,
    handleStartItemEditing,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList);
