import React, { useContext } from 'react';
import { func } from 'prop-types';
import { Redirect } from 'react-router-dom';
import AuthContext from '../../utils/auth-context';

export const withAdminAndDesignerRole = WrappedComponent => {
    return function AdminAndDesignerRole(props) {
        const { roles } = useContext(AuthContext);
        const { authUser } = props;

        if (roles && !roles.isAdmin && !roles.isDesigner) {
            if (authUser && Object.keys(authUser).length) {
                return <Redirect to="/" />;
            }
        }

        return <WrappedComponent {...props} />;
    };
};

withAdminAndDesignerRole.propTypes = {
    back: func,
};

export default withAdminAndDesignerRole;
