import React, { useRef, useState } from 'react';
import { func, bool } from 'prop-types';
import { Redirect } from 'react-router';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { Form } from 'formik';
import Typography from '@material-ui/core/Typography';
import IconButton from '../../components/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Container from '../../components/Container';
import FormikField from '../../components/FormikField';
import FormikWrapper from '../../components/FormikWrapper';
import Btn from '../../components/Button';
import { getBaseRoute } from '../../utils/common';
import { useMount } from '../../utils/hooks';
import User from '../../types/User';
import { locale } from '../../constants/locales';
// import persistStore from '../../store/configureStore';
import { PASSWORD_FIELD, TEXT_FIELD, UNAUTH_PATHS } from '../../constants/variables';
import { COMPANY_LOGOS } from '../../constants/companies';
import validationSchema from '../../schemas/validations/login-validation.js';
import classes from './Login.module.scss';

// const { persistor } = persistStore();

function Login({ isLoggedIn, user, loginUser, clearNotifications, toggleMenu }) {
    const accessToken = localStorage.getItem('access_token');
    const { closeSnackbar } = useSnackbar();
    const [types, setTypes] = useState({
        showPassword: false,
    });
    const form = useRef(null);
    const initialValues = {
        email: '',
        password: '',
    };

    useMount(async () => {
        toggleMenu(false);
    });

    const baseRoute = getBaseRoute(user.roles);

    const submit = async (values, submitProps) => {
        clearNotifications();
        closeSnackbar();
        const { setSubmitting, validateForm } = submitProps;
        const errors = await validateForm(values);
        await setSubmitting(false);
        if (!Object.keys(errors).length) {
            await loginUser(values);
        }

        return null;
    };

    const handleChangeType = prop => {
        setTypes({ ...types, [prop]: !types[prop] });
    };

    if (isLoggedIn && accessToken) {
        return <Redirect to={baseRoute} />;
    }

    return (
        <Container maxWidth="sm" className={classes.Login}>
            <div className={classes.flexRow}>
                <img src={COMPANY_LOGOS.ALL} alt="Logo" />
            </div>
            <Typography variant="h4" align="center" className={classes.gutterBottom}>
                {locale.VIDEO_EDITOR}
            </Typography>
            <FormikWrapper
                ref={form}
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={submit}
            >
                {(values, submitForm, handleSubmit) =>
                    Object.keys(values).length && (
                        <Form className={classes.form}>
                            <FormikField
                                variant="outlined"
                                label={locale.EMAIL}
                                type={TEXT_FIELD}
                                name="email"
                                autoComplete={'username'}
                                className={classes.gutterBottom}
                                fullWidth
                            />
                            <FormikField
                                variant="outlined"
                                label={locale.PASSWORD}
                                type={types.showPassword ? TEXT_FIELD : PASSWORD_FIELD}
                                name="password"
                                className={classes.gutterBottom}
                                fullWidth
                                autoComplete={'current-password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label={locale.TOGGLE_PASS}
                                                onClick={() =>
                                                    handleChangeType('showPassword')
                                                }
                                            >
                                                {types.showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <div className={clsx(classes.flexRow, classes.loginText)}>
                                <Typography variant="subtitle1" align="center">
                                    {locale.FIRST_TIME_LOGIN}
                                </Typography>
                                <Btn
                                    to={UNAUTH_PATHS.setPassword}
                                    className={classes.link}
                                >
                                    here
                                </Btn>
                                <Typography variant="subtitle1" align="center">
                                    {locale.FIRST_TIME_LOGIN2}
                                </Typography>
                            </div>
                            <Btn
                                text={locale.LOGIN}
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submitBtn}
                                onClick={handleSubmit}
                            />
                        </Form>
                    )
                }
            </FormikWrapper>
        </Container>
    );
}

Login.propTypes = {
    loginUser: func.isRequired,
    toggleMenu: func.isRequired,
    isLoggedIn: bool,
    user: User,
};

export default Login;
