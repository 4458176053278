import React, { useState, useContext } from 'react';
import { arrayOf, bool, func, shape } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import FiltersForm from '../../containers/FiltersForm';
import TemplatesList from '../../containers/TemplatesList';
import Container from '../../components/Container';
import ActionBar from '../../components/ActionBar';
import Button from '../../components/Button';
import withSpinner from '../../components/withSpinner';
import withAdminAndDesignerRole from '../../components/withAdminAndDesignerRole';
import Pagination from '../../components/Pagination';
import Categories from '../../containers/Categories';
import { useUnmount } from '../../utils/hooks';
import {
    buildOptions,
    storeFiltersToLocalStorage,
    getStoredFilters,
    buildCategoriesOptions,
} from '../../utils/common';
import AuthContext from '../../utils/auth-context';
import validationSchema from '../../schemas/validations/create-template';
import {
    AUTOCOMPLETE,
    MULTIPLE_SELECT_FIELD,
    SELECT_FIELD,
    TEXT_FIELD,
} from '../../constants/variables';
import { COMPANIES_OPTIONS } from '../../constants/companies';
import { STATUSES_TEMPLATE, STATUSES_TEMPLATE_OPTIONS } from '../../constants/statuses';
import {
    DEFAULT_TEMPLATES_PAGE_OPTIONS,
    DEFAULT_TEMPLATES_PAGE_SIZE,
    PATH_TO_LOCAL_STORE,
    TEMPLATES_FILTERS,
} from '../../constants/filters';
import { locale } from '../../constants/locales';
import { PlusIcon } from '../../constants/icons';
import { COLORS } from '../../constants/colors';
import classes from './Templates.module.scss';
import withFormDialog from '../../components/withFormDialog';
import Category from '../../types/Category';

function Templates(props) {
    const {
        templates,
        go,
        createTemplate,
        getTemplates,
        editTemplate,
        clearTemplates,
        toggleLoading,
        totalItems,
        isLoading,
        history,
        openFormDialog,
        getCategories,
    } = props;
    const { companyNames } = useContext(AuthContext);
    const [params, setParams] = useState({
        limit: DEFAULT_TEMPLATES_PAGE_SIZE,
        page: 1,
    });

    const [filters, setFilters] = useState({});
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [noInitialTemplates, setNoInitialTemplates] = useState(true);
    const [resetKey, setResetKey] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const fetchData = async (query = params) => {
        const res = await getTemplates(query);
        if (isLoading) {
            toggleLoading();
        }
        if (res?.items?.length) {
            setNoInitialTemplates(false);
        }
    };

    useUnmount(() => clearTemplates());

    const handleSelectedCategories = async categories => {
        setSelectedCategories(categories);
        storeFiltersToLocalStorage(PATH_TO_LOCAL_STORE.TEMPLATES, {
            ...getStoredFilters(PATH_TO_LOCAL_STORE.TEMPLATES),
            categories,
        });
        await fetchData({ ...filters, ...params, categories });
    };

    async function handleChange(data) {
        setResetKey(false);
        if (data) {
            await fetchData({ ...filters, ...data });
            setParams(data);
        }
    }

    const handleCreateTemplate = async formData => {
        let { categories } = formData;
        if (Array.isArray(categories)) {
            categories = categories.map(c => c.value);
        }
        const res = await createTemplate({ ...formData, categories });
        if (res && res.id) {
            return go(`${res.id}/template-editor`);
        }

        return res;
    };

    async function handleEditTemplate(formData, id) {
        let { categories } = formData;
        if (Array.isArray(categories)) {
            categories = categories.map(c => c.value);
        }
        return await editTemplate(id, { ...formData, categories }, true);
    }

    async function handleUpdateTemplates() {
        await fetchData({ ...filters, ...params });
    }

    async function handleCompanyChange({ companyName, name }, withStateUpdate = false) {
        if (companyName) {
            let categoriesOptions = [];
            let categoriesValue = [];
            try {
                const { items } = await getCategories(
                    {
                        companyNames: [companyName],
                    },
                    withStateUpdate,
                );
                categoriesOptions = buildCategoriesOptions(items);
                // Reset input value on company change
                if (selectedCompany !== companyName) {
                    categoriesValue = [];
                }
                setSelectedCompany(companyName);
            } catch (e) {
                console.error(e);
            } finally {
                openFormDialog({
                    confirmAction: handleCreateTemplate,
                    dialogTitle: locale.CREATE_TEMPLATE,
                    validationSchema,
                    id: null,
                    actionBtnText: locale.CREATE,
                    fields: [
                        {
                            id: 'name',
                            value: name,
                            label: locale.NAME,
                            type: TEXT_FIELD,
                        },
                        {
                            id: 'companyName',
                            value: companyName,
                            label: locale.FOR_COMPANY,
                            type: SELECT_FIELD,
                            onChangeCallback: handleCompanyChange,
                            options: buildOptions(
                                companyNames.data,
                                COMPANIES_OPTIONS,
                                true,
                            ),
                        },
                        {
                            id: 'categories',
                            value: categoriesValue,
                            options: categoriesOptions,
                            label: locale.CATEGORY,
                            type: AUTOCOMPLETE,
                        },
                    ],
                });
            }
        }
    }

    async function handleCreateTemplateDialog(event) {
        if (event) {
            event.stopPropagation();
        }
        await handleCompanyChange({
            companyName:
                (companyNames.isOpus && COMPANIES_OPTIONS[0].value) ||
                COMPANIES_OPTIONS[1].value,
        });
    }

    // Get all filters changes
    async function handleFiltersChange({ search, ...values }) {
        let paginationValues = { page: 1, limit: DEFAULT_TEMPLATES_PAGE_SIZE };
        try {
            const storedPagination = localStorage.getItem('pagination');
            if (storedPagination) {
                paginationValues = JSON.parse(storedPagination);
            }
        } catch (e) {
            console.info(e);
        }
        // Reset page on filters change
        let updatedParams = { ...params, ...paginationValues };
        if (params.page > 1) {
            setResetKey(true);
            updatedParams = { ...updatedParams, page: 1 };
        }
        setParams(updatedParams);
        setFilters({
            statuses: values.statuses,
            companyNames: values.companyNames,
            search,
        });
        await fetchData({
            search,
            statuses: values.statuses.length
                ? values.statuses
                : Object.values(STATUSES_TEMPLATE),
            companyNames: values.companyNames.length
                ? values.companyNames
                : companyNames.data,
            categories: selectedCategories,
            ...updatedParams,
        });
        storeFiltersToLocalStorage(PATH_TO_LOCAL_STORE.TEMPLATES, {
            search: '',
            categories: selectedCategories,
            ...values,
        });
    }

    return (
        <div className={classes.templates}>
            <ActionBar>
                <FiltersForm
                    path={PATH_TO_LOCAL_STORE.TEMPLATES}
                    onChange={handleFiltersChange}
                    config={{
                        validationSchema: null,
                        fields: [
                            {
                                id: TEMPLATES_FILTERS.COMPANY_NAMES,
                                label: locale.User.COMPANY,
                                type: MULTIPLE_SELECT_FIELD,
                                value: [
                                    ...(Array.isArray(companyNames.data)
                                        ? companyNames.data
                                        : []),
                                ],
                                options: buildOptions(
                                    companyNames.data,
                                    COMPANIES_OPTIONS,
                                    true,
                                ),
                                selectedValues: [
                                    ...(Array.isArray(companyNames.data)
                                        ? companyNames.data
                                        : []),
                                ],
                                filterMode: true,
                            },
                            {
                                id: TEMPLATES_FILTERS.STATUSES,
                                label: locale.STATUS,
                                type: MULTIPLE_SELECT_FIELD,
                                value: [
                                    STATUSES_TEMPLATE.DRAFT,
                                    STATUSES_TEMPLATE.PUBLISHED,
                                ],
                                options: buildOptions(
                                    Object.values(STATUSES_TEMPLATE),
                                    STATUSES_TEMPLATE_OPTIONS,
                                    true,
                                ),
                                selectedValues: [
                                    STATUSES_TEMPLATE.DRAFT,
                                    STATUSES_TEMPLATE.PUBLISHED,
                                ],
                                filterMode: true,
                            },
                        ],
                    }}
                    filterDropdown
                    filtersOpen={filtersOpen}
                    setFiltersOpen={setFiltersOpen}
                />
                <Button
                    btnType="primaryBtn"
                    size="small"
                    onClick={handleCreateTemplateDialog}
                >
                    <PlusIcon size="small" fill={COLORS.white} />
                </Button>
            </ActionBar>

            {!!Object.keys(templates).length && (
                <Container
                    maxWidth="md"
                    preset={
                        filtersOpen
                            ? { withHeaderBarPaginationAndFilters: true }
                            : { withHeaderAndBarAndPagination: true }
                    }
                    className={classes.box}
                    innerClassName={classes.templatesContainer}
                >
                    <TemplatesList
                        templates={templates}
                        confirmAction={handleEditTemplate}
                        updateTemplates={handleUpdateTemplates}
                        text={locale.TEMPLATES}
                    />
                </Container>
            )}
            <Categories
                onChange={handleSelectedCategories}
                filtersOpen={filtersOpen}
                noTemplates={!Object.keys(templates).length}
                hidden={noInitialTemplates}
            />
            {!isLoading && !Object.keys(templates).length && (
                <Container
                    maxWidth="md"
                    innerClassName="noSearchData"
                    preset={{ withHeaderAndBar: true }}
                >
                    <Typography variant="body1" align="center">
                        {filters.search
                            ? locale.NO_RESULTS_FOUND
                            : locale.NO_DATA_AVAILABLE}
                    </Typography>
                </Container>
            )}
            {!!Object.keys(templates).length && templates && (
                <Pagination
                    resetKey={resetKey}
                    count={totalItems}
                    rowPerPage={DEFAULT_TEMPLATES_PAGE_SIZE}
                    rowsPerPageOptions={DEFAULT_TEMPLATES_PAGE_OPTIONS}
                    rows={templates}
                    labelRowsPerPage={locale.Messages.ITEMS_PER_PAGE(locale.TEMPLATES)}
                    onChangePageCallback={handleChange}
                    history={history}
                    component="nav"
                />
            )}
        </div>
    );
}

Templates.propTypes = {
    isLoading: bool,
    durationNotificationIsOn: bool,
    go: func.isRequired,
    createTemplate: func.isRequired,
    getTemplates: func.isRequired,
    editTemplate: func.isRequired,
    clearTemplates: func.isRequired,
    openFormDialog: func.isRequired,
    getCategories: func.isRequired,
    categories: arrayOf(Category),
    roles: shape({
        isAdmin: bool,
        isUser: bool,
        isDesigner: bool,
    }).isRequired,
};

export default withSpinner(withAdminAndDesignerRole(withFormDialog(Templates)));
