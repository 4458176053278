import React, { useState, useRef } from 'react';
import { any } from 'prop-types';
import { useMount, useUnmount } from '../../utils/hooks';
import classes from './withSpinner.module.scss';

export const withSpinner = WrappedComponent => {
    return function Spinner(props) {
        const mounted = useRef(false);
        const [loading, setLoading] = useState(true);
        useMount(() => {
            mounted.current = true;
        });

        useUnmount(() => {
            mounted.current = false;
        });

        const toggleLoading = () => {
            if (mounted?.current) {
                setLoading(!loading);
            }
        };

        return (
            <>
                {loading && (
                    <div className={classes.Spinner}>
                        <div className={classes.donut} />
                    </div>
                )}
                <WrappedComponent
                    {...props}
                    toggleLoading={toggleLoading}
                    isLoading={loading}
                />
            </>
        );
    };
};

withSpinner.propTypes = {
    props: any,
};
export default withSpinner;
