import React, { useState, useEffect, useRef, useCallback } from 'react';
import { bool, func, number, object } from 'prop-types';
import { Form } from 'formik';
import debouncePromise from 'debounce-promise-with-cancel';
import FormikWrapper from '../../../components/FormikWrapper';
import Collapse from '../../../components/Collapse';
import Grid from '../../../components/Grid';
import FormikField from '../../../components/FormikField';
import { WithCanvasContext } from '../../../utils/context';
import { usePrevious, useUnmount } from '../../../utils/hooks';
import { saveCanvasState, setCanvasObjectParams } from '../../../utils/canvas';
import { locale } from '../../../constants/locales';
import {
  TRANSITION_VALUES,
  DISCRETE_TEXT_SLIDER_STEP,
  DISCRETE_TEXT_SLIDER_MIN_VALUE,
  DISCRETE_TEXT_SLIDER_MAX_VALUE,
  DISCRETE_ZOOM_SLIDER_MAX_VALUE,
  DISCRETE_ZOOM_SLIDER_MIN_VALUE,
  DISCRETE_ZOOM_SLIDER_STEP,
  MARKS_ZOOM_SLIDER,
  MARKS_TYPING_ANIMATION_SLIDER,
} from '../../../constants/transitions';
import { DISCRETE_SLIDER_FIELD } from '../../../constants/variables';
import {
  CANVAS_SCALE_TYPES,
  CANVAS_TEXT_TYPING_EFFECT_TYPES,
  CANVAS_TYPE_OBJECTS,
} from '../../../constants/canvas';
import { valueToText } from '../../../utils/common';
import classes from './TransitionsPanel.module.scss';

function TransitionsPanel({
  canvas,
  activeObject,
  saveCanvasToHistory,
  disabled,
  /*animationPlaying,
    abortAnimation,
    resetPlayingAnimation,
    toggleAnimationPlaying,*/
}) {
  const isMounted = useRef(true);
  const form = useRef(null);
  const [state, setState] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showScaleUp, setShowScaleUp] = useState(false);
  const [showTextTypingEffect, setShowTextTypingEffect] = useState(false);
  const [initialValues, setValues] = useState({});
  const prevActiveObject = usePrevious(activeObject);
  const ENABLE_TYPING_ANIMATION = true;
  const isScaleObject =
    activeObject && CANVAS_SCALE_TYPES.includes(activeObject.type);
  const isTextTypingEffectObject =
    activeObject && CANVAS_TEXT_TYPING_EFFECT_TYPES.includes(activeObject.type);

  /*const abort = () => {
        return abortAnimation();
    };*/

  const setProperties = async (values) => {
    try {
      if (values /*&& !animationPlaying*/) {
        const zoomChange =
          isScaleObject &&
          activeObject &&
          (showScaleUp !== activeObject.zoomIn ||
            values.zoomInValue !== activeObject.zoomInValue);

        const typingChange =
          isTextTypingEffectObject &&
          activeObject &&
          (showTextTypingEffect !== activeObject.typingAnimation ||
            values.typingAnimationInterval !==
              activeObject.typingAnimationInterval);

        // const width = activeObject.getScaledWidth();
        // const height = activeObject.getScaledHeight();

        if (zoomChange || typingChange) {
          // const { left, top } = activeObject;
          setCanvasObjectParams(activeObject, {
            zoomIn: showScaleUp,
            zoomInValue: values.zoomInValue,
            typingAnimation: showTextTypingEffect,
            typingAnimationInterval: values.typingAnimationInterval,
          });
          /*const w = activeObject.width;
                    const h = activeObject.height;*/
          // const clipPath = activeObject.clipPath;
          // canvas.discardActiveObject();
          // activeObject.clone(async cloned => {
          //     // We have fabric.js bug that cloned element doesn't save original clipPath
          //     // for Image elements so we need to set them explicitly and reset after animation
          //     cloned.set({ clipPath });
          //     // Save cloned object
          //     const isGroup = activeObject.type === CANVAS_TYPE_OBJECTS.group;
          //     const isVideo =
          //         activeObject.type === CANVAS_TYPE_OBJECTS.videoImage;
          //     const isText =
          //         activeObject.type === CANVAS_TYPE_OBJECTS.animatedTextbox;
          //     if (isText) {
          //         const { index } = findCanvasItem(
          //             activeObject.objectId,
          //             canvas,
          //         );
          //         const { angle } = activeObject;
          //         //Reset angle
          //         setCanvasObjectParams(activeObject, { angle: 0 });
          //         activeObject.setCoords();
          //         activeObject.cloneAsImage(
          //             img => {
          //                 setCanvasObjectParams(img, {
          //                     angle,
          //                     left: left,
          //                     top: top,
          //                     originX: 'center',
          //                     originY: 'center',
          //                     objectId: activeObject.objectId,
          //                 });
          //                 img.setCoords();
          //                 /*const ratio = w / h;*/
          //                 canvas.insertAt(img, index, true).renderAll();
          //                 /*img.animate('width', w * values.zoomInValue, {
          //                     from: w,
          //                     abort,
          //                     duration: convertStoMS(2),
          //                     onChange: val => {
          //                         setCanvasObjectParams(img, {
          //                             height: val / ratio,
          //                             dirty: true,
          //                         });
          //                         canvas.renderAll();
          //                     },
          //                     onComplete: async () => {
          //                         const { index } = findCanvasItem(
          //                             cloned.objectId,
          //                             canvas,
          //                         );
          //                         // Insert previously copied object
          //                         canvas.insertAt(cloned, index, true);
          //                         canvas.renderAll();
          //                         canvas.setActiveObject(cloned);
          //                         await saveCanvasState(
          //                             canvas,
          //                             saveCanvasToHistory,
          //                         );
          //                         canvas.renderAll();
          //                         if (isMounted.current) {
          //                             toggleAnimationPlaying(false);
          //                             setFormDisabled(false);
          //                         }
          //                     },
          //                 });*/
          //             },
          //             {
          //                 format: 'png',
          //                 width,
          //                 height,
          //             },
          //         );
          //     } else if (isGroup) {
          //         const imgElement = await createElement({
          //             type: 'img',
          //             url: BUILD_FULL_FILE_PATH(SCALE_PLACEHOLDER_URL_SHORT),
          //             width: 721,
          //             height: 1280,
          //         });
          //         /*const ratio = imgElement.width / imgElement.height;*/
          //         const options = CLIPPATH_CREATE_OPTIONS(activeObject);
          //         const mediaElement = new fabric.AnimatedImage(imgElement, {
          //             ...IMAGE_CREATE_OPTIONS(),
          //             originX: 'center',
          //             originY: 'center',
          //             left: left + width * 0.5,
          //             top: top + height * 0.5,
          //             width: 720,
          //             height: 1280,
          //             evented: false,
          //             selectable: false,
          //             clipPath: new fabric.AnimatedRect({
          //                 ...options,
          //                 fill: null,
          //                 absolutePositioned: true,
          //             }),
          //         });
          //         const { index } = findCanvasItem(
          //             activeObject.objectId,
          //             canvas,
          //         );
          //         canvas.insertAt(mediaElement, index, true);
          //         canvas.renderAll();
          //         /*const w = mediaElement.getScaledWidth();*/
          //         /*mediaElement.animate('width', w * values.zoomInValue, {
          //             abort,
          //             from: w,
          //             duration: convertStoMS(2),
          //             onChange: val => {
          //                 mediaElement.set({
          //                     height: val / ratio,
          //                     dirty: true,
          //                 });
          //                 canvas.renderAll();
          //             },
          //             onComplete: async () => {
          //                 canvas.insertAt(cloned, index, true);
          //                 canvas.renderAll();
          //                 canvas.setActiveObject(cloned);
          //                 await saveCanvasState(canvas, saveCanvasToHistory);
          //                 canvas.renderAll();
          //                 if (isMounted.current) {
          //                     toggleAnimationPlaying(false);
          //                     setFormDisabled(false);
          //                 }
          //             },
          //         });*/
          //     } else {
          //         /*const ratio = w / h;*/
          //         if (activeObject.clipPath) {
          //             // Get center before
          //             const { x, y } = activeObject.getCenterPoint();
          //             setCanvasObjectParams(activeObject, {
          //                 evented: false,
          //                 selectable: false,
          //                 originX: 'center',
          //                 originY: 'center',
          //                 absolutePositioned: isVideo,
          //             });
          //             activeObject.setCoords();
          //             // Get center after origin change
          //             const {
          //                 x: offsetX,
          //                 y: offsetY,
          //             } = activeObject.getCenterPoint();
          //             setCanvasObjectParams(activeObject, {
          //                 left: left + (x - offsetX),
          //                 top: top + (y - offsetY),
          //             });
          //             activeObject.setCoords();
          //         } else {
          //             // Get center before
          //             const { x, y } = activeObject.getCenterPoint();
          //             const scaleObject = {
          //                 evented: false,
          //                 selectable: false,
          //                 absolutePositioned: false,
          //                 originX: 'center',
          //                 originY: 'center',
          //             };
          //             setCanvasObjectParams(activeObject, scaleObject);
          //             activeObject.setCoords();
          //             // Get center after origin change
          //             const {
          //                 x: offsetX,
          //                 y: offsetY,
          //             } = activeObject.getCenterPoint();
          //             const options = CLIPPATH_CREATE_OPTIONS(activeObject);
          //             const clipPath = new fabric.AnimatedRect({
          //                 ...options,
          //                 left: options.left + (x - offsetX),
          //                 top: options.top + (y - offsetY),
          //                 fill: null,
          //                 absolutePositioned: true,
          //             });
          //             setCanvasObjectParams(activeObject, {
          //                 left: left + (x - offsetX),
          //                 top: top + (y - offsetY),
          //                 clipPath,
          //             });
          //             activeObject.setCoords();
          //         }
          //         canvas.renderAll();
          //         /*activeObject.animate('width', w * values.zoomInValue, {
          //             from: w,
          //             abort,
          //             duration: convertStoMS(2),
          //             onChange: val => {
          //                 setCanvasObjectParams(activeObject, {
          //                     height: val / ratio,
          //                     dirty: true,
          //                 });
          //                 canvas.renderAll();
          //             },
          //             onComplete: async () => {
          //                 const { index } = findCanvasItem(
          //                     cloned.objectId,
          //                     canvas,
          //                 );
          //                 // Insert previously copied object
          //                 canvas.insertAt(cloned, index, true);
          //                 canvas.renderAll();
          //                 canvas.setActiveObject(cloned);
          //                 await saveCanvasState(canvas, saveCanvasToHistory);
          //                 canvas.renderAll();
          //                 if (isMounted.current) {
          //                     toggleAnimationPlaying(false);
          //                     setFormDisabled(false);
          //                 }
          //             },
          //         });*/
          //     }
          // }, CANVAS_CLONE_PROPERTIES);
          await saveCanvasState(canvas, saveCanvasToHistory);
          canvas.renderAll();
        }
        if (isMounted.current) {
          setState(!state);
        }
        canvas.renderAll();
      }
    } catch (e) {
      console.info(e);
      console.info('Cannot set animation');
    }
  };

  const updateForm = useCallback(() => {
    try {
      if (activeObject) {
        const {
          zoomIn = false,
          typingAnimation = false,
          zoomInValue = DISCRETE_ZOOM_SLIDER_MIN_VALUE,
          typingAnimationInterval = DISCRETE_TEXT_SLIDER_MAX_VALUE,
        } = activeObject;
        setValues({ zoomInValue, typingAnimationInterval });
        if (form?.current) {
          const { setFieldValue } = form.current;
          setFieldValue('zoomInValue', zoomInValue, true);
          setFieldValue('zoomIn', zoomIn, true);
          setFieldValue('typingAnimation', typingAnimation, true);
          setFieldValue(
            'typingAnimationInterval',
            typingAnimationInterval,
            true
          );
        }
      }
    } catch (e) {
      console.info('Cannot update form', e.message);
    }
  }, [activeObject]);

  const setAnimation = useCallback(() => {
    /*if (animationPlaying) {
            resetPlayingAnimation();
        }*/
    const { zoomIn = false, typingAnimation = false } = activeObject;
    updateForm();
    setShowScaleUp(zoomIn);
    setShowTextTypingEffect(typingAnimation);
    setExpanded(zoomIn || typingAnimation);
  }, [activeObject, /*animationPlaying, resetPlayingAnimation,*/ updateForm]);

  const toggleCheckbox = async ({ target }) => {
    try {
      const {
        setFieldValue,
        values: { zoomInValue, typingAnimationInterval },
      } = form.current;
      if (target.name) {
        setFieldValue(target.name, target.checked, true);
        if (target.name === 'typingAnimation') {
          setShowTextTypingEffect(target.checked);
        } else {
          setShowScaleUp(target.checked);
        }
        activeObject.set({
          [target.name]: target.checked,
          zoomInValue,
          typingAnimationInterval,
        });
      }
      await saveCanvasState(canvas, saveCanvasToHistory);
    } catch (e) {
      console.info("can't update object");
    }
  };

  const handleCheckbox = async (event) => {
    const { checked } = event.target;
    if (!checked) {
      // Reset current animation
      /*if (animationPlaying) {
                resetPlayingAnimation();
                await setProperties({
                    animation: null,
                    zoomInValue: DISCRETE_ZOOM_SLIDER_MIN_VALUE,
                    zoomIn: false,
                });
            }*/
      // await setProperties({
      //     zoomInValue: DISCRETE_ZOOM_SLIDER_MIN_VALUE,
      //     zoomIn: false,
      // });
      if (isMounted.current) {
        setExpanded(checked);
      }
    }
    if (checked) {
      await setProperties(TRANSITION_VALUES);
      setAnimation();
    }
    setExpanded(checked);
  };

  // Add debounce for form values changes
  const setPropertiesDebounced = debouncePromise(setProperties, 300);

  const submit = async (values, submitProps) => {
    const { setSubmitting, validateForm } = submitProps;
    const errors = await validateForm(values);
    if (!Object.keys(errors).length) {
      await setPropertiesDebounced(values);
    }
    await setSubmitting(false);
  };

  // Trigger validation and submission
  const change = async () => {
    try {
      setPropertiesDebounced.cancel();
      await form?.current?.validateForm?.();
      await form?.current?.handleSubmit?.();
    } catch (e) {
      console.info(e);
    }
  };

  useEffect(() => {
    if (activeObject) {
      const { zoomIn, typingAnimation } = activeObject;
      setExpanded(zoomIn || typingAnimation);
      setShowScaleUp(zoomIn);
      setShowTextTypingEffect(typingAnimation);
      updateForm();
    }
  }, [updateForm, activeObject]);

  // Handle active object / canvas history changes
  useEffect(() => {
    try {
      isMounted.current = true;
      if (
        prevActiveObject &&
        activeObject &&
        prevActiveObject.zoomInValue !== activeObject.zoomInValue
      ) {
        setAnimation();
      }
      if (
        prevActiveObject &&
        activeObject &&
        prevActiveObject.zoomIn !== activeObject.zoomIn
      ) {
        setAnimation();
      }
      if (
        prevActiveObject &&
        activeObject &&
        prevActiveObject.objectId !== activeObject.objectId
      ) {
        setAnimation();
      }
    } catch (e) {
      console.info(e);
    }
  }, [
    activeObject,
    prevActiveObject,
    setAnimation,
    /*toggleAnimationPlaying*/
  ]);

  useUnmount(() => {
    isMounted.current = false;
    /*
        resetPlayingAnimation();
        toggleAnimationPlaying(false);
        */
  });

  return activeObject &&
    activeObject.type !== CANVAS_TYPE_OBJECTS.activeSelection &&
    isScaleObject ? (
    <div className={`${classes.transitionsPanel} boxBorder`}>
      <Collapse
        expanded={expanded}
        collapseBtn
        checkboxColor="secondary"
        label={locale.EFFECTS}
        onChange={handleCheckbox}
        timeout={0}
        labelPlacement="start"
        disabled={disabled}
      >
        <FormikWrapper
          ref={form}
          initialValues={initialValues}
          enableReinitialize
          onSubmit={submit}
        >
          {({ values, handleSubmit }) =>
            !!Object.keys(values).length && (
              <Form noValidate onSubmit={handleSubmit} onChange={change}>
                <Grid container spacing={1}>
                  {isScaleObject && (
                    <Grid item xs={12} className={classes.scaleUp}>
                      <Collapse
                        expanded={showScaleUp}
                        checkbox
                        label={locale.SCALE_UP}
                        checkboxColor="secondary"
                        onChange={toggleCheckbox}
                        timeout={0}
                        labelPlacement="start"
                        fullWidth
                        className={classes.collapseTitle}
                        name="zoomIn"
                        disabled={disabled || showTextTypingEffect}
                      >
                        <div className={classes.collapse}>
                          <FormikField
                            type={DISCRETE_SLIDER_FIELD}
                            name="zoomInValue"
                            onChangeCallback={change}
                            valueLabelDisplay="auto"
                            minValue={DISCRETE_ZOOM_SLIDER_MIN_VALUE}
                            maxValue={DISCRETE_ZOOM_SLIDER_MAX_VALUE}
                            step={DISCRETE_ZOOM_SLIDER_STEP}
                            valueLabelFormat={valueToText}
                            getAriaValueText={valueToText}
                            marks={MARKS_ZOOM_SLIDER}
                            disabled={disabled}
                          />
                        </div>
                      </Collapse>
                    </Grid>
                  )}
                  {isTextTypingEffectObject && (
                    <Grid
                      item
                      xs={12}
                      className={classes.textTypingEffectEffect}
                    >
                      <Collapse
                        expanded={showTextTypingEffect}
                        checkbox
                        label={locale.TEXT_TYPING_EFFECT}
                        checkboxColor="secondary"
                        onChange={toggleCheckbox}
                        timeout={0}
                        labelPlacement="start"
                        fullWidth
                        className={classes.collapseTitle2}
                        name="typingAnimation"
                        disabled={disabled || showScaleUp}
                      >
                        {ENABLE_TYPING_ANIMATION && (
                          <div className={classes.collapse}>
                            <FormikField
                              type={DISCRETE_SLIDER_FIELD}
                              name="typingAnimationInterval"
                              onChangeCallback={change}
                              valueLabelDisplay="auto"
                              valueLabelFormat={(val) => `${val} ms`}
                              getAriaValueText={(val) => val}
                              minValue={DISCRETE_TEXT_SLIDER_MIN_VALUE}
                              maxValue={DISCRETE_TEXT_SLIDER_MAX_VALUE}
                              step={DISCRETE_TEXT_SLIDER_STEP}
                              marks={MARKS_TYPING_ANIMATION_SLIDER}
                              disabled={disabled}
                            />
                          </div>
                        )}
                      </Collapse>
                    </Grid>
                  )}
                </Grid>
              </Form>
            )
          }
        </FormikWrapper>
      </Collapse>
    </div>
  ) : null;
}

TransitionsPanel.propTypes = {
  activeObject: object,
  canvas: object,
  disabled: bool,
  canvasIndex: number.isRequired,
  animationPlaying: bool.isRequired,
  abortAnimation: func.isRequired,
  toggleAnimationPlaying: func.isRequired,
  saveCanvasToHistory: func.isRequired,
};

export default WithCanvasContext(TransitionsPanel);
