import React, { useCallback, useEffect, useRef, useState } from 'react';
import { bool, func, shape } from 'prop-types';
import FormikWrapper from '../../components/FormikWrapper';
import Container from '../../components/Container';
import Btn from '../../components/Button';
import ActionBar from '../../components/ActionBar';
import ActionButton from '../../components/ActionButton';
import UserForm from '../../components/UserForm';
import withFormDialog from '../../components/withFormDialog';
import { useMount } from '../../utils/hooks';
import Alert from '../../components/Alert';
import { locale } from '../../constants/locales';
import validationSchema from '../../schemas/validations/user-validation';
import passwordsValidationSchema from '../../schemas/validations/change-password';
import User from '../../types/User';
import classes from './UserProfile.module.scss';

function UserProfile(props) {
  const {
    authUser,
    roles: { isAdmin },
    getUserById,
    updateUserById,
    removeUser,
    go,
    resetPassword,
    changePassword,
    match: { params },
  } = props;
  const { userId } = params;
  const id = userId || authUser.id;
  const selfUpdate = id === authUser.id;
  const [removeAlert, setRemoveAlert] = useState(false);
  const [initialValues, setValues] = useState({});
  const form = useRef(null);

  const fetchData = useCallback(async () => {
    let user = authUser;
    if (userId) {
      user = await getUserById(userId);
    }
    if (user && Object.keys(user).length) {
      const { firstName, lastName, email, roles, companyNames } = user;
      setValues({
        firstName,
        lastName,
        email,
        roles,
        companyNames,
      });
    }
  }, [authUser, getUserById, userId]);

  useMount(() => fetchData());

  useEffect(() => {
    // Update data on profile changes
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, userId]);

  const submit = async (values, submitProps) => {
    const { setSubmitting, validateForm } = submitProps;
    const errors = await validateForm(values);
    if (!Object.keys(errors).length) {
      await updateUserById(isAdmin, selfUpdate, id, values);
      await setSubmitting(false);
      if (isAdmin) {
        // avoid fetching users by old (not updated) companies
        localStorage.setItem('users_filters', null);
        go('/users');
      }
    } else {
      await setSubmitting(false);
    }
  };

  const handleUpdate = async () => {
    try {
      await form?.current?.handleSubmit?.();
    } catch (e) {
      console.info(e);
    }
  };

  const handleResetPassword = async () => {
    const properId = userId || authUser.id;

    // eslint-disable-next-line no-return-await
    return await resetPassword(properId);
  };

  const handleRemoveProfile = async () => {
    const properId = userId || authUser.id;
    const res = await removeUser(properId);
    if (res) {
      return go('/users');
    }

    return handleDeleteAlertClose();
  };

  const handleChangePassword = async (data) => {
    const res = await changePassword(data);

    return res != null;
  };

  function handleDeleteAlertOpen() {
    setRemoveAlert(true);
  }

  function handleDeleteAlertClose(event, reason) {
    if (reason !== 'backdropClick') {
      setRemoveAlert(false);
    }
  }

  return (
    <>
      <ActionBar goBackLink goBackText={locale.CLOSE}>
        <Btn
          btnType="primaryBtn"
          text={locale.SAVE_CHANGES}
          size="small"
          onClick={handleUpdate}
        />
      </ActionBar>
      {!!Object.keys(initialValues).length && (
        <Container
          maxWidth="sm"
          className={classes.userProfile}
          preset={{ withBar: true }}
        >
          <FormikWrapper
            ref={form}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={submit}
          >
            {(formProps) =>
              Object.keys(formProps.values).length > 0 && (
                <UserForm
                  {...formProps}
                  isAdmin={isAdmin}
                  selfUpdate={selfUpdate}
                  classes={classes}
                />
              )
            }
          </FormikWrapper>
          {isAdmin && userId && (
            <>
              <div className={classes.btnBox}>
                <Btn
                  text={locale.RESET_PASSWORD}
                  btnType="primaryBtn"
                  size="small"
                  className={classes.btn}
                  onClick={handleResetPassword}
                  marginReset
                />
              </div>
              <div className={classes.btnBox}>
                <Btn
                  text={locale.REMOVE_PROFILE}
                  btnType="secondaryBtn"
                  size="small"
                  className={classes.btn}
                  onClick={handleDeleteAlertOpen}
                  marginReset
                />
              </div>
              <Alert
                alertConfig={{
                  alertTitle: locale.actionString(
                    locale.REMOVE,
                    initialValues &&
                      initialValues.firstName &&
                      `${initialValues.firstName} ${initialValues.lastName}`
                  ),
                  alertText: locale.User.CONFIRM_REMOVE_USER,
                  alertBtnText: locale.CONFIRM,
                  alertBtnColor: 'primary',
                  actionClick: handleRemoveProfile,
                  cancelAction: handleDeleteAlertClose,
                }}
                open={removeAlert}
              />
            </>
          )}

          {!userId && (
            <div className={classes.btnBox}>
              <ActionButton
                config={{
                  dialogTitle: locale.CHANGE_PASSWORD,
                  dialogText: null,
                  actionBtnText: locale.CHANGE,
                  id: authUser.id,
                  validationSchema: passwordsValidationSchema,
                  fields: [
                    {
                      id: 'currentPassword',
                      label: locale.User.CURRENT_PASSWORD,
                      type: 'password',
                    },
                    {
                      id: 'newPassword',
                      label: locale.User.NEW_PASSWORD,
                      type: 'password',
                    },
                  ],
                }}
                text={locale.CHANGE_PASSWORD}
                confirmAction={handleChangePassword}
                buttonProps={{
                  btnType: 'primaryBtn',
                  size: 'small',
                  className: classes.btn,
                }}
                marginReset
              />
            </div>
          )}
        </Container>
      )}
    </>
  );
}

UserProfile.propTypes = {
  authUser: User,
  updateUserById: func.isRequired,
  changePassword: func.isRequired,
  roles: shape({
    isAdmin: bool,
    isUser: bool,
    isDesigner: bool,
  }).isRequired,
};

export default withFormDialog(UserProfile);
