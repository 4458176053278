import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import LinesEllipsis from 'react-lines-ellipsis';
import { bool, func, number, oneOfType, arrayOf, shape, string } from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '../../components/IconButton';
import { withStyles } from '@material-ui/core/styles';
import validationSchema from '../../schemas/validations/create-project';
import Slide from '../../components/Slide';
import withFormDialog from '../../components/withFormDialog';
import AuthContext from '../../utils/auth-context';
import { buildOptions } from '../../utils/common';
import { useDimensions } from '../../utils/hooks';
import { locale } from '../../constants/locales';
import { STATUSES_PROJECT } from '../../constants/statuses';
import { LIST_SLIDE_PLACEHOLDER } from '../../constants/slides';
import { PROJECT_TYPES_OPTIONS } from '../../constants/project-types';
import { SELECT_FIELD, TEXT_FIELD } from '../../constants/variables';
import { COMPANIES_OPTIONS, COMPANY_LOGOS } from '../../constants/companies';
import { ITEM_TYPE } from '../../constants/item';
import { SLIDE_ASPECT_RATIO } from '../../constants/sizes';
import { CameraIcon } from '../../constants/icons';
import { COLORS } from '../../constants/colors';
import { styles } from './styles';
import { dateShortFormat } from '../../utils/helpers';
import Grow from '@material-ui/core/Grow';

function ProjectsList({
    projects,
    authUser,
    handleListUpdate,
    classes,
    openFormDialog,
    deleteAlertOpen,
    duplicationProject,
    handleStartItemEditing,
    processingQueueIds,
}) {
    const { companyNames } = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [itemRef, itemSize] = useDimensions();
    const open = Boolean(anchorEl);

    async function handleDelete(event) {
        if (event) {
            event.stopPropagation();
        }
        deleteAlertOpen(selectedProject.id);
        handleMenuClose();
    }

    async function handleDuplication() {
        if (selectedProject && selectedProject.id) {
            await duplicationProject(selectedProject.id);
            await handleListUpdate();
        }
        handleMenuClose();
    }

    async function handleUpdate(event) {
        if (event) {
            event.stopPropagation();
        }
        openFormDialog({
            dialogTitle: locale.EDIT_PROJECT,
            actionBtnText: locale.EDIT,
            dialogText: locale.CHANGE_PROJECT_TITLE,
            alertBtnColor: 'primary',
            id: selectedProject.id,
            validationSchema,
            fields: [
                {
                    id: 'title',
                    value: selectedProject.title || '',
                    label: locale.PROJECT_TITLE,
                    type: TEXT_FIELD,
                },
                {
                    id: 'projectType',
                    value: selectedProject.projectType || PROJECT_TYPES_OPTIONS[0].value,
                    label: locale.PROJECT_TYPE,
                    type: SELECT_FIELD,
                    options: PROJECT_TYPES_OPTIONS,
                    disabled: true,
                },
                {
                    id: 'companyName',
                    value: selectedProject.companyName || COMPANIES_OPTIONS[0].value,
                    label: locale.FOR_COMPANY,
                    type: SELECT_FIELD,
                    options: buildOptions(companyNames.data, COMPANIES_OPTIONS, true),
                    disabled: true,
                },
            ],
        });
        handleMenuClose();
    }

    function handleMenuOpen(event, project) {
        if (event) {
            event.stopPropagation();
        }
        setSelectedProject(project);
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose(event) {
        if (event) {
            event.stopPropagation();
        }
        setSelectedProject(null);
        setAnchorEl(null);
    }

    const previewElement = (slides, company) => {
        if (slides.length) {
            const slide = slides.find(sl => sl.index === 0) || slides[0];

            return <Slide className={classes.cover} company={company} slide={slide} />;
        }

        return (
            <Slide
                className={classes.cover}
                company={company}
                slide={LIST_SLIDE_PLACEHOLDER}
            />
        );
    };

    const projectProcessing = project => {
        if (project && Object.keys(project).length) {
            return Boolean(processingQueueIds.find(item => project.id === item.id));
        }
        return false;
    };

    const progressInfoCheck = project => {
        if (project && Object.keys(project).length) {
            const inLineProject = processingQueueIds.find(item => project.id === item.id);

            if (inLineProject) {
                if (inLineProject.progress === 0) {
                    return `${locale.IN_LINE}`;
                } else if (inLineProject.progress > 0) {
                    return `${locale.RENDERING} (${inLineProject.progress}%)`;
                }
            }

            const text =
                project.videoUrl === locale.RENDERING_FAILED
                    ? project.videoUrl
                    : locale.READY_TO_DOWNLOAD;

            return project.videoUrl ? text : <span>&nbsp;</span>;
        }

        return <span>&nbsp;</span>;
    };

    return (
        <Grid container className={classes.root} spacing={3}>
            {projects.map(project => (
                <Grid key={project.id} item xs={12} sm={4} md={3}>
                    <div
                        className={classes.paperWrapper}
                        onClick={() =>
                            handleStartItemEditing({
                                id: project.id,
                                isLockedByAnotherUser:
                                    project.lockedBy &&
                                    project.lockedBy.id !== authUser.id,
                                type: ITEM_TYPE.PROJECT,
                            })
                        }
                    >
                        <div
                            ref={itemRef}
                            style={{
                                minHeight: itemSize?.width
                                    ? itemSize?.width / SLIDE_ASPECT_RATIO + 'px'
                                    : 'auto',
                            }}
                            id={project.id}
                            className={clsx(
                                classes.paper,
                                project.lockedBy &&
                                    project.lockedBy.id !== authUser.id &&
                                    classes.paperLock,
                            )}
                        >
                            {previewElement(project.slides, project.companyName)}
                            <IconButton
                                dataid={project.id}
                                className={classes.menuButton}
                                aria-label="More"
                                aria-controls="actions-menu"
                                aria-haspopup="true"
                                onClick={event => handleMenuOpen(event, project)}
                                disabled={
                                    !!project.lockedBy &&
                                    project.lockedBy.id !== authUser.id
                                }
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <div
                                className={clsx(
                                    classes.overlay,
                                    (project.status !== STATUSES_PROJECT.NEW ||
                                        projectProcessing(project)) &&
                                        classes.overlayWithStatus,
                                )}
                            >
                                {(project.status !== STATUSES_PROJECT.NEW ||
                                    projectProcessing(project)) && (
                                    <div className={classes.status}>
                                        <CameraIcon
                                            htmlColor={
                                                projectProcessing(project) ||
                                                project.status === STATUSES_PROJECT.FAILED
                                                    ? COLORS.red
                                                    : COLORS.black
                                            }
                                        />
                                        <Typography
                                            className={classes.processingInfo}
                                            align="left"
                                            variant="body2"
                                            display="block"
                                            noWrap
                                        >
                                            {progressInfoCheck(project)}
                                        </Typography>
                                    </div>
                                )}
                                <div className={classes.lastChanges}>
                                    <Typography
                                        align="left"
                                        variant="caption"
                                        display="block"
                                    >
                                        {locale.LAST_CHANGES}
                                    </Typography>
                                    <Typography
                                        align="left"
                                        variant="caption"
                                        display="block"
                                    >
                                        {dateShortFormat(
                                            new Date(project.canvasDataUpdatedAt),
                                            false,
                                            false,
                                        )}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div
                            className={clsx(
                                classes.paper,
                                classes.info,
                                project.lockedBy &&
                                    project.lockedBy.id !== authUser.id &&
                                    classes.paperLock,
                            )}
                        >
                            <div className={classes.contentHeader}>
                                <Typography
                                    className={classes.projectType}
                                    align="left"
                                    variant="body2"
                                    noWrap
                                >
                                    {
                                        PROJECT_TYPES_OPTIONS.find(
                                            p => p.value === project.projectType,
                                        ).label
                                    }
                                </Typography>
                                <span className={classes.verticalDivider}>|</span>
                                <img
                                    className={classes.companyLogo}
                                    src={COMPANY_LOGOS[project.companyName]}
                                    alt={project.companyName}
                                />
                            </div>
                            <Typography
                                className={classes.projectTitle}
                                align="left"
                                variant="subtitle1"
                                display="block"
                            >
                                <LinesEllipsis
                                    maxLine="2"
                                    basedOn="letters"
                                    text={project.title}
                                />
                            </Typography>
                        </div>
                    </div>
                </Grid>
            ))}
            <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
                TransitionComponent={Grow}
            >
                <MenuItem onClick={event => handleUpdate(event)}>{locale.EDIT}</MenuItem>
                <MenuItem onClick={() => handleDuplication()}>
                    {locale.DUPLICATE}
                </MenuItem>
                <MenuItem onClick={event => handleDelete(event)}>
                    {locale.DELETE}
                </MenuItem>
            </Menu>
        </Grid>
    );
}

ProjectsList.propTypes = {
    duplicationProject: func.isRequired,
    handleListUpdate: func.isRequired,
    deleteAlertOpen: func.isRequired,
    progress: oneOfType([number, bool]),
    processingQueueIds: arrayOf(
        shape({
            id: string,
            progress: number,
        }),
    ),
};

export default withStyles(styles)(withFormDialog(ProjectsList));
