import {
    SAVE_INSTANCE_STATUS,
    CLEAR_INSTANCE_STATUS,
    SAVE_VIDEO_SERVICE_STATUS,
    CLEAR_VIDEO_SERVICE_STATUS,
} from '../actions/types';

const initialState = {
    instance: {},
    service: {},
};

export default (state = initialState, { type, instance = {}, service = {} }) => {
    switch (type) {
        case SAVE_INSTANCE_STATUS:
            return {
                ...state,
                ...{ instance },
            };
        case CLEAR_INSTANCE_STATUS:
            return {
                ...state,
                ...{ instance: {} },
            };
        case SAVE_VIDEO_SERVICE_STATUS:
            return {
                ...state,
                ...{ service },
            };
        case CLEAR_VIDEO_SERVICE_STATUS:
            return {
                ...state,
                ...{ service: {} },
            };
        default:
            return state;
    }
};
