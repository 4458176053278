import React, { useCallback, useEffect, useRef, useState } from 'react';
import { bool, func, object } from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { customFabric as fabric } from '../../../components/FabricComponents';
import IconButton from '../../../components/IconButton';
import Grid from '../../../components/Grid';
import { WithCanvasContext } from '../../../utils/context';
import { usePrevious, useUnmount } from '../../../utils/hooks';
import { buildGrid, findCanvasItem, saveCanvasState } from '../../../utils/canvas';
import { locale } from '../../../constants/locales';
import {
    CANVAS_CROP_TYPES,
    CANVAS_TYPE_OBJECTS,
    CLIPPATH_CREATE_OPTIONS,
    CROP_OPTIONS,
} from '../../../constants/canvas';
import { ActionsSchema } from '../../../schemas/actions';
import { DEFAULT_BG_COLOR } from '../../../constants/variables';
import classes from './ActionsPanel.module.scss';

function ActionsPanel({
    canvas,
    canvasIndex,
    activeObject,
    clearDurationNotifications,
    saveCanvasToHistory,
    clearCanvasHistory,
    isVisible,
    disableAll,
}) {
    const [schema, setSchema] = useState(null);
    const [action, setAction] = useState(locale.ACTIONS);
    const [lastActiveObject, setLastActiveObject] = useState(null);
    const [currentClipPath, setCurrentClipPath] = useState(null);
    const prevActiveObject = usePrevious(activeObject);
    const canvasIndexRef = useRef(canvasIndex);

    useEffect(() => {
        if (canvasIndexRef) {
            canvasIndexRef.current = canvasIndex;
        }
    }, [canvasIndex]);
    const getElement = () => activeObject ?? canvas?.getActiveObject();

    const buildSchema = useCallback(() => {
        const newSchema = cloneDeep(ActionsSchema);
        for (const key in newSchema) {
            if (Object.prototype.hasOwnProperty.call(newSchema, key)) {
                switch (key) {
                    case 'crop':
                        newSchema[key].disabled = activeObject
                            ? !CANVAS_CROP_TYPES.includes(activeObject.type)
                            : true;
                        if (!isVisible) {
                            newSchema[key].show = false;
                        }
                        break;
                    case 'done':
                        newSchema[key].disabled = activeObject
                            ? activeObject.type !== CANVAS_TYPE_OBJECTS.crop
                            : true;
                        if (!isVisible) {
                            newSchema[key].show = true;
                        }
                        break;
                    case 'cancel':
                        newSchema[key].disabled = activeObject
                            ? activeObject.type !== CANVAS_TYPE_OBJECTS.crop
                            : true;
                        if (!isVisible) {
                            newSchema[key].show = true;
                        }
                        break;
                    case 'resetState':
                        newSchema[key].disabled = false;
                        if (!isVisible) {
                            newSchema[key].show = false;
                        }
                        break;
                    default:
                        if (!isVisible) {
                            newSchema[key].show = false;
                            newSchema[key].disabled = true;
                        } else {
                            newSchema[key].show = true;
                            newSchema[key].disabled = !activeObject;
                        }
                }
            }
        }

        return newSchema;
    }, [activeObject, isVisible]);

    useEffect(() => {
        const rebuildSchema = () => {
            const newSchema = buildSchema();
            setSchema(newSchema);
        };
        if (prevActiveObject !== activeObject) {
            rebuildSchema();
        }
    }, [activeObject, prevActiveObject, schema, setSchema, buildSchema]);

    useUnmount(() => {
        setSchema(null);
    });

    const methods = {
        flipX: () => {
            try {
                const { flipX } = getElement();
                getElement().set('flipX', !flipX);
                saveCanvasState(canvas, saveCanvasToHistory, canvasIndexRef?.current);
                canvas.renderAll();
            } catch (e) {
                console.info('Cannot find active element');
            }
        },
        flipY: () => {
            try {
                const { flipY } = getElement();
                getElement().set('flipY', !flipY);
                saveCanvasState(canvas, saveCanvasToHistory, canvasIndexRef?.current);
                canvas.renderAll();
            } catch (e) {
                console.info('Cannot find active element');
            }
        },
        resetState: () => {
            clearCanvasHistory();
            clearDurationNotifications();
            canvas.clear();
            canvas.set('backgroundColor', DEFAULT_BG_COLOR);
            if (canvas.hasGrid) {
                buildGrid(canvas);
            }
            saveCanvasState(canvas, saveCanvasToHistory, canvasIndexRef?.current);
            canvas.renderAll();
        },
        crop: () => {
            try {
                if (activeObject && CANVAS_CROP_TYPES.includes(activeObject.type)) {
                    canvas.forEachObject(o => {
                        if (o.objectId) {
                            o.selectable = false;
                            o.evented = false;
                        }
                    });
                    const { object: imageObject } = findCanvasItem(
                        activeObject.objectId,
                        canvas,
                    );
                    setCurrentClipPath(imageObject.clipPath);
                    // Add opacity to image
                    imageObject.set('opacity', 0.7);
                    let options = CLIPPATH_CREATE_OPTIONS(imageObject);
                    // Reverse calc of sizes
                    if (imageObject.clipPath) {
                        const { x, y } = fabric.util.transformPoint(
                            new fabric.Point(
                                imageObject.clipPath.left,
                                imageObject.clipPath.top,
                            ),
                            imageObject.calcTransformMatrix(),
                        );
                        const cWidth =
                            (imageObject.clipPath.width / imageObject.width) *
                            imageObject.getScaledWidth();
                        const cHeight =
                            (imageObject.clipPath.height / imageObject.height) *
                            imageObject.getScaledHeight();
                        options = {
                            left: x,
                            top: y,
                            width: cWidth,
                            height: cHeight,
                            angle: imageObject.clipPath.angle + imageObject.angle,
                        };
                    }
                    const addCrop = new fabric.Crop({
                        ...CROP_OPTIONS(),
                        ...options,
                    });
                    imageObject.clipPath = null;
                    addCrop.setCoords();
                    // Save image
                    setLastActiveObject(activeObject);
                    setAction(locale.CROPPING);
                    canvas.add(addCrop).setActiveObject(addCrop);
                    canvas.renderAll();
                }
            } catch (e) {
                // console.log(e);
                console.info('Cannot find active element');
            }
        },
        done: () => {
            try {
                if (lastActiveObject) {
                    const { object: lastObject } = findCanvasItem(
                        lastActiveObject.objectId,
                        canvas,
                    );
                    const { top, left } = activeObject;
                    const mInverse = fabric.util.invertTransform(
                        lastObject.calcTransformMatrix(),
                    );
                    const { x, y } = fabric.util.transformPoint(
                        new fabric.Point(left, top),
                        mInverse,
                    );
                    lastObject.set({
                        clipPath: new fabric.AnimatedRect({
                            width:
                                lastObject.width *
                                (activeObject.getScaledWidth() /
                                    lastObject.getScaledWidth()),
                            height:
                                lastObject.height *
                                (activeObject.getScaledHeight() /
                                    lastObject.getScaledHeight()),
                            left: x,
                            top: y,
                            angle: activeObject.angle - lastObject.angle,
                            originX: 'left',
                            originY: 'top',
                            fill: null,
                        }),
                        // Restore opacity
                        opacity: 1,
                    });
                    canvas.remove(activeObject);
                    canvas.setActiveObject(lastObject);
                    canvas
                        .forEachObject(o => {
                            if (o.objectId) {
                                o.selectable = true;
                                o.evented = true;
                            }
                        })
                        .renderAll();
                    saveCanvasState(canvas, saveCanvasToHistory, canvasIndexRef?.current);
                    setAction(locale.ACTIONS);
                }
            } catch (e) {
                console.info('Cannot find active element');
            }
        },
        cancel: () => {
            try {
                if (activeObject && activeObject.type === CANVAS_TYPE_OBJECTS.crop) {
                    canvas.remove(activeObject);
                    canvas.renderAll();
                    if (lastActiveObject) {
                        const { object: lastObject } = findCanvasItem(
                            lastActiveObject.objectId,
                            canvas,
                        );
                        lastObject.set({
                            clipPath: currentClipPath,
                            evented: true,
                            selectable: true,
                            // Restore opacity
                            opacity: 1,
                        });
                        canvas.setActiveObject(lastObject);
                        canvas
                            .forEachObject(o => {
                                if (o.objectId) {
                                    o.selectable = true;
                                    o.evented = true;
                                }
                            })
                            .renderAll();
                    }
                    setAction(locale.ACTIONS);
                }
            } catch (e) {
                console.info('Cannot find active element');
            }
        },
    };

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        className={classes.smallTitle}
                        variant="subtitle2"
                        align="left"
                        gutterBottom
                    >
                        <strong>{action}</strong>
                    </Typography>
                </Grid>
                <Grid container className={classes.gridContainer} spacing={0}>
                    {schema &&
                        Object.values(schema).map(
                            ({ label, Icon, methodName, iconClass, disabled, show }) =>
                                show && (
                                    <Grid item className={classes.gridItem} key={label}>
                                        <IconButton
                                            id={label}
                                            disabled={disableAll || disabled}
                                            aria-label={label}
                                            onClick={event => methods[methodName](event)}
                                            classes={{
                                                root: classes.btnRoot,
                                                label: classes.btnLabel,
                                            }}
                                        >
                                            <Icon
                                                fontSize="inherit"
                                                className={iconClass}
                                            />
                                        </IconButton>
                                        <Typography
                                            className={clsx(
                                                classes.labelText,
                                                disabled && classes.disabled,
                                            )}
                                            align="left"
                                            variant="caption"
                                        >
                                            {label}
                                        </Typography>
                                    </Grid>
                                ),
                        )}
                </Grid>
            </Grid>
        </div>
    );
}

ActionsPanel.propTypes = {
    canvas: object,
    activeObject: object,
    saveCanvasToHistory: func.isRequired,
    disableAll: bool.isRequired,
    clearCanvasHistory: func.isRequired,
};

export default WithCanvasContext(ActionsPanel);
