import { connect } from 'react-redux';
import CanvasTabsPanel from './CanvasTabsPanel';
import { uploadMedia } from '../../../actions/templates';
import {
    showDurationNotifications,
    clearDurationNotifications,
} from '../../../actions/duration-notifications';
import { showLoader, hideLoader } from '../../../actions/loading';
import { saveCanvasToHistory } from '../../../actions/canvas';

const mapStateToProps = state => ({
    companyName: state.template && state.template.companyName,
    durationNotificationIsOn: Boolean(state?.showDurationNotifications?.show),
});

const mapDispatchToProps = {
    uploadMedia,
    showLoader,
    hideLoader,
    showDurationNotifications,
    clearDurationNotifications,
    saveCanvasToHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(CanvasTabsPanel);
