import { SAVE_AVAILABLE_TEMPLATES, CLEAR_AVAILABLE_TEMPLATES } from '../actions/types';

const initialState = [];

export default (state = initialState, { type, templates }) => {
    switch (type) {
        case SAVE_AVAILABLE_TEMPLATES:
            return [...templates];
        case CLEAR_AVAILABLE_TEMPLATES:
            return initialState;
        default:
            return state;
    }
};
