import { connect } from 'react-redux';
import UsersListPage from './UsersListPage';
import { getUsersList, removeUser } from '../../actions/users';

const mapStateToProps = state => ({
    user: state.auth && state.auth.user,
    users: state.users && state.users.list,
    totalItems: state.users && state.users.totalItems,
});

const mapDispatchToProps = {
    getUsersList,
    removeUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersListPage);
