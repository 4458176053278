export const ERROR_NOTIFICATION = 'ERROR_NOTIFICATION';
export const INFO_NOTIFICATION = 'INFO_NOTIFICATION';
export const WARNING_NOTIFICATION = 'WARNING_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

export const SHOW_DURATION_NOTIFICATION = 'SHOW_DURATION_NOTIFICATION';
export const CLEAR_DURATION_NOTIFICATION = 'CLEAR_DURATION_NOTIFICATION';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const LOGIN = 'LOGIN';
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const LOGOUT = 'LOGOUT';

export const SAVE_PROJECT = 'SAVE_PROJECT';
export const SAVE_PROJECTS = 'SAVE_PROJECTS';
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
export const CLEAR_PROJECT = 'CLEAR_PROJECT';

export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const SAVE_TEMPLATES = 'SAVE_TEMPLATES';
export const CLEAR_TEMPLATE = 'CLEAR_TEMPLATE';
export const CLEAR_TEMPLATES = 'CLEAR_TEMPLATES';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const SAVE_CATEGORIES = 'SAVE_CATEGORIES';
export const CLEAR_CATEGORIES = 'CLEAR_CATEGORIES';

export const SAVE_AVAILABLE_TEMPLATES = 'SAVE_AVAILABLE_TEMPLATES';
export const CLEAR_AVAILABLE_TEMPLATES = 'CLEAR_AVAILABLE_TEMPLATES';

// export const SAVE_PROJECT_SLIDE = 'SAVE_PROJECT_SLIDE';
// export const CLEAR_PROJECT_SLIDE = 'CLEAR_PROJECT_SLIDE';
export const SAVE_SLIDES = 'SAVE_SLIDES';
export const CLEAR_SLIDES = 'CLEAR_SLIDES';

export const PROCESS_VIDEO = 'PROCESS_VIDEO';
export const STOP_PROCESS_VIDEO = 'STOP_PROCESS_VIDEO';

export const SAVE_USER_NOTIFICATIONS = 'SAVE_USER_NOTIFICATIONS';
export const CLEAR_USER_NOTIFICATIONS = 'CLEAR_USER_NOTIFICATIONS';
export const SAVE_USER_NOTIFICATIONS_HISTORY = 'SAVE_USER_NOTIFICATIONS_HISTORY';
// export const CLEAR_USERS = 'CLEAR_USERS';

export const POPUP_OPEN = 'POPUP_OPEN';
export const POPUP_CLOSE = 'POPUP_CLOSE';
// Users
export const SAVE_USERS_LIST = 'SAVE_USERS_LIST';

// Canvas history
export const ADD_CANVAS_TO_HISTORY = 'ADD_CANVAS_TO_HISTORY';
export const DELETE_CANVAS_FROM_HISTORY = 'DELETE_CANVAS_FROM_HISTORY';
export const UPDATE_CANVAS_ITEM = 'UPDATE_CANVAS_ITEM';
export const CLEAR_CANVAS_HISTORY = 'CLEAR_CANVAS_HISTORY';
export const RESET_HISTORY_TO_INDEX = 'RESET_HISTORY_TO_INDEX';

// Lock
export const IS_STILL_EDITING = 'IS_STILL_EDITING';
export const FINISH_EDITING = 'FINISH_EDITING';
export const CLEAR_IS_STILL_EDITING_MESSAGE = 'CLEAR_IS_STILL_EDITING_MESSAGE';
export const CLEAR_FINISH_EDITING_MESSAGE = 'CLEAR_FINISH_EDITING_MESSAGE';

// System
export const SAVE_INSTANCE_STATUS = 'SAVE_INSTANCE_STATUS';
export const CLEAR_INSTANCE_STATUS = 'CLEAR_INSTANCE_STATUS';
export const SAVE_VIDEO_SERVICE_STATUS = 'SAVE_VIDEO_SERVICE_STATUS';
export const CLEAR_VIDEO_SERVICE_STATUS = 'CLEAR_VIDEO_SERVICE_STATUS';
