import React, { useRef, useEffect, useCallback, useState } from 'react';
import { func, bool, number } from 'prop-types';
import { Form } from 'formik';
import debouncePromise from 'debounce-promise-with-cancel';
import FormikWrapper from '../../../components/FormikWrapper';
import FormikField from '../../../components/FormikField';
import validationSchema from '../../../schemas/validations/story-options-validation';
import { usePrevious } from '../../../utils/hooks';
import { TEXT_FIELD, SELECT_FIELD } from '../../../constants/variables';
import { TEMPLATE_SLIDE_DEFAULT_DURATION } from '../../../constants/slides';
import { LIMIT_DURATION_VIDEO } from '../../../constants/sizes';
import SlideObject from '../../../types/Slide';
import classes from './StoryOptionsForm.module.scss';
import {
    TRANSITION_NAMES,
    STORY_TRANSITION_NAMES_OPTIONS,
    TRANSITION_DURATION_OPTIONS,
} from '../../../constants/slides';

const StoryOptionsForm = ({
    slide,
    handleUpdate,
    slideTemplateDuration,
    disableSlideDuration = false,
    durationNotificationIsOn,
    showDurationNotifications,
    clearDurationNotifications,
}) => {
    const form = useRef(null);
    const [showCustomDurationError, setShowCustomDurationError] = useState(false);
    const prevSlide = usePrevious(slide);
    const initialValues = {
        duration: TEMPLATE_SLIDE_DEFAULT_DURATION,
        transitionName: TRANSITION_NAMES.None,
        transitionDuration: 2,
    };

    const checkDurationNotification = useCallback(
        duration => {
            if (duration > LIMIT_DURATION_VIDEO) {
                Promise.resolve(1).then(() => showDurationNotifications());
            } else if (durationNotificationIsOn) {
                Promise.resolve(1).then(() => clearDurationNotifications());
            }
        },
        [clearDurationNotifications, durationNotificationIsOn, showDurationNotifications],
    );

    const updateForm = async ({ duration, transitionName, transitionDuration }) => {
        await handleUpdate({
            duration,
            id: slide.id,
            transitionName,
            transitionDuration,
        });
    };

    // Add debounce for form values changes
    const setPropertiesDebounced = debouncePromise(updateForm, 400);

    const submit = async (values, submitProps) => {
        const { setSubmitting, validateForm } = submitProps;
        const errors = await validateForm(values);
        if (!Object.keys(errors).length) {
            await setPropertiesDebounced(values);
            await checkDurationNotification(values.duration);
            setShowCustomDurationError(values.duration < slideTemplateDuration);
        }
        await setSubmitting(false);
    };

    // Trigger submission
    const change = async () => {
        try {
            setPropertiesDebounced.cancel();
            await form?.current?.validateForm?.();
            await form?.current?.handleSubmit?.();
        } catch (e) {
            console.info(e);
        }
    };

    useEffect(() => {
        const setValue = () => {
            const { setFieldValue } = form.current;
            checkDurationNotification(slide.duration);
            setFieldValue('duration', slide.duration, true);
            setFieldValue('transitionName', slide.transitionName, true);
            setFieldValue('transitionDuration', slide.transitionDuration, true);
            setShowCustomDurationError(slide.duration < slideTemplateDuration);
        };

        if (form?.current) {
            if (prevSlide && prevSlide !== slide) {
                setValue();
            }
            if (!prevSlide && slide?.duration) {
                setValue();
            }
        }
    }, [slideTemplateDuration, checkDurationNotification, prevSlide, slide]);

    return (
        <FormikWrapper
            ref={form}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={submit}
        >
            {({ values, handleSubmit }) =>
                !!Object.keys(values).length && (
                    <Form
                        className={classes.StoryOptionsForm}
                        onSubmit={handleSubmit}
                        onChange={change}
                    >
                        <FormikField
                            name="duration"
                            className={classes.formControl}
                            label="Slide duration (seconds)"
                            type={TEXT_FIELD}
                            fullWidth
                            disabled={disableSlideDuration}
                        />
                        {showCustomDurationError && (
                            <div className={classes.customDurationError}>
                                The slide duration is less than the total template
                                duration
                            </div>
                        )}
                        <FormikField
                            name="transitionName"
                            label="Transition Type"
                            type={SELECT_FIELD}
                            select
                            fullWidth
                            margin="dense"
                            options={STORY_TRANSITION_NAMES_OPTIONS}
                            onChangeCallback={change}
                        />
                        <FormikField
                            select
                            type={SELECT_FIELD}
                            name="transitionDuration"
                            label="Duration, s"
                            fullWidth
                            options={TRANSITION_DURATION_OPTIONS}
                            onChangeCallback={change}
                            disabled={values?.transitionName === TRANSITION_NAMES.None}
                        />
                    </Form>
                )
            }
        </FormikWrapper>
    );
};

StoryOptionsForm.propTypes = {
    slide: SlideObject.isRequired,
    handleUpdate: func.isRequired,
    disableSlideDuration: bool,
    slideTemplateDuration: number.isRequired,
    durationNotificationIsOn: bool.isRequired,
    showDurationNotifications: func.isRequired,
    clearDurationNotifications: func.isRequired,
};

export default StoryOptionsForm;
