import { connect } from 'react-redux';
import TemplatePreview from './TemplatePreview';
import { clearTemplate } from '../../actions/templates';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    clearTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePreview);
