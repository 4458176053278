import {
    IS_STILL_EDITING,
    FINISH_EDITING,
    CLEAR_IS_STILL_EDITING_MESSAGE,
    CLEAR_FINISH_EDITING_MESSAGE,
} from '../actions/types';

const initialState = {
    isStillEditing: {},
    finishEditing: {},
};

export default (state = initialState, { type, data }) => {
    switch (type) {
        case IS_STILL_EDITING:
            return {
                ...state,
                isStillEditing: {
                    ...state.isStillEditing,
                    ...{ [data.id]: data },
                },
            };
        case FINISH_EDITING:
            return {
                ...state,
                finishEditing: {
                    ...state.finishEditing,
                    ...{ [data.id]: data },
                },
            };
        case CLEAR_IS_STILL_EDITING_MESSAGE:
            delete state.isStillEditing[data.id];

            return {
                ...state,
            };
        case CLEAR_FINISH_EDITING_MESSAGE:
            delete state.isStillEditing[data.id];
            delete state.finishEditing[data.id];

            return {
                ...state,
            };
        default:
            return state;
    }
};
