import React, { useEffect, useState } from 'react';
import { any, bool, func } from 'prop-types';
import clsx from 'clsx';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Close';
import IconButton from '../../components/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { withStyles } from '@material-ui/core/styles';
import LockLayerButton from './LockLayerButton';
import { WithCanvasContext } from '../../utils/context';
import RenameField from '../../components/CanvasLayers/RenameField';
import { usePrevious } from '../../utils/hooks';
import { findCanvasItem, getObjectLabel } from '../../utils/canvas';
import { locale } from '../../constants/locales';
import styles from './styles';

const CanvasLayers = ({
    authUser,
    canvas,
    slide,
    activeObject,
    setActiveObject,
    canvasData,
    saveCanvas,
    errorNotification,
    warningNotification,
    classes,
    dndDisabled = false,
}) => {
    const prevSlide = usePrevious(slide);
    const prevCanvasData = usePrevious(canvasData);
    const [expanded, setExpanded] = useState(false);

    const [items, setItems] = useState([]);
    const matches = useMediaQuery('(min-width:1600px)');

    const handleChange = () => {
        setExpanded(!expanded);
    };

    const handleClick = id => {
        try {
            if (canvas && Object.keys(canvas).length) {
                canvas.discardActiveObject();
                const { object } = findCanvasItem(id, canvas);
                if (object) {
                    object.set('evented', true);

                    // To avoid selecting object from layer block
                    if (!object.isLocked) {
                        canvas.setActiveObject(object);
                    }

                    if (setActiveObject) {
                        setActiveObject(object);
                    }
                    canvas.forEachObject(obj => {
                        if (obj === object) {
                            return;
                        }
                        if (object.intersectsWithObject(obj)) {
                            obj.set('evented', false);
                        }
                    });
                    canvas.renderAll();
                }
            }
        } catch (e) {
            console.info('Cannot find element', e?.message);
        }
    };

    const onDragEnd = async result => {
        if (!result.destination) {
            // dropped outside the list
            return;
        }
        if (result.destination.index !== result.source.index) {
            try {
                if (canvas && Object.keys(canvas).length) {
                    const { object, index } = findCanvasItem(result.draggableId, canvas);
                    const diff = result.source.index - result.destination.index;
                    canvas.moveTo(object, index + diff);
                    await saveCanvas(true);
                }
            } catch (e) {
                console.info('Cannot update layers', e?.message);
            }
        }
    };

    const deleteItem = async id => {
        try {
            if (canvas && Object.keys(canvas).length) {
                const { object, index } = findCanvasItem(id, canvas);

                if (index >= 0 && !object?.isLocked) {
                    canvas.remove(object);
                    await saveCanvas(true);
                } else if (object?.isLocked) {
                    warningNotification(locale.Messages.UNABLE_TO_DELETE, authUser, {
                        preventDuplicate: true,
                    });
                } else {
                    errorNotification(locale.ITEM_DELETE_ERROR);
                }
            }
        } catch (e) {
            console.info('Cannot delete layer', e?.message);
        }
    };

    useEffect(() => {
        const setCanvasItems = () => {
            const objects =
                canvasData?.objects?.filter(o => o.objectId != null).reverse() || [];
            setItems(objects);
        };
        // Initial set
        if (
            (prevCanvasData == null || prevCanvasData?.objects?.length === 0) &&
            canvasData?.objects?.length
        ) {
            setCanvasItems();
            setExpanded(true);
        }
        // Slide change
        if (prevSlide && slide && slide.id && prevSlide.id !== slide.id) {
            setCanvasItems();
            if (canvasData?.objects?.length) {
                setExpanded(true);
            }
        }
        // Last slide delete
        if (prevSlide && !Object.keys(slide).length) {
            setExpanded(false);
        }
        // No slides
        if (slide && !Object.keys(slide).length && items.length) {
            setItems([]);
            if (!Object.keys(slide).length) {
                setExpanded(false);
            }
        }
        // canvasData change
        if (prevCanvasData && prevCanvasData !== canvasData) {
            setCanvasItems();
        }
        // No canvasData
        if (canvasData && canvasData?.objects?.length === 0) {
            setExpanded(false);
        }
    }, [slide, items.length, prevSlide, canvasData, prevCanvasData]);

    return (
        <Accordion
            className={clsx(!dndDisabled && classes.overflow)}
            classes={{ root: classes.panel }}
            elevation={0}
            expanded={expanded}
            onChange={handleChange}
            style={
                matches
                    ? { maxHeight: dndDisabled ? '100%' : '60%' }
                    : { maxHeight: dndDisabled ? '100%' : '50%' }
            }
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="layers-panel-content"
                id="layers-panel-header"
                classes={{ content: classes.content }}
            >
                <Typography classes={{ root: classes.heading }}>
                    {locale.LAYERS}
                </Typography>
            </AccordionSummary>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                    droppableId="droppable-layers"
                    direction="vertical"
                    isDropDisabled={dndDisabled}
                >
                    {provided => (
                        <AccordionDetails
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            <List
                                classes={{ root: classes.list }}
                                dense
                                disablePadding
                                component="nav"
                                aria-label="canvas layers"
                            >
                                {Object.keys(items).length ? (
                                    items.map((object, i) => (
                                        <Draggable
                                            key={object.objectId}
                                            draggableId={object.objectId}
                                            index={i}
                                            isDragDisabled={dndDisabled}
                                        >
                                            {(providedProps, snapshot) => (
                                                <ListItem
                                                    ref={providedProps.innerRef}
                                                    {...providedProps.draggableProps}
                                                    {...providedProps.dragHandleProps}
                                                    button
                                                    disableRipple
                                                    key={object.objectId}
                                                    onClick={() =>
                                                        handleClick(object.objectId)
                                                    }
                                                    classes={{
                                                        root: clsx(
                                                            classes.listItem,
                                                            classes[
                                                                snapshot.isDragging
                                                                    ? 'dragging'
                                                                    : 'nonDragging'
                                                            ],
                                                            activeObject &&
                                                                activeObject.objectId ===
                                                                    object.objectId &&
                                                                classes.activeObject,
                                                        ),
                                                    }}
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            variant="rounded"
                                                            alt="Layer Icon"
                                                            src={object.src ?? ''}
                                                            imgProps={{
                                                                className:
                                                                    classes.imageIcon,
                                                            }}
                                                            classes={{
                                                                root: classes.icon,
                                                                colorDefault:
                                                                    classes.defaultColor,
                                                            }}
                                                        >
                                                            {!object.src &&
                                                                getObjectLabel(
                                                                    object.type,
                                                                ).icon}
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <RenameField
                                                        object={object}
                                                        saveCanvas={saveCanvas}
                                                        canvas={canvas}
                                                        dndDisabled={dndDisabled}
                                                    />
                                                    {!dndDisabled && (
                                                        <LockLayerButton
                                                            canvas={canvas}
                                                            object={object}
                                                            saveCanvas={saveCanvas}
                                                        />
                                                    )}
                                                    {!dndDisabled && (
                                                        <IconButton
                                                            aria-label="remove"
                                                            className={classes.marginLeft}
                                                            onClick={() =>
                                                                deleteItem(
                                                                    object.objectId,
                                                                )
                                                            }
                                                            disableRipple
                                                        >
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    )}
                                                </ListItem>
                                            )}
                                        </Draggable>
                                    ))
                                ) : (
                                    <Typography paragraph>{locale.NO_DATA}</Typography>
                                )}
                                {provided.placeholder}
                            </List>
                        </AccordionDetails>
                    )}
                </Droppable>
            </DragDropContext>
        </Accordion>
    );
};

CanvasLayers.propTypes = {
    canvas: any,
    canvasData: any,
    activeObject: any,
    slide: any.isRequired,
    saveCanvas: func,
    errorNotification: func.isRequired,
    setActiveObject: func,
    dndDisabled: bool,
};
export default withStyles(styles)(WithCanvasContext(CanvasLayers));
