import { connect } from 'react-redux';
import Popup from './Popup';
import { popupOpen } from '../../actions/popup';

const mapStateToProps = state => ({
    popupClose: state && state.popup && state.popup.popupClose,
});

const mapDispatchToProps = {
    popupOpen,
};
export default connect(mapStateToProps, mapDispatchToProps)(Popup);
