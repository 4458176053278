import React from 'react';
import { func } from 'prop-types';
import IconButton from '../../components/IconButton';
import ReplayIcon from '@material-ui/icons/Replay';
import Typography from '@material-ui/core/Typography';
import { locale } from '../../constants/locales';
import classes from './ButtonReplay.module.scss';

/**
 * @param handleClick - callback func
 * */

function ButtonReplay({ handleClick }) {
    return (
        <div className={classes.SlideStopOverlay}>
            <div className="flexColumn">
                <IconButton className={classes.replayBtn} onClick={handleClick}>
                    <ReplayIcon fontSize="inherit" />
                </IconButton>
                <Typography
                    className={classes.replayText}
                    align="center"
                    variant="subtitle1"
                    display="block"
                >
                    {locale.TAP_TO_REPLAY}
                </Typography>
            </div>
        </div>
    );
}

ButtonReplay.propTypes = {
    handleClick: func,
};

export default ButtonReplay;
