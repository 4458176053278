import React, { useState, useContext } from 'react';
import { arrayOf, bool, func } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Container from '../../components/Container';
import Btn from '../../components/Button';
import ActionBar from '../../components/ActionBar';
import UsersList from '../../containers/UsersList';
import FiltersForm from '../../containers/FiltersForm';
import Pagination from '../../components/Pagination';
import withAdminRole from '../../components/withAdminRole';
import withSpinner from '../../components/withSpinner';
import Alert from '../../components/Alert';
import { DEFAULT_FILTERS, SELECT_FIELD } from '../../constants/variables';
import AuthContext from '../../utils/auth-context';
import { buildOptions, storeFiltersToLocalStorage } from '../../utils/common';
import { ROLES, ROLES_OPTIONS } from '../../constants/roles';
import { COMPANIES_OPTIONS } from '../../constants/companies';
import {
  DEFAULT_PAGE_OPTIONS,
  DEFAULT_TEMPLATES_PAGE_SIZE,
  PATH_TO_LOCAL_STORE,
} from '../../constants/filters';
import { locale } from '../../constants/locales';
import User from '../../types/User';
import { PlusIcon } from '../../constants/icons';
import classes from './UserListPage.module.scss';
import { COLORS } from '../../constants/colors';

function UsersListPage(props) {
  const {
    users,
    getUsersList,
    totalItems,
    removeUser,
    go,
    isLoading,
    toggleLoading,
    history,
  } = props;

  const { companyNames } = useContext(AuthContext);
  const [params, setParams] = useState({ limit: 10, page: 1 });
  const [resetKey, setResetKey] = useState(false);
  const [filters, setFilters] = useState({});
  const [removeAlert, setRemoveAlert] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const fetchData = async (query = params) => {
    await getUsersList(query);
    if (isLoading) {
      toggleLoading();
    }
  };

  async function handleChange(data) {
    setResetKey(false);
    if (data) {
      await fetchData({ ...filters, ...data });
      setParams(data);
    }
  }

  function handleRemoveAlert(user) {
    if (typeof user === 'object') {
      setCurrentUser(user);
    }
    setRemoveAlert(true);
  }

  function handleDeleteAlertClose(event, reason) {
    if (reason !== 'backdropClick') {
      setRemoveAlert(false);
    }
  }

  function handleCreateUser() {
    go('/users/create');
  }

  // Get all filters changes
  async function handleFiltersChange({ search, ...values }) {
    const companies =
      values.companyNames === DEFAULT_FILTERS.value
        ? companyNames.data
        : [values.companyNames];
    const roles =
      values.roles === DEFAULT_FILTERS.value
        ? Object.values(ROLES)
        : [values.roles];

    let paginationValues = { page: 1, limit: DEFAULT_TEMPLATES_PAGE_SIZE };
    try {
      const storedPagination = localStorage.getItem('pagination');
      if (storedPagination) {
        paginationValues = JSON.parse(storedPagination);
      }
    } catch (e) {
      console.info(e);
    }
    // Reset page on filters change
    let updatedParams = { ...params, ...paginationValues };
    if (params.page > 1) {
      setResetKey(true);
      updatedParams = { ...updatedParams, page: 1 };
    }
    setParams(updatedParams);
    setFilters({ roles, companyNames: companies, search });
    await fetchData({
      roles,
      companyNames: companies,
      search,
      ...updatedParams,
    });
    storeFiltersToLocalStorage('users', values);
  }

  async function handleDeleteAlertAction() {
    await removeUser(currentUser.id);
    await fetchData({ ...filters, ...params });
    handleDeleteAlertClose();
  }

  return (
    <>
      <ActionBar>
        <div className={classes.createUserBtnWrap}>
          {companyNames.data && (
            <FiltersForm
              path={PATH_TO_LOCAL_STORE.USERS}
              onChange={handleFiltersChange}
              config={{
                validationSchema: null,
                fields: [
                  {
                    id: 'roles',
                    label: locale.User.ROLE,
                    type: SELECT_FIELD,
                    value: DEFAULT_FILTERS.value,
                    options: buildOptions(Object.values(ROLES), ROLES_OPTIONS),
                    className: 'filtersSelectField',
                  },
                  {
                    id: 'companyNames',
                    label: locale.User.COMPANY,
                    type: SELECT_FIELD,
                    options: buildOptions(companyNames.data, COMPANIES_OPTIONS),
                    className: 'filtersSelectField',
                  },
                ],
              }}
            />
          )}
          <Btn btnType="primaryBtn" size="small" onClick={handleCreateUser}>
            <PlusIcon size="small" fill={COLORS.white} />
          </Btn>
        </div>
      </ActionBar>

      {!!Object.keys(users).length && (
        <Container
          className={classes.usersListPage}
          preset={{ withHeaderAndBarAndPagination: true }}
          maxWidth="sm"
        >
          <UsersList
            list={users}
            removeAlert={handleRemoveAlert}
            go={go}
            isLoading={isLoading}
          />
        </Container>
      )}
      {!isLoading && !Object.keys(users).length && (
        <Container
          maxWidth="md"
          preset={{ withBar: true }}
          innerClassName="noSearchData"
        >
          <Typography variant="body1" align="center">
            {locale.NO_SEARCH_RESULTS}
          </Typography>
        </Container>
      )}

      {!!Object.keys(users).length && (
        <Pagination
          resetKey={resetKey}
          count={totalItems}
          rowsPerPageOptions={DEFAULT_PAGE_OPTIONS}
          rows={users}
          labelRowsPerPage={locale.Messages.ITEMS_PER_PAGE(locale.User.USERS)}
          onChangePageCallback={handleChange}
          history={history}
          component="nav"
        />
      )}
      <Alert
        alertConfig={{
          alertTitle: locale.actionString(
            locale.REMOVE,
            currentUser &&
              currentUser.firstName &&
              `${currentUser.firstName} ${currentUser.lastName}`
          ),
          alertText: locale.User.CONFIRM_REMOVE_USER,
          alertBtnText: locale.CONFIRM,
          alertBtnColor: 'primary',
          actionClick: handleDeleteAlertAction,
          cancelAction: handleDeleteAlertClose,
        }}
        open={removeAlert}
      />
    </>
  );
}

UsersListPage.propTypes = {
  users: arrayOf(User).isRequired,
  isLoading: bool,
  toggleLoading: func.isRequired,
};

export default withAdminRole(withSpinner(UsersListPage));
