import { connect } from 'react-redux';
import BorderColorPicker from './BorderColorPicker';
import { saveCanvasToHistory } from '../../actions/canvas';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    saveCanvasToHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(BorderColorPicker);
