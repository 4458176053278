import React, { useEffect, useState } from 'react';
import withSizes from 'react-sizes';
import { arrayOf, bool, func, object, number, string, shape } from 'prop-types';
import Canvas from '../../containers/Canvas';
import ItemLockedWarning from '../../components/ItemLockedWarning';
import Container from '../../components/Container';
import withAdminAndDesignerRole from '../../components/withAdminAndDesignerRole';
import Spinner from '../../components/Spinner';
import { useMount, useUnmount } from '../../utils/hooks';
import { RefContextProvider } from '../../utils/refs-context';
import { EDITOR_TYPE } from '../../constants/item';
import { HISTORY_DEFAULT_INDEX } from '../../constants/variables';
import TemplateObject from '../../types/Template';
import CanvasObject from '../../types/Canvas';
import { SLIDE_ASPECT_RATIO } from '../../constants/sizes';
import { STATUSES_TEMPLATE } from '../../constants/statuses';
import classes from './TemplateEditor.module.scss';

function TemplateEditor({
    match: { params },
    history,
    height,
    width,
    template,
    canvasHistory,
    getTemplateById,
    clearCanvasHistory,
    clearTemplate,
}) {
    const { templateId } = params;
    const [canvasData, setCanvasData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [canvasIndex, setCanvasIndex] = useState(HISTORY_DEFAULT_INDEX);

    useMount(() => {
        const fetchTemplate = async () => {
            if (
                !Object.keys(template).length ||
                (Object.keys(template).length && templateId !== template.id)
            ) {
                await getTemplateById(templateId);
            }
        };
        try {
            fetchTemplate();
        } catch (e) {
            console.error(e);
        }
    });

    useUnmount(() => {
        clearCanvasHistory();
        clearTemplate();
    });

    useEffect(() => {
        // Update current index
        setCanvasData(canvasHistory[canvasHistory.length - 1]);
        setCanvasIndex(canvasHistory.length - 1);
    }, [canvasHistory, canvasHistory.length, setCanvasIndex]);

    useEffect(() => {
        if (Object.keys(template).length) {
            setCanvasData(template.canvasData || {});
        }
    }, [template, setCanvasData]);

    const undo = () => {
        const newIndex = canvasIndex - 1 >= 0 ? canvasIndex - 1 : 0;
        setCanvasData(canvasHistory[newIndex]);
        setCanvasIndex(newIndex);
    };
    const loadCallback = () => {
        setLoading(false);
    };

    const redo = () => {
        const newIndex =
            canvasIndex + 1 < canvasHistory.length - 1
                ? canvasIndex + 1
                : canvasHistory.length - 1;
        setCanvasData(canvasHistory[newIndex]);
        setCanvasIndex(newIndex);
    };

    return (
        <>
            <Spinner loading={loading} fixed opaque />
            {canvasData && (
                <Container
                    fixed={false}
                    maxWidth={false}
                    className={classes.templateEditor}
                    noPadding
                >
                    <RefContextProvider>
                        <Canvas
                            history={history}
                            className={classes.canvasBox}
                            canvasHistoryLength={canvasHistory.length}
                            width={width}
                            height={height}
                            undo={undo}
                            redo={redo}
                            canvasIndex={canvasIndex}
                            canvasData={canvasData}
                            templateId={templateId}
                            template={template}
                            loadCallback={loadCallback}
                        />
                    </RefContextProvider>
                    {/* Only for templates that can be edited */}
                    {template && template.status === STATUSES_TEMPLATE.DRAFT && (
                        <ItemLockedWarning
                            itemId={templateId}
                            editorType={EDITOR_TYPE.TEMPLATE}
                        />
                    )}
                </Container>
            )}
        </>
    );
}

const mapSizesToProps = ({ height }) => ({
    height: Math.floor(height - (136 + 32)),
    width: Math.floor((height - (136 + 32)) * SLIDE_ASPECT_RATIO),
});

TemplateEditor.propTypes = {
    width: number.isRequired,
    template: TemplateObject,
    canvasHistory: arrayOf(CanvasObject),
    match: shape({
        isExact: bool,
        params: object.isRequired,
        path: string,
        url: string,
    }),
    height: number.isRequired,
    getTemplateById: func.isRequired,
    clearTemplate: func.isRequired,
    clearCanvasHistory: func.isRequired,
};

export default withAdminAndDesignerRole(withSizes(mapSizesToProps)(TemplateEditor));
