// Check apps/frontend/src/index.scss - # FONTS
export const FONTS_MAP = Object.freeze({
    AVENIR: {
        100: 'Avenir 100',
        300: 'Avenir 300',
        500: 'Avenir 500',
        700: 'Avenir 700',
    },
    MUSEO_SANS: {
        100: 'Museo Sans 100',
        300: 'Museo Sans 300',
        500: 'Museo Sans 500',
        700: 'Museo Sans 700',
    },
    TRADE_GOTHIC: {
        700: 'Trade Gothic Condensed',
    },
    NOBEL: {
        300: 'Nobel Light',
        500: 'Nobel Regular',
        700: 'Nobel Bold',
    },
    DEPRECATED: {
        MUSEO: 'Museo Sans',
        TRADE_GOTHIC: 'Trade Gothic',
        AVENIR: 'Avenir',
        NOBEL: 'Nobel',
    },
});

export const textParamsSchema = {
    FontSize: [8, 9, 10, 11, 12, 14, 18, 20, 24, 30, 36, 48, 60, 72, 96],
    FontWeight: [100, 300, 500, 700],
    Alignment: { left: 'left', center: 'center', right: 'right' },
    Transform: {
        capitalize: 'capitalize',
        uppercase: 'uppercase',
        lowercase: 'lowercase',
    },
    Formats: {
        regular: 'regular',
        normal: 'normal',
        bold: 'bold',
        underline: 'underlined',
        linethrough: 'linethrough',
        italic: 'italic',
        regularWeight: 500,
        boldWeight: 700,
    },
    CompanyFonts: {
        OPUS: [...Object.values(FONTS_MAP.NOBEL), ...Object.values(FONTS_MAP.AVENIR)],
        SOMEDAY: [
            ...Object.values(FONTS_MAP.TRADE_GOTHIC),
            ...Object.values(FONTS_MAP.MUSEO_SANS),
        ],
    },
};
