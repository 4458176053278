import React from 'react';
import { bool, func, node, object, string } from 'prop-types';
import Btn from '../Button';
import withFormDialog from '../withFormDialog';

function ActionButton(props) {
    const {
        text,
        config = {},
        buttonProps = {},
        openFormDialog,
        children,
        marginReset,
    } = props;

    const handleOpen = () => {
        if (Object.keys(config).length) {
            openFormDialog(config);
        } else {
            openFormDialog();
        }
    };

    return (
        <Btn
            text={text}
            btnType="primaryBtn"
            size="small"
            onClick={handleOpen}
            marginReset={marginReset}
            {...buttonProps}
        >
            {children}
        </Btn>
    );
}

ActionButton.propTypes = {
    openFormDialog: func.isRequired,
    text: string,
    config: object,
    marginReset: bool,
    children: node,
    buttonProps: object,
};

export default withFormDialog(ActionButton);
