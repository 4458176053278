import { connect } from 'react-redux';
import ActionsPanel from './ActionsPanel';
import { clearDurationNotifications } from '../../../actions/duration-notifications';
import { saveCanvasToHistory, clearCanvasHistory } from '../../../actions/canvas';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    clearDurationNotifications,
    saveCanvasToHistory,
    clearCanvasHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsPanel);
