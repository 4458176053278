import React from 'react';
import { any, node } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import classes from './CanvasTabsPanel.module.scss';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={classes.tabBox}
            {...other}
        >
            <Box p={3} className={classes.box}>
                {children}
            </Box>
        </Typography>
    );
}

function allyProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

TabPanel.propTypes = {
    children: node,
    index: any.isRequired,
    value: any.isRequired,
};
export { allyProps, TabPanel };
