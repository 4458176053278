import React, { forwardRef } from 'react';
import { any, func, oneOf, string } from 'prop-types';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import classes from './IconButton.module.scss';

const IconButtonCustom = forwardRef(
    ({ onClick, className, children, border, size, iconButtonColor, ...rest }, ref) => (
        <IconButton
            {...rest}
            aria-label="icon"
            onClick={onClick}
            className={clsx(
                className,
                classes.iconButton,
                classes.iconButtonBorder,
                classes[iconButtonColor],
                border && classes.border,
                (size === 'small' && classes.smallIcon) ||
                    (size === 'medium' && classes.mediumIcon) ||
                    '',
            )}
            disableRipple
            disableFocusRipple
            ref={ref}
        >
            {children}
        </IconButton>
    ),
);

IconButtonCustom.propTypes = {
    onClick: func,
    className: string,
    children: any,
    size: oneOf(['small', 'medium']),
    iconButtonColor: oneOf(['colorPrimary', 'colorSecondary']),
};

export default IconButtonCustom;
