import { connect } from 'react-redux';
import ProcessingQueueList from './ProcessingQueueList';
import { deleteFromQueue, getQueueProgress } from '../../actions/processingProgress';

const mapStateToProps = state => ({
    processingQueue: state.processingProgress && state.processingProgress.list,
});
const mapDispatchToProps = {
    deleteFromQueue,
    getQueueProgress,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProcessingQueueList);
