import React from 'react';
import { array, bool, func, number, oneOfType, string } from 'prop-types';
import { useField } from 'formik';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/colors';

export const SliderField = ({
    onChangeCallback,
    step,
    minValue,
    maxValue,
    valueLabelDisplay,
    valueLabelFormat,
    getAriaValueText,
    marks = false,
    form: { values },
    field: { name },
    ...props
}) => {
    // Skip meta destructuring
    const [input, , helpers] = useField({ name, ...props });
    const { setValue } = helpers;
    const { onBlur, ...restOfInputProps } = input;
    const handleSliderCommitted = async (event, newValue) => {
        if (newValue && onChangeCallback != null) {
            onChangeCallback({ ...values, [name]: newValue });
        }
        setValue(newValue);
    };

    return (
        <Slider
            {...restOfInputProps}
            {...props}
            name={name}
            onChangeCommitted={handleSliderCommitted}
            onChange={(event, value) => setValue(value)}
            valueLabelFormat={valueLabelFormat}
            aria-labelledby="zoomInSlider"
            valueLabelDisplay={valueLabelDisplay}
            getAriaValueText={getAriaValueText}
            step={step}
            min={minValue}
            max={maxValue}
            marks={marks}
        />
    );
};

const SliderBoxShadow = 'none';

const DiscreteSliderField = withStyles({
    root: {
        color: COLORS.primary,
        height: 2,
        padding: '25px 0 5px',
    },
    thumb: {
        width: 16,
        height: 16,
        backgroundColor: COLORS.primary,
        boxShadow: SliderBoxShadow,
        marginTop: -8,
        marginLeft: -8,
        borderRadius: 2,
        '&:focus, &:hover, &$active': {
            boxShadow:
                '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: SliderBoxShadow,
            },
        },
        '&$disabled': {
            width: 16,
            height: 16,
            marginTop: -8,
            marginLeft: -8,
            '&:focus, &:hover, &$active': {
                boxShadow: 'none',
            },
        },
    },
    active: {},
    disabled: {
        opacity: 0.5,
    },
    valueLabel: {
        left: 'calc(-50% - 3px)',
        top: -14,
        '& *': {
            transform: 'none',
            borderRadius: 2,
            backgroundColor: COLORS.transparent,
            color: '#ffffff',
        },
        '& > span': {
            backgroundColor: COLORS.medium,
            width: 38,
            height: 20,
        },
    },
    track: {
        height: 2,
        backgroundColor: COLORS.primary,
    },
    rail: {
        height: 2,
        opacity: 1,
        backgroundColor: COLORS.primary,
    },
    mark: props => ({
        backgroundColor: COLORS.primary,
        height: 4,
        width: 2,
        marginTop: -4,
        '&[data-index="0"]': {
            height: 10,
        },
        [`&[data-index="${props.marks.length - 1}"]`]: {
            height: 10,
        },
    }),
    markActive: {
        opacity: 1,
        backgroundColor: COLORS.primary,
    },
    markLabel: {
        top: -6,
        color: COLORS.hardGray,
    },
})(SliderField);

SliderField.propTypes = {
    onChangeCallback: func,
    step: number,
    minValue: number,
    maxValue: number,
    valueLabelDisplay: string,
    valueLabelFormat: func,
    getAriaValueText: func,
    name: string,
    marks: oneOfType([bool, array]),
};
export default DiscreteSliderField;
