import { GET_CATEGORIES, SAVE_CATEGORIES, CLEAR_CATEGORIES } from '../actions/types';

export const initialState = {
    list: [],
    loading: false,
};

export default (state = initialState, { type, categories }) => {
    switch (type) {
        case GET_CATEGORIES:
            return {
                ...state,
                loading: true,
            };
        case SAVE_CATEGORIES:
            return {
                ...state,
                ...categories,
                loading: false,
            };
        case CLEAR_CATEGORIES:
            return initialState;
        default:
            return state;
    }
};
