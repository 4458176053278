import API from '../utils/api';
import { errorNotification } from './notifications';
import { fetchItemData } from './lockedItems';
import { compareArrays, getBaseRoute } from '../utils/common';
import { LOGIN } from './types';
import { go } from './navigation';
import { ROOT_PATHS } from '../constants/variables';
import { ITEM_TYPE } from '../constants/item';
import { loadFonts } from '../utils/canvas';

export const updateUser = user => ({
    type: LOGIN,
    user,
});

export const getUserInfo = id => async (dispatch, getState) => {
    const user = await API.getUserInfo(id).catch(error => {
        dispatch(errorNotification(error));
    });
    if (user) {
        const { auth } = getState();
        if (auth?.user.roles) {
            dispatch(updateUser(user));
            const sameRoles = compareArrays(auth.user.roles, user.roles);
            // Handle roles change and redirect
            const baseRoute = getBaseRoute(user.roles);
            if (!sameRoles) {
                dispatch(go(baseRoute));
            }
            for await (const [index, companyName] of user.companyNames.entries()) {
                await loadFonts(companyName, index);
            }
            // Handle companies change and list data fetch
            const sameCompanies = compareArrays(
                auth.user.companyNames,
                user.companyNames,
            );
            if (!sameCompanies) {
                dispatch(go(baseRoute));
                const { pathname } = window.location;
                if (pathname === ROOT_PATHS.projects) {
                    dispatch(
                        fetchItemData(ITEM_TYPE.PROJECT, user.companyNames, user.roles),
                    );
                } else if (pathname === ROOT_PATHS.templates) {
                    dispatch(
                        fetchItemData(ITEM_TYPE.TEMPLATE, user.companyNames, user.roles),
                    );
                }
            }
        }

        return user;
    }
    dispatch(errorNotification(user));

    return null;
};
