import { get, del, post, postFile, put } from './api';

export const getSlides = id => get(`/projects/${id}/slides`);
export const updateIndexes = (startIndex, endIndex, id) =>
    post(`/projects/${id}/updateIndexes`, { startIndex, endIndex });

export const addAsset = file => postFile('/assets/upload', file);
export const createSlide = values => post('/slides/create', values);
export const updateSlide = (id, data) => put(`/slides/${id}`, data);
export const deleteSlide = id => del(`/slides/${id}`);
export const restoreSlide = id => put(`/slides/${id}/restore`);
export const processVideo = (projectId, type) =>
    post(`/videos/queue`, { projectId, type });
