import {
    SHOW_DURATION_NOTIFICATION,
    CLEAR_DURATION_NOTIFICATION,
} from '../actions/types';

const initialState = {
    show: false,
};

export default (state = initialState, { type }) => {
    switch (type) {
        case SHOW_DURATION_NOTIFICATION:
            return {
                show: true,
            };
        case CLEAR_DURATION_NOTIFICATION:
            return initialState;
        default:
            return state;
    }
};
