import React from 'react';
import clsx from 'clsx';
import { arrayOf } from 'prop-types';
import Tooltip from '../Tooltip';
import { locale } from '../../constants/locales';
import { TOOLTIP_PLACEMENTS } from '../../constants/variables';
import Typography from '@material-ui/core/Typography';
import { dateShortFormat } from '../../utils/helpers';
import { InfoIcon } from '../../constants/icons';
import classes from './CategoriesTooltip.module.scss';
import Category from '../../types/Category';

function CategoriesTooltip({ template }) {
    return (
        <div className={clsx(classes.infoBoxWrapper, 'flexRow')}>
            <Tooltip
                noHover={false}
                placement={TOOLTIP_PLACEMENTS.BOTTOM_START}
                tooltipClass={classes.infoBoxTooltip}
                appearDelay={250}
                TransitionProps={{
                    appear: false,
                }}
                title={
                    <>
                        <Typography
                            align="left"
                            variant="caption"
                            display="block"
                            className={classes.tooltipText}
                        >
                            {locale.LAST_CHANGES}
                            <br />
                            {dateShortFormat(
                                new Date(template.canvasDataUpdatedAt),
                                false,
                                false,
                            )}
                        </Typography>
                        <Typography
                            align="left"
                            variant="caption"
                            display="block"
                            className={classes.tooltipText}
                        >
                            {!!template?.categories.length && (
                                <span>
                                    {template?.categories.length} {locale.CATEGORIES}
                                    :<br />
                                    {template?.categories.map((c, index) => (
                                        <span key={c.name + index}>
                                            "{c.name}"
                                            {index < template?.categories?.length - 1 && (
                                                <>,</>
                                            )}
                                        </span>
                                    ))}
                                </span>
                            )}
                        </Typography>
                    </>
                }
            >
                <InfoIcon className={classes.infoBoxIcon} />
            </Tooltip>
        </div>
    );
}

CategoriesTooltip.propTypes = {
    categories: arrayOf(Category),
};

export default CategoriesTooltip;
