import React from 'react';
import { bool, func, oneOf, string } from 'prop-types';
import { useField } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import classes from './FormikField.module.scss';
import clsx from 'clsx';
export const CheckboxField = ({
    label,
    className,
    fullWidth,
    classCheckBox,
    labelPlacement,
    form: { touched, errors },
    field: { name },
    ...props
}) => {
    const [input] = useField({ name, ...props });

    return (
        <>
            <FormControlLabel
                control={
                    <MuiCheckbox
                        {...input}
                        {...props}
                        color="primary"
                        className={clsx(classCheckBox, classes.checkBox)}
                    />
                }
                label={label}
                labelPlacement={labelPlacement}
                className={clsx(className, fullWidth && classes.fullWidth)}
            />
            {touched[name] && errors[name] && (
                <FormHelperText error>{errors[name]}</FormHelperText>
            )}
        </>
    );
};

CheckboxField.propTypes = {
    label: string,
    labelPlacement: oneOf(['bottom', 'start', 'end', 'top']),
    className: string,
    classCheckBox: string,
    fullWidth: bool,
    name: func,
};
