import { get, post, del, put } from './api';

export const getUsersList = query => get('/users', query);
export const removeUser = id => del(`/users/${id}`);
export const createUser = query => post(`/users/create`, query);
export const getUserById = id => get(`/users/${id}`);
export const updateUserById = (id, data) => put(`/users/${id}`, data);
export const updateCurrentUser = (id, data) => put(`/users/update/current`, data);
export const resetPassword = id => post(`/auth/resetPassword`, { id });
export const changePassword = query => put(`/auth/changePassword`, query);
export const setPassword = query => post(`/auth/setPassword`, query);
export const registerNewUser = query => post(`/auth/register`, query);
