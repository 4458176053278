import { connect } from 'react-redux';
import StoryPreviewCarousel from './StoryPreviewCarousel';

const mapStateToProps = state => ({
    project: state?.project,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StoryPreviewCarousel);
