import { connect } from 'react-redux';
import AuthRoute from './AuthRoute';
import { go, back } from '../../actions/navigation';

const mapStateToProps = state => ({
    isLoggedIn: state?.auth?.isLoggedIn,
    roles: state?.auth?.user?.roles,
    authUser: state?.auth?.user,
    companyNames: state?.auth?.user?.companyNames,
});

const mapDispatchToProps = {
    go,
    back,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
