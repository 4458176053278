import { connect } from 'react-redux';
import UserMenu from './UserMenu';
import { logoutUser } from '../../actions/auth';
import { go } from '../../actions/navigation';

const mapStateToProps = state => ({
    user: state.auth && state.auth.user,
});

const mapDispatchToProps = {
    logoutUser,
    go,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
