import { connect } from 'react-redux';
import StoryOptionsForm from './StoryOptionsForm';
import {
    clearDurationNotifications,
    showDurationNotifications,
} from '../../../actions/duration-notifications';

const mapStateToProps = state => ({
    durationNotificationIsOn: Boolean(state?.showDurationNotifications?.show),
});

const mapDispatchToProps = {
    showDurationNotifications,
    clearDurationNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoryOptionsForm);
