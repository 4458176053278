import React from 'react';
import { bool, func } from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { WithCanvasContext } from '../../../utils/context';
import { CANVAS_GRID_OPTIONS } from '../../../constants/canvas';
import { locale } from '../../../constants/locales';
import classes from './SnappingOptionsForm.module.scss';

const SnappingOptionsForm = ({
    hasGrid,
    gridSize,
    objectSnapping,
    toggleSnapping,
    toggleCanvasGrid,
    handleGridSize,
}) => {
    const handleChange = ({ target: { value } }) => {
        handleGridSize(value);
    };

    return (
        <div className={classes.SnappingOptionsForm}>
            <div className={classes.boxBorder}>
                <FormControlLabel
                    label={locale.Grid.GRID}
                    control={
                        <Switch
                            checked={hasGrid}
                            onChange={toggleCanvasGrid}
                            color="primary"
                        />
                    }
                />
                <FormControl className="formControl">
                    <Select
                        variant="outlined"
                        inputProps={{
                            'aria-label': locale.Grid.GRID_SIZE,
                        }}
                        autoWidth
                        id="grid-size"
                        value={gridSize}
                        onChange={handleChange}
                        classes={{
                            icon: classes.arrowIcon,
                        }}
                    >
                        {CANVAS_GRID_OPTIONS.map(i => (
                            <MenuItem key={i} value={i}>
                                {i}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{locale.Grid.GRID_SIZE}</FormHelperText>
                </FormControl>
            </div>
            <div className={classes.boxBorder}>
                <FormControlLabel
                    label={locale.Grid.SNAP_TO_GRID}
                    control={
                        <Switch
                            checked={objectSnapping}
                            onChange={toggleSnapping}
                            color="primary"
                        />
                    }
                />
            </div>
        </div>
    );
};

SnappingOptionsForm.propTypes = {
    hasGrid: bool.isRequired,
    objectSnapping: bool.isRequired,
    toggleSnapping: func.isRequired,
    toggleCanvasGrid: func.isRequired,
};

export default WithCanvasContext(SnappingOptionsForm);
