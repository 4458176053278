export const STATUSES_TEMPLATE = Object.freeze({
    DRAFT: 'DRAFT',
    PUBLISHED: 'PUBLISHED',
    ARCHIVED: 'ARCHIVED',
});

export const STATUSES_TEMPLATE_OPTIONS = Object.freeze([
    { value: STATUSES_TEMPLATE.DRAFT, label: 'Draft' },
    { value: STATUSES_TEMPLATE.PUBLISHED, label: 'Published' },
    { value: STATUSES_TEMPLATE.ARCHIVED, label: 'Archived' },
]);

export const statusesRegex = new RegExp(
    `(${STATUSES_TEMPLATE.PUBLISHED}|${STATUSES_TEMPLATE.DRAFT}|${STATUSES_TEMPLATE.ARCHIVED})`,
);

export const STATUSES_PROJECT = Object.freeze({
    NEW: 'NEW',
    READY: 'READY',
    FAILED: 'FAILED',
});

export const STATUSES_PROJECT_OPTIONS = Object.freeze([
    { value: STATUSES_PROJECT.NEW, label: 'New' },
    { value: STATUSES_PROJECT.READY, label: 'Ready for download' },
    { value: STATUSES_PROJECT.FAILED, label: 'Rendering failed' },
]);
