import { connect } from 'react-redux';
import ProjectPreview from './ProjectPreview';
import { clearAllSlides } from '../../actions/slides';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    clearAllSlides,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPreview);
