import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { array, bool, oneOfType, object, string } from 'prop-types';
import SlideObject from '../../types/Slide';
import { usePrevious } from '../../utils/hooks';
import { BUILD_FULL_FILE_PATH } from '../../constants/slides';
import classes from './Slide.module.scss';
import Spinner from '../Spinner';
import SlideLogo from '../SlideLogo';

const Slide = ({
  index,
  slide,
  company,
  className,
  mainSlide = false,
  noPointer = false,
  dimensions,
  smallSpinner,
}) => {
  const elementRef = useRef(null);
  const elementWrapper = useRef(null);
  const prevSlide = usePrevious(slide);
  const [isLoading, setLoaded] = useState(true);
  const [slideSrc, setSlideSrc] = useState('');

  let load = null;

  const isVideo = slide?.imageUrl?.indexOf('.mp4') >= 0;
  const SlideTag = isVideo ? 'video' : 'img';

  function play() {
    if (
      isVideo &&
      !mainSlide &&
      elementRef.current &&
      elementRef.current.nodeName === 'VIDEO'
    ) {
      const isPlaying =
        elementRef.current.currentTime > 0 &&
        !elementRef.current.paused &&
        !elementRef.current.ended &&
        elementRef.current.readyState > 2;
      if (!isPlaying) {
        elementRef.current.play();
      }
    }
  }

  function pause() {
    if (
      isVideo &&
      !mainSlide &&
      elementRef.current &&
      elementRef.current.nodeName === 'VIDEO'
    ) {
      if (!elementRef.current.paused) {
        elementRef.current.pause();
      }
    }
  }

  function getLoader() {
    load = setInterval(() => {
      if (
        load &&
        isVideo &&
        elementRef &&
        elementRef.current &&
        elementRef.current.readyState >= 3
      ) {
        clearInterval(load);
        setLoaded(false);
      }
    }, 500);
  }

  // handle proper change of canvas preview
  useEffect(() => {
    if (slide?.canvasData?.preview) {
      if (
        (prevSlide && prevSlide.updatedAt !== slide.updatedAt) ||
        (prevSlide &&
          prevSlide.canvasDataUpdatedAt !== slide.canvasDataUpdatedAt) ||
        prevSlide == null
      ) {
        if (slide.canvasData.preview !== slideSrc) {
          setSlideSrc(
            BUILD_FULL_FILE_PATH(slide.canvasData.preview) +
              `?cache=${Date.now()}`
          );
        }
      }
    } else {
      setSlideSrc(BUILD_FULL_FILE_PATH(slide.imageUrl));
    }
  }, [slideSrc, slide, prevSlide]);

  return (
    <div className={className} ref={elementWrapper}>
      {isLoading && isVideo && (
        <Spinner loading={isLoading} overlay absolute small={smallSpinner} />
      )}
      {slideSrc && (
        <SlideTag
          ref={elementRef}
          style={{
            ...dimensions,
          }}
          className={clsx(classes.slideImg, noPointer && classes.noPointer)}
          src={slideSrc}
          onMouseOver={play}
          onMouseLeave={pause}
          onFocus={play}
          onLoadStart={getLoader}
          autoPlay={mainSlide}
          muted
        />
      )}
      {slideSrc && slide?.logo && (
        <SlideLogo
          company={company}
          logoType={slide?.logo}
          logoPosition={slide?.logoPosition}
          dimensions={dimensions}
        />
      )}
      {(!isLoading || !isVideo) && !mainSlide && (
        <span className={classes.number}>{index}</span>
      )}
    </div>
  );
};

Slide.propTypes = {
  className: oneOfType([string, array, object]),
  company: string.isRequired,
  slide: SlideObject,
  mainSlide: bool,
  smallSpinner: bool,
};

export default Slide;
