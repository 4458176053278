import * as Yup from 'yup';
import { locale } from '../../constants/locales';
import { companyRegex } from '../../constants/companies';

export default Yup.object().shape({
    name: Yup.string().required(locale.Messages.REQUIRED_FIELD),
    companyNames: Yup.array()
        .of(Yup.string().matches(companyRegex, locale.Messages.NOT_VALID('Company name')))
        .required(locale.Messages.REQUIRED_FIELD),
});
