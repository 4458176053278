import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { array, arrayOf, func } from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
// import { withStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '../../components/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import withFormDialog from '../../components/withFormDialog';
import Alert from '../../components/Alert';
import Grid from '../../components/Grid';
import CategoriesTooltip from '../../components/CategoriesTooltip';
import {
  buildOptions,
  buildCategoriesOptions,
  uppercaseFirstLetter,
} from '../../utils/common';
import AuthContext from '../../utils/auth-context';
import { useDimensions } from '../../utils/hooks';
import { dateShortFormat } from '../../utils/helpers';
import { locale } from '../../constants/locales';
import { STATUSES_TEMPLATE } from '../../constants/statuses';
import { COMPANIES_OPTIONS, COMPANY_LOGOS } from '../../constants/companies';
import { SLIDE_ASPECT_RATIO } from '../../constants/sizes';
import {
  AUTOCOMPLETE,
  SELECT_FIELD,
  TEXT_FIELD,
} from '../../constants/variables';
import { BUILD_FULL_FILE_PATH } from '../../constants/slides';
import { ITEM_TYPE } from '../../constants/item';
import validationSchema from '../../schemas/validations/create-template';
import User from '../../types/User';
import Category from '../../types/Category';
import classes from './TemplatesList.module.scss';

function TemplatesList({
  authUser,
  templates,
  openFormDialog,
  publishTemplate,
  archiveTemplate,
  updateTemplates,
  handleStartItemEditing,
  deleteTemplate,
  duplicateTemplate,
  categories,
  getCategories,
}) {
  const [itemRef, itemSize] = useDimensions();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({});
  const open = Boolean(anchorEl);
  const { companyNames, roles } = useContext(AuthContext);

  function handleOpenAlert() {
    setOpenAlert(true);
  }

  function handleCloseAlert(event, reason) {
    if (reason !== 'backdropClick') {
      setOpenAlert(false);
      setAlertConfig({});
      handleMenuClose();
    }
  }

  function handleAlertConfig(config) {
    setAlertConfig(config);
  }

  async function handlePublishAlert() {
    handleMenuClose();
    if (selectedTemplate?.id) {
      const publish = await publishTemplate(selectedTemplate.id);
      if (publish) {
        updateTemplates();
        handleCloseAlert();
      }
    }
  }

  async function handleDeleteAlert() {
    handleMenuClose();
    if (selectedTemplate?.id) {
      const deleted = await deleteTemplate(selectedTemplate.id);
      if (deleted) {
        updateTemplates();
        handleCloseAlert();
      }
    }
  }

  async function handleArchiveAlert() {
    handleMenuClose();
    if (selectedTemplate?.id) {
      const archive = await archiveTemplate(selectedTemplate.id);
      if (archive) {
        updateTemplates();
        handleCloseAlert();
      }
    }
  }

  async function handleCompanyChange({ companyName }, withStateUpdate = false) {
    if (companyName && selectedTemplate) {
      let categoriesOptions = buildCategoriesOptions(categories);
      let categoriesValue = buildCategoriesOptions(
        selectedTemplate?.categories
      );
      try {
        const { items } = await getCategories(
          {
            companyNames: [companyName],
          },
          withStateUpdate
        );
        categoriesOptions = buildCategoriesOptions(items);
        // Reset input value on company change
        if (selectedTemplate.companyName !== companyName) {
          categoriesValue = [];
        }
      } catch (e) {
        console.error(e);
      } finally {
        openFormDialog({
          dialogTitle: locale.EDIT_TEMPLATE,
          validationSchema,
          id: selectedTemplate.id,
          actionBtnText: locale.SAVE,
          fields: [
            {
              id: 'name',
              value: selectedTemplate?.name,
              label: locale.NAME,
              type: TEXT_FIELD,
              // TODO Figure out why we allow to edit non-draft templates??
              // disabled: selectedTemplate.status !== STATUSES_TEMPLATE.DRAFT,
            },
            {
              id: 'companyName',
              value: companyName,
              label: locale.FOR_COMPANY,
              type: SELECT_FIELD,
              onChangeCallback: handleCompanyChange,
              options: buildOptions(companyNames.data, COMPANIES_OPTIONS, true),
              disabled: selectedTemplate.status !== STATUSES_TEMPLATE.DRAFT,
            },
            {
              id: 'categories',
              value: categoriesValue,
              options: categoriesOptions,
              label: locale.CATEGORY,
              type: AUTOCOMPLETE,
              // disabled: selectedTemplate.status !== STATUSES_TEMPLATE.DRAFT,
            },
          ],
        });
      }
    }
  }

  async function handleDeleteTemplate(event) {
    if (event) {
      event.stopPropagation();
    }
    handleMenuClose();
    if (selectedTemplate?.name) {
      handleOpenAlert();
      handleAlertConfig({
        alertTitle: locale.actionString(locale.DELETE, 'template'),
        alertText: locale.Messages.DELETE_TEMPLATE_ALERT(
          selectedTemplate?.name
        ),
        alertBtnText: locale.DELETE,
        alertBtnColor: 'primary',
        actionClick: handleDeleteAlert,
        cancelAction: handleCloseAlert,
      });
    }
  }

  function handleMenuOpen(event, template) {
    if (event) {
      event.stopPropagation();
    }
    setSelectedTemplate(template);
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose(event) {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
    setSelectedTemplate(null);
  }

  async function handleEditTemplate(event) {
    if (event) {
      event.stopPropagation();
    }
    if (selectedTemplate?.id) {
      await handleCompanyChange({
        companyName: selectedTemplate.companyName,
      });
    }
    handleMenuClose();
  }

  async function handlePublishTemplate(id, event) {
    if (event) {
      event.stopPropagation();
    }
    handleMenuClose();
    if (selectedTemplate?.id) {
      handleOpenAlert();
      handleAlertConfig({
        alertTitle: locale.actionString(locale.PUBLISH, 'template'),
        alertText: locale.Messages.PUBLISH_ALERT,
        alertBtnText: locale.OK,
        alertBtnColor: 'primary',
        actionClick: handlePublishAlert,
        cancelAction: handleCloseAlert,
      });
    }
  }

  async function handleDuplicateTemplate(id, event) {
    if (event) {
      event.stopPropagation();
    }
    handleMenuClose();
    if (selectedTemplate?.id) {
      await duplicateTemplate(selectedTemplate.id);
      updateTemplates();
    }
  }

  async function handleArchiveTemplate(id, event) {
    if (event) {
      event.stopPropagation();
    }
    handleMenuClose();
    handleOpenAlert();
    handleAlertConfig({
      alertTitle: locale.actionString(locale.ARCHIVE, 'template'),
      alertText: locale.Messages.ARCHIVE_ALERT,
      alertBtnText: locale.ARCHIVE,
      alertBtnColor: 'primary',
      actionClick: handleArchiveAlert,
      cancelAction: handleCloseAlert,
    });
  }

  return (
    <Grid container spacing={3} className={classes.templatesList}>
      {!!Object.keys(templates).length &&
        roles &&
        templates.map((template) => (
          <Grid key={template.id} item xs={12} sm={4} lg={3}>
            <div className={classes.paper}>
              <div
                ref={itemRef}
                style={{
                  minHeight: itemSize?.width
                    ? itemSize?.width / SLIDE_ASPECT_RATIO + 'px'
                    : 'auto',
                  backgroundImage:
                    template?.canvasData?.preview &&
                    `url(${BUILD_FULL_FILE_PATH(
                      template.canvasData.preview
                    )}?cache=${template.updatedAt})`,
                }}
                id={template.id}
                className={clsx(
                  classes.cover,
                  template.lockedBy &&
                    template.lockedBy.id !== authUser.id &&
                    classes.paperLock
                )}
                onClick={() =>
                  handleStartItemEditing({
                    id: template.id,
                    isLockedByAnotherUser:
                      template.lockedBy && template.lockedBy.id !== authUser.id,
                    type: ITEM_TYPE.TEMPLATE,
                  })
                }
              >
                {template && (
                  <IconButton
                    dataid={template.id}
                    className={classes.menuButton}
                    aria-label="More"
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    onClick={(event) => handleMenuOpen(event, template)}
                    disabled={
                      !!template.lockedBy &&
                      template.lockedBy.id !== authUser.id
                    }
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
                <div
                  className={clsx(
                    classes.infoOverlay,
                    !template?.categories?.length && classes.noCategories
                  )}
                >
                  <div className="flexRow">
                    <div className={classes.infoOverlayBox}>
                      <Typography
                        align="left"
                        variant="caption"
                        display="block"
                        className={classes.caption}
                      >
                        {locale.LAST_CHANGES}:{' '}
                        {dateShortFormat(
                          new Date(template.canvasDataUpdatedAt),
                          true
                        )}
                      </Typography>
                      {!!template?.categories?.length && (
                        <Typography
                          align="left"
                          variant="caption"
                          display="block"
                          className={classes.caption}
                        >
                          {uppercaseFirstLetter(locale.CATEGORIES)}:{' '}
                          {template?.categories?.length}
                        </Typography>
                      )}
                    </div>
                    <div className="flexRow">
                      <CategoriesTooltip template={template} />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classes.content}
                title={`${template.companyName} ${template.name}`}
              >
                <div className="flexRow">
                  <Typography
                    align="left"
                    classes={{
                      root: classes.statusTitle,
                    }}
                    className={clsx(
                      template.status === STATUSES_TEMPLATE.DRAFT &&
                        classes.draftStatus
                    )}
                    display="block"
                    variant="body2"
                    noWrap
                  >
                    {template.status && uppercaseFirstLetter(template.status)}
                  </Typography>
                  <span className={classes.verticalDivider}>|</span>
                  <img
                    className={classes.logoImg}
                    src={COMPANY_LOGOS[template.companyName]}
                    alt={template.companyName}
                  />
                </div>
                <Typography
                  className={classes.templateTitle}
                  align="left"
                  variant="h6"
                >
                  <LinesEllipsis
                    maxLine="2"
                    basedOn="letters"
                    text={template.name}
                  />
                </Typography>
              </div>
            </div>
          </Grid>
        ))}
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        TransitionComponent={Grow}
        PopoverClasses={{ paper: classes.menuList }}
      >
        <MenuItem onClick={(event) => handleEditTemplate(event)}>
          {locale.EDIT}
        </MenuItem>
        {selectedTemplate?.status === STATUSES_TEMPLATE.DRAFT && (
          <MenuItem
            onClick={(event) =>
              handlePublishTemplate(selectedTemplate.id, event)
            }
          >
            {locale.PUBLISH}
          </MenuItem>
        )}
        <MenuItem
          onClick={(event) =>
            handleDuplicateTemplate(selectedTemplate.id, event)
          }
        >
          {locale.DUPLICATE}
        </MenuItem>

        {(selectedTemplate?.status === STATUSES_TEMPLATE.DRAFT ||
          selectedTemplate?.status === STATUSES_TEMPLATE.PUBLISHED) && (
          <MenuItem
            onClick={(event) =>
              handleArchiveTemplate(selectedTemplate.id, event)
            }
          >
            {locale.ARCHIVE}
          </MenuItem>
        )}
        <MenuItem onClick={(event) => handleDeleteTemplate(event)}>
          {locale.DELETE}
        </MenuItem>
      </Menu>
      {!!Object.keys(alertConfig).length && alertConfig && (
        <Alert alertConfig={alertConfig} open={openAlert} />
      )}
    </Grid>
  );
}

TemplatesList.propTypes = {
  authUser: User,
  updateTemplates: func,
  templates: array.isRequired,
  handleStartItemEditing: func.isRequired,
  duplicateTemplate: func.isRequired,
  openFormDialog: func.isRequired,
  publishTemplate: func.isRequired,
  archiveTemplate: func.isRequired,
  deleteTemplate: func.isRequired,
  getCategories: func.isRequired,
  categories: arrayOf(Category),
};

export default withFormDialog(TemplatesList);
