import { arrayOf, oneOf, shape, string } from 'prop-types';
import { ROLES } from '../constants/roles';
import { COMPANIES } from '../constants/companies';

export default shape({
    id: string,
    createdAt: string,
    updatedAt: string,
    firstName: string,
    lastName: string,
    username: string,
    roles: arrayOf(oneOf([ROLES.ADMIN, ROLES.DESIGNER, ROLES.CONTENT_MANAGER])),
    email: string,
    companyNames: arrayOf(oneOf([COMPANIES.OPUS, COMPANIES.SOMEDAY])),
    // phone: string,
    // industry: string,
    // address: string,
    // country: string,
    // state: string,
    // city: string,
    // zipCode: string,
});
