import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { arrayOf, func, string } from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import Btn from '../../../components/Button';
import Slide from '../../../components/Slide/Slide';
import SlideObject from '../../../types/Slide';
import { locale } from '../../../constants/locales';
import { usePrevious } from '../../../utils/hooks';
import classes from './ActiveSellerSlides.module.scss';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { TRANSITION_ICONS } from '../../../constants/transitions';

const ActiveSellerSlides = ({
    activeSlide,
    selectSlide,
    projectId,
    company,
    slides,
    updateIndexes,
    deleteSlide,
    infoNotification,
    authUser,
}) => {
    const [disabled, setDisabled] = useState(false);
    const [items, setItems] = useState([]);
    const prevSlidesLength = usePrevious(items.length);

    // // Set items from slides
    useEffect(() => {
        setItems(slides);
    }, [setItems, slides]);

    // Remove disabled flag only then new slides served as prop and update slides
    useEffect(() => {
        setDisabled(false);
    }, [slides.length]);

    // Update on slides add/restore and select last one
    // and avoid to do this on first render
    useEffect(() => {
        if (
            prevSlidesLength != null &&
            items.length > 0 &&
            prevSlidesLength < items.length &&
            items.length - prevSlidesLength === 1
        ) {
            const lastIndex = Math.max(items.length - 1, 0);
            if (items[lastIndex]) {
                selectSlide(items[lastIndex]);
            }
        }
        // Select first slide when slides appears initially
        if (!prevSlidesLength && items.length > 0) {
            selectSlide(items[0] || {});
        }
    }, [prevSlidesLength, items.length, items, selectSlide]);

    async function addSlide() {
        // Set disabled for add button to prevent same indexes creation
        setDisabled(true);
        const slide = {
            imageUrl: 'empty',
        };
        selectSlide(slide);
    }

    async function handleDelete(index) {
        // Set disabled for add button to prevent same indexes creation
        setDisabled(true);
        const message = `${locale.Messages.SLIDE_WAS_DELETED(slides[index].index + 1)}`;
        const data = {
            actionType: locale.NotificationActionTypes.SLIDE_UNDO,
            slideId: slides[index].id,
            projectId,
        };

        infoNotification(message, authUser, data);
        try {
            await deleteSlide(slides[index].id, projectId);
            const newSlides = [...slides];
            newSlides.splice(index, 1);
            if (newSlides[index]) {
                selectSlide(newSlides[index]);
            } else if (newSlides.length) {
                selectSlide(newSlides[newSlides.length - 1]);
            } else {
                selectSlide({});
            }
        } catch (e) {
            console.error(e);
        }
    }

    const reorder = (startIndex, endIndex, list) => {
        const result = Array.from(list);
        result[endIndex].index = startIndex;
        result[startIndex].index = endIndex;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    async function onDragEnd({ source, destination }) {
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.index !== destination.index) {
            const startIndex = source.index;
            const endIndex = destination.index;
            const reorderedItems = reorder(startIndex, endIndex, items);
            setItems(reorderedItems);
            try {
                const updatedSlides = await updateIndexes(
                    startIndex,
                    endIndex,
                    projectId,
                );
                selectSlide(updatedSlides[endIndex]);
            } catch (e) {
                console.error(e);
            }
        }
    }

    const slidesTimeLine = items.map(
        (slide, i) =>
            Object.keys(slide).length > 0 && (
                <Draggable key={slide.id} draggableId={slide.id} index={i}>
                    {provided => (
                        <div
                            className={classes.TimeLineSlide}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <div className={classes.SlideTransition}>
                                {i !== 0 && slide.transitionName !== 'none' && (
                                    <>
                                        <span>
                                            <ArrowRightAltIcon
                                                classes={{ root: classes.ArrowRoot }}
                                            />
                                        </span>
                                        <span className={classes.transitionDuration}>
                                            {slide.transitionDuration}s
                                        </span>
                                        <span className="item">
                                            <img
                                                src={
                                                    TRANSITION_ICONS[slide.transitionName]
                                                }
                                                alt={slide.transitionName}
                                            />
                                        </span>
                                    </>
                                )}
                            </div>
                            <Fab
                                size="small"
                                variant="extended"
                                disableFocusRipple
                                disabled={disabled}
                                onClick={() => handleDelete(i)}
                                className={classes.DeleteSlide}
                                aria-label="Delete"
                            >
                                <DeleteIcon fontSize="small" htmlColor="#5C5C5C" />
                            </Fab>
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                            <span
                                role="button"
                                className={clsx(
                                    classes.Outline,
                                    slide.id === activeSlide.id && classes.active,
                                )}
                                // onKeyDown={selectSlide}
                                tabIndex={i}
                                onClick={() => selectSlide(slide)}
                            >
                                <Slide
                                    index={i + 1}
                                    slide={slide}
                                    company={company}
                                    className={classes.Slide}
                                    noPointer
                                    smallSpinner
                                />
                            </span>
                        </div>
                    )}
                </Draggable>
            ),
    );

    return (
        <div className={classes.SlidesLine}>
            <Btn
                className={classes.TimeLineBtn}
                text="add slide"
                onClick={addSlide}
                disabled={disabled}
                classes={{ label: classes.TimeLineBtnLabel }}
            >
                <AddIcon />
            </Btn>
            {items && !!Object.keys(items).length && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-slides" direction="horizontal">
                        {provided => (
                            <div
                                className={classes.SlidesLineRow}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {slidesTimeLine}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </div>
    );
};

ActiveSellerSlides.propTypes = {
    projectId: string.isRequired,
    activeSlide: SlideObject,
    company: string.isRequired,
    slides: arrayOf(SlideObject),
    selectSlide: func.isRequired,
    updateIndexes: func.isRequired,
    deleteSlide: func.isRequired,
};
export default ActiveSellerSlides;
