import { oneOfType, shape, string } from 'prop-types';

export default shape({
    createdAt: string,
    id: string,
    message: string,
    params: shape({
        projectId: string,
        type: string,
        videoUrl: oneOfType([string]),
    }),
    status: string,
    type: string,
    updatedAt: string,
});
