import { get, post } from './api';
import { environment } from '../../../environments/environment';

export const getVideoProcessingServiceStatus = () => get('/system/service-status');
export const getVideoInstanceStatus = () => get('/system/instance-status');
export const startVideoInstance = () => post('/system/start-instance');
export const stopVideoInstance = () => post('/system/stop-instance');
export const restartBackend = () =>
    environment?.serviceUrl
        ? post(`${environment.serviceUrl}/system/backend-restart`, {}, true)
        : Promise.reject('Not supported');
