import { LOCATION_CHANGE } from 'connected-react-router';
import { POPUP_OPEN, POPUP_CLOSE } from '../actions/types';

const initialState = {
    popupClose: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POPUP_OPEN:
            return {
                ...state,
                popupClose: false,
            };
        case POPUP_CLOSE:
            return {
                ...state,
                popupClose: true,
            };
        case LOCATION_CHANGE:
            return {
                ...state,
                popupClose: true,
            };
        default:
            return state;
    }
};
