import React, { useRef, useState, useContext } from 'react';
import { bool, func, object } from 'prop-types';
import clsx from 'clsx';
import { Form } from 'formik';
import IconButton from '../../components/IconButton';
import FormikWrapper from '../../components/FormikWrapper';
import FormikField from '../../components/FormikField';
import AuthContext from '../../utils/auth-context';
import { useMount } from '../../utils/hooks';
import {
    DEFAULT_FILTERS,
    SELECT_FIELD,
    SEARCH_FIELD,
    MULTIPLE_SELECT_FIELD,
} from '../../constants/variables';
import { locale } from '../../constants/locales';
import { PROJECTS_FILTERS, TEMPLATES_FILTERS } from '../../constants/filters';
import { FilterIcon } from '../../constants/icons';
import { STATUSES_TEMPLATE } from '../../constants/statuses';
import classes from './FiltersForm.module.scss';

const FiltersForm = ({
    onChange,
    config = {},
    filterDropdown,
    filtersOpen,
    setFiltersOpen,
    path,
}) => {
    const { companyNames } = useContext(AuthContext);
    const form = useRef(null);
    const [initialValues, setValues] = useState({});
    const [filtersConfig, setConfig] = useState({});
    const [lastActiveField, setLastActiveField] = useState(null);
    const [inputRef, setInputRef] = useState(null);

    const setSearchRef = ref => setInputRef(ref);

    function setFormValues(fields) {
        const values = {};
        if (fields && fields.length > 0) {
            fields.map(field => {
                values[field.id] = field.value || '';
                // Handle default params for multiple select fields
                if (field.type === MULTIPLE_SELECT_FIELD) {
                    if (field.id === TEMPLATES_FILTERS.STATUSES && path === 'templates') {
                        values[field.id] = [
                            STATUSES_TEMPLATE.DRAFT,
                            STATUSES_TEMPLATE.PUBLISHED,
                        ];
                    }
                    if (
                        field.id === TEMPLATES_FILTERS.COMPANY_NAMES &&
                        path === 'templates'
                    ) {
                        // Use companyNames.data since we need to pass Array here
                        values[field.id] =
                            field.options.length > 1 ? [] : companyNames?.data;
                    }
                    if (
                        field.id === PROJECTS_FILTERS.PROJECT_STATUSES &&
                        path === 'projects'
                    ) {
                        values[field.id] = [];
                    }
                    if (
                        field.id === PROJECTS_FILTERS.COMPANY_NAMES &&
                        path === 'projects'
                    ) {
                        // Use companyNames.data since we need to pass Array here
                        values[field.id] =
                            field.options.length > 1 ? [] : companyNames?.data;
                    }
                    if (
                        field.id === PROJECTS_FILTERS.PROJECT_TYPE &&
                        path === 'projects'
                    ) {
                        values[field.id] = [];
                    }
                }
                // If user have only one company we're hiding "Show all" option (not for multiple select field)
                if (
                    field.id === TEMPLATES_FILTERS.COMPANY_NAMES &&
                    field.type !== MULTIPLE_SELECT_FIELD
                ) {
                    values[field.id] =
                        field.options.length > 1
                            ? DEFAULT_FILTERS.value
                            : companyNames?.data?.[0];
                }
                return true;
            });
            values.search = '';
        }
        if (values && Object.keys(values).length) {
            const savedFilters = localStorage.getItem(`${path}_filters`);
            try {
                const filterValues = JSON.parse(savedFilters);
                if (!filterValues) {
                    throw new Error('no previously stored values');
                }
                setValues({ ...filterValues, search: '' });
                onChange({ ...filterValues, search: '' });
            } catch (e) {
                setValues(values);
                onChange(values);
            }
        }
    }

    function handleInit() {
        setConfig({ ...{}, ...config });
        setFormValues(config.fields);
    }

    useMount(() => {
        if (companyNames.data) {
            handleInit();
        }
    });

    const handleSearch = async () => {
        try {
            await form?.current?.validateForm?.();
            await form?.current?.handleSubmit?.();
        } catch (e) {
            console.info(e);
        }
    };

    const handleOpenFilter = () => {
        setFiltersOpen(!filtersOpen);
    };

    const submit = async (values, submitProps) => {
        const { setSubmitting, validateForm } = submitProps;
        const errors = await validateForm(values);
        if (!Object.keys(errors).length) {
            onChange(values);
        }
        await setSubmitting(false);
        // Handle search input focus restore
        if (lastActiveField === SEARCH_FIELD && inputRef) {
            try {
                inputRef.focus();
            } catch (e) {
                console.info('cannot focus on input');
            }
        }
    };

    const onKeyPress = async ev => {
        if (ev && ev?.key === 'Enter') {
            ev.preventDefault();
            setLastActiveField(ev.target.name);
            await handleSearch();
        }
    };

    return (
        <FormikWrapper
            ref={form}
            initialValues={initialValues}
            enableReinitialize
            validationSchema={config.validationSchema ?? null}
            onSubmit={submit}
        >
            {({ values, isSubmitting }) =>
                Object.keys(values).length > 0 && (
                    <Form className={classes.filtersForm} onKeyPress={onKeyPress}>
                        <div className={classes.filterTop}>
                            {filtersConfig &&
                                filtersConfig.fields &&
                                filtersConfig.fields.map(
                                    field =>
                                        !field.filterMode && (
                                            <FormikField
                                                margin="dense"
                                                variant={field.variant || 'outlined'}
                                                key={field.id}
                                                label={field.label}
                                                select={[
                                                    SELECT_FIELD,
                                                    MULTIPLE_SELECT_FIELD,
                                                ].includes(field.type)}
                                                multiple={
                                                    field.type === MULTIPLE_SELECT_FIELD
                                                }
                                                type={field.type}
                                                name={field.id}
                                                options={field.options}
                                                autoComplete={field.autocomplete || 'off'}
                                                className={clsx(
                                                    classes.formControl,
                                                    field.className,
                                                )}
                                                selectedValues={
                                                    form?.current?.values[field.id]
                                                }
                                                disabled={
                                                    isSubmitting ||
                                                    (field.options &&
                                                        field.options.length < 2)
                                                }
                                                onChangeCallback={onChange}
                                            />
                                        ),
                                )}
                            {filterDropdown && (
                                <IconButton
                                    aria-label={locale.FILTER}
                                    onClick={handleOpenFilter}
                                    className={classes.filterButton}
                                    size="small"
                                    border
                                >
                                    <FilterIcon />
                                </IconButton>
                            )}
                            <FormikField
                                innerRef={setSearchRef}
                                margin="dense"
                                key={SEARCH_FIELD}
                                variant="outlined"
                                label={locale.SEARCH}
                                type={SEARCH_FIELD}
                                name={SEARCH_FIELD}
                                className={clsx(classes.formControl, classes.search)}
                                onChangeCallback={onChange}
                            />
                        </div>
                        {filterDropdown && (
                            <div
                                className={clsx(
                                    classes.filterDropdown,
                                    filtersOpen && classes.active,
                                )}
                            >
                                {filtersConfig &&
                                    filtersConfig.fields &&
                                    filtersConfig.fields.map(
                                        field =>
                                            field.filterMode && (
                                                <FormikField
                                                    margin="dense"
                                                    variant={field.variant || 'outlined'}
                                                    key={field.id}
                                                    label={field.label}
                                                    select={[
                                                        SELECT_FIELD,
                                                        MULTIPLE_SELECT_FIELD,
                                                    ].includes(field.type)}
                                                    multiple={
                                                        field.type ===
                                                        MULTIPLE_SELECT_FIELD
                                                    }
                                                    type={field.type}
                                                    name={field.id}
                                                    options={field.options}
                                                    autoComplete={
                                                        field.autocomplete || 'off'
                                                    }
                                                    className={clsx(
                                                        classes.formControl,
                                                        field.className,
                                                    )}
                                                    selectedValues={
                                                        form.current.values[field.id]
                                                    }
                                                    disabled={
                                                        isSubmitting ||
                                                        (field.options &&
                                                            field.options.length < 2)
                                                    }
                                                    onChangeCallback={onChange}
                                                />
                                            ),
                                    )}
                            </div>
                        )}
                    </Form>
                )
            }
        </FormikWrapper>
    );
};

FiltersForm.propTypes = {
    onChange: func.isRequired,
    config: object.isRequired,
    filterDropdown: bool,
};

export default FiltersForm;
