import { ANIMATIONS } from '../../constants/variables';
import { COLORS } from '../../constants/colors';

export const styles = ({ zIndex, spacing }) => ({
    root: {
        height: spacing(9),
        zIndex: zIndex.drawer + 1,
        position: 'relative',
        '& header': {
            height: spacing(9),
            backgroundColor: COLORS.white,
            borderBottom: `1px solid ${COLORS.secondary}`,
            boxShadow: 'none',
        },
    },
    appBar: {
        width: '100%',
        transition: ANIMATIONS.transitionCubicBezierIn,
    },
    appBarShift: {
        transition: ANIMATIONS.transitionCubicBezierOut,
    },
    appBarStandalone: {
        marginLeft: 0,
        width: '100%',
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center',
    },
    rightSide: {
        justifyContent: 'flex-end',
        flexBasis: '50%',
    },
    marginLeft: {
        marginRight: spacing(1),
    },
    logo: {
        width: spacing(14),
        margin: spacing(1),
    },
    logoBig: {
        width: spacing(20),
        margin: spacing(1),
    },
    toolbar: {
        height: spacing(9),
        justifyContent: 'space-between',
    },
    title: {
        maxWidth: spacing(60),
        fontWeight: 400,
        marginRight: spacing(1),
        marginLeft: spacing(3),
    },
    typeName: {
        color: COLORS.red,
        marginRight: spacing(1),
    },
    companyImage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: spacing(3),
        maxWidth: spacing(7),
        margin: `0 ${spacing(2)}px 0 ${spacing(1)}px`,
        '& img': {
            width: '100%',
        },
    },
});
