export const SIDEMENU_WIDTH = 240;
export const LIMIT_DURATION_VIDEO = 15;
export const DEFAULT_FONT_SIZE = 16;
export const CANVAS_GRID_SIZE = 20;
export const TRANSITION_DURATION = 1;
export const ANIMATION_DURATION = 1;
export const ANIMATION_DELAY = 0;
export const DEFAULT_TEXT_SPACING = 0;
export const DEFAULT_TEXT_LINE_HEIGHT = 1.14;
export const DEFAULT_MAX_CHARS_VALUE = '';
export const SLIDE_ASPECT_RATIO = 0.5625;
export const MAX_LENGTH_RENAME_LAYER = 30;
export const DELAY_SHOW_DRAWER = 225;
export const DELAY_HIDDEN_DRAWER = 195;
