import { PROCESS_VIDEO, STOP_PROCESS_VIDEO } from '../actions/types';

const initialState = {
    list: [],
};

export default (state = initialState, { type, data }) => {
    switch (type) {
        case PROCESS_VIDEO:
            return {
                ...state,
                list: data,
            };
        case STOP_PROCESS_VIDEO:
            return initialState;
        default:
            return state;
    }
};
