import {
    ADD_CANVAS_TO_HISTORY,
    CLEAR_CANVAS_HISTORY,
    RESET_HISTORY_TO_INDEX,
} from './types';
import { editTemplate } from './templates';
import { updateSlide } from './slides';
import { HISTORY_DEFAULT_INDEX } from '../constants/variables';

export const addCanvasItem = canvas => ({
    type: ADD_CANVAS_TO_HISTORY,
    canvas,
});
// export const updateCanvasItem = (canvas, index) => ({
//     type: UPDATE_CANVAS_ITEM,
//     canvas,
//     index,
// });
// export const deleteCanvasItem = index => ({
//     type: DELETE_CANVAS_FROM_HISTORY,
//     index,
// });
export const resetHistoryToIndex = index => ({
    type: RESET_HISTORY_TO_INDEX,
    index,
});
export const clearHistory = () => ({
    type: CLEAR_CANVAS_HISTORY,
});

export const saveCanvasToHistory = (
    canvas,
    canvasIndex,
    saveCanvasToDb = true,
    withHistoryUpdate = true,
    withPreview = false,
) => async (dispatch, getState) => {
    const lastIndex = getState().canvas.history.length - 1;
    // You need to pass current canvasIndex to "saveCanvasState" method
    // if You want to reset history to current index
    if (canvasIndex > HISTORY_DEFAULT_INDEX && canvasIndex < lastIndex) {
        dispatch(resetHistoryToIndex(canvasIndex));
    }
    if (withHistoryUpdate) {
        dispatch(addCanvasItem(canvas));
    }

    if (saveCanvasToDb) {
        // Update canvas inside template/slide
        if (!canvas.slideId && canvas.templateId) {
            // Update canvas inside template
            await dispatch(
                editTemplate(canvas.templateId, {
                    canvasData: canvas,
                    withPreview,
                }),
            );
        } else if (canvas.slideId) {
            await dispatch(
                updateSlide(
                    canvas.slideId,
                    { canvasData: canvas, withPreview },
                    canvas.projectId,
                    true,
                ),
            );
        }
    }
};

export const saveCanvasWithoutHistory = (
    canvas,
    canvasIndex,
    saveCanvasToDb,
    withHistoryUpdate,
    withPreview,
) => async dispatch => {
    await dispatch(
        saveCanvasToHistory(canvas, canvasIndex, saveCanvasToDb, false, withPreview),
    );
};

export const clearCanvasHistory = () => dispatch => {
    dispatch(clearHistory());
};
