import React from 'react';
import { any, string, number } from 'prop-types';
import clsx from 'clsx';
import { Grid as GridWrapper } from '@material-ui/core';
import classes from './Grid.module.scss';

export function Grid(props) {
    const { children, className, spacing, ...rest } = props;

    return (
        <GridWrapper
            {...rest}
            spacing={spacing}
            className={clsx(classes.grid, className)}
        >
            {children}
        </GridWrapper>
    );
}

Grid.propTypes = {
    children: any,
    className: string,
    spacing: number,
};
