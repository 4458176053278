import TextFieldsRoundedIcon from '@material-ui/icons/TextFieldsRounded';
import Crop54Icon from '@material-ui/icons/Crop54';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import { locale } from '../constants/locales';
import { FiberManualRecordOutlined } from '@material-ui/icons';

export const CanvasTabsSchema = {
    Media: {
        image: {
            label: locale.OBJECTS.MEDIA,
            methodName: 'image',
            disabled: true,
        },
        video: {
            label: locale.OBJECTS.VIDEO,
            methodName: 'video',
            disabled: true,
        },
        addPlaceholder: {
            label: locale.OBJECTS.PLACEHOLDER,
            methodName: 'placeholder',
            disabled: true,
        },
    },
    Objects: {
        addText: {
            label: locale.OBJECTS.TEXT,
            methodName: 'addText',
            Icon: TextFieldsRoundedIcon,
            disabled: false,
            show: true,
        },
        addRect: {
            label: locale.OBJECTS.RECT,
            methodName: 'addRect',
            Icon: Crop54Icon,
            disabled: false,
            show: true,
        },
        addLine: {
            label: locale.OBJECTS.LINE,
            methodName: 'addLine',
            Icon: LinearScaleIcon,
            disabled: false,
            show: true,
        },
        addEllipse: {
            label: locale.OBJECTS.ELLIPSE,
            methodName: 'addEllipse',
            Icon: FiberManualRecordOutlined,
            disabled: false,
            show: true,
        },
    },
};
