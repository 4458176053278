import API from '../utils/api';
import { errorNotification, warningNotification } from './notifications';
import { SAVE_VIDEO_SERVICE_STATUS, SAVE_INSTANCE_STATUS } from './types';

const saveServiceStatus = service => async dispatch => {
    dispatch({
        type: SAVE_VIDEO_SERVICE_STATUS,
        service,
    });
};

const saveInstanceStatus = instance => async dispatch => {
    dispatch({
        type: SAVE_INSTANCE_STATUS,
        instance,
    });
};

export const getVideoServiceStatus = () => async (dispatch, getState) => {
    try {
        const response = await API.getVideoProcessingServiceStatus();
        if (response) {
            dispatch(saveServiceStatus(response));
            return response;
        }
        return null;
    } catch (e) {
        dispatch(saveServiceStatus(e));
        dispatch(
            errorNotification({
                message: e.message,
                data: { preventDuplicate: true },
            }),
        );
    }
};

export const getVideoInstanceStatus = () => async (dispatch, getState) => {
    try {
        const response = await API.getVideoInstanceStatus();
        if (response) {
            dispatch(saveInstanceStatus(response));

            return response;
        }

        return null;
    } catch (e) {
        dispatch(saveInstanceStatus(e));
        return dispatch(
            errorNotification({
                message: e.message,
                data: { preventDuplicate: true },
            }),
        );
    }
};

export const startVideoInstance = () => async (dispatch, getState) => {
    try {
        const response = await API.startVideoInstance();
        if (response?.CurrentState) {
            dispatch(saveInstanceStatus({ InstanceState: response.CurrentState }));
        } else if (response?.message) {
            return dispatch(
                warningNotification(response.message, null, {
                    preventDuplicate: true,
                }),
            );
        }
        return response;
    } catch (e) {
        return dispatch(
            errorNotification({
                message: e.message,
                data: { preventDuplicate: true },
            }),
        );
    }
};

export const stopVideoInstance = () => async (dispatch, getState) => {
    try {
        const response = await API.stopVideoInstance();
        if (response?.CurrentState) {
            dispatch(saveInstanceStatus({ InstanceState: response.CurrentState }));
        } else if (response?.message) {
            return dispatch(
                warningNotification(response.message, null, {
                    preventDuplicate: true,
                }),
            );
        }
        return response;
    } catch (e) {
        return dispatch(
            errorNotification({
                message: e.message,
                data: { preventDuplicate: true },
            }),
        );
    }
};

export const restartBackend = () => async dispatch => {
    try {
        await API.restartBackend();
    } catch (e) {
        const message = typeof e === 'string' ? e : e.message;
        return dispatch(
            errorNotification({
                message,
                data: { preventDuplicate: true },
            }),
        );
    } finally {
        setTimeout(() => window?.location.reload(), 5000);
    }
};
