import { connect } from 'react-redux';
import TemplateEditor from './TemplateEditor';
import { clearCanvasHistory } from '../../actions/canvas';
import { getTemplateById, clearTemplate } from '../../actions/templates';

const mapStateToProps = state => ({
    template: state.template,
    canvasHistory: state.canvas && state.canvas.history,
});

const mapDispatchToProps = {
    getTemplateById,
    clearCanvasHistory,
    clearTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateEditor);
