import Categories from './Categories';
import { connect } from 'react-redux';
import {
    getCategories,
    deleteCategory,
    editCategory,
    createNewCategory,
} from '../../actions/categories';

const mapStateToProps = state => ({
    isLoading: state?.categories?.loading,
    categories: state?.categories?.list,
    userId: state?.auth?.user?.id,
});

const mapDispatchToProps = {
    getCategories,
    createNewCategory,
    deleteCategory,
    editCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
