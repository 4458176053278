import React from 'react';
import { bool, func, shape, string, any } from 'prop-types';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Btn from '../Button';
import withFormDialog from '../withFormDialog';
import { locale } from '../../constants/locales';
import classes from './Alert.module.scss';

function Alert(props) {
  const { open, alertConfig, children, className } = props;

  return (
    <Dialog
      maxWidth={alertConfig.maxWidth || 'xs'}
      width={alertConfig.width}
      open={open}
      onClose={alertConfig.cancelAction}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        root: className,
        paper: clsx(classes.popupDialog, classes[alertConfig.paperClass]),
      }}
    >
      <DialogTitle id="alert-dialog-title" classes={{ root: classes.title }}>
        {alertConfig && alertConfig.alertTitle}
      </DialogTitle>
      <DialogContent classes={{ root: classes.noSidePadding }}>
        <DialogContentText id="alert-dialog-description">
          {alertConfig && alertConfig.alertText}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions classes={{ root: classes.noSidePadding }}>
        {(alertConfig.showCancelAction || alertConfig.cancelAction) && (
          <Btn
            onClick={alertConfig.cancelAction}
            color="secondary"
            variant="outlined"
            text={alertConfig.alertCancelBtnText || locale.CANCEL}
            disabled={alertConfig.cancelActionDisable}
          />
        )}
        {alertConfig.actionClick && (
          <Btn
            onClick={alertConfig.actionClick}
            color="primary"
            variant="contained"
            text={alertConfig.alertBtnText || locale.PROCEED}
            disabled={alertConfig.actionClickDisable}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

Alert.propTypes = {
  open: bool.isRequired,
  children: any,
  className: string,
  alertConfig: shape({
    alertTitle: string.isRequired,
    alertText: string,
    alertBtnColor: string,
    alertBtnText: string,
    actionClick: func,
    actionClickDisable: bool,
    cancelActionDisable: bool,
    paperClass: string, // custom class for paper
    showCancelAction: bool,
    cancelAction: func,
    alertCancelBtnText: string,
  }),
};

export default withFormDialog(Alert);
