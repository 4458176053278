import React, { useState } from 'react';
import { arrayOf, bool, func, number, object } from 'prop-types';
import ActionBar from '../../components/ActionBar';
import NotificationsList from '../../containers/NotificationsList';
import Pagination from '../../components/Pagination';
import Container from '../../components/Container';
import withSpinner from '../../components/withSpinner';
import { useMount } from '../../utils/hooks';
import classes from './UserNotificationsHistoryPage.module.scss';
import { locale } from '../../constants/locales';
import { DEFAULT_PAGE_OPTIONS, DEFAULT_PAGE_SIZE } from '../../constants/filters';
import Notification from '../../types/Notification';
import User from '../../types/User';

const UserNotificationsHistoryPage = props => {
    const {
        list,
        authUser,
        totalItems,
        getUserNotifications,
        isLoading,
        toggleLoading,
        history: { location },
    } = props;
    const [params, setParams] = useState({
        limit: DEFAULT_PAGE_SIZE,
        page: 1,
    });
    const unreadNotifications = list.filter(i => !i?.readBy.includes(authUser.id));
    const fetchData = async (query = params) => {
        await getUserNotifications(query, true);
        if (isLoading) {
            toggleLoading();
        }
    };
    useMount(() => fetchData());

    async function handleChange(data) {
        if (data) {
            await fetchData(data);
            setParams(data);
        }
    }

    return (
        <>
            <ActionBar goBackLink goBackText={locale.BACK} />
            <Container
                className={classes.userNotificationsHistory}
                preset={{ withHeaderAndBarAndPagination: true }}
                maxWidth="sm"
            >
                {!isLoading && (
                    <NotificationsList
                        list={list}
                        unreadNotifications={unreadNotifications}
                        historyPage
                        location={location}
                    />
                )}
            </Container>
            {!!Object.keys(list).length && (
                <Pagination
                    count={totalItems}
                    rowsPerPageOptions={DEFAULT_PAGE_OPTIONS}
                    rows={list}
                    labelRowsPerPage="Notifications per page"
                    onChangePageCallback={handleChange}
                    component="nav"
                />
            )}
        </>
    );
};

UserNotificationsHistoryPage.propTypes = {
    list: arrayOf(Notification),
    authUser: User,
    totalItems: number,
    history: object,
    isLoading: bool,
    getUserNotifications: func,
    toggleLoading: func,
};

export default withSpinner(UserNotificationsHistoryPage);
