import { ANIMATION_DELAY, ANIMATION_DURATION } from './sizes';
import { valueToText } from '../utils/common';

export const APPEARANCE_TRANSITIONS_TYPE = Object.freeze([
    {
        value: 'none',
        label: 'None',
    },
    {
        value: 'appear',
        label: 'Appear',
    },
    {
        value: 'opacity',
        label: 'Fade in',
    },
    {
        value: 'top',
        label: 'Direction Top',
    },
    {
        value: 'bottom',
        label: 'Direction Bottom',
    },
    {
        value: 'left',
        label: 'Direction Left',
    },
    {
        value: 'right',
        label: 'Direction Right',
    },
]);

export const DISAPPEARANCE_TRANSITIONS_TYPE = Object.freeze([
    {
        value: 'none',
        label: 'None',
    },
    {
        value: 'appear',
        label: 'Disappear',
    },
    {
        value: 'opacity',
        label: 'Fade out',
    },
    {
        value: 'top',
        label: 'Direction Top',
    },
    {
        value: 'bottom',
        label: 'Direction Bottom',
    },
    {
        value: 'left',
        label: 'Direction Left',
    },
    {
        value: 'right',
        label: 'Direction Right',
    },
]);

export const TRANSITIONS_ANIMATION_EASING = Object.freeze([
    {
        value: 'none',
        label: 'None',
    },
    {
        value: 'easeInQuad',
        label: 'Ease In Quad',
    },
    {
        value: 'easeOutQuad',
        label: 'Ease Out Quad',
    },
    {
        value: 'easeInOutQuad',
        label: 'Ease In Out Quad',
    },
    {
        value: 'easeInCubic',
        label: 'Ease In Cubic',
    },
    {
        value: 'easeOutCubic',
        label: 'Ease Out Cubic',
    },
    {
        value: 'easeInOutCubic',
        label: 'Ease In Out Cubic',
    },
    {
        value: 'easeInQuart',
        label: 'Ease In Quart',
    },
    {
        value: 'easeOutQuart',
        label: 'Ease Out Quart',
    },
    {
        value: 'easeInOutQuart',
        label: 'Ease In Out Quart',
    },
    {
        value: 'easeInQuint',
        label: 'Ease In Quint',
    },
    {
        value: 'easeOutQuint',
        label: 'Ease Out Quint',
    },
    {
        value: 'easeInOutQuint',
        label: 'Ease In Out Quint',
    },
    {
        value: 'easeInSine',
        label: 'Ease In Sine',
    },
    {
        value: 'easeOutSine',
        label: 'Ease Out Sine',
    },
    {
        value: 'easeInOutSine',
        label: 'Ease In Out Sine',
    },
    {
        value: 'easeInExpo',
        label: 'Ease In Expo',
    },
    {
        value: 'easeOutExpo',
        label: 'Ease Out Expo',
    },
    {
        value: 'easeInOutExpo',
        label: 'Ease In Out Expo',
    },
    {
        value: 'easeInCirc',
        label: 'Ease In Circ',
    },
    {
        value: 'easeOutCirc',
        label: 'Ease Out Circ',
    },
    {
        value: 'easeInOutCirc',
        label: 'Ease In Out Circ',
    },
    {
        value: 'easeInElastic',
        label: 'Ease In Elastic',
    },
    {
        value: 'easeOutElastic',
        label: 'Ease Out Elastic',
    },
    {
        value: 'easeInOutElastic',
        label: 'Ease In Out Elastic',
    },
    {
        value: 'easeInBack',
        label: 'Ease In Back',
    },
    {
        value: 'easeOutBack',
        label: 'Ease Out Back',
    },
    {
        value: 'easeInOutBack',
        label: 'Ease In Out Back',
    },
    {
        value: 'easeInBounce',
        label: 'Ease In Bounce',
    },
    {
        value: 'easeOutBounce',
        label: 'Ease Out Bounce',
    },
    {
        value: 'easeInOutBounce',
        label: 'Ease In Out Bounce',
    },
]);

export const TRANSITION_ICONS = Object.freeze({
    directional: '/assets/images/DirectionalTransition.png',
    fade: '/assets/images/FadeInTransition.png',
});

export const DISCRETE_ZOOM_SLIDER_MIN_VALUE = 1.05;
export const DISCRETE_ZOOM_SLIDER_MAX_VALUE = 1.5;
export const DISCRETE_ZOOM_SLIDER_STEP = 0.05;

export const DISCRETE_TEXT_SLIDER_MIN_VALUE = 1;
export const DISCRETE_TEXT_SLIDER_MIN_LABEL = 'Fast';
export const DISCRETE_TEXT_SLIDER_MAX_VALUE = 400;
export const DISCRETE_TEXT_SLIDER_MAX_LABEL = 'Slow';
export const DISCRETE_TEXT_SLIDER_STEP = 5;

export const TRANSITION_VALUES = Object.freeze({
    duration: ANIMATION_DURATION,
    typeTransition: APPEARANCE_TRANSITIONS_TYPE[0].value,
    easing: TRANSITIONS_ANIMATION_EASING[0].value,
    zoomInValue: DISCRETE_ZOOM_SLIDER_MIN_VALUE,
    zoomIn: false,
});

export const ANIMATION_DEFAULT_VALUES = Object.freeze({
    typeTransition: APPEARANCE_TRANSITIONS_TYPE[0].value,
    options: {
        delay: ANIMATION_DELAY,
        duration: ANIMATION_DURATION,
        easing: TRANSITIONS_ANIMATION_EASING[0].value,
    },
});

export const ANIMATION_TYPE = Object.freeze({
    APPEARANCE: 'appearance',
    DISAPPEARANCE: 'disappearance',
});

export const MARKS_ZOOM_SLIDER = Object.freeze([
    {
        value: DISCRETE_ZOOM_SLIDER_MIN_VALUE,
        label: valueToText(DISCRETE_ZOOM_SLIDER_MIN_VALUE),
    },
    {
        value: DISCRETE_ZOOM_SLIDER_MIN_VALUE + DISCRETE_ZOOM_SLIDER_STEP,
    },
    {
        value: DISCRETE_ZOOM_SLIDER_MIN_VALUE + DISCRETE_ZOOM_SLIDER_STEP * 2,
    },
    {
        value: DISCRETE_ZOOM_SLIDER_MIN_VALUE + DISCRETE_ZOOM_SLIDER_STEP * 3,
    },
    {
        value: DISCRETE_ZOOM_SLIDER_MIN_VALUE + DISCRETE_ZOOM_SLIDER_STEP * 4,
    },
    {
        value: DISCRETE_ZOOM_SLIDER_MIN_VALUE + DISCRETE_ZOOM_SLIDER_STEP * 5,
    },
    {
        value: DISCRETE_ZOOM_SLIDER_MIN_VALUE + DISCRETE_ZOOM_SLIDER_STEP * 6,
    },
    {
        value: DISCRETE_ZOOM_SLIDER_MIN_VALUE + DISCRETE_ZOOM_SLIDER_STEP * 7,
    },
    {
        value: DISCRETE_ZOOM_SLIDER_MIN_VALUE + DISCRETE_ZOOM_SLIDER_STEP * 8,
    },
    {
        value: DISCRETE_ZOOM_SLIDER_MAX_VALUE,
        label: valueToText(DISCRETE_ZOOM_SLIDER_MAX_VALUE),
    },
]);

export const MARKS_TYPING_ANIMATION_SLIDER = Object.freeze([
    {
        value: DISCRETE_TEXT_SLIDER_MIN_VALUE,
        label: DISCRETE_TEXT_SLIDER_MIN_LABEL,
    },
    {
        value: DISCRETE_TEXT_SLIDER_MIN_VALUE + 100,
    },
    {
        value: DISCRETE_TEXT_SLIDER_MIN_VALUE + 100 * 2,
    },
    {
        value: DISCRETE_TEXT_SLIDER_MIN_VALUE + 100 * 3,
    },
    {
        value: DISCRETE_TEXT_SLIDER_MAX_VALUE,
        label: DISCRETE_TEXT_SLIDER_MAX_LABEL,
    },
]);
