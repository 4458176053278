import { connect } from 'react-redux';
import StoryCanvasPreview from './StoryCanvasPreview';
import { saveCanvasToHistory } from '../../../actions/canvas';
import { errorNotification } from '../../../actions/notifications';

const mapStateToProps = state => ({
    canvasHistoryLength: state?.canvas?.history?.length,
});

const mapDispatchToProps = {
    saveCanvasToHistory,
    errorNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoryCanvasPreview);
