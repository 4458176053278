import { oneOf, string } from 'prop-types';
import classes from './SlideLogo.module.scss';
import React from 'react';

const SlideLogo = ({ logoType, company, dimensions, logoPosition }) => {
  const slideLogo = `./assets/images/${company?.toLowerCase()}_logo_${logoType}.png`;
  const className = `${classes.slideLogo} ${
    company === 'SOMEDAY' ? classes.somedayLogo : ''
  }`;

  const styles = { width: dimensions?.width };
  if (logoPosition === 'center') {
    styles.top = company === 'SOMEDAY' ? '43%' : '46%';
    styles.maxWidth = company === 'SOMEDAY' ? '70%' : '67%';
    styles.left = '15%';
  }

  return (
    <img src={slideLogo} alt={slideLogo} className={className} style={styles} />
  );
};

SlideLogo.propTypes = {
  logoType: oneOf(['white', 'black']),
  company: string.isRequired,
  logoPosition: oneOf(['bottom', 'center', null]),
};

export default SlideLogo;
