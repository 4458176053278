import React from 'react';
import clsx from 'clsx';
import { bool, object } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { SendMessage, TakePhotoIcon } from '../../constants/icons';
import {
    COMPANIES,
    INSTAGRAM_COMPANY_NAME,
    COMPANY_LOGOS,
} from '../../constants/companies';
import { locale } from '../../constants/locales';
import classes from './InstagramMode.module.scss';

const InstagramMode = ({ object, sendMessageImage }) => {
    const { companyName } = object;

    const isOpus = companyName === COMPANIES.OPUS;
    const currentCompanyLogo = isOpus
        ? COMPANY_LOGOS[`${companyName}-WHITE`]
        : COMPANY_LOGOS[companyName];

    return (
        <div className={classes.instagramMode}>
            <div className={classes.accountInfo}>
                <img
                    src={currentCompanyLogo}
                    alt={companyName}
                    className={clsx(
                        classes.accountLogo,
                        isOpus && classes.accountLogoGray,
                    )}
                />
                <Typography className={classes.accountName} variant="h4" noWrap>
                    {INSTAGRAM_COMPANY_NAME[companyName]}
                </Typography>
                <Typography align="left" variant="caption" className={classes.timePosted}>
                    {locale.StaticText.FIVE_MIN_AGO}
                </Typography>
            </div>
            <div className={classes.sendMessageField}>
                {sendMessageImage ? (
                    <img
                        src={`/assets/images/send-message-plug.svg`}
                        alt={locale.SEND_MESSAGE}
                    />
                ) : (
                    <>
                        <div className={classes.massageWrapper}>
                            <IconButton
                                aria-label="takePhoto"
                                className={classes.takePhoto}
                                disableFocusRipple
                                disableRipple
                            >
                                <TakePhotoIcon fontSize="default" />
                            </IconButton>
                            <InputBase
                                placeholder={locale.SEND_MESSAGE}
                                className={classes.sendMessageInput}
                            />
                        </div>
                        <IconButton
                            aria-label="sendMessageButton"
                            className={classes.sendMessageButton}
                            disableFocusRipple
                            disableRipple
                        >
                            <SendMessage fontSize="inherit" />
                        </IconButton>
                        <IconButton
                            aria-label="more"
                            className={classes.moreButton}
                            disableFocusRipple
                            disableRipple
                        >
                            <MoreHorizIcon fontSize="inherit" />
                        </IconButton>
                    </>
                )}
            </div>
        </div>
    );
};

InstagramMode.propTypes = {
    object: object,
    sendMessageImage: bool,
};

export default InstagramMode;
