import React, { useContext } from 'react';
import { func } from 'prop-types';
import { useSnackbar } from 'notistack';
import Avatar from '@material-ui/core/Avatar';
import Person from '@material-ui/icons/Person';
import Popup from '../../components/Popup';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AuthContext from '../../utils/auth-context';
import { validateRoute } from '../../utils/common';
import { EDITOR_PATHS } from '../../constants/variables';
import User from '../../types/User';
import { locale } from '../../constants/locales';
import classes from './UserMenu.module.scss';

function UserMenu({ user, go, logoutUser }) {
    const { closeSnackbar } = useSnackbar();
    const { roles } = useContext(AuthContext);

    // const userName = Object.keys(user).length ? `${user.lastName} ${user.firstName}` : '';

    function renderIcon() {
        if (user && user.avatar) {
            return <Avatar alt="User icon" src={user.avatar} />;
        }

        return <Person />;
    }

    const handleUserClick = () =>
        validateRoute(`/${EDITOR_PATHS.profile}`).then(path => go(path));

    const handleLogout = async () => {
        // Hide ALL notifications since we did not pass key
        closeSnackbar();
        await logoutUser();
    };

    return (
        roles && (
            <div className={classes.userMenu}>
                <Popup iconButton={renderIcon()} menuLeft>
                    <List disablePadding component="ul" className={classes.userMenuList}>
                        <ListItem
                            button
                            className={classes.userMenuListItem}
                            alignItems="flex-start"
                            onClick={handleUserClick}
                        >
                            {locale.User.MY_PROFILE}
                        </ListItem>
                        <ListItem
                            alignItems="flex-start"
                            className={classes.userMenuListItem}
                            button
                            onClick={handleLogout}
                        >
                            {locale.User.LOGOUT}
                        </ListItem>
                    </List>
                </Popup>
            </div>
        )
    );
}

UserMenu.propTypes = {
    logoutUser: func.isRequired,
    go: func.isRequired,
    user: User,
};
export default UserMenu;
