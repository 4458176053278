import { connect } from 'react-redux';
import CanvasLayers from './CanvasLayers';
import { errorNotification, warningNotification } from '../../actions/notifications';

const mapStateToProps = state => ({
    authUser: state?.auth?.user,
});
const mapDispatchToProps = {
    errorNotification,
    warningNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(CanvasLayers);
