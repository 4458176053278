import { textParamsSchema } from '../schemas/text-params';
import { DELAY_HIDDEN_DRAWER, DELAY_SHOW_DRAWER } from './sizes';
import { COLORS } from './colors';

export const DEVELOP_ENVS = ['local', 'development'];

export const ROOT_PATHS = Object.freeze({
    templates: '/templates',
    projects: '/projects',
    users: '/users',
    system: '/system',
});

export const EDITOR_PATHS = Object.freeze({
    templateEditor: 'template-editor',
    projectEditor: 'project-editor',
    projectPreview: 'project-preview',
    templatePreview: 'template-preview',
    profile: 'user-profile',
});

export const OTHER_PATHS = Object.freeze({
    userNotifications: 'user-notifications-history',
});

export const UNAUTH_PATHS = Object.freeze({
    login: '/',
    setPassword: '/create-password',
});

export const DEFAULT_FILTERS = Object.freeze({ value: 'ALL', label: 'Show all' });

export const monthNames = Object.freeze([
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
]);
// Field types
export const PASSWORD_FIELD = 'password';
export const TEXT_FIELD = 'text';
export const SELECT_FIELD = 'select';
export const DISCRETE_SLIDER_FIELD = 'slider';
export const DROPDOWN_FIELD = 'dropdown';
export const AUTOCOMPLETE = 'autocomplete';
export const CHECKBOX_FIELD = 'checkbox';
export const NUMBER_FIELD = 'number';
export const SWITCH_FIELD = 'switch';
export const SEARCH_FIELD = 'search';
export const MULTIPLE_SELECT_FIELD = 'multipleSelect';

export const GET_COMPANY_DEFAULT_FONT = companyName =>
    textParamsSchema.CompanyFonts[companyName];
export const DEFAULT_FONT = 'Times New Roman';

export const DEFAULT_BG_COLOR = COLORS.default;
export const DEFAULT_BORDER_COLOR = COLORS.black;
export const DEFAULT_RECT_COLOR = COLORS.secondary;
export const DEFAULT_LINE_COLOR = COLORS.black;
export const DEFAULT_ELLIPSE_COLOR = COLORS.secondary;
export const DEFAULT_OBJECT_COLOR = COLORS.black;
export const DEFAULT_SNAP_ANGLE = 10;
export const DEFAULT_TRANSPARENCY = 100;

export const DEFAULT_PLACEHOLDER_BG_COLOR = COLORS.secondary;
export const DEFAULT_PLACEHOLDER_BORDER_COLOR = COLORS.hardGray;

export const DEFAULT_CROP_BORDER_COLOR = COLORS.liteGreen;
export const DEFAULT_CROP_STROKE_COLOR = COLORS.gray;
export const DEFAULT_CROP_BG_COLOR = COLORS.whiteOpacityHex;

export const SOCKET_RECONNECT_ATTEMPTS = 10;
export const SOCKET_RECONNECT_DELAY = 6000;

export const SLIDES_HEIGHT = 168;
export const HEADER_HEIGHT = 72;
export const ACTIVE_BAR_HEIGHT = 64;
export const PADDING_STORY_AREA = 16;

export const HISTORY_DEFAULT_INDEX = -1;

export const DEFAULT_BORDER_WIDTH = 1;

export const ANIMATIONS = Object.freeze({
    transitionCubicBezierIn: `width ${DELAY_SHOW_DRAWER}ms cubic-bezier(0.4, 0, 0.6, 1) 0ms`,
    transitionCubicBezierOut: `width ${DELAY_HIDDEN_DRAWER}ms cubic-bezier(0.4, 0, 0.6, 1) 0ms`,
});

export const TOOLTIP_PLACEMENTS = Object.freeze({
    TOP: 'top',
    BOTTOM: 'bottom',
    LEFT: 'left',
    RIGHT: 'right',
    BOTTOM_START: 'bottom-start',
    BOTTOM_END: 'bottom-end',
    LEFT_END: 'left-end',
    LEFT_START: 'left-start',
    RIGHT_END: 'right-end',
    RIGHT_START: 'right-start',
    TOP_END: 'top-end',
    TOP_START: 'top-start',
});
