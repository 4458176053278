import * as Yup from 'yup';
import { locale } from '../../constants/locales';

export default Yup.object().shape({
    typeTransition: Yup.string(),
    duration: Yup.number()
        .transform(cv => Number(cv))
        .integer()
        .typeError(locale.Messages.MUST_BE_A_TYPE('number'))
        .min(1, locale.Messages.MUST_BE_BIGGER_THEN(0))
        .max(99, locale.Messages.MUST_BE_EQUAL_OR_LESS('99s'))
        .test('defined', locale.Messages.REQUIRED_FIELD, value => value !== undefined),
    transitionDuration: Yup.number()
        .typeError(locale.Messages.MUST_BE_A_TYPE('number'))
        .min(0, locale.Messages.MUST_BE_EQUAL_OR_BIGGER(0))
        .max(3, locale.Messages.MUST_BE_EQUAL_OR_LESS('3s'))
        .test('defined', locale.Messages.REQUIRED_FIELD, value => value !== undefined),
    animationEasing: Yup.string(),
});
