import React from 'react';
import clsx from 'clsx';
import { bool, func, object, shape, string } from 'prop-types';
import ProjectPreview from '../../containers/ProjectPreview';
import ItemLockedWarning from '../../components/ItemLockedWarning';
import { EDITOR_TYPE } from '../../constants/item';
import { useMount, useUnmount } from '../../utils/hooks';
import classes from './ProjectPreviewPage.module.scss';

function ProjectPreviewPage({
    slides,
    getProjectById,
    getSlides,
    match: { params },
    project,
    clearProject,
}) {
    const { projectId } = params;

    useMount(() => {
        const fetchData = async () => {
            if (projectId) {
                await getProjectById(projectId);
            }
            await getSlides(projectId);
        };
        if (projectId) {
            fetchData();
        }
    });

    useUnmount(() => {
        clearProject();
    });

    return (
        <section className={clsx(classes.Wrapper, 'no-scroll', classes.BlackBackground)}>
            {Object.keys(slides).length && Object.keys(project).length && (
                <ProjectPreview
                    params={params}
                    projectType={project.projectType}
                    slides={slides}
					company={project.companyName}
                />
            )}
            <ItemLockedWarning itemId={projectId} editorType={EDITOR_TYPE.PROJECT} />
        </section>
    );
}

ProjectPreviewPage.propTypes = {
    match: shape({
        isExact: bool,
        params: object.isRequired,
        path: string,
        url: string,
    }),
    getSlides: func.isRequired,
    getProjectById: func.isRequired,
    clearProject: func.isRequired,
	company: string.isRequired,
};

export default ProjectPreviewPage;
