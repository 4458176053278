import React, { useState, useEffect, useRef, useCallback } from 'react';
import { bool, func, number, object } from 'prop-types';
import { Form } from 'formik';
import debouncePromise from 'debounce-promise-with-cancel';
import FormikWrapper from '../../../../components/FormikWrapper';
import Collapse from '../../../../components/Collapse';
import Grid from '../../../../components/Grid';
import FormikField from '../../../../components/FormikField';
import { WithCanvasContext } from '../../../../utils/context';
import { usePrevious, useUnmount } from '../../../../utils/hooks';
import {
    convertAnimations,
    getAnimatedProp,
    getDisappearanceAnimations,
    getDisappearanceAnimationsParams,
    isDisappearanceAnimation,
} from '../../../../utils/animations';
import {
    addNewAnimation,
    prepareSelectOptionsFromAnimation,
    saveCanvasState,
    setCanvasObjectParams,
} from '../../../../utils/canvas';
import { locale } from '../../../../constants/locales';
import {
    ANIMATION_TYPE,
    DISAPPEARANCE_TRANSITIONS_TYPE,
    ANIMATION_DEFAULT_VALUES,
    TRANSITIONS_ANIMATION_EASING,
    APPEARANCE_TRANSITIONS_TYPE,
} from '../../../../constants/transitions';
import { SELECT_FIELD, TEXT_FIELD } from '../../../../constants/variables';
import validationSchema from '../../../../schemas/validations/transitions-validation';
import { CANVAS_TYPE_OBJECTS } from '../../../../constants/canvas';
import classes from '../AnimationsPanel.module.scss';
import Btn from '../../../../components/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

function DisappearanceAnimationsPanel({
    canvas,
    activeObject,
    canvasIndex,
    saveCanvasToHistory,
    animationPlaying,
    disabled,
    // Todo: Uncomment for live playing
    // abortAnimation,
    // resetPlayingAnimation,
    // toggleAnimationPlaying,
}) {
    const isMounted = useRef(true);
    const form = useRef(null);

    // Todo: Uncomment for live playing
    // const [formDisabled, setFormDisabled] = useState(false);

    const [options, setOptions] = useState([DISAPPEARANCE_TRANSITIONS_TYPE]);
    const [expanded, setExpanded] = useState(false);
    const [initialValues, setValues] = useState({ animations: [] });
    const prevActiveObject = usePrevious(activeObject);

    // Todo: Uncomment for live playing
    // const abort = () => {
    //     return abortAnimation();
    // };

    // set properties from form to object
    const setProperties = async values => {
        try {
            if (values && !animationPlaying) {
                // Todo: Uncomment for live playing
                // if (values?.typeTransition !== DISAPPEARANCE_TRANSITIONS_TYPE[0].value || zoomChange) {
                //     toggleAnimationPlaying(true);
                //     if (isMounted.current) {
                //         setFormDisabled(true);
                //     }
                // }

                const width = activeObject.getScaledWidth();
                const height = activeObject.getScaledHeight();

                const animationsToPush = [];
                for (const v of values.animations) {
                    const animatedProp = getAnimatedProp([v.typeTransition]);
                    const { params, value } = getDisappearanceAnimationsParams(
                        v,
                        canvas,
                        activeObject,
                        width,
                        height,
                    );

                    const propertiesToUpdate = { ...params };
                    delete propertiesToUpdate.animation;
                    setCanvasObjectParams(activeObject, {
                        ...propertiesToUpdate,
                        storedAnimation: null,
                        [animatedProp]: value,
                    });
                    animationsToPush.push({
                        ...params.animation,
                    });

                    // // Todo: Uncomment for live playing
                    // if (v.typeTransition !== DISAPPEARANCE_TRANSITIONS_TYPE[0].value) {
                    //     canvas.discardActiveObject();
                    //     // Turn on animation
                    //     setCanvasObjectParams(activeObject, {
                    //         ...params,
                    //         storedAnimation: { [animatedProp]: value },
                    //     });
                    //     activeObject.animate(animatedProp, value, {
                    //         onChange: canvas.renderAll.bind(canvas),
                    //         duration: convertStoMS(values.duration),
                    //         easing: fabric.util.ease[values.easing],
                    //         abort,
                    //         onComplete: async endValue => {
                    //             setCanvasObjectParams(activeObject, {
                    //                 ...activeObject.storedAnimation,
                    //                 [animatedProp]: endValue,
                    //             });
                    //             await saveCanvasState(canvas, saveCanvasToHistory);
                    //             canvas.renderAll();
                    //     if (isMounted.current) {
                    //         toggleAnimationPlaying(false);
                    //         setFormDisabled(false);
                    //     }
                    //         },
                    //     });
                    // } else {
                    //     if (isMounted.current) {
                    //         toggleAnimationPlaying(false);
                    //         setFormDisabled(false);
                    //     }
                    // }
                }

                const { animation: allAnimations } = activeObject;
                if (animationsToPush.length) {
                    activeObject.set({
                        animation: {
                            ...allAnimations,
                            playDisappearanceAnimations: true,
                            [ANIMATION_TYPE.DISAPPEARANCE]: animationsToPush,
                        },
                    });
                    await saveCanvasState(canvas, saveCanvasToHistory);
                }
                canvas.renderAll();
                recalculateOptions(animationsToPush);
            }
        } catch (e) {
            console.info(e);
            console.info('Cannot set animation');
        }
    };

    const recalculateOptions = animations => {
        setOptions(
            prepareSelectOptionsFromAnimation(animations, ANIMATION_TYPE.DISAPPEARANCE),
        );
    };

    const updateForm = useCallback(() => {
        try {
            const { animation } = activeObject;

            if (isDisappearanceAnimation(animation)) {
                // update values in form
                const convertedDisappearanceAnimations = convertAnimations(
                    getDisappearanceAnimations(animation),
                );
                setValues({
                    animations: convertedDisappearanceAnimations,
                });
                if (
                    form?.current &&
                    animation.disappearance &&
                    Array.isArray(animation.disappearance)
                ) {
                    const { setFieldValue } = form.current;
                    convertedDisappearanceAnimations.forEach((item, index) => {
                        setFieldValue(`animations[${index}]`, item, true);
                    });
                }
                // update options in select fields
                recalculateOptions(convertedDisappearanceAnimations);
            }
        } catch (e) {
            console.info('Cannot update form', e.message);
        }
    }, [activeObject]);

    // set animations to form
    const setAnimation = useCallback(() => {
        // Todo: Uncomment for live playing
        // if (animationPlaying) {
        //     resetPlayingAnimation();
        // }

        updateForm();

        const { animation } = activeObject;
        const isDisappearance = !!(
            isDisappearanceAnimation(animation) && animation.playDisappearanceAnimations
        );

        setExpanded(isDisappearance);
    }, [
        activeObject,
        updateForm,
        // Todo: Uncomment for live playing
        // animationPlaying,
        // resetPlayingAnimation,
    ]);

    const handleCheckbox = async event => {
        const { checked } = event.target;
        const { animation } = activeObject;
        if (!checked) {
            activeObject.set({
                animation: { ...animation, playDisappearanceAnimations: false },
            });
            await saveCanvasState(canvas, saveCanvasToHistory);

            // Todo: Uncomment for live playing
            // Reset current animation
            // if (animationPlaying) {
            //     resetPlayingAnimation();
            //     await setProperties({
            //         animation: null,
            //         zoomInValue: DISCRETE_ZOOM_SLIDER_MIN_VALUE,
            //         zoomIn: false,
            //     });
            // } else {
            //     activeObject.set({ animation: null });
            //     saveCanvasState(canvas, saveCanvasToHistory);
            // }

            if (isMounted.current) {
                // Todo: Uncomment for live playing
                // setFormDisabled(checked);

                setExpanded(checked);
            }
        } else {
            const disappearanceAnimationsArray = convertAnimations(
                getDisappearanceAnimations(animation),
            );
            const animationsToSet =
                Array.isArray(disappearanceAnimationsArray) &&
                disappearanceAnimationsArray.length > 0
                    ? disappearanceAnimationsArray
                    : [ANIMATION_DEFAULT_VALUES];
            await setProperties({ animations: animationsToSet });
            setAnimation();
        }
        setExpanded(checked);
    };

    // Add debounce for form values changes
    const setPropertiesDebounced = debouncePromise(setProperties, 300);

    const submit = async (values, submitProps) => {
        const { setSubmitting, validateForm } = submitProps;
        const errors = await validateForm(values);
        if (!Object.keys(errors).length) {
            await setPropertiesDebounced(values);
        }
        await setSubmitting(false);
    };

    // Trigger validation and submission
    const change = async e => {
        // Don't trigger submit on zoomIn toggle click
        if (e?.target?.name === 'zoomIn') {
            return;
        }
        try {
            setPropertiesDebounced.cancel();
            await form?.current?.validateForm?.();
            await form?.current?.handleSubmit?.();
        } catch (e) {
            console.info(e);
        }
    };

    const addAnimation = async () => {
        try {
            await setProperties(
                addNewAnimation(activeObject.animation, ANIMATION_TYPE.DISAPPEARANCE),
            );
            updateForm();
        } catch (e) {
            console.info("Can't set new properties during adding new animation");
        }
    };

    const removeAnimation = async index => {
        try {
            const disappearanceAnimations = getDisappearanceAnimations(
                activeObject.animation,
            );
            const animationsToSet = convertAnimations(disappearanceAnimations).filter(
                val => val.group !== disappearanceAnimations[index].group,
            );

            await setProperties({ animations: animationsToSet });
            updateForm();
        } catch (e) {
            console.info("Can't set new properties during removing animation");
        }
    };

    // Handle active object / canvas history changes
    useEffect(() => {
        try {
            isMounted.current = true;
            if (
                !prevActiveObject &&
                activeObject &&
                getDisappearanceAnimations(activeObject.animation)
            ) {
                setAnimation();
            }
            if (
                prevActiveObject &&
                activeObject &&
                getDisappearanceAnimations(prevActiveObject.animation) !==
                    getDisappearanceAnimations(activeObject.animation)
            ) {
                setAnimation();
            }
            if (
                prevActiveObject &&
                activeObject &&
                prevActiveObject.objectId !== activeObject.objectId
            ) {
                setAnimation();
            }
        } catch (e) {
            console.info(e);
        }
    }, [
        expanded,
        activeObject,
        canvasIndex,
        setValues,
        prevActiveObject,
        setAnimation,
        // Todo: Uncomment for live playing
        // toggleAnimationPlaying,
    ]);

    useUnmount(() => {
        isMounted.current = false;
        // Todo: Uncomment for live playing
        // resetPlayingAnimation();
        // toggleAnimationPlaying(false);
    });

    return (
        activeObject &&
        activeObject.type !== CANVAS_TYPE_OBJECTS.activeSelection && (
            <div className={`${classes.disappearanceAnimationPanel}`}>
                <Collapse
                    expanded={expanded}
                    checkbox
                    checkboxColor="secondary"
                    label={locale.DISAPPEARANCE}
                    onChange={handleCheckbox}
                    timeout={0}
                    labelPlacement="start"
                    disabled={disabled}
                    className={classes.collapseTitle}
                >
                    <FormikWrapper
                        ref={form}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize
                        onSubmit={submit}
                    >
                        {({ values, handleSubmit }) => {
                            return (
                                !!Object.keys(values).length &&
                                Array.isArray(values.animations) && (
                                    <Form onSubmit={handleSubmit} onChange={change}>
                                        {values.animations.map((val, ind) => {
                                            return (
                                                <Grid key={ind} container spacing={1}>
                                                    <Grid item xs={9}>
                                                        <FormikField
                                                            disabled={disabled}
                                                            label={locale.ANIMATION_TYPE}
                                                            type={SELECT_FIELD}
                                                            name={`animations[${ind}].typeTransition`}
                                                            options={options[ind]}
                                                            fullWidth
                                                            select
                                                            onChangeCallback={change}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <FormikField
                                                            label={locale.DELAY}
                                                            type={TEXT_FIELD}
                                                            name={`animations[${ind}].options.delay`}
                                                            fullWidth
                                                            disabled={
                                                                val.typeTransition ===
                                                                    DISAPPEARANCE_TRANSITIONS_TYPE[0]
                                                                        .value || disabled
                                                            }
                                                            inputProps={{
                                                                autoComplete: 'off',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormikField
                                                            label={
                                                                locale.ANIMATION_EASING
                                                            }
                                                            type={SELECT_FIELD}
                                                            name={`animations[${ind}].options.easing`}
                                                            options={
                                                                TRANSITIONS_ANIMATION_EASING
                                                            }
                                                            fullWidth
                                                            select
                                                            onChangeCallback={change}
                                                            disabled={
                                                                val.typeTransition ===
                                                                    DISAPPEARANCE_TRANSITIONS_TYPE[0]
                                                                        .value ||
                                                                val.typeTransition ===
                                                                    APPEARANCE_TRANSITIONS_TYPE[1]
                                                                        .value ||
                                                                disabled
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <FormikField
                                                            label={locale.DURATION}
                                                            type={TEXT_FIELD}
                                                            name={`animations[${ind}].options.duration`}
                                                            fullWidth
                                                            disabled={
                                                                val.typeTransition ===
                                                                    DISAPPEARANCE_TRANSITIONS_TYPE[0] ||
                                                                val.typeTransition ===
                                                                    DISAPPEARANCE_TRANSITIONS_TYPE[1]
                                                                        .value ||
                                                                disabled
                                                            }
                                                            inputProps={{
                                                                autoComplete: 'off',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        className={classes.btnContainer}
                                                    >
                                                        <Grid item xs={6}>
                                                            {values.animations.length >
                                                                1 && (
                                                                <Btn
                                                                    btnType="collapseBtn"
                                                                    variant="contained"
                                                                    size="small"
                                                                    className={
                                                                        classes.removeBtn
                                                                    }
                                                                    onClick={() =>
                                                                        removeAnimation(
                                                                            ind,
                                                                        )
                                                                    }
                                                                    onlyIcon={
                                                                        <RemoveIcon />
                                                                    }
                                                                />
                                                            )}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            className={
                                                                classes.addBtnContainer
                                                            }
                                                        >
                                                            {val.typeTransition !==
                                                                DISAPPEARANCE_TRANSITIONS_TYPE[0]
                                                                    .value &&
                                                                ind ===
                                                                    values.animations
                                                                        .length -
                                                                        1 &&
                                                                values.animations.length <
                                                                    2 && (
                                                                    <Btn
                                                                        btnType="collapseBtn"
                                                                        variant="contained"
                                                                        size="small"
                                                                        className={
                                                                            classes.addBtn
                                                                        }
                                                                        onClick={
                                                                            addAnimation
                                                                        }
                                                                        onlyIcon={
                                                                            <AddIcon />
                                                                        }
                                                                    />
                                                                )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </Form>
                                )
                            );
                        }}
                    </FormikWrapper>
                </Collapse>
            </div>
        )
    );
}

DisappearanceAnimationsPanel.propTypes = {
    activeObject: object,
    canvas: object,
    disabled: bool,
    canvasIndex: number.isRequired,
    animationPlaying: bool.isRequired,
    abortAnimation: func.isRequired,
    toggleAnimationPlaying: func.isRequired,
    saveCanvasToHistory: func.isRequired,
};

export default WithCanvasContext(DisappearanceAnimationsPanel);
