import { connect } from 'react-redux';
import CanvasPreview from './CanvasPreview';
import { clearTemplate, getTemplateById } from '../../actions/templates';

const mapStateToProps = state => ({
    user: state?.auth?.user,
    template: state?.template,
    project: state?.project,
});

const mapDispatchToProps = {
    getTemplateById,
    clearTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(CanvasPreview);
