import React from 'react';
import Typography from '@material-ui/core/Typography';
import classes from './VideoFormat.module.scss';
import Btn from '../Button';

function VideoFormat(props) {
    const { buttonTitle, size, onClick } = props;

    return (
        <Btn
            classes={{ label: classes.contentCenter }}
            className={classes.button}
            onClick={onClick}
        >
            <>
                <Typography
                    component="h4"
                    color="inherit"
                    className={classes.buttonTitle}
                >
                    {buttonTitle}
                </Typography>
                <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    className={classes.buttonSubTitle}
                >
                    {size}
                </Typography>
            </>
        </Btn>
    );
}

export default VideoFormat;
