import { connect } from 'react-redux';
import Notification from './Notification';
import { restoreSlide } from '../../actions/slides';
import { go } from '../../actions/navigation';
import { restoreProject } from '../../actions/projects';
import { clearNotification } from '../../actions/notifications';

const mapStateToProps = state => ({
    notification: state.notifications,
    authUser: state.auth && state.auth.user,
});

const mapDispatchToProps = {
    restoreSlide,
    go,
    restoreProject,
    clearNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
