import React from 'react';
import clsx from 'clsx';
import { bool, oneOfType, number, string } from 'prop-types';
import classes from './Spinner.module.scss';

const Spinner = ({
    loading,
    fixed,
    overlay,
    opaque,
    absolute,
    small,
    inverse,
    className,
}) =>
    loading && (
        <div
            className={clsx(
                classes.Spinner,
                fixed && classes.fixed,
                overlay && classes.overlay,
                opaque && classes.opaque,
                small && classes.small,
                absolute && classes.absolute,
                className,
            )}
        >
            <div className={clsx(classes.donut, inverse && classes.inverse)} />
        </div>
    );

Spinner.propTypes = {
    loading: oneOfType([number, bool]),
    fixed: bool,
    overlay: bool,
    opaque: bool,
    className: string,
};

export default Spinner;
