import { connect } from 'react-redux';
import Header from './Header';

const mapStateToProps = state => ({
    isLoading: state?.loading?.show,
    user: state?.auth?.user,
    template: state?.template,
    project: state?.project,
});

export default connect(mapStateToProps)(Header);
