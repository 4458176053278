import API from '../utils/api';
import { hideLoader, showLoader } from './loading';
import { loginSuccess } from './auth';
import { go } from './navigation';
import { errorNotification, infoNotification } from './notifications';
import { SAVE_USERS_LIST } from './types';
import { locale } from '../constants/locales';
import { ROOT_PATHS } from '../constants/variables';

const saveUsersList = users => ({
    type: SAVE_USERS_LIST,
    users,
});

export const getUsersList = (query = {}) => async dispatch => {
    if (Object.keys(query).length) {
        dispatch(showLoader());
        const response = await API.getUsersList({
            ...query,
        }).catch(error => {
            dispatch(errorNotification(error));
        });
        if (response) {
            if (Object.keys(response).length) {
                const {
                    items = [],
                    meta: { itemCount, totalItems, currentPage: pageCount },
                } = response;
                dispatch(
                    saveUsersList({
                        list: items,
                        itemCount,
                        totalItems,
                        pageCount,
                    }),
                );
            }
            dispatch(hideLoader());

            return response;
        }
        dispatch(errorNotification(response));
        dispatch(hideLoader());
    }
    return null;
};

export const createUser = query => async dispatch => {
    const response = await API.createUser(query).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        // Update users list
        dispatch(getUsersList());
        if (Object.keys(response).length) {
            dispatch(go(`/users`));
        }
        dispatch(
            infoNotification(
                locale.actionString(locale.User.USER_SUCCESSFULLY, locale.CREATED),
            ),
        );

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const getUserById = id => async dispatch => {
    const response = await API.getUserById(id).catch(error => {
        // Handle 404 error
        if (error && error.message.includes("doesn't exist")) {
            dispatch(go(ROOT_PATHS.users));
        }
        dispatch(errorNotification(error));
    });
    if (response) {
        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const updateUserById = (isAdmin, selfUpdate, id, updateData) => async dispatch => {
    const fetcher = isAdmin ? API.updateUserById : API.updateCurrentUser;
    const data = isAdmin ? updateData : { id, ...updateData };
    const response = await fetcher(id, data).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        // Update user in local store
        if (selfUpdate) {
            dispatch(loginSuccess(response));
        }
        dispatch(
            infoNotification(
                locale.actionString(locale.User.USER_SUCCESSFULLY, locale.UPDATED),
            ),
        );

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const removeUser = id => async dispatch => {
    const response = await API.removeUser(id).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        // Update users list
        dispatch(getUsersList());
        dispatch(infoNotification(response.message));

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const resetPassword = id => async dispatch => {
    const response = await API.resetPassword(id).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        dispatch(infoNotification(response.message));

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const changePassword = query => async dispatch => {
    const response = await API.changePassword(query).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        dispatch(infoNotification(response.message));

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};
