import { connect } from 'react-redux';
import ColorPicker from './ColorPicker';
import { saveCanvasToHistory } from '../../actions/canvas';

const mapStateToProps = state => ({
    data: state?.data,
});

const mapDispatchToProps = {
    saveCanvasToHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorPicker);
