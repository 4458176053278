import {
    INFO_NOTIFICATION,
    ERROR_NOTIFICATION,
    WARNING_NOTIFICATION,
    CLEAR_NOTIFICATION,
} from '../actions/types';
import { nanoid as generate } from 'nanoid';

const initialState = {
    type: null,
    message: '',
    user: null,
    data: {},
    key: generate(),
};

export default (state = initialState, { type, message, user = null, data }) => {
    const key = generate();
    switch (type) {
        case ERROR_NOTIFICATION:
            return {
                user,
                key,
                data,
                message: message || 'Something went wrong!',
                type: 'error',
            };
        case INFO_NOTIFICATION:
            return {
                user,
                message,
                data,
                key,
                type: 'success',
            };
        case WARNING_NOTIFICATION:
            return {
                user,
                message,
                data,
                key,
                type: 'warning',
            };
        case CLEAR_NOTIFICATION:
            return { ...initialState, key: generate() };
        default:
            return state;
    }
};
