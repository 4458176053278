import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '../IconButton';
import { useField } from 'formik';
import { locale } from '../../constants/locales';
import classes from './FormikField.module.scss';

const InputField = React.forwardRef((props, ref) => (
    <TextField inputRef={ref} {...props} />
));

export const SearchField = ({
    label,
    className,
    field: { name },
    form: { values },
    innerRef,
    onChange,
    withSelection = false,
    onChangeCallback,
    onToggleCallback,
    ...props
}) => {
    const [input, meta, helpers] = useField({ name, ...props });
    const { error, touched } = meta;
    const { setValue } = helpers;
    const [showInput, setShowInput] = useState(false);
    const ref = useRef();

    const setRef = currentRef => {
        ref.current = currentRef;
        innerRef(currentRef);
    };

    const handleToggle = () => {
        // eslint-disable-next-line no-unused-expressions
        showInput ? setValue('') && ref.current?.blur() : ref.current?.focus();
        showInput && onChangeCallback && onChangeCallback({ ...values, search: '' });
        onToggleCallback && onToggleCallback && onToggleCallback(!showInput);
        setShowInput(!showInput);
    };

    return (
        <div className={classes.searchField}>
            <InputField
                ref={setRef}
                className={clsx(
                    classes.searchInput,
                    showInput && classes.showInput,
                    className,
                )}
                label={label}
                {...input}
                {...props}
                helperText={touched ? error : null}
                error={Boolean(error) ?? false}
                variant="outlined"
                onClick={({ target }) =>
                    withSelection && target?.value && target.select()
                }
                onChange={event => {
                    if (onChange) {
                        onChange(event);
                    } else {
                        const { value } = event.target;
                        const cleanValue =
                            value !== '' && !Number.isNaN(Number(value))
                                ? Number(value)
                                : value;
                        setValue(cleanValue);
                    }
                }}
                InputProps={{
                    autoComplete: 'off',
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label={locale.SEARCH}
                                onClick={handleToggle}
                                size="small"
                            >
                                {showInput ? <CloseIcon /> : <Search />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};
