import {
    IS_STILL_EDITING,
    FINISH_EDITING,
    CLEAR_FINISH_EDITING_MESSAGE,
    CLEAR_IS_STILL_EDITING_MESSAGE,
} from './types';
import API from '../utils/api';
import { go } from './navigation';
import { warningNotification } from './notifications';
import { getProjects } from './projects';
import { getTemplates } from './templates';
import { locale } from '../constants/locales';
import { ITEM_TYPE } from '../constants/item';
import { EDITOR_PATHS, ROOT_PATHS } from '../constants/variables';
import {
    buildProjectsDefaultBaseQuery,
    buildTemplatesDefaultBaseQuery,
} from '../constants/search';
import { ROLES } from '../constants/roles';

export const askIsStillEditing = data => ({
    type: IS_STILL_EDITING,
    data,
});

export const finishEditingWithoutChanges = data => ({
    type: FINISH_EDITING,
    data,
});

export const clearIsStillEditingMessage = data => ({
    type: CLEAR_IS_STILL_EDITING_MESSAGE,
    data,
});

export const clearFinishEditingMessage = data => ({
    type: CLEAR_FINISH_EDITING_MESSAGE,
    data,
});

export const handleStartItemEditing = ({
    id,
    isLockedByAnotherUser,
    type,
}) => dispatch => {
    const isProject = type === ITEM_TYPE.PROJECT;
    if (isLockedByAnotherUser) {
        const warningMessage = isProject
            ? locale.LockedProjectWarning
            : locale.LockedTemplateWarning;
        dispatch(
            warningNotification(warningMessage, null, {
                preventDuplicate: true,
            }),
        );
    } else if (id) {
        const urlToNavigate = isProject
            ? `${id}/${EDITOR_PATHS.projectEditor}`
            : `${id}/${EDITOR_PATHS.templateEditor}`;
        dispatch(go(urlToNavigate));
    }

    return null;
};

export const continueEdit = data => dispatch => {
    dispatch(clearIsStillEditingMessage(data));
    dispatch(lockItem(data.id, data.itemType));

    return null;
};

export const lockItem = (id, type) => dispatch => {
    const lockItemAction = type === ITEM_TYPE.PROJECT ? lockProject : lockTemplate;
    dispatch(lockItemAction(id));

    return null;
};

const unlockItem = (id, type) => dispatch => {
    const unlockItemAction = type === ITEM_TYPE.PROJECT ? unlockProject : unlockTemplate;
    dispatch(unlockItemAction(id));

    return null;
};

export const fetchItemData = (
    type,
    userCompanyNames,
    roles,
    useStoredFilter = false,
) => dispatch => {
    const isProject = type === ITEM_TYPE.PROJECT;
    const getItems = isProject ? getProjects : getTemplates;
    const getItemsQuery = isProject
        ? buildProjectsDefaultBaseQuery
        : buildTemplatesDefaultBaseQuery;
    // Omit Designer to update lists since they can't see this routes
    const companyNames = useStoredFilter ? {} : { companyNames: userCompanyNames };
    if (roles) {
        if (roles.includes(ROLES.ADMIN)) {
            dispatch(getItems(getItemsQuery(companyNames)));
        } else {
            // Omit Designer to update projects since they can't see this route
            if (
                roles.includes(ROLES.DESIGNER) &&
                !roles.includes(ROLES.CONTENT_MANAGER) &&
                isProject
            ) {
                return;
            }
            // Omit Content Manager to update templates since they can't see this routes
            if (
                roles.includes(ROLES.CONTENT_MANAGER) &&
                !roles.includes(ROLES.DESIGNER) &&
                !isProject
            ) {
                return;
            }
            dispatch(getItems(getItemsQuery(companyNames)));
        }
    }
};

export const unlockItemAndFetchData = (id, type, userCompanyNames) => async dispatch => {
    try {
        const userId = localStorage.getItem('user_id');
        if (userId) {
            // TODO Find better way to handle redirects between any routes and  preview/editor pages
            const { pathname } = window.location;
            const isPreview =
                pathname.includes('/project-') || pathname.includes('/template-');
            // Prevent unlock on preview pages
            if (!isPreview) {
                dispatch(clearIsStillEditingMessage({ id }));
                dispatch(unlockItem(id, type));
                dispatch(fetchItemData(type, userCompanyNames));
            }
        }
    } catch (e) {
        console.error('Something went wrong during unmount. Error: ', e);
    }
};

export const lockProject = id => async dispatch => {
    let result = null;
    try {
        result = await API.lockProject(id);
    } catch (e) {
        dispatch(warningNotification(`Error during locking project. ${e.message}`));
        dispatch(go(ROOT_PATHS.projects));
    }

    return result;
};

export const lockTemplate = id => async dispatch => {
    let result = null;
    try {
        result = await API.lockTemplate(id);
    } catch (e) {
        dispatch(warningNotification(`Error during locking template. ${e.message}`));
        dispatch(go(ROOT_PATHS.templates));
    }

    return result;
};

export const unlockProject = id => async dispatch => {
    let result = null;
    try {
        result = await API.unlockProject(id);
    } catch (e) {
        dispatch(warningNotification(`Error during unlocking project. ${e.message}`));
        dispatch(go(ROOT_PATHS.templates));
    }

    return result;
};
export const unlockTemplate = id => async dispatch => {
    let result = null;
    try {
        result = await API.unlockTemplate(id);
    } catch (e) {
        dispatch(warningNotification(`Error during unlocking template. ${e.message}`));
        dispatch(go(ROOT_PATHS.templates));
    }

    return result;
};

export const unlockAllItems = roles => async () => {
    const requestsToExecute = [];

    if (roles && roles.includes(ROLES.ADMIN)) {
        requestsToExecute.push(API.unlockAllProjects());
        requestsToExecute.push(API.unlockAllTemplates());
    } else {
        if (roles.includes(ROLES.CONTENT_MANAGER)) {
            requestsToExecute.push(API.unlockAllProjects());
        }
        if (roles.includes(ROLES.DESIGNER)) {
            requestsToExecute.push(API.unlockAllTemplates());
        }
    }

    await Promise.all(requestsToExecute);
};
