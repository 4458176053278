// Only one ORIGINAL customFabric instance entry
import { fabric as customFabric } from 'fabric';
import {
    DEFAULT_CROP_BORDER_COLOR,
    DEFAULT_CROP_STROKE_COLOR,
} from '../../constants/variables';
import { TEXT_BOUND_POINTS, IMAGE_BOUND_POINTS } from '../../constants/canvas';

function formatTime(seconds) {
    const date = new Date(0);
    date.setHours(0, 0, seconds, 0);
    const mm = String(date.getMinutes());
    const ss = String(date.getUTCSeconds());

    return `${mm.padStart(2, '0')}:${ss.padStart(2, '0')}`;
}

/** **** EXTEND customFabric CLASSES WITH OUR CUSTOM ONE ***** */
customFabric.Object.NUM_FRACTION_DIGITS = 10;
customFabric.perfLimitSizeTotal = 25000000;
customFabric.maxCacheSideLimit = 11000;
customFabric.textureSize = 1024 * 4;
// Disable insert copied text with styles
customFabric.disableStyleCopyPaste = true;

// Helper for dimensions set up
customFabric.setUpMediaDimesions = function (htmlElement, customFabricElement, callback) {
    // const elWidth = htmlElement.naturalWidth || htmlElement.width;
    // const elHeight = htmlElement.naturalHeight || htmlElement.height;
    // const scaleX = ((customFabricElement.scaleX || 1) * customFabricElement.width) / elWidth;
    // const scaleY = ((customFabricElement.scaleY || 1) * customFabricElement.height) / elHeight;
    // customFabricElement.width = elWidth;
    // customFabricElement.height = elHeight;
    // customFabricElement.scaleX = scaleX;
    // customFabricElement.scaleY = scaleY;
    callback && callback(customFabricElement);
};

/** * AnimatedTextbox ** */
// Extend customFabric.Textbox to support serialization
customFabric.AnimatedTextbox = customFabric.util.createClass(customFabric.Textbox, {
    type: 'AnimatedTextbox',

    initialize(text, options = {}) {
        this.set('disableStyleCopying', true);
        this.setControlsVisibility(TEXT_BOUND_POINTS);
        options.lockScalingFlip = true;
        // TODO Find how initialize animation without timeout hack
        this.callSuper('initialize', text, options);
    },
});
customFabric.AnimatedTextbox.fromObject = (object, callback) => {
    return customFabric.Object._fromObject('AnimatedTextbox', object, callback, 'text');
};

/** * AnimatedRect ** */
customFabric.AnimatedRect = customFabric.util.createClass(customFabric.Rect, {
    type: 'AnimatedRect',

    initialize(options = {}) {
        this.callSuper('initialize', options);
    },
});
customFabric.AnimatedRect.fromObject = (object, callback) => {
    return customFabric.Object._fromObject('AnimatedRect', object, callback);
};

/** * AnimatedLine ** */
customFabric.AnimatedLine = customFabric.util.createClass(customFabric.Line, {
    type: 'AnimatedLine',

    initialize(options = {}) {
        this.callSuper('initialize', options);
    },
});
customFabric.AnimatedLine.fromObject = (object, callback) => {
    return customFabric.Object._fromObject('AnimatedLine', object, callback);
};

/** * Crop ** */
customFabric.Crop = customFabric.util.createClass(customFabric.Rect, {
    type: 'Crop',

    initialize(options = {}) {
        this.callSuper('initialize', options);
        this.set({
            borderColor: DEFAULT_CROP_BORDER_COLOR,
            cornerColor: DEFAULT_CROP_BORDER_COLOR,
            stroke: DEFAULT_CROP_STROKE_COLOR,
            strokeDashArray: [2, 2],
            strokeWidth: 1,
            strokeUniform: true,
            // We had to disable this since customFabric scales
            // with rotated clipPaths together with zoomed images
            hasRotatingPoint: false,
        });
    },
});

customFabric.Crop.fromObject = (object, callback) => {
    return customFabric.Object._fromObject('Crop', object, callback);
};

/** * AnimatedPlaceholder ** */
customFabric.AnimatedPlaceholder = customFabric.util.createClass(customFabric.Rect, {
    type: 'AnimatedPlaceholder',

    initialize(options = {}) {
        this.callSuper('initialize', options);
    },
});
customFabric.AnimatedPlaceholder.fromObject = (object, callback) => {
    return customFabric.Object._fromObject('AnimatedPlaceholder', object, callback);
};

/** * AnimatedImage ** */
// Extend customFabric.Image to support serialization
customFabric.AnimatedImage = customFabric.util.createClass(customFabric.Image, {
    type: 'AnimatedImage',
    initialize(element, options = {}) {
        this.callSuper('initialize', element, options);
        this.setControlsVisibility(IMAGE_BOUND_POINTS);
    },
});
customFabric.AnimatedImage.fromURL = function (url, callback, imgOptions) {
    customFabric.util.loadImage(
        url,
        function (img) {
            const customFabricImg = new customFabric.AnimatedImage(img, imgOptions);
            customFabric.setUpMediaDimesions(img, customFabricImg, callback);
        },
        null,
        imgOptions && imgOptions.crossOrigin,
    );
};

/* eslint-disable */
customFabric.AnimatedImage.fromObject = function (_object, callback) {
    const object = customFabric.util.object.clone(_object);
    customFabric.util.loadImage(
        object.src,
        function (img, error) {
            if (error) {
                callback && callback(null, error);

                return;
            }
            customFabric.Image.prototype._initFilters.call(
                object,
                object.filters,
                function (filters) {
                    object.filters = filters || [];
                    customFabric.Image.prototype._initFilters.call(
                        object,
                        [object.resizeFilter],
                        function (resizeFilters) {
                            object.resizeFilter = resizeFilters[0];
                            if (!object._controlsVisibility) {
                                object._controlsVisibility = IMAGE_BOUND_POINTS;
                            }
                            object.lockScalingFlip = true;
                            customFabric.util.enlivenObjects([object.clipPath], function (
                                enlivedProps,
                            ) {
                                object.clipPath = enlivedProps[0];
                                const image = new customFabric.Image(img, object);
                                customFabric.setUpMediaDimesions(img, image, callback);
                            });
                        },
                    );
                },
            );
        },
        null,
        object.crossOrigin,
    );
};
/* eslint-enable */

/* ** VideoImage ** */
// Extend customFabric.Image to support serialization
// Use only for Video Icon and video duration presentation
customFabric.VideoImage = customFabric.util.createClass(customFabric.Image, {
    type: 'VideoImage',
    initialize(element, options = {}) {
        let duration = options.videoDuration || '';
        const videoSrc = options.videoSrc || '';
        const previewUrl = options.previewUrl || '';
        const isPreview = options.isPreview || false;
        if (typeof duration === 'number') {
            duration = formatTime(duration);
        }
        this.callSuper('initialize', element, options);
        this.setControlsVisibility(IMAGE_BOUND_POINTS);
        this.set('videoDuration', duration);
        this.set('videoSrc', videoSrc);
        this.set('previewUrl', previewUrl);
        this.set('isPreview', isPreview);
    },
    _render(ctx) {
        this.callSuper('_render', ctx);
        if (!this.isPreview) {
            const flipX = this.flipX ? -1 : 1;
            const flipY = this.flipY ? -1 : 1;
            ctx.scale(flipX, flipY);
            const factor = this.width / 1080;
            ctx.font = `${112 * factor}px Material Icons`;
            ctx.fillStyle = '#ffffff';
            ctx.fillText(
                '\uE04B',
                this.width / 2 - 130 * factor,
                -this.height / 2 + 130 * factor,
                160 * factor,
            );
            ctx.font = `${80 * factor}px Nobel`;
            ctx.fillStyle = '#ffffff';
            const { width, actualBoundingBoxAscent } = ctx.measureText(
                this.videoDuration,
            );
            ctx.fillText(
                this.videoDuration,
                this.width / 2 - width * 1.1,
                this.height / 2 - actualBoundingBoxAscent,
            );
        }
    },
});
customFabric.VideoImage.fromURL = function (url, callback, imgOptions) {
    customFabric.util.loadImage(
        url,
        function (img) {
            const image = new customFabric.VideoImage(img, imgOptions);
            customFabric.setUpMediaDimesions(img, image, callback);
        },
        null,
        imgOptions && imgOptions.crossOrigin,
    );
};
/* eslint-disable */
customFabric.VideoImage.fromObject = function (_object, callback) {
    const object = customFabric.util.object.clone(_object);
    customFabric.util.loadImage(
        object.src,
        function (img, error) {
            if (error) {
                callback && callback(null, error);

                return;
            }
            customFabric.Image.prototype._initFilters.call(
                object,
                object.filters,
                function (filters) {
                    object.filters = filters || [];
                    customFabric.Image.prototype._initFilters.call(
                        object,
                        [object.resizeFilter],
                        function (resizeFilters) {
                            object.resizeFilter = resizeFilters[0];
                            if (!object._controlsVisibility) {
                                object._controlsVisibility = IMAGE_BOUND_POINTS;
                            }
                            object.lockScalingFlip = true;
                            customFabric.util.enlivenObjects([object.clipPath], function (
                                enlivedProps,
                            ) {
                                object.clipPath = enlivedProps[0];
                                const image = new customFabric.VideoImage(img, object);
                                customFabric.setUpMediaDimesions(img, image, callback);
                            });
                        },
                    );
                },
            );
        },
        null,
        object.crossOrigin,
    );
};
/* eslint-enable */
export { customFabric };
