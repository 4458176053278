import React, { useRef } from 'react';
import { func } from 'prop-types';
import FormikWrapper from '../../components/FormikWrapper';
import Container from '../../components/Container';
import UserForm from '../../components/UserForm';
import Btn from '../../components/Button';
import ActionBar from '../../components/ActionBar';
import withAdminRole from '../../components/withAdminRole';
import { ROLES } from '../../constants/roles';
import { COMPANIES } from '../../constants/companies';
import validationSchema from '../../schemas/validations/user-validation';
import { locale } from '../../constants/locales';
import classes from '../UserProfile/UserProfile.module.scss';

function UserCreate(props) {
    const { createUser } = props;
    const form = useRef(null);
    const initialValues = {
        firstName: '',
        lastName: '',
        roles: [ROLES.CONTENT_MANAGER],
        companyNames: [COMPANIES.OPUS],
        email: '',
    };

    const submit = async (values, submitProps) => {
        const { setSubmitting, validateForm } = submitProps;
        const errors = await validateForm(values);
        await setSubmitting(false);
        if (!Object.keys(errors).length) {
            await createUser(values);
        }
    };

    const handleCreate = async () => {
        await form?.current?.handleSubmit?.();
    };

    return (
        <>
            <ActionBar goBackLink goBackText={locale.CLOSE}>
                <Btn
                    btnType="primaryBtn"
                    text={locale.CREATE}
                    size="small"
                    onClick={handleCreate}
                />
            </ActionBar>
            <Container
                maxWidth="sm"
                className={classes.userProfile}
                preset={{ withBar: true }}
            >
                <FormikWrapper
                    ref={form}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={submit}
                >
                    {formProps =>
                        Object.keys(formProps.values).length && (
                            <UserForm {...formProps} isAdmin userId="null" />
                        )
                    }
                </FormikWrapper>
            </Container>
        </>
    );
}

UserCreate.propTypes = {
    createUser: func.isRequired,
};

export default withAdminRole(UserCreate);
