import { connect } from 'react-redux';
import { setNewPassword } from '../../actions/auth';
import { errorNotification } from '../../actions/notifications';
import { go } from '../../actions/navigation';
import CreatePassword from './CreatePassword';

const mapDispatchToProps = {
    go,
    errorNotification,
    setNewPassword,
};

export default connect(() => ({}), mapDispatchToProps)(CreatePassword);
