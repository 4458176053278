import { connect } from 'react-redux';
import ActiveSellerEditor from './ActiveSellerEditor';
import { addAsset, createSlide, updateSlide } from '../../../actions/slides';
import { hideLoader, showLoader } from '../../../actions/loading';

const mapStateToProps = state => ({
    slides: state?.slides?.list,
});

const mapDispatchToProps = {
    addAsset,
    createSlide,
    updateSlide,
    hideLoader,
    showLoader,
};
export default connect(mapStateToProps, mapDispatchToProps)(ActiveSellerEditor);
