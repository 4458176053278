import openSocket from 'socket.io-client';
import { bindActionCreators } from 'redux';
import { go } from '../actions/navigation';
import { getProjectById, getProjects } from '../actions/projects';
import {
    infoNotification,
    errorNotification,
    warningNotification,
} from '../actions/notifications';
import {
    onProcessing,
    stopProcessing,
    getQueueProgress,
} from '../actions/processingProgress';
import {
    askIsStillEditing,
    finishEditingWithoutChanges,
    fetchItemData,
} from '../actions/lockedItems';
import { getUserNotifications } from '../actions/user-notifications';
import { getUserInfo } from '../actions/user-info';
import {
    UNAUTH_PATHS,
    SOCKET_RECONNECT_ATTEMPTS,
    SOCKET_RECONNECT_DELAY,
} from '../constants/variables';
import { onlyRole } from './roles';
import { SOCKET_EVENTS } from '../constants/sockets';
import { locale } from '../constants/locales';
import persistStore from '../store/configureStore';
import { environment } from '../../environments/environment';
import { LOGOUT } from '../actions/types';
import { ITEM_TYPE } from '../constants/item';
import { ROLES } from '../constants/roles';
import { getSlides } from '../actions/slides';
import { removeUserLocalData } from './common';

const { persistor } = persistStore();

// Need to be here since of circular import issue
export const logout = () => ({
    type: LOGOUT,
});

export const serverUrl = environment.apiUrl || 'https://api.dimamotion-dev.de';

async function withTryCatch(functionToExecute) {
    try {
        await functionToExecute();
    } catch (e) {
        console.info(e);
    }
}

async function getVideoProgress(dispatch) {
    const boundActions = bindActionCreators(
        {
            getUserNotifications,
            getQueueProgress,
        },
        dispatch,
    );
    const userId = localStorage.getItem('user_id');
    // Only for logged in users
    const { pathname } = window.location;
    if (
        userId &&
        pathname !== UNAUTH_PATHS.login &&
        pathname !== UNAUTH_PATHS.setPassword
    ) {
        try {
            // Update notifications list
            boundActions.getUserNotifications();
            // Update processing list
            boundActions.getQueueProgress();
        } catch (e) {
            console.info(e);
        }
    }
}

const baseSocketHandlers = {
    connect: (socket, callbacks = []) =>
        socket.on('connect', () => {
            // Store socket id for unsubscribe later
            localStorage.setItem('socketId', socket.id);
            callbacks.forEach(callback => callback());
            console.info(`Socket Connected to ${serverUrl}`);
        }),
    reconnectError: socket =>
        socket.on('reconnect_error', e => {
            console.info(`Socket reconnect_error: ${e}`);
        }),
    disconnect: socket =>
        socket.on('disconnect', () => console.info('Socket Disconnected')),
    reconnect_attempt: (socket, boundActions) =>
        socket.on('reconnect_attempt', async attempt => {
            console.info(`Socket reconnect attempt: ${attempt}`);
            if (attempt === SOCKET_RECONNECT_ATTEMPTS) {
                removeUserLocalData();
                if (persistor) {
                    await persistor.purge();
                    await persistor.persist();
                }
                boundActions.logout();
                alert(locale.Messages.SERVER_PROBLEMS);
                boundActions.go(UNAUTH_PATHS.login);
            }
        }),
};

function setupSocketConnection(dispatch, getState) {
    const socket = openSocket(serverUrl + '?token=unfold-app', {
        reconnectionAttempts: SOCKET_RECONNECT_ATTEMPTS,
        randomizationFactor: 1,
        reconnectionDelay: SOCKET_RECONNECT_DELAY,
    });

    const boundActions = bindActionCreators(
        {
            go,
            logout,
            getSlides,
            getProjects,
            getUserInfo,
            onProcessing,
            fetchItemData,
            stopProcessing,
            getProjectById,
            getQueueProgress,
            infoNotification,
            errorNotification,
            askIsStillEditing,
            warningNotification,
            getUserNotifications,
            finishEditingWithoutChanges,
        },
        dispatch,
    );

    baseSocketHandlers.reconnect_attempt(socket, boundActions);
    baseSocketHandlers.reconnectError(socket);
    baseSocketHandlers.connect(socket, [
        boundActions.getUserNotifications,
        boundActions.getQueueProgress,
    ]);
    baseSocketHandlers.disconnect(socket);

    return [socket, boundActions, getState];
}

function subscribeOnMessages(socket, boundActions, getState) {
    subscribeToVideoMessages(socket, boundActions, getState);
    subscribeToItemLockMessages(socket, boundActions, getState);
}

export function handleProcessingEnd(event, data, boundActions, getState) {
    return withTryCatch(() => {
        const { message, project, initiatedBy } = data;
        const { auth, project: currentProject } = getState();
        if (auth?.user?.id && auth.user.id === initiatedBy) {
            if (event === SOCKET_EVENTS.VIDEO_PROCESSING_ERROR) {
                boundActions.errorNotification({ message }, auth.user);
            } else if (event === SOCKET_EVENTS.VIDEO_PROCESSING_FINISH) {
                boundActions.infoNotification(message, auth.user);
            }
            if (currentProject && currentProject?.id === project.id) {
                boundActions.getProjectById(project.id);
                boundActions.getSlides(project.id);
            }
        }
        if (
            auth?.user?.companyNames &&
            auth.user.companyNames.includes(project.companyName)
        ) {
            boundActions.getQueueProgress();
            // Update notifications list
            boundActions.getUserNotifications({}, 'both');
            // Update processing list
            boundActions.fetchItemData(
                ITEM_TYPE.PROJECT,
                auth.user.companyNames,
                auth.user.roles,
            );
        }
    });
}

function subscribeToVideoMessages(socket, boundActions, getState) {
    // Video messages channels
    socket.on(SOCKET_EVENTS.VIDEO_PROCESSING_FINISH, async data => {
        await handleProcessingEnd(
            SOCKET_EVENTS.VIDEO_PROCESSING_FINISH,
            data,
            boundActions,
            getState,
        );
    });
    socket.on(SOCKET_EVENTS.VIDEO_PROCESSING_ERROR, async data => {
        await handleProcessingEnd(
            SOCKET_EVENTS.VIDEO_PROCESSING_ERROR,
            data,
            boundActions,
            getState,
        );
    });
    socket.on(SOCKET_EVENTS.VIDEO_PROCESSING_PROGRESS, async data => {
        await withTryCatch(() => {
            const { auth } = getState();
            if (auth?.user?.roles && !onlyRole(auth.user.roles, ROLES.DESIGNER)) {
                if (Object.keys(data).length) {
                    const { project } = data;
                    if (
                        auth?.user?.companyNames &&
                        auth.user.companyNames.includes(project?.companyName)
                    ) {
                        boundActions.getQueueProgress();
                    }
                }
            }
        });
    });
}

function subscribeToItemLockMessages(socket, boundActions, getState) {
    socket.on(SOCKET_EVENTS.UPDATE_ITEMS_LIST, async data => {
        await withTryCatch(() => {
            const { auth } = getState();
            // Do not fetch items for current active user since he already has last data fetched
            if (auth?.user?.id !== data.userId || data.forceUpdate) {
                if (
                    auth?.user?.companyNames &&
                    auth.user.companyNames.includes(data.companyName)
                ) {
                    // Do not update list is we are at another project
                    // and initial request was for previous project
                    if (window.location.pathname.includes('editor') && data.forceUpdate) {
                        return;
                    }
                    boundActions.fetchItemData(
                        data.type,
                        auth.user.companyNames,
                        auth.user.roles,
                        data.forceUpdate,
                    );
                }
            }
        });
    });
    socket.on(SOCKET_EVENTS.IS_STILL_EDITING, async data => {
        const userId = localStorage.getItem('user_id');
        if (data?.user?.id === userId) {
            await withTryCatch(() => {
                boundActions.askIsStillEditing(data);
            });
        }
    });
    socket.on(SOCKET_EVENTS.FINISH_EDITING, async data => {
        const userId = localStorage.getItem('user_id');
        if (data?.user?.id === userId) {
            await withTryCatch(() => {
                boundActions.finishEditingWithoutChanges(data);
            });
        }
    });
    socket.on(SOCKET_EVENTS.UPDATE_USER_EVENT, async data => {
        const userId = localStorage.getItem('user_id');
        if (data?.userId === userId) {
            await withTryCatch(() => {
                boundActions.getUserInfo(data.userId);
                boundActions.getUserNotifications({}, 'both');
                boundActions.getQueueProgress();
            });
        }
    });
}

export { getVideoProgress, setupSocketConnection, subscribeOnMessages };
