import React from 'react';
import { bool, object, shape, string } from 'prop-types';
import Editor from '../../containers/Editor';
import Container from '../../components/Container';
import ItemLockedWarning from '../../components/ItemLockedWarning';
import { EDITOR_TYPE } from '../../constants/item';
import classes from './EditorPage.module.scss';

const EditorPage = ({ history, match: { params } }) => {
    const { projectId } = params;

    return (
        <Container fixed={false} maxWidth={false} className={classes.Editor} noPadding>
            <Editor params={params} history={history} />
            <ItemLockedWarning itemId={projectId} editorType={EDITOR_TYPE.PROJECT} />
        </Container>
    );
};

Editor.propTypes = {
    match: shape({
        isExact: bool,
        params: object.isRequired,
        path: string,
        url: string,
    }),
};

export default EditorPage;
