import { connect } from 'react-redux';
import RenameField from './RenameField';
import { errorNotification } from '../../../actions/notifications';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    errorNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(RenameField);
