export const ROLES = Object.freeze({
    ADMIN: 'ADMIN',
    CONTENT_MANAGER: 'CONTENT_MANAGER',
    DESIGNER: 'DESIGNER',
});

export const ROLES_OPTIONS = Object.freeze([
    { value: ROLES.ADMIN, label: 'Admin' },
    { value: ROLES.CONTENT_MANAGER, label: 'Content Manager' },
    { value: ROLES.DESIGNER, label: 'Designer' },
]);

export const roleRegex = new RegExp(
    `(${ROLES.CONTENT_MANAGER}|${ROLES.ADMIN}|${ROLES.DESIGNER})`,
);
