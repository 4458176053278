import React from 'react';
import WarningIcon from '@material-ui/icons/WarningRounded';
import Fade from '@material-ui/core/Fade';
import { LIMIT_DURATION_VIDEO } from '../../constants/sizes';
import SlideObject from '../../types/Slide';
import classes from './VideoDurationAlertIcon.module.scss';

const VideoDurationAlertIcon = ({ slide }) => {
    const { duration } = slide;
    const notificationIsOn = !!duration && duration > LIMIT_DURATION_VIDEO;

    return (
        <Fade
            in={notificationIsOn}
            timeout={0}
            style={notificationIsOn ? { zIndex: 9 } : { zIndex: -1 }}
        >
            <span>
                <span className={classes.AlertIcon}>
                    <WarningIcon color="action" fontSize="small" />
                </span>
            </span>
        </Fade>
    );
};

VideoDurationAlertIcon.propTypes = {
    slide: SlideObject.isRequired,
};

export default VideoDurationAlertIcon;
