import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { arrayOf, func, bool, string, number } from 'prop-types';
import ActiveSellerPreview from '../ActiveSellerPreview';
import ActionBar from '../../../components/ActionBar';
import SlidesLine from '../ActiveSellerSlides';
import Grid from '../../../components/Grid';
import EffectsBlock from '../../../components/EffectsBlock';
import DurationNotifications from '../../../components/DurationNotifications';
import { createActiveSellerSlide } from '../../../utils/slides';
import SlideObject from '../../../types/Slide';
import { ROOT_PATHS } from '../../../constants/variables';
import { SLIDE_ASPECT_RATIO } from '../../../constants/sizes';
import classes from '../Editor.module.scss';
import Spinner from '../../../components/Spinner';
import { useMount } from '../../../utils/hooks';

const ActiveSellerEditor = ({
    projectId,
    company,
    blockHeight,
    handleCreate,
    handleUpdate,
    isProcessingCurrentProject,
    slides,
    addAsset,
    hideLoader,
    showLoader,
}) => {
    const inputEl = useRef(null);
    const [slide, setSlide] = useState({});
    const [isLoading, setLoading] = useState(true);

    async function selectSlide(data) {
        if (data.id) {
            setSlide(data);
        } else if (!data.id && data.imageUrl === 'empty') {
            // Create new slide
            try {
                showLoader();
                const newSlide = await handleCreate(
                    createActiveSellerSlide(projectId, slides),
                );
                if (newSlide) {
                    setSlide(newSlide);
                }
            } catch (e) {
                console.error(e);
                setSlide({});
            } finally {
                hideLoader();
            }
        }
        if (data && !Object.keys(data).length) {
            setSlide({});
        }
    }

    async function storeFormValues(data) {
        const newSlide = await handleUpdate(data);
        await selectSlide(newSlide);
    }

    async function handleChange(event) {
        try {
            const file = event.target?.files?.[0];
            if (file) {
                const updatedSlide = await addAsset(file, slide.id, projectId);
                if (updatedSlide) {
                    await selectSlide(updatedSlide);
                }
                if (inputEl?.current) {
                    inputEl.current.value = '';
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    const handleFile = () => {
        inputEl.current.click();
    };

    useMount(() => {
        setLoading(false);
    });

    return (
        <>
            <ActionBar
                projectId={projectId}
                goBackLink={ROOT_PATHS.projects}
                showPreview
                activeSellerDownloadButton
                processVideoDisabled={isProcessingCurrentProject}
            />
            <Spinner loading={isLoading} fixed opaque />
            <Grid
                style={{ height: blockHeight }}
                item
                xs={9}
                className={classes.overflow}
            >
                {Object.keys(slide).length > 0 && (
                    <>
                        <input ref={inputEl} onChange={handleChange} type="file" hidden />
                        <ActiveSellerPreview
                            company={company}
                            dimensions={{
                                height: blockHeight,
                                width: blockHeight * SLIDE_ASPECT_RATIO,
                            }}
                            addFile={handleFile}
                            slide={slide}
                        />
                    </>
                )}
                <DurationNotifications />
            </Grid>
            <Grid
                item
                style={{ height: blockHeight }}
                xs={3}
                className={clsx(
                    classes.borderLeft,
                    classes.overflowScroll,
                    classes.relative,
                    classes.rightPanel,
                )}
            >
                <EffectsBlock
                    slide={slide}
                    storeFormValues={storeFormValues}
                    slides={slides}
                    projectId={projectId}
                />
            </Grid>
            <Grid item xs={12}>
                <SlidesLine
                    projectId={projectId}
                    selectSlide={selectSlide}
                    company={company}
                    setSlide={setSlide}
                    slides={slides}
                    activeSlide={slide}
                />
            </Grid>
        </>
    );
};

ActiveSellerEditor.propTypes = {
    projectId: string.isRequired,
    company: string.isRequired,
    blockHeight: number.isRequired,
    handleCreate: func.isRequired,
    handleUpdate: func.isRequired,
    isProcessingCurrentProject: bool.isRequired,
    slides: arrayOf(SlideObject),
    addAsset: func.isRequired,
    hideLoader: func.isRequired,
    showLoader: func.isRequired,
};

export default ActiveSellerEditor;
