import React from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import classes from './ColorPicker.module.scss';

const PickerDialog = ({ value, onClick, onChange, onChangeComplete }) => {
    return (
        <ClickAwayListener onClickAway={onClick}>
            <div className={classes.pickerDialog}>
                <SketchPicker
                    color={value}
                    onChange={onChange}
                    onChangeComplete={onChangeComplete}
                    presetColors={[]}
                    className={classes.sketchPicker}
                />
            </div>
        </ClickAwayListener>
    );
};

PickerDialog.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
};

export default PickerDialog;
