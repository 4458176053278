import { CLEAR_TEMPLATE, SAVE_TEMPLATE } from '../actions/types';

const initialState = {};

export default (state = initialState, { type, template }) => {
    switch (type) {
        case SAVE_TEMPLATE:
            return {
                ...state,
                ...template,
            };
        case CLEAR_TEMPLATE:
            return initialState;
        default:
            return state;
    }
};
