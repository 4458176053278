import * as Yup from 'yup';
import { locale } from '../../constants/locales';
import { passwordRegex } from '../../constants/validations';

export default Yup.object().shape({
    email: Yup.string()
        .required(locale.Messages.REQUIRED_FIELD)
        .matches(passwordRegex, locale.Messages.HAS_SPACES('Password'))
        .email(locale.Messages.INVALID_EMAIL),
    password: Yup.string()
        .required(locale.Messages.REQUIRED_FIELD)
        .matches(passwordRegex, locale.Messages.HAS_SPACES('Password')),
    confirmPassword: Yup.string()
        .required(locale.Messages.REQUIRED_FIELD)
        .matches(passwordRegex, locale.Messages.HAS_SPACES('Password')),
});
