import React, { useRef } from 'react';
import { func } from 'prop-types';
import { Form } from 'formik';
import Typography from '@material-ui/core/Typography';
import IconButton from '../../components/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormikWrapper from '../../components/FormikWrapper';
import Container from '../../components/Container';
import FormikField from '../../components/FormikField';
import ActionBar from '../../components/ActionBar';
import Btn from '../../components/Button';
import { locale } from '../../constants/locales';
import classes from '../Login/Login.module.scss';
import validationSchema from '../../schemas/validations/create-password-validation';

function CreatePassword(props) {
    const { go, setNewPassword, errorNotification } = props;
    const form = useRef(null);
    const initialValues = {
        password: '',
        email: '',
        confirmPassword: '',
    };

    const [types, setTypes] = React.useState({
        showPassword: false,
        showConfirmPassword: false,
    });

    const handleChangeType = prop => {
        setTypes({ ...types, [prop]: !types[prop] });
    };

    const submit = async (values, submitProps) => {
        const { setSubmitting, validateForm } = submitProps;
        const errors = await validateForm(values);
        const { email, password, confirmPassword } = values;
        await setSubmitting(false);
        if (confirmPassword !== password) {
            return errorNotification({
                message: 'Passwords does not match',
            });
        }
        if (!Object.keys(errors).length) {
            const res = await setNewPassword({
                email,
                password,
                mode: 'CREATE',
            });

            return res && res.user && go('/templates');
        }

        return null;
    };

    return (
        <>
            <ActionBar goBackLink="/" goBackText={locale.BACK} />
            <Container maxWidth="sm" className={classes.Login} preset={{ withBar: true }}>
                <FormikWrapper
                    ref={form}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={submit}
                >
                    {(values, submitForm, handleSubmit) =>
                        Object.keys(values).length && (
                            <Form className={classes.form}>
                                <Typography
                                    variant="h4"
                                    align="center"
                                    className={classes.gutterBottom}
                                >
                                    {locale.CREATE_NEW_PASSWORD}
                                </Typography>
                                <FormikField
                                    variant="outlined"
                                    label={locale.EMAIL}
                                    type="text"
                                    name="email"
                                    className={classes.gutterBottom}
                                    fullWidth
                                />
                                <FormikField
                                    variant="outlined"
                                    label={locale.PASSWORD}
                                    type={types.showPassword ? 'text' : 'password'}
                                    name="password"
                                    className={classes.gutterBottom}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    aria-label={locale.TOGGLE_PASS}
                                                    onClick={() =>
                                                        handleChangeType('showPassword')
                                                    }
                                                >
                                                    {types.showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <FormikField
                                    variant="outlined"
                                    label={locale.CONFIRM_PASSWORD}
                                    type={types.showConfirmPassword ? 'text' : 'password'}
                                    name="confirmPassword"
                                    className={classes.gutterBottom}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    aria-label={locale.TOGGLE_PASS}
                                                    onClick={() =>
                                                        handleChangeType(
                                                            'showConfirmPassword',
                                                        )
                                                    }
                                                >
                                                    {types.showConfirmPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Btn
                                    text={locale.CREATE_PASSWORD}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submitBtn}
                                    onClick={handleSubmit}
                                />
                            </Form>
                        )
                    }
                </FormikWrapper>
            </Container>
        </>
    );
}

CreatePassword.propTypes = {
    setNewPassword: func.isRequired,
    errorNotification: func.isRequired,
    go: func.isRequired,
};

export default CreatePassword;
