export const COLORS = Object.freeze({
    secondary: '#efefef',
    primary: '#333333',
    default: '#ffffff',
    white: '#ffffff',
    lightgray: '#fafafa',
    gray: '#e0e0e0',
    darkGray: '#333',
    midGray: '#bdbdbd',
    hardGray: '#646464',
    liteGreen: '#36FD00',
    medium: '#8D8D8D',
    black: '#000000',
    lightBlack: '#999999',
    blackMedium: '#333',
    blackOpacity: 'rgba(0,0,0,0.54)',
    blackOpacityHex: 'rgba(0,0,0,0.3)',
    blackOpacityLite: 'rgba(0,0,0,0.04)',
    whiteOpacityHex: 'rgba(255,255,255,0.3)',
    transparent: 'transparent',
    red: '#E83D3D',
    lightRed: '#FF5757',
    darkRed: '#CF2424',
});
