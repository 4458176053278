import { connect } from 'react-redux';
import Canvas from './Canvas';
import {
    showDurationNotifications,
    clearDurationNotifications,
} from '../../actions/duration-notifications';
import { saveCanvasToHistory, saveCanvasWithoutHistory } from '../../actions/canvas';
import { warningNotification, errorNotification } from '../../actions/notifications';

const mapStateToProps = state => ({
    authUser: state?.auth?.user,
    template: state?.template,
    durationNotificationIsOn: Boolean(state?.showDurationNotifications?.show),
});

const mapDispatchToProps = {
    showDurationNotifications,
    clearDurationNotifications,
    saveCanvasToHistory,
    saveCanvasWithoutHistory,
    warningNotification,
    errorNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Canvas);
