import React, { useEffect, useState } from 'react';
import { arrayOf, func } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import EffectsBlockForm from '../EffectsBlockForm';
import SlideObject from '../../types/Slide';
import { SLIDE_FADE_DEFAULT_DURATION } from '../../constants/slides';
import { locale } from '../../constants/locales';
import classes from './EffectsBlock.module.scss';

function EffectsBlock({ slide, slides, storeFormValues }) {
    const [totalDuration, setTotalDuration] = useState(0);
    const [slideDuration, setSlideDuration] = useState(0);

    // Calculate durations on slide updates
    useEffect(() => {
        if (Object.keys(slide).length) {
            let slideFades = 0;
            if (Object.keys(slides).length) {
                slideFades =
                    (slide.index === 0 && slide.fadeIn && SLIDE_FADE_DEFAULT_DURATION) +
                    (slide.index === slides[slides.length - 1].index &&
                        slide.fadeOut &&
                        SLIDE_FADE_DEFAULT_DURATION);
            }
            const slDuration =
                slide.index > 0
                    ? +slide.transitionDuration + slide.duration + slideFades
                    : slide.duration + slideFades;
            const total = slides.reduce(
                (a, b) => +a + +b.transitionDuration + +b.duration,
                0,
            );
            let fadesDurations = 0;
            if (Object.keys(slides).length) {
                fadesDurations =
                    (slides[0].fadeIn && SLIDE_FADE_DEFAULT_DURATION) +
                    (slides[slides.length - 1] &&
                        slides[slides.length - 1].fadeOut &&
                        SLIDE_FADE_DEFAULT_DURATION) -
                    slides[0].transitionDuration;
            }
            const sum = total + fadesDurations;
            setSlideDuration(slDuration);
            setTotalDuration(sum);
        }
    }, [slide, slides, setTotalDuration, setSlideDuration, slideDuration]);

    return (
        <div className={classes.EffectsBlock}>
            {!!Object.keys(slide).length && (
                <div className={classes.flexColumn}>
                    <EffectsBlockForm
                        slide={slide}
                        totalSlides={slides.length - 1}
                        handleUpdate={storeFormValues}
                        disableSlideDuration={slide?.imageUrl?.indexOf('.mp4') >= 0}
                    />
                    <div>
                        {!!slideDuration && (
                            <Typography align="left" variant="caption" display="block">
                                Slide duration: {slideDuration} seconds
                            </Typography>
                        )}
                        {!!totalDuration && (
                            <Typography align="left" variant="caption" display="block">
                                Total: {totalDuration} seconds
                            </Typography>
                        )}
                    </div>
                </div>
            )}
            {!Object.keys(slide).length && (
                <Typography variant="subtitle1">{locale.NO_DATA}</Typography>
            )}
        </div>
    );
}

EffectsBlock.propTypes = {
    storeFormValues: func.isRequired,
    slide: SlideObject.isRequired,
    slides: arrayOf(SlideObject).isRequired,
};

export default EffectsBlock;
