import { connect } from 'react-redux';
import SystemPage from './SystemPage';
import {
    getVideoServiceStatus,
    getVideoInstanceStatus,
    startVideoInstance,
    stopVideoInstance,
    restartBackend,
} from '../../actions/system';

const mapStateToProps = state => ({
    user: state?.auth?.user,
    videoServiceStatus: state?.system?.service,
    videoInstanceStatus: state?.system?.instance,
});

const mapDispatchToProps = {
    getVideoServiceStatus,
    getVideoInstanceStatus,
    startVideoInstance,
    stopVideoInstance,
    restartBackend,
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemPage);
