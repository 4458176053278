import React, { useCallback, useState, useEffect, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import clsx from 'clsx';
import { array, func, object, oneOfType, number } from 'prop-types';
import IconButton from '../../../components/IconButton';
import Typography from '@material-ui/core/Typography';
import { customFabric as fabric } from '../../../components/FabricComponents';
import Grid from '../../../components/Grid';
import { CMActionsSchema } from '../../../schemas/actions';
import { WithCanvasContext } from '../../../utils/context';
import { usePrevious, useUnmount } from '../../../utils/hooks';
import {
    findCanvasItem,
    preventRotateAndScale,
    getNewSlideDuration,
} from '../../../utils/canvas';
import {
    CANVAS_TYPE_OBJECTS,
    IMAGE_CREATE_OPTIONS,
    CLIPPATH_CREATE_OPTIONS,
} from '../../../constants/canvas';
import { BUILD_FULL_FILE_PATH } from '../../../constants/slides';
import { locale } from '../../../constants/locales';
import classes from './Actions.module.scss';

function Actions({
    canvas,
    activeObject,
    saveCanvas,
    handleUpdate,
    uploadMedia,
    toggleLoading,
    droppedFile,
    setDroppedFile,
    slideDuration,
}) {
    const isMounted = useRef(true);
    const [schema, setSchema] = useState(null);
    const prevActiveObject = usePrevious(activeObject);
    const prevFile = usePrevious(droppedFile);
    const inputEl = useRef(null);

    const buildSchema = useCallback(() => {
        const newSchema = cloneDeep(CMActionsSchema);
        // const activeObject = canvas.getActiveObject();
        for (const key in newSchema) {
            if (Object.prototype.hasOwnProperty.call(newSchema, key)) {
                switch (key) {
                    case 'uploadMedia':
                        newSchema[key].disabled = activeObject
                            ? !(
                                  activeObject.type === 'group' ||
                                  ((activeObject.type ===
                                      CANVAS_TYPE_OBJECTS.animatedImage ||
                                      activeObject.type ===
                                          CANVAS_TYPE_OBJECTS.videoImage) &&
                                      activeObject.clipPath?.type ===
                                          CANVAS_TYPE_OBJECTS.animatedPlaceholder)
                              )
                            : true;
                        break;
                    case 'resetState':
                        newSchema[key].disabled = !activeObject;
                        break;
                    default:
                        newSchema[key].show = true;
                        newSchema[key].disabled = false;
                }
            }
        }

        return newSchema;
    }, [activeObject]);

    useEffect(() => {
        const rebuildSchema = () => {
            const newSchema = activeObject ? buildSchema() : cloneDeep(CMActionsSchema);
            isMounted.current && setSchema(newSchema);
        };
        if (prevActiveObject !== activeObject) {
            rebuildSchema();
        }
    }, [activeObject, schema, setSchema, buildSchema, prevActiveObject]);

    useUnmount(() => {
        setSchema(null);
        isMounted.current = false;
    });

    const handleChangeFile = useCallback(
        async file => {
            try {
                if (canvas && file && activeObject) {
                    isMounted.current && toggleLoading(true);
                    const { url, duration, previewUrl, dimensions } = await uploadMedia(
                        file,
                    );
                    const mediaElement = duration
                        ? fabric.VideoImage
                        : fabric.AnimatedImage;
                    const options = duration
                        ? {
                              ...IMAGE_CREATE_OPTIONS(),
                              dimensions,
                              videoSrc: BUILD_FULL_FILE_PATH(url),
                              videoDuration: duration,
                              zoomIn: activeObject.zoomIn,
                              zoomInValue: activeObject.zoomInValue,
                              animation: activeObject.animation,
                          }
                        : {
                              ...IMAGE_CREATE_OPTIONS(),
                              dimensions,
                              zoomIn: activeObject.zoomIn,
                              zoomInValue: activeObject.zoomInValue,
                              animation: activeObject.animation,
                          };
                    mediaElement.fromURL(
                        BUILD_FULL_FILE_PATH(duration ? previewUrl : url),
                        async function (img) {
                            let objectToClip;
                            const isGroup =
                                activeObject.type === CANVAS_TYPE_OBJECTS.group;
                            const isMedia =
                                (activeObject.type ===
                                    CANVAS_TYPE_OBJECTS.animatedImage ||
                                    activeObject.type ===
                                        CANVAS_TYPE_OBJECTS.videoImage) &&
                                activeObject.clipPath?.type ===
                                    CANVAS_TYPE_OBJECTS.animatedPlaceholder;
                            if (isGroup) {
                                const lines = activeObject
                                    .getObjects()
                                    .filter(
                                        item =>
                                            item.type === CANVAS_TYPE_OBJECTS.polyline,
                                    );
                                lines.forEach(line => activeObject.remove(line));
                                objectToClip = activeObject;
                            }
                            if (isMedia) {
                                objectToClip = activeObject.clipPath;
                                objectToClip.set({
                                    zoomIn: activeObject.zoomIn,
                                    zoomInValue: activeObject.zoomInValue,
                                    animation: activeObject.animation,
                                });
                            }
                            const options = CLIPPATH_CREATE_OPTIONS(objectToClip);
                            // We need to remove 0.5px shift for Placeholder and add 2 px to width and height
                            // because of border width
                            const clipPath = isGroup
                                ? new fabric.AnimatedPlaceholder({
                                      ...options,
                                      strokeWidth: 0,
                                      stroke: null,
                                      left: options.left - 1.5,
                                      top: options.top - 1.5,
                                      width: options.width + 2,
                                      height: options.height + 2,
                                      originX: 'left',
                                      originY: 'top',
                                      clipFor: img.objectId,
                                  })
                                : objectToClip;
                            const sX = clipPath.getScaledWidth() / img.width;
                            const sY = clipPath.getScaledHeight() / img.height;
                            if (sX > sY) {
                                img.scaleToWidth(
                                    img.getScaledWidth() * (sX / img.scaleX),
                                );
                            } else {
                                img.scaleToHeight(
                                    img.getScaledHeight() * (sY / img.scaleY),
                                );
                            }
                            img.set({
                                clipPath,
                                top:
                                    clipPath.top +
                                    clipPath.getScaledHeight() * 0.5 -
                                    img.getScaledHeight() * 0.5,
                                left:
                                    clipPath.left +
                                    clipPath.getScaledWidth() * 0.5 -
                                    img.getScaledWidth() * 0.5,
                                minScaleLimit: Math.max(sX, sY),
                            });
                            img.setCoords();
                            clipPath.set({
                                selectable: false,
                                absolutePositioned: true,
                            });
                            preventRotateAndScale(img);
                            // Insert video at the same index as it was in JSON to preserve stacking
                            const { index } = findCanvasItem(
                                activeObject.objectId,
                                canvas,
                            );
                            canvas.insertAt(img, index, true);
                            canvas.setActiveObject(img);
                            const objects = canvas.getObjects();
                            // Handle slide duration
                            const longestVideoDuration = getNewSlideDuration({
                                canvasData: { objects },
                            });
                            if (
                                longestVideoDuration > 0 &&
                                longestVideoDuration > slideDuration &&
                                img.type !== CANVAS_TYPE_OBJECTS.animatedImage
                            ) {
                                await handleUpdate(
                                    {
                                        id: canvas.slideId,
                                        duration: longestVideoDuration,
                                    },
                                    false,
                                );
                            }
                            await saveCanvas(true);
                            isMounted.current && toggleLoading(false);
                            canvas.renderAll();
                        },
                        options,
                    );
                }
            } catch (e) {
                isMounted.current && toggleLoading(false);
                console.error(e);
            }
            // reset file input
            if (inputEl?.current) {
                inputEl.current.value = '';
            }

            // reset droppedFile
            if (setDroppedFile) {
                isMounted.current && setDroppedFile([]);
            }
        },
        [
            activeObject,
            canvas,
            handleUpdate,
            saveCanvas,
            setDroppedFile,
            toggleLoading,
            uploadMedia,
            slideDuration,
        ],
    );

    const methods = {
        uploadMedia: async () => {
            try {
                await inputEl?.current?.click();
            } catch (e) {
                console.info('Cannot find active element');
            }
        },
    };

    useEffect(() => {
        if (droppedFile && Object.keys(droppedFile).length && prevFile !== droppedFile) {
            handleChangeFile(droppedFile);
        }
    }, [handleChangeFile, droppedFile, prevFile]);

    return (
        <div className={classes.root}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography
                        className={classes.smallTitle}
                        variant="subtitle2"
                        align="left"
                        gutterBottom
                    >
                        <strong>{locale.ACTIONS}</strong>
                    </Typography>
                    <input
                        ref={inputEl}
                        onChange={event => handleChangeFile(event.target?.files?.[0])}
                        type="file"
                        hidden
                    />
                </Grid>
                {schema &&
                    Object.values(schema).map(
                        ({ label, Icon, methodName, iconClass, disabled, show }) =>
                            show && (
                                <Grid
                                    item
                                    xs={3}
                                    className={classes.gridItem}
                                    key={label}
                                >
                                    <IconButton
                                        id={label}
                                        disabled={disabled}
                                        aria-label={label}
                                        onClick={event => methods[methodName](event)}
                                        classes={{
                                            root: classes.btnRoot,
                                            label: classes.btnLabel,
                                        }}
                                    >
                                        <Icon fontSize="inherit" className={iconClass} />
                                    </IconButton>
                                    <Typography
                                        className={clsx(
                                            classes.labelText,
                                            disabled && classes.disabled,
                                        )}
                                        align="left"
                                        variant="caption"
                                    >
                                        {label}
                                    </Typography>
                                </Grid>
                            ),
                    )}
            </Grid>
        </div>
    );
}

Actions.propTypes = {
    canvas: object,
    activeObject: object,
    slideDuration: number,
    saveCanvas: func.isRequired,
    handleUpdate: func.isRequired,
    uploadMedia: func.isRequired,
    toggleLoading: func.isRequired,
    droppedFile: oneOfType([array, object]),
    setDroppedFile: func,
};

export default WithCanvasContext(Actions);
