import * as Yup from 'yup';
import { locale } from '../../constants/locales';
import { roleRegex } from '../../constants/roles';
import { companyRegex } from '../../constants/companies';

export default Yup.object().shape({
    firstName: Yup.string().required(locale.Messages.REQUIRED_FIELD),
    lastName: Yup.string().required(locale.Messages.REQUIRED_FIELD),
    roles: Yup.array()
        .of(Yup.string().matches(roleRegex, locale.Messages.NOT_VALID('Role')))
        .required(locale.Messages.REQUIRED_FIELD),
    companyNames: Yup.array()
        .of(Yup.string().matches(companyRegex, locale.Messages.NOT_VALID('Company name')))
        .required(locale.Messages.REQUIRED_FIELD),
    email: Yup.string()
        .required(locale.Messages.REQUIRED_FIELD)
        .email(locale.Messages.INVALID_EMAIL),
});
