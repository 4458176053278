import React from 'react';
import { array, bool, oneOfType, number, func } from 'prop-types';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import classes from './ProgressTimeline.module.scss';
import { PREVIEW_PLACEHOLDER_URL_SHORT } from '../../constants/slides';

const ProgressTimeline = ({
    progress = 0,
    slides,
    handleSelectedSlide,
    counter,
    width,
    isPlaying,
    projectPreview,
    instagramMode,
}) => {
    const isPlaceholderSlide = slide => {
        return (
            slide.index === 0 &&
            (slide.imageUrl ? slide.imageUrl === PREVIEW_PLACEHOLDER_URL_SHORT : false)
        );
    };
    return (
        <div
            className={clsx(
                classes.progressTimeline,
                instagramMode && classes.dropShadow,
                projectPreview && classes.dropShadowUpper,
            )}
            style={{ width: width + 'px' }}
        >
            {!!Object.keys(slides).length &&
                slides.map((slide, index) => {
                    let value;
                    if (index === counter) {
                        value = progress;
                    } else if (index > counter) {
                        value = 0;
                    } else {
                        value = 100;
                    }

                    return (
                        <button
                            type="button"
                            className={classes.progressSlide}
                            key={slide.id + value}
                            onClick={() => handleSelectedSlide(index)}
                            id={slide?.id}
                            style={{
                                display: isPlaceholderSlide(slide)
                                    ? 'none'
                                    : 'inline-block',
                            }}
                        >
                            <div className={classes.clipArea}>
                                <LinearProgress
                                    classes={{
                                        colorSecondary: classes.innerProgress,
                                        bar: classes.innerProgressBar,
                                    }}
                                    color="primary"
                                    variant="determinate"
                                    value={isPlaying ? value : 0}
                                />
                            </div>
                        </button>
                    );
                })}
        </div>
    );
};

ProgressTimeline.propTypes = {
    slides: array,
    counter: number,
    progress: oneOfType([number, bool]),
    handleSelectedSlide: func,
    width: number,
    instagramMode: bool,
    projectPreview: bool,
};

export default ProgressTimeline;
