import React, { Component } from 'react';
import { arrayOf, bool, func, number } from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import CanvasPreview from '../../../components/CanvasPreview';
import classes from './StoryPreviewCarousel.module.scss';
import ProgressTimeline from '../../../components/ProgressTimeline';
import Spinner from '../../../components/Spinner';
import SlideObject from '../../../types/Slide';
import { TRANSITION_NAMES } from '../../../constants/slides';
import { VIDEOS_WRAPPER_ID } from '../../../constants/canvas';
import { createElement } from '../../../utils/common';
import InstagramMode from '../../../components/InstagramMode';
import Project from '../../../types/Project';

class StoryPreviewCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            counter: 0,
            progress: 0,
            slideDuration: 0,
            transitionDuration: 500,
            items: [],
            isLoading: true,
        };
        this.mounted = false;
        this.nextSlide = this.nextSlide.bind(this);
        this.updateProgress = this.updateProgress.bind(this);
        this.handleSelectedSlide = this.handleSelectedSlide.bind(this);
        this.updateTransitionDuration = this.updateTransitionDuration.bind(this);
    }

    updateProgress(newProgress) {
        const { progress } = this.state;
        if (newProgress > progress && progress === 0 && newProgress - progress > 10) {
            // handle progress jumping on intentional slide change
            this.mounted &&
                this.setState({
                    progress: 0,
                });
        } else {
            this.mounted &&
                this.setState({
                    progress: newProgress,
                });
        }
    }

    hideElement(element) {
        if (element?.classList) {
            element.classList.add(classes.hidden);
            element.classList.remove(classes.visible);
        }
    }

    showElement(element) {
        if (element?.classList) {
            element.classList.add(classes.visible);
            element.classList.remove(classes.hidden);
        }
    }

    // Force update of transition duration
    updateTransitionDuration(id, type = 'enter') {
        const { counter } = this.state;
        const { slides } = this.props;
        const inRange = [slides[counter]?.id, slides[counter - 1]?.id].includes(id);
        if (inRange) {
            const slideEl = document.getElementById(`slide-${id}`);
            if (slideEl && type === 'exited') {
                this.hideElement(slideEl);
            } else if (slideEl && ['enter', 'exit'].includes(type)) {
                this.showElement(slideEl);
            }
        }
    }

    async nextSlide() {
        const { counter } = this.state;
        const { slides } = this.props;
        const nextSlide = counter + 1;
        await this.handleSelectedSlide(nextSlide, nextSlide < slides.length);
    }

    async handleSelectedSlide(nextSlideIndex = 0, nonLastSlide = true) {
        const { slides, videoEnd } = this.props;
        const { counter } = this.state;
        const transitionDuration =
            slides[nextSlideIndex] &&
            slides[nextSlideIndex]?.transitionName !== TRANSITION_NAMES.None
                ? +slides[nextSlideIndex].transitionDuration * 1000
                : 1;
        const transitionName = slides[nextSlideIndex]?.transitionName ?? 'fade';
        if (
            Math.max(counter, nextSlideIndex) - Math.min(counter, nextSlideIndex) > 1 ||
            nextSlideIndex < counter
        ) {
            const prevSlideEl = document.getElementById(
                `slide-${slides[nextSlideIndex - 1]?.id}`,
            );
            // TODO Find another way?
            //  We had to handle CSSTransition dumb element exit bug
            if (prevSlideEl) {
                prevSlideEl.classList.add(
                    `canvas-${transitionName}-exit`,
                    `canvas-${transitionName}-exit-active`,
                );
                setTimeout(
                    () =>
                        prevSlideEl.classList.remove(
                            `canvas-${transitionName}-exit`,
                            `canvas-${transitionName}-exit-active`,
                        ),
                    transitionDuration,
                );
            }
        }
        await videoEnd(nonLastSlide);
        Array.from(document.querySelectorAll(`div[id^='slide-']`)).forEach(el => {
            const inRange = [
                `slide-${slides[nextSlideIndex - 1]?.id}`,
                `slide-${slides[nextSlideIndex]?.id}`,
            ].includes(el?.id);
            if (inRange) {
                this.showElement(el);
            } else {
                this.hideElement(el);
            }
        });
        this.mounted &&
            nonLastSlide &&
            this.setState(
                () => {
                    return {
                        counter: nextSlideIndex,
                        progress: 0,
                        transitionDuration,
                    };
                },
                () => setTimeout(() => this.setState({ inProp: false }), 100),
            );
    }

    componentDidMount() {
        this.mounted = true;
        this.mounted &&
            this.setState((_, prevProps) => {
                const { slides } = prevProps;
                return {
                    counter: 0,
                    progress: 0,
                    items: [...slides],
                    transitionDuration: slides[1]
                        ? +slides[1].transitionDuration * 1000
                        : 1,
                };
            });
        // Cache videos
        const videoWrapper = document.getElementById(VIDEOS_WRAPPER_ID);
        if (!videoWrapper) {
            // Create video wrapper inside DOM
            const videosWrapper = document.createElement('div');
            videosWrapper.id = VIDEOS_WRAPPER_ID;
            videosWrapper.setAttribute('class', 'hidden');
            document.body.appendChild(videosWrapper);
        }
        const { slides } = this.props;
        const cachedPromises = this.cacheVideos(slides);
        if (Array.isArray(cachedPromises) && cachedPromises.length > 0) {
            Promise.all(cachedPromises).then(
                () => this.mounted && this.setState({ isLoading: false }),
            );
        } else {
            this.setState({ isLoading: false });
        }
    }

    cacheVideos(slides) {
        const promises = [];
        if (slides && Array.isArray(slides)) {
            slides.forEach(slide => {
                const { objects } = slide.canvasData;
                const videos = objects.filter(o =>
                    Object.prototype.hasOwnProperty.call(o, 'videoSrc'),
                );
                if (videos?.length) {
                    try {
                        for (const video of videos) {
                            const { videoSrc: url, dimensions, objectId } = video;
                            const videoExists = document.getElementById(objectId);
                            if (!videoExists) {
                                const loadedPromise = createElement({
                                    url,
                                    type: 'video',
                                    id: objectId,
                                    ...dimensions,
                                }).then(videoEl => {
                                    const videosWrapper = document.getElementById(
                                        VIDEOS_WRAPPER_ID,
                                    );
                                    if (videosWrapper) {
                                        videosWrapper.appendChild(videoEl);
                                    }
                                    return new Promise(resolve => {
                                        videoEl.addEventListener(
                                            'canplaythrough',
                                            () => {
                                                resolve(true);
                                            },
                                            { once: true },
                                        );
                                    });
                                });
                                promises.push(loadedPromise);
                            } else {
                                promises.push(Promise.resolve(true));
                            }
                        }
                    } catch (e) {
                        console.error('caching Error', e);
                    }
                }
            });
            return promises;
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const {
            slides,
            transitions,
            width,
            isPlaying,
            height,
            instagramMode,
            projectId,
            replayPreview,
            project,
        } = this.props;
        const {
            isLoading,
            counter,
            progress,
            items,
            transitionDuration = 1000,
            inProp,
        } = this.state;
        const nextTransitionName = transitions[counter + 1] || '';
        const selfTransitionName = transitions[counter] || '';

        return (
            <div className={classes.storyCarousel}>
                <Spinner loading={isLoading} inverse absolute />
                {!isLoading && items && !!Object.keys(items).length && (
                    <>
                        <ProgressTimeline
                            slides={slides}
                            counter={counter}
                            progress={progress}
                            handleSelectedSlide={this.handleSelectedSlide}
                            width={width}
                            isPlaying={isPlaying}
                            instagramMode={instagramMode}
                            projectPreview
                        />
                        <div className={classes.slidesList}>
                            <div
                                style={{
                                    position: 'relative',
                                    width: width + 'px',
                                    height: height + 'px',
                                    overflow: 'hidden',
                                }}
                            >
                                {instagramMode && (
                                    <InstagramMode object={projectId && project} />
                                )}
                                <div className={classes.slidesList}>
                                    {items.map(({ id, index, canvasData, duration }) => (
                                        <CSSTransition
                                            key={id}
                                            appear={false}
                                            in={inProp || index === counter}
                                            timeout={transitionDuration}
                                            classNames={`canvas-${selfTransitionName}`}
                                            onEnter={() =>
                                                this.updateTransitionDuration(id, 'enter')
                                            }
                                            onExit={() =>
                                                this.updateTransitionDuration(id, 'exit')
                                            }
                                            onExited={() =>
                                                this.updateTransitionDuration(
                                                    id,
                                                    'exited',
                                                )
                                            }
                                        >
                                            <CanvasPreview
                                                key={id}
                                                slideId={`slide-${id}`}
                                                timeout
                                                index={index}
                                                counter={counter}
                                                slideDuration={duration}
                                                transitionDuration={transitionDuration}
                                                nextTransitionName={nextTransitionName}
                                                selfTransitionName={selfTransitionName}
                                                canvasData={canvasData}
                                                isPlayingStory={isPlaying}
                                                updateProgress={this.updateProgress}
                                                restart={replayPreview}
                                                playNextSlide={this.nextSlide}
                                                width={width}
                                                instagramMode={instagramMode}
                                                projectId={projectId}
                                            />
                                        </CSSTransition>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

StoryPreviewCarousel.propTypes = {
    slides: arrayOf(SlideObject).isRequired,
    width: number.isRequired,
    videoEnd: func.isRequired,
    isPlaying: bool,
    replayPreview: func,
    project: Project,
};

export default StoryPreviewCarousel;
