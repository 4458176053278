import * as Yup from 'yup';
import { locale } from '../../constants/locales';
import { passwordRegex } from '../../constants/validations';

export default Yup.object().shape({
    currentPassword: Yup.string()
        .required(locale.Messages.REQUIRED_FIELD)
        .matches(passwordRegex, locale.Messages.HAS_SPACES('Password')),
    newPassword: Yup.string()
        .required(locale.Messages.REQUIRED_FIELD)
        .matches(passwordRegex, locale.Messages.HAS_SPACES('Password')),
});
