import { COLORS } from '../../constants/colors';

export const styles = ({ spacing, shadows, palette }) => ({
    root: {
        padding: 0,
        position: 'relative',
    },
    paper: {
        backgroundColor: palette.background.paper,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        position: 'relative',
        overflow: 'hidden',
        boxShadow: 'none',
    },
    info: {
        width: '100%',
        padding: `${spacing(2)}px ${spacing(1)}px`,
    },
    overlay: {
        height: '32px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        width: '100%',
        padding: '8px',
        position: 'absolute',
        bottom: '-32px',
        transition: 'bottom 0.5s',
        '&:hover': {
            bottom: 0,
        },
    },
    overlayWithStatus: {
        height: '58px',
    },
    status: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        top: '-5px',
    },
    paperWrapper: {
        border: `1px solid ${COLORS.secondary}`,
        boxSizing: 'border-box',
        '&:hover': {
            boxShadow: `0 0 0 4px ${COLORS.secondary}`,
            transition: 'box-shadow 0.25s ease-out',
        },
        '&:hover $overlay': {
            bottom: 0,
        },
        '&:hover $menuButton': {
            opacity: 1,
        },
    },
    lastChanges: {
        display: 'flex',
        fontSize: '10px',
        color: COLORS.lightBlack,
        letterSpacing: '-0.5px',
        lineHeight: spacing(1.75),
        '&>span:not(:first-child)': {
            marginLeft: spacing(1),
        },
    },
    paperLock: {
        opacity: 0.4,
        cursor: 'not-allowed',
        '&:hover *': {
            transition: 'none',
        },
    },
    cover: {
        position: 'absolute',
        zIndex: 0,
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
    },
    projectType: {
        pointerEvents: 'none',
        color: COLORS.lightBlack,
        fontSize: '12px',
    },
    content: {
        position: 'relative',
        zIndex: 2,
        pointerEvents: 'none',
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    companyLogo: {
        maxWidth: spacing(5),
        display: 'block',
        height: 'auto',
        width: '100%',
    },
    projectTitle: {
        marginTop: spacing(1),
        wordBreak: 'break-word',
        height: spacing(5),
        overflow: 'hidden',
        fontSize: '14px',
        lineHeight: '20px',
    },
    menuButton: {
        position: 'absolute !important',
        right: spacing(1),
        top: spacing(1),
        width: spacing(3),
        height: spacing(3),
        padding: 0,
        zIndex: 3,
        opacity: 0,
        backgroundColor: 'rgba(255,255,255, 0.38)',
        transition: 'opacity 0.5s',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255, 0.38)',
        },
    },
    processingInfo: {
        marginLeft: spacing(),
    },
    menuList: {
        boxShadow:
            '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    },
    verticalDivider: {
        padding: `0 ${spacing(0.375)}px 0 ${spacing(0.625)}px`,
        lineHeight: 1,
    },
});
