import React, { useState } from 'react';
import { array, bool } from 'prop-types';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '../../components/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Paper from '@material-ui/core/Paper';
import { locale } from '../../constants/locales';
import { buildRolesString, buildCompanyLogos } from '../../utils/common';
import classes from './UserList.module.scss';

function UsersList(props) {
    const { list, removeAlert, go, isLoading } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const open = Boolean(anchorEl);

    function handleMenuOpen(event, user) {
        if (event) {
            event.stopPropagation();
        }
        setSelectedUser(user);
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose(event) {
        if (event) {
            event.stopPropagation();
        }
        setAnchorEl(null);
    }

    function handleGoToEditUser() {
        go(`/users/${selectedUser.id}`);
    }

    function handleRemoveUser(event) {
        if (event) {
            event.stopPropagation();
        }
        removeAlert(selectedUser);
        handleMenuClose();
    }

    if (!!Object.keys(list).length && isLoading) {
        return (
            <Typography align="center" className="noData">
                {locale.NO_DATA_AVAILABLE}
            </Typography>
        );
    }

    return (
        Object.keys(list).length && (
            <div>
                <List
                    disablePadding
                    component="ul"
                    style={{ ul: { padding: '0' } }}
                    className={classes.usersList}
                >
                    {list.map(user => (
                        <ListItem key={user.id} className={classes.usersListItem}>
                            <Paper elevation={0}>
                                <IconButton
                                    dataid={user.id}
                                    className={classes.menuButton}
                                    aria-label="More"
                                    aria-controls="actions-menu"
                                    aria-haspopup="true"
                                    onClick={event => handleMenuOpen(event, user)}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Link
                                    className={classes.userLink}
                                    to={`/users/${user.id}`}
                                >
                                    <Typography variant="h6" className={classes.userName}>
                                        {user.lastName} {user.firstName}
                                    </Typography>
                                    <div className="flexRow">
                                        {buildRolesString(user.roles)}
                                    </div>
                                    <div className="flexRow">{user?.email}</div>
                                    <div className="flexRow">
                                        {buildCompanyLogos(
                                            user.companyNames,
                                            classes.logo,
                                        )}
                                    </div>
                                </Link>
                            </Paper>
                        </ListItem>
                    ))}
                </List>
                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                >
                    <MenuItem key={locale.EDIT} onClick={handleGoToEditUser}>
                        {locale.EDIT}
                    </MenuItem>
                    <MenuItem key={locale.REMOVE} onClick={handleRemoveUser}>
                        {locale.REMOVE}
                    </MenuItem>
                </Menu>
            </div>
        )
    );
}

UsersList.propTypes = {
    list: array.isRequired,
    isLoading: bool,
};

export default UsersList;
