import React from 'react';
import { func, object } from 'prop-types';
import clsx from 'clsx';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { findCanvasItem } from '../../../utils/canvas';
import {
    LOCKED_OBJECT_DEFAULT_CONFIGURATION,
    UNLOCKED_OBJECT_DEFAULT_CONFIGURATION,
    CANVAS_TYPE_OBJECTS,
} from '../../../constants/canvas';
import classes from './LockLayerButton.module.scss';

const LockLayerButton = ({ canvas, object, saveCanvas }) => {
    const handleChange = async event => {
        if (event) {
            event.stopPropagation();
        }
        const { object: activeObject } = findCanvasItem(object?.objectId, canvas);
        if (activeObject) {
            if (activeObject.type === CANVAS_TYPE_OBJECTS.animatedTextbox) {
                activeObject.exitEditing();
            }
            activeObject.set(
                activeObject.isLocked
                    ? UNLOCKED_OBJECT_DEFAULT_CONFIGURATION
                    : LOCKED_OBJECT_DEFAULT_CONFIGURATION,
            );
        }
        canvas.discardActiveObject();
        canvas.renderAll();
        await saveCanvas(true);
    };

    return (
        <ToggleButton
            value="lock"
            selected={object?.isLocked}
            classes={{
                root: clsx(classes.lockLayer, classes.lockLayerButton),
                selected: object?.isLocked && classes.lockLayerSelected,
            }}
            disableRipple
            onChange={handleChange}
        >
            {object?.isLocked ? (
                <LockIcon fontSize="small" />
            ) : (
                <LockOpenIcon fontSize="small" />
            )}
        </ToggleButton>
    );
};

LockLayerButton.propTypes = {
    object: object,
    canvas: object,
    saveCanvas: func,
};
export default LockLayerButton;
