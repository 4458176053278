import { connect } from 'react-redux';
import TemplateSlides from './TemplateSlides';
import { getTemplates } from '../../../actions/templates';
import {
    getAvailableTemplates,
    clearAvailableTemplates,
} from '../../../actions/available-templates';
import { getCategories } from '../../../actions/categories';
import { updateIndexes, deleteSlide } from '../../../actions/slides';
import { infoNotification } from '../../../actions/notifications';

const mapStateToProps = state => ({
    authUser: state?.auth?.user,
    templates: state?.availableTemplates,
    slides: state?.slides?.list,
    projectCompanyName: state?.project?.companyName,
    categories: state?.categories?.list,
});

const mapDispatchToProps = {
    getTemplates,
    getCategories,
    updateIndexes,
    deleteSlide,
    infoNotification,
    getAvailableTemplates,
    clearAvailableTemplates,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSlides);
