import { connect } from 'react-redux';
import SideMenu from './SideMenu';

const mapStateToProps = state => ({
    isLoading: state?.loading?.show,
    user: state?.auth?.user,
    project: state?.project,
});

export default connect(mapStateToProps)(SideMenu);
