import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],
    blacklist: [
        'loading',
        'notifications',
        'template',
        'templates',
        'project',
        'projects',
        'slides',
        'processingProgress',
        'userNotifications',
        'userNotificationsHistory',
        'showDurationNotifications',
        'popup',
        'users',
        'canvas',
        'lockedItems',
        'availableTemplates',
        'categories',
        'system',
    ],
};
export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export default () => {
    const store = createStore(
        persistedReducer,
        composeEnhancers(applyMiddleware(routerMiddleware(history), thunk)),
    );
    const persistor = persistStore(store);

    return { store, persistor };
};
