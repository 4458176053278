import { CLEAR_PROJECTS, SAVE_PROJECTS } from '../actions/types';

const initialState = {
    list: [],
    loading: false,
};

export default (state = initialState, { type, projects }) => {
    switch (type) {
        case SAVE_PROJECTS:
            return {
                ...state,
                ...projects,
                loading: true,
            };
        case CLEAR_PROJECTS:
            return initialState;
        default:
            return state;
    }
};
