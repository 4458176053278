import {
    CLEAR_CANVAS_HISTORY,
    RESET_HISTORY_TO_INDEX,
    ADD_CANVAS_TO_HISTORY,
    DELETE_CANVAS_FROM_HISTORY,
    UPDATE_CANVAS_ITEM,
} from '../actions/types';

const initialState = {
    history: [],
};

export default (state = initialState, { type, canvas, index }) => {
    switch (type) {
        case ADD_CANVAS_TO_HISTORY:
            return {
                ...state,
                history: [...state.history, canvas],
            };
        case DELETE_CANVAS_FROM_HISTORY:
            return {
                ...state,
                history: [
                    ...state.history.slice(0, index),
                    ...state.history.slice(index + 1),
                ],
            };
        case RESET_HISTORY_TO_INDEX:
            return {
                ...state,
                history: [...state.history.splice(0, index + 1)],
            };
        // case SAVE_CANVAS:
        //   return {
        //     ...state,
        //     history: [
        //       ...state.history.map((item, itemIndex) => {
        //         if (itemIndex !== lastIndex) {
        //           return item;
        //         }
        //
        //         return {
        //           ...canvas,
        //         };
        //       }),
        //     ],
        //   };
        case UPDATE_CANVAS_ITEM:
            return {
                ...state,
                history: state.history.map((c, cIdx) => {
                    if (cIdx !== index) {
                        return c;
                    }

                    return {
                        ...canvas,
                    };
                }),
            };
        case CLEAR_CANVAS_HISTORY:
            return initialState;
        default:
            return state;
    }
};
