import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { array, func } from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '../../../components/Grid';
import { BUILD_FULL_FILE_PATH } from '../../../constants/slides';
import Checkbox from '../../../components/Fields/Checkbox';
import { useDimensions, useUnmount } from '../../../utils/hooks';
import { uppercaseFirstLetter } from '../../../utils/common';
import { dateShortFormat } from '../../../utils/helpers';
import { SLIDE_ASPECT_RATIO } from '../../../constants/sizes';
import { locale } from '../../../constants/locales';
import classes from './SelectTemplate.module.scss';

function SelectTemplate({ selectTemplate, templates, selectedTemplates }) {
    const [itemRef, itemSize] = useDimensions();
    const [activeTemplates, setActiveTemplates] = useState([]);

    function handleSelectTemplate(templateId) {
        const set = new Set(activeTemplates);
        if (set.has(templateId)) {
            set.delete(templateId);
        } else {
            set.add(templateId);
        }
        setActiveTemplates([...set]);
        selectTemplate([...set]);
    }

    useEffect(() => {
        if (!selectedTemplates) {
            setActiveTemplates([]);
        }
    }, [selectedTemplates]);

    useUnmount(() => {
        setActiveTemplates([]);
    });

    return (
        <div className={classes.root}>
            <Grid container className={classes.templatesList} spacing={2}>
                {!!Object.keys(templates).length &&
                    templates.map(template => {
                        const select = activeTemplates?.includes(template.id);

                        return (
                            <Grid key={template.id} item xs={6} sm={4} md={3}>
                                <Paper
                                    elevation={0}
                                    className={clsx(
                                        classes.paper,
                                        select && classes.selected,
                                    )}
                                    onClick={() => handleSelectTemplate(template.id)}
                                    title={`${template.name}`}
                                >
                                    <div
                                        ref={itemRef}
                                        className={classes.cover}
                                        style={{
                                            minHeight: itemSize?.width
                                                ? itemSize?.width / SLIDE_ASPECT_RATIO +
                                                  'px'
                                                : 'auto',
                                            backgroundImage:
                                                template?.previewUrl &&
                                                `url(${BUILD_FULL_FILE_PATH(
                                                    template.previewUrl,
                                                )}`,
                                        }}
                                    >
                                        {select && (
                                            <Checkbox
                                                checkboxClassName={classes.templateCheck}
                                                color="primary"
                                                checked={select}
                                            />
                                        )}
                                    </div>
                                    <div className={classes.content}>
                                        <Typography
                                            align="left"
                                            variant="caption"
                                            display="block"
                                        >
                                            {uppercaseFirstLetter(locale.CREATED)}:{' '}
                                            {dateShortFormat(
                                                new Date(template.createdAt),
                                                true,
                                            )}
                                        </Typography>
                                        <Typography
                                            className={classes.templateTitle}
                                            align="left"
                                            variant="h6"
                                            display="block"
                                        >
                                            {template.name}
                                        </Typography>
                                    </div>
                                </Paper>
                            </Grid>
                        );
                    })}
            </Grid>
        </div>
    );
}

SelectTemplate.propTypes = {
    templates: array.isRequired,
    selectTemplate: func,
};

export default SelectTemplate;
