import { CLEAR_PROJECT, SAVE_PROJECT } from '../actions/types';

const initialState = {};

export default (state = initialState, { type, project }) => {
    switch (type) {
        case SAVE_PROJECT:
            return {
                ...state,
                ...project,
            };
        case CLEAR_PROJECT:
            return initialState;
        default:
            return state;
    }
};
