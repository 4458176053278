import { arrayOf, oneOf, shape, string } from 'prop-types';
import { COMPANIES } from '../constants/companies';

export default shape({
    id: string,
    name: string,
    companyNames: arrayOf(oneOf([COMPANIES.OPUS, COMPANIES.SOMEDAY])),
    createdAt: string,
    updatedAt: string,
});
