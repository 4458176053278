import React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import classes from './NavigationItem.module.scss';

const NavigationItem = ({ link, children, className, onClick = () => undefined }) => {
    return (
        <NavLink
            onClick={onClick}
            className={clsx(classes.Link, className)}
            exact
            to={link}
        >
            {children}
        </NavLink>
    );
};

export default NavigationItem;
