import API from '../utils/api';
import { errorNotification, warningNotification } from './notifications';
import { getUserNotifications } from './user-notifications';
import { CLEAR_PROJECT, SAVE_PROJECT, SAVE_PROJECTS, CLEAR_PROJECTS } from './types';
import { go } from './navigation';
import { ROOT_PATHS } from '../constants/variables';
import { locale } from '../constants/locales';

const saveProject = project => ({
    type: SAVE_PROJECT,
    project,
});

const saveUserProjects = projects => ({
    type: SAVE_PROJECTS,
    projects,
});

const removeProject = () => ({
    type: CLEAR_PROJECT,
});

const clearUserProjects = () => ({
    type: CLEAR_PROJECTS,
});

export const getProjects = (query = {}) => async dispatch => {
    if (Object.keys(query).length) {
        const response = await API.getProjects({ ...query }).catch(error => {
            dispatch(errorNotification(error));
        });
        if (response) {
            if (Object.keys(response).length) {
                const {
                    items = [],
                    meta: { itemCount, totalItems, currentPage: pageCount },
                } = response;
                dispatch(
                    saveUserProjects({
                        list: items,
                        itemCount,
                        totalItems,
                        pageCount,
                    }),
                );
            }

            return response;
        }
        dispatch(errorNotification(response));

        return null;
    }

    return null;
};

export const getProjectById = id => async (dispatch, getState) => {
    const response = await API.getProjectById(id).catch(error => {
        dispatch(errorNotification(error));
        // Handle 404 error
        if (error && error.message.includes("doesn't exist")) {
            dispatch(go(ROOT_PATHS.projects));
        }
    });
    if (response) {
        // Omit empty response if user is wrong
        if (Object.keys(response).length) {
            const { auth } = getState();
            if (response.lockedBy && auth?.user?.id !== response.lockedBy.id) {
                dispatch(warningNotification(locale.LockedProjectWarning));
                dispatch(go(ROOT_PATHS.projects));
            } else {
                dispatch(saveProject(response));
            }
        }

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const createProject = values => async dispatch => {
    const response = await API.createProject(values).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        dispatch(saveProject(response));

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const updateProject = (id, data) => async dispatch => {
    const response = await API.updateProject(id, data).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const duplicationProject = id => async dispatch => {
    const response = await API.duplicationProject(id).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const deleteProject = id => async dispatch => {
    const response = await API.deleteProject(id).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        dispatch(getUserNotifications());

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const restoreProject = (query, id) => async dispatch => {
    const response = await API.restoreProject(id).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        // Update project's slides list
        dispatch(getProjects(query));

        // Update notifications list
        dispatch(getUserNotifications());

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const clearProject = () => async dispatch => {
    dispatch(removeProject());
};
export const clearProjects = () => async dispatch => {
    dispatch(clearUserProjects());
};
