import React from 'react';
import { useField } from 'formik';
import Select from '@material-ui/core/Select/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import classes from './FormikField.module.scss';

export const SelectTextField = ({
    onChangeCallback,
    label,
    className,
    InputProps,
    select,
    form: { values },
    field: { name },
    variant = 'outlined',
    MenuProps,
    ...props
}) => {
    // Skip meta destructuring
    const [input, meta, helpers] = useField({ name, ...props });
    const { error } = meta;
    const { setValue } = helpers;

    return (
        <FormControl
            variant={variant}
            className={className}
            error={Boolean(error) ?? false}
        >
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <Select
                variant={variant}
                label={label}
                {...input}
                {...props}
                MenuProps={{
                    ...MenuProps,
                    classes: {
                        paper: classes.selectMenuPaper,
                        list: classes.selectMenuList,
                    },
                }}
                onChange={event => {
                    const { value } = event.target;
                    setValue(value);
                    if (onChangeCallback != null) {
                        onChangeCallback({
                            ...values,
                            [name]: value,
                        });
                    }
                }}
            />
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};
