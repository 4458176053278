import { connect } from 'react-redux';
import Progress from './Progress';

const mapStateToProps = (state, ownProps) => ({
    progress:
        ownProps.progress >= 0
            ? ownProps.progress
            : state?.processingProgress?.list.map(i => ({
                  progress: i?.progress,
                  id: i?.project.id,
              })),
});
export default connect(mapStateToProps)(Progress);
