import React, { useCallback, useEffect, useRef, useState } from 'react';
import { func, number, object, oneOf, string } from 'prop-types';
import { COMPANIES } from '../../../constants/companies';
import { usePrevious, useUnmount } from '../../../utils/hooks';
import { WithCanvasContext } from '../../../utils/context';
import { validateFontForCanvas } from '../../../utils/canvas';
import {
    CANVAS_TYPE_OBJECTS,
    STOP_MODIFICATION_PROPERTIES,
} from '../../../constants/canvas';
import classes from './StoryCanvasPreview.module.scss';

function StoryCanvasPreview({
    projectCompanyName,
    currentSlideId,
    canvas,
    width,
    height,
    setUpCanvas,
    canvasData,
    canvasIndex,
    canvasHistoryLength,
}) {
    const isMounted = useRef(true);
    const prevCanvas = usePrevious(canvas);
    const prevCanvasData = usePrevious(canvasData);
    const prevCanvasIndex = usePrevious(canvasIndex);
    const prevSlideId = usePrevious(currentSlideId);
    const prevWidth = usePrevious(width);
    const prevHeight = usePrevious(height);

    const [rehydrated, setRehydrated] = useState(false);
    const [historyModeOn, setHistoryModeOn] = useState(false);

    const _rehydrateCanvas = useCallback(
        prevState => {
            const initZoom = zoomedCanvas => {
                if (
                    width !== prevState.canvasInitialWidth &&
                    width !== prevState.canvasCurrentWidth
                ) {
                    const scale = width / prevState.canvasInitialWidth;
                    let zoom = zoomedCanvas.getZoom();
                    zoom *= scale;
                    if (!Number.isNaN(Number(zoom)) && Number(zoom) > 0) {
                        zoomedCanvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
                    }
                    zoomedCanvas.renderAll();
                }
            };

            const canvasLoaded = async () => {
                // Update fonts to proper ones if template company was changed
                validateFontForCanvas(canvas, projectCompanyName);
                // Block modification of objects
                canvas.forEachObject(o => {
                    if (o?.clipPath?.type !== CANVAS_TYPE_OBJECTS.animatedPlaceholder) {
                        o.set(STOP_MODIFICATION_PROPERTIES);
                    }
                    // Fix for unable to select text objects on load
                    o.setCoords();
                    // if (o?.type !== CANVAS_TYPE_OBJECTS.animatedTextbox) {
                    // 	o.set({ splitByGrapheme: false });
                    // }
                });
                canvas.set({
                    backgroundColor: prevState.background,
                    savedColor: prevState.savedColor,
                    slideId: prevState.slideId,
                    projectId: prevState.projectId,
                });
                // Restore focus on last active object
                initZoom(canvas);
                isMounted.current && setUpCanvas(canvas);
                canvas.renderAll();
            };

            if (Object.keys(canvas).length) {
                canvas.loadFromJSON(prevState, canvasLoaded);
            }
        },
        [canvas, setUpCanvas, width, projectCompanyName],
    );

    useUnmount(() => {
        isMounted.current = false;
    });
    useEffect(() => {
        isMounted.current = true;
        const updateZoom = () => {
            let w = width;
            let h = height;
            const ratio = canvas.getWidth() / canvas.getHeight();
            if (width / height > ratio) {
                w = height * ratio;
            } else {
                h = width / ratio;
            }
            const scale = w / canvas.getWidth();
            let zoom = canvas.getZoom();
            zoom *= scale;
            canvas.setDimensions({ width: w, height: h });
            if (!Number.isNaN(Number(zoom)) && Number(zoom) > 0) {
                canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
            }
            canvas.renderAll();
        };

        if (prevWidth !== width || prevHeight !== height) {
            if (canvas && Object.keys(canvas).length) {
                updateZoom(canvas);
            }
        }

        // console.log(currentSlideId, prevSlideId);

        // Update on slide change
        if (currentSlideId && prevSlideId !== currentSlideId) {
            _rehydrateCanvas(canvasData);
            if (canvas && Object.keys(canvas).length) {
                canvas.renderAll();
            }
        }

        // Update canvas only at Undo/Redo operations
        if (
            prevCanvasIndex != null &&
            prevCanvasIndex !== canvasIndex &&
            canvasIndex !== canvasHistoryLength - 1
        ) {
            if (rehydrated) {
                isMounted.current && setHistoryModeOn(true);
                _rehydrateCanvas(canvasData);
                if (canvas && Object.keys(canvas).length) {
                    canvas.renderAll();
                }
            }
        }

        if (
            prevCanvasIndex != null &&
            prevCanvasIndex < canvasIndex &&
            canvasIndex === canvasHistoryLength - 1 &&
            historyModeOn
        ) {
            if (rehydrated) {
                isMounted.current && historyModeOn && setHistoryModeOn(false);
                _rehydrateCanvas(canvasData);
                if (canvas && Object.keys(canvas).length) {
                    canvas.renderAll();
                }
            }
        }

        if (prevCanvasIndex >= 0 && canvasIndex >= 0 && prevCanvasIndex !== canvasIndex) {
            if (canvas && Object.keys(canvas).length) {
                canvas.renderAll();
            }
        }

        if (canvasData != null && canvas && Object.keys(canvas).length) {
            // For proper initial load we need to check both - canvasData and canvas since of theirs
            // concurrency to be sure that we have both on mounting
            if (!rehydrated) {
                _rehydrateCanvas(canvasData);
                updateZoom();
                isMounted.current && setRehydrated(true);
            }
        }
    }, [
        _rehydrateCanvas,
        canvas,
        canvasData,
        canvasIndex,
        height,
        prevCanvas,
        prevCanvasData,
        prevCanvasIndex,
        prevHeight,
        prevWidth,
        rehydrated,
        width,
        historyModeOn,
        prevSlideId,
        currentSlideId,
        canvasHistoryLength,
    ]);

    return (
        <div className={classes.storyCanvas}>
            <canvas
                id="preview-canvas"
                width={width}
                height={height}
                className={classes.canvas}
            />
        </div>
    );
}

StoryCanvasPreview.propTypes = {
    projectCompanyName: oneOf(Object.values(COMPANIES)),
    currentSlideId: string,
    canvas: object,
    height: number,
    width: number,
    setUpCanvas: func.isRequired,
    canvasData: object,
    canvasIndex: number.isRequired,
    canvasHistoryLength: number,
};

export default WithCanvasContext(StoryCanvasPreview);
