import API from '../utils/api';
import { errorNotification, infoNotification } from './notifications';
import { showLoader, hideLoader } from './loading';
import { SAVE_SLIDES, CLEAR_SLIDES } from './types';
import { getQueueProgress } from './processingProgress';
import { PREVIEW_SLIDE_ID, SLIDE_TYPE } from '../constants/slides';

const saveProjectSlides = slides => ({
    type: SAVE_SLIDES,
    slides,
});

const clearSlides = () => ({
    type: CLEAR_SLIDES,
});

export const getSlides = id => async dispatch => {
    const response = await API.getSlides(id).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        dispatch(saveProjectSlides(response));
        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const addAsset = (file, id, projectId) => async dispatch => {
    dispatch(showLoader());
    const response = await API.addAsset(file).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        const { url, duration } = response;
        const updateData = duration ? { duration, imageUrl: url } : { imageUrl: url };
        const updatedSlide = await API.updateSlide(id, updateData);
        dispatch(hideLoader());
        dispatch(getSlides(projectId));

        return updatedSlide;
    }

    dispatch(errorNotification(response));
    dispatch(hideLoader());

    return null;
};

export const createSlide = values => async dispatch => {
    const response = await API.createSlide(values).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        // Update project's slides list only for active seller
        if (values.slideType === SLIDE_TYPE.AssetBased) {
            dispatch(getSlides(values.projectId));
        }
        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const updateSlide = (id, data, projectId, reload = true) => async (
    dispatch,
    getState,
) => {
    // TODO Find out why placeholder slide is updating
    if (id === PREVIEW_SLIDE_ID) return;
    const response = await API.updateSlide(id, data).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        // Update project's slides list
        if (reload) {
            const { project } = getState();
            // Fetch slides only for the same project!
            if (project && project.id === projectId) {
                await dispatch(getSlides(projectId));
            }
        }

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const deleteSlide = (id, projectId) => async dispatch => {
    const response = await API.deleteSlide(id).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        // Update project's slides list
        dispatch(getSlides(projectId));

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const restoreSlide = (id, projectId) => async dispatch => {
    const response = await API.restoreSlide(id).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        // Update project's slides list
        dispatch(getSlides(projectId));

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const processVideo = (projectId, type) => async dispatch => {
    const response = await API.processVideo(projectId, type).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        dispatch(infoNotification(response.message));
        dispatch(getQueueProgress());

        return response;
    }
    dispatch(errorNotification(response));

    return null;
};

export const clearAllSlides = () => async dispatch => {
    dispatch(clearSlides());
};

export const updateIndexes = (startIndex, endIndex, id) => async dispatch => {
    dispatch(showLoader());
    const response = await API.updateIndexes(startIndex, endIndex, id).catch(error => {
        dispatch(errorNotification(error));
    });
    if (response) {
        // // Update project's slides list
        dispatch(saveProjectSlides(response));
        dispatch(hideLoader());
        return response;
    }
    dispatch(errorNotification(response));
    dispatch(hideLoader());
    return null;
};
