import { monthNames } from '../constants/variables';

export const dateShortFormat = (date, short = false, prefix = true) => {
    const dateShort = new Date(date);
    const hh = String(dateShort.getHours()).padStart(2, '0');
    const mm = String(dateShort.getMinutes()).padStart(2, '0');
    const dd = String(dateShort.getDate()).padStart(2, '0');
    const m = monthNames[dateShort.getMonth()];
    const y = dateShort.getFullYear();
    if (short) return `${dd} ${m} ${y}`;

    return `${prefix ? `${hh}:${mm} | ` : ''}${dd} ${m} ${y} ${
        !prefix ? `, ${hh}:${mm}` : ''
    }`;
};

export const convertStoMS = s => {
    return s * 1000;
};

export const convertMStoS = ms => {
    return ms / 1000;
};

export const getAlphaFromRGBA = color => {
    return Math.round(color.replace(/^.*,(.+)\)/, '$1') * 100);
};

export const rgb2hex = c =>
    '#' + c.match(/\d+/g).map(x => (+x).toString(16).padStart(2, 0)).join``;

export const isMac = () => {
    return navigator?.platform.indexOf('Mac') > -1;
};

export const isWindows = () => {
    return navigator?.platform.indexOf('Win') > -1;
};
