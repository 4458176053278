import * as Yup from 'yup';
import { locale } from '../../constants/locales';

export default Yup.object().shape({
    width: Yup.number()
        .transform(cv => Number(cv))
        .typeError(locale.Messages.MUST_BE_A_TYPE('number'))
        .integer()
        .min(1, locale.Messages.MUST_BE_BIGGER_THEN(0))
        .max(99, locale.Messages.MUST_BE_EQUAL_OR_LESS(99))
        .test('defined', locale.Messages.REQUIRED_FIELD, value => value !== undefined),
});
