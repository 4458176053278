import React, { Component } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import withSizes from 'react-sizes';
import { nanoid as generate } from 'nanoid';
import CircularProgress from '@material-ui/core/CircularProgress';
import PreviewCarousel from './PreviewCarousel';
import ActionBar from '../../components/ActionBar';
import SlideObject from '../../types/Slide';
import {
    PREVIEW_SLIDE_PLACEHOLDER,
    PREVIEW_CANVAS_DATA,
    TRANSITION_DURATIONS,
    TRANSITION_NAMES,
} from '../../constants/slides';
import { SLIDE_ASPECT_RATIO } from '../../constants/sizes';
import { SLIDE_TYPE } from '../../constants/slides';
import { PROJECT_TYPES } from '../../constants/project-types';
import StoryPreviewCarousel from './StoryPreviewCarousel';
import classes from './PreviewCarousel/PreviewCarousel.module.scss';
import { VIDEOS_WRAPPER_ID } from '../../constants/canvas';

class ProjectPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            key: 0,
            isPlaying: true,
            transitions: [],
            instagramMode: true,
        };
        this.handleInstagramMode = this.handleInstagramMode.bind(this);
        this.setStateAsync = this.setStateAsync.bind(this);
        this.reload = this.reload.bind(this);
        this.videoEnd = this.videoEnd.bind(this);
    }

    setStateAsync(state) {
        return new Promise(resolve => {
            if (this.mounted) {
                this.setState(state, resolve);
            }
        });
    }

    buildTransitionsMap(slidesArr) {
        const transitionsMap = slidesArr.map(s => s.transitionName);

        return transitionsMap.filter(t => t);
    }

    handleInstagramMode() {
        this.setState({ instagramMode: !this.state.instagramMode });
    }

    buildImages(slides) {
        const transitions = this.buildTransitionsMap(slides);
        this.setState({ transitions, slides, visible: true });
    }

    async reload() {
        const { key } = this.state;
        const k = key + 1;
        await this.setStateAsync({ isPlaying: true, key: k });
    }

    async videoEnd(boolean) {
        await this.setStateAsync({ isPlaying: boolean });
    }

    componentDidMount() {
        this.mounted = true;
        const { slides } = this.props;
        if (slides[0] && slides[0].fadeIn) {
            const firstPlaceholder = {
                ...{ id: generate() },
                ...PREVIEW_SLIDE_PLACEHOLDER,
            };
            slides.unshift(firstPlaceholder);
            // Override next slide animation to "fade"
            if (slides[1]) {
                slides[1].transitionName = TRANSITION_NAMES.Fade;
                slides[1].transitionDuration = TRANSITION_DURATIONS.Normal;
            }
        }
        if (
            slides[0]?.slideType === SLIDE_TYPE.TemplateBased &&
            slides[0]?.transitionName !== TRANSITION_NAMES.None
        ) {
            const { transitionName } = slides[0];
            const firstPlaceholder = {
                ...{ id: generate() },
                ...PREVIEW_SLIDE_PLACEHOLDER,
                canvasData: PREVIEW_CANVAS_DATA,
                transitionName,
            };
            slides.unshift(firstPlaceholder);
        }
        if (slides[slides.length - 1] && slides[slides.length - 1].fadeOut) {
            const lastPlaceholder = {
                ...PREVIEW_SLIDE_PLACEHOLDER,
                ...{ id: generate() },
            };
            slides.push(lastPlaceholder);
        }
        // Update slides indexes
        // eslint-disable-next-line no-return-assign
        slides.map((s, i) => (s.index = i));
        this.buildImages(slides);
    }

    componentWillUnmount() {
        this.mounted = false;
        // Remove cached videos from DOM
        const videosWrapper = document.getElementById(VIDEOS_WRAPPER_ID);
        if (videosWrapper && videosWrapper.parentNode) {
            videosWrapper.parentNode.removeChild(videosWrapper);
        }
        const { clearAllSlides } = this.props;
        clearAllSlides();
    }

    render() {
        const {
            visible,
            slides,
            transitions,
            isPlaying,
            key,
            instagramMode,
        } = this.state;
        const {
            params: { projectId },
            width,
            height,
            projectType,
			company,
        } = this.props;
        const isStory = projectType && projectType === PROJECT_TYPES.STORY;
        const isActiveSeller = projectType && projectType === PROJECT_TYPES.ACTIVE_SELLER;

        return (
            <>
                {projectId && (
                    <ActionBar
                        projectId={projectId}
                        goBackLink={`/${projectId}/project-editor`}
                        replayPreview={this.reload}
                        isPlaying={isPlaying}
                        showReplay
                        showInstagramMode={isStory}
                        instagramMode={instagramMode}
                        setInstagramMode={this.handleInstagramMode}
                    />
                )}
                {visible ? (
                    <>
                        {isActiveSeller && (
                            <PreviewCarousel
                                key={key}
                                slides={slides}
                                transitions={transitions}
                                width={width}
                                height={height}
                                isPlaying={isPlaying}
                                videoEnd={this.videoEnd}
                                replayPreview={this.reload}
                                visible={visible}
								company={company}
                            />
                        )}
                        {isStory && (
                            <StoryPreviewCarousel
                                key={key}
                                slides={slides}
                                transitions={transitions}
                                width={width}
                                height={height}
                                isPlaying={isPlaying}
                                videoEnd={this.videoEnd}
                                replayPreview={this.reload}
                                instagramMode={instagramMode}
                                projectId={projectId}
                            />
                        )}
                    </>
                ) : (
                    <div className={classes.Preloader}>
                        <CircularProgress color="secondary" />
                    </div>
                )}
            </>
        );
    }
}

const mapSizesToProps = ({ height }) => ({
    height: height - (72 + 69 + 32),
    width: (height - (72 + 69 + 32)) * SLIDE_ASPECT_RATIO,
});

ProjectPreview.propTypes = {
    slides: arrayOf(SlideObject),
    params: shape({
        projectId: string.isRequired,
    }),
    clearAllSlides: func.isRequired,
    width: number.isRequired,
    height: number.isRequired,
    projectType: string,
	company: string.isRequired,
};

export default withSizes(mapSizesToProps)(ProjectPreview);
