export const DEFAULT_TEMPLATES_PAGE_SIZE = 8;
export const DEFAULT_PROJECTS_PAGE_SIZE = 16;
export const DEFAULT_TEMPLATES_PAGE_OPTIONS = Object.freeze([8, 16, 24]);
export const DEFAULT_PAGE_OPTIONS = Object.freeze([5, 10, 25]);
export const DEFAULT_PAGE_SIZE = 10;

export const TEMPLATES_FILTERS = Object.freeze({
    COMPANY_NAMES: 'companyNames',
    STATUSES: 'statuses',
    SEARCH: 'search',
});

export const PROJECTS_FILTERS = Object.freeze({
    SORT: 'sort',
    PROJECT_TYPE: 'projectType',
    COMPANY_NAMES: 'companyNames',
    PROJECT_STATUSES: 'projectStatuses',
});

export const PATH_TO_LOCAL_STORE = Object.freeze({
    TEMPLATES: 'templates',
    PROJECTS: 'projects',
    USERS: 'users',
});
