import { SIDEMENU_WIDTH } from '../../constants/sizes';
import { ANIMATIONS } from '../../constants/variables';
import { COLORS } from '../../constants/colors';

export const styles = ({ zIndex, spacing }) => ({
    sideMenu: {
        padding: `${spacing(3)}px 0`,
        borderTop: `1px solid ${COLORS.secondary}`,
        overflowX: 'hidden',
    },
    drawer: {
        width: SIDEMENU_WIDTH,
        flexShrink: 0,
        flex: '0 0 auto',
        whiteSpace: 'nowrap',
    },
    drawerPaper: {
        width: SIDEMENU_WIDTH,
    },
    drawerOpen: {
        width: SIDEMENU_WIDTH,
        transition: ANIMATIONS.transitionCubicBezierIn,
    },
    drawerClose: {
        overflowX: 'hidden',
        width: spacing(7) + 1,
        transition: ANIMATIONS.transitionCubicBezierOut,
    },
    logoShow: {
        width: 'auto',
        overflowX: 'auto',
        transition: ANIMATIONS.transitionCubicBezierOut,
    },
    logoHidden: {
        width: 0,
        overflowX: 'hidden',
        transition: ANIMATIONS.transitionCubicBezierOut,
    },
    drawerTop: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: spacing(9) - 1,
        width: spacing(7) + 1,
        zIndex: zIndex.drawer + 1,
        transition: ANIMATIONS.transitionCubicBezierOut,
    },
    drawerTopOpen: {
        width: '100%',
        padding: `0 ${spacing(2)}px`,
        transition: ANIMATIONS.transitionCubicBezierIn,
    },
    navOpen: {
        flexGrow: 2,
    },
    navItem: {
        position: 'relative',
        display: 'block',
        '&.active': {
            '& span': {
                display: 'block',
                fontWeight: 500,
                color: COLORS.blackMedium,
            },
            '&::after': {
                content: '""',
                position: 'absolute',
                display: 'block',
                right: 0,
                bottom: 0,
                width: spacing(0.5),
                height: '100%',
                backgroundColor: COLORS.blackMedium,
                borderRadius: '2px 0 0 2px',
            },
        },
        '&.active .MuiListItem-button': {
            backgroundColor: COLORS.blackOpacityLite,
        },
    },
    menuButton: {
        display: 'block',
        zIndex: 3,
    },
    paperAnchorDockedLeft: {
        borderRight: `1px solid ${COLORS.secondary}`,
    },
});
