import { connect } from 'react-redux';
import StoryEditor from './StoryEditor';
import { clearCanvasHistory, saveCanvasToHistory } from '../../../actions/canvas';
import { hideLoader, showLoader } from '../../../actions/loading';
import { clearDurationNotifications } from '../../../actions/duration-notifications';
import { errorNotification, warningNotification } from '../../../actions/notifications';

const mapStateToProps = state => ({
    authUser: state?.auth?.user,
    slides: state?.slides?.list,
    canvasHistory: state.canvas && state.canvas.history,
});

const mapDispatchToProps = {
    clearCanvasHistory,
    saveCanvasToHistory,
    clearDurationNotifications,
    errorNotification,
    warningNotification,
    hideLoader,
    showLoader,
};
export default connect(mapStateToProps, mapDispatchToProps)(StoryEditor);
