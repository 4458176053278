import React from 'react';
import clsx from 'clsx';
import { bool, func, number, oneOfType, object, oneOf, string } from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import classes from './Checkbox.module.scss';

/**
 * @param checked -   If true, the component is checked.
 * @param color - 	  The color of the component. It supports those theme colors that make sense for this component.
 * @param onChange - 	Callback fired when the state is changed.
 * @param className - Custom class.
 * @param checkboxClassName - Custom class for checkbox.
 * @param classesParents
 * @param label - 		The label of the component.
 * @param labelBold - 		Add font weight for component's label.
 * @param labelPlacement - The position of the label.
 * @param fullWidth -  width for form control.
 * @param rest - 		  Other props.
 * */

function CheckboxWrapper({
    checked,
    color,
    className,
    classes: classesParents,
    onChange,
    label,
    labelBold,
    labelPlacement,
    checkboxClassName,
    fullWidth,
    ...rest
}) {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={onChange}
                    label={label}
                    color={color}
                    classes={{
                        ...classesParents,
                        root: clsx(
                            classes.checkboxInput,
                            checkboxClassName,
                            labelPlacement === 'start' && classes.marginRight,
                        ),
                        colorSecondary: classes.colorSecondary,
                    }}
                    {...rest}
                />
            }
            label={label}
            labelPlacement={labelPlacement}
            classes={{
                root: clsx(
                    classes.checkbox,
                    labelBold && classes.labelBold,
                    className,
                    fullWidth && classes.fullWidth,
                ),
                labelPlacementStart: classes.labelPlacementStart,
            }}
        />
    );
}

CheckboxWrapper.propTypes = {
    className: oneOfType([object, string]),
    checkboxClassName: oneOfType([object, string]),
    value: oneOfType([string, number, bool]),
    name: string,
    color: oneOf(['primary', 'secondary']),
    labelLite: bool,
    labelPlacement: oneOf(['end', 'start', 'top', 'bottom']),
    checked: bool,
    required: bool, // If true, the input element will be required.
    disabled: bool, // If true, the switch will be disabled.
    label: string,
    onChange: func,
    fullWidth: bool,
};

export default CheckboxWrapper;
