import { connect } from 'react-redux';
import TemplatesList from './TemplatesList';
import {
    publishTemplate,
    archiveTemplate,
    deleteTemplate,
    duplicateTemplate,
} from '../../actions/templates';
import { getCategories } from '../../actions/categories';
import { handleStartItemEditing } from '../../actions/lockedItems';

const mapStateToProps = state => ({
    authUser: state.auth && state.auth.user,
    categories: state?.categories?.list,
});

const mapDispatchToProps = {
    publishTemplate,
    archiveTemplate,
    duplicateTemplate,
    handleStartItemEditing,
    deleteTemplate,
    getCategories,
};
export default connect(mapStateToProps, mapDispatchToProps)(TemplatesList);
