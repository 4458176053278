import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useField } from 'formik';
import MuiSwitch from '@material-ui/core/Switch/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';

export const SwitchField = ({
    label,
    fullWidth,
    labelPlacement,
    className,
    form,
    field: { name },
    ...props
}) => {
    const [input, meta] = useField({ name, ...props });
    const { error, touched } = meta;
    return (
        <>
            <FormControlLabel
                labelPlacement={labelPlacement}
                className={className}
                control={<MuiSwitch {...input} {...props} color="primary" />}
                label={label}
            />
            {touched && error && <FormHelperText error>{error}</FormHelperText>}
        </>
    );
};
