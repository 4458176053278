import { useRef, useEffect, useState, useCallback, useLayoutEffect } from 'react';

export const useEffectOnce = effect => {
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    useEffect(effect, []);
};

export const useMount = fn => {
    useEffectOnce(() => {
        fn();
    });
};

export const useUnmount = fn => {
    useEffectOnce(() => fn);
};

export const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

function getDimensionObject(node) {
    const rect = node.getBoundingClientRect();

    return {
        width: rect.width,
        height: rect.height,
        top: 'x' in rect ? rect.x : rect.top,
        left: 'y' in rect ? rect.y : rect.left,
        x: 'x' in rect ? rect.x : rect.left,
        y: 'y' in rect ? rect.y : rect.top,
        right: rect.right,
        bottom: rect.bottom,
    };
}

export function useDimensions({ liveMeasure } = {}) {
    const [dimensions, setDimensions] = useState({});
    const [node, setNode] = useState(null);

    const ref = useCallback(nodrRef => {
        setNode(nodrRef);
    }, []);

    // eslint-disable-next-line consistent-return
    useLayoutEffect(() => {
        if (node) {
            const measure = () =>
                window.requestAnimationFrame(() =>
                    setDimensions(getDimensionObject(node)),
                );
            measure();

            if (liveMeasure) {
                window.addEventListener('resize', measure);
                window.addEventListener('scroll', measure);

                return () => {
                    window.removeEventListener('resize', measure);
                    window.removeEventListener('scroll', measure);
                };
            }
        }
    }, [node, liveMeasure]);

    return [ref, dimensions, node];
}
