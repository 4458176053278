import { connect } from 'react-redux';
import { loginUser } from '../../actions/auth';
import { clearNotifications } from '../../actions/notifications';
import Login from './Login';

const mapStateToProps = state => ({
    isLoggedIn: state?.auth?.isLoggedIn,
    user: state?.auth?.user,
});

const mapDispatchToProps = {
    loginUser,
    clearNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
