import { STATUSES_PROJECT } from '../../constants/statuses';

import { connect } from 'react-redux';
import ActionBar from './ActionBar';
import { go, back } from '../../actions/navigation';
import { clearAllSlides, processVideo } from '../../actions/slides';
import {
    archiveTemplate,
    publishTemplate,
    getTemplateById,
} from '../../actions/templates';

const mapStateToProps = state => ({
    slides: state?.slides?.list,
    projectVideo:
        state?.project?.status !== STATUSES_PROJECT.FAILED
            ? state?.project?.videoUrl
            : null,
    template: state.template,
});

const mapDispatchToProps = {
    clearAllSlides,
    go,
    back,
    processVideo,
    getTemplateById,
    publishTemplate,
    archiveTemplate,
};
export default connect(mapStateToProps, mapDispatchToProps)(ActionBar);
