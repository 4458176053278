import { connect } from 'react-redux';
import UserCreate from './UserCreate';
import { createUser } from '../../actions/users';

const mapStateToProps = state => ({
    user: state.auth && state.auth.user,
});

const mapDispatchToProps = {
    createUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate);
